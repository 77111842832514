import { useColorMode, usePreferredColorScheme } from '@vueuse/core'
import { computed } from 'vue'

export function useTheme() {
  const theme = useColorMode({
    attribute: 'data-theme',
    selector: 'body',
    storageKey: 'vueuse-color-scheme',
    emitAuto: true,
  })

  /** The system-level prefered theme */
  const themePreference = usePreferredColorScheme()

  // Define a method to set the theme
  const setTheme = (value: 'dark' | 'light' | 'auto') => {
    theme.value = value
  }

  /** The theme currently used in the app */
  const actualTheme = computed<'light' | 'dark'>(() => {
    if (theme.value === 'auto') {
      // If the user has indicated no preference at either a system
      // level or within Go, default to light mode
      return themePreference.value === 'no-preference' ? 'light' : themePreference.value
    }

    return theme.value
  })

  return { theme, setTheme, actualTheme, themePreference }
}
