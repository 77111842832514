import type { components } from '@/api'
import { post } from './client'

export type CreateInvitationsPath = `/api/workspaces/${string}/invitations`
export type CreateInvitationsResponse =
  components['schemas']['Invitations.CreateInvitationResponse']
export type CreateInvitationsRequest = components['schemas']['Invitations.CreateInvitationRequest']

export type CreateInvitationsParams = {
  workspaceId: string
  invitations: CreateInvitationsRequest['invitations']
}

export const createInvitations = ({ workspaceId, invitations }: CreateInvitationsParams) =>
  post<CreateInvitationsRequest, CreateInvitationsResponse, CreateInvitationsPath>(
    `/api/workspaces/${workspaceId}/invitations`,
    {
      invitations,
    },
  )
