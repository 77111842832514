import type { operations } from '@/api'
import { remove } from './client'

export type RemoveViewPath = `/api/workspaces/${string}/projects/${string}/views/${string}`
export type RemoveViewResponse =
  operations['project-delete-view']['responses']['200']['content']['application/json']

export const removeView = (workspaceId: string, projectId: string, viewId: string) =>
  remove<undefined, RemoveViewResponse, RemoveViewPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/views/${viewId}`,
    undefined,
  )
