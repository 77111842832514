<script lang="ts" setup>
import { TYPE_ICON } from '@/modules/Project/icons'
import { useAskGo } from '@/modules/Project/useAskGo'
import { useProject } from '@/modules/Project/useProject'
import { computed } from 'vue'
import BadgeItem from './BadgeItem.vue'

const props = defineProps<{
  text: string
}>()

const REGEX = /\[.+?\]\(P<[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}>\)/g

type TextPart = {
  type: 'text' | 'highlight' | 'newline'
  content: string
  metadata?: {
    propertyId?: string
  }
}

const textParts = computed((): TextPart[][] => {
  // Computes an array of `TextPart` objects starting from raw text.
  // Example:
  // - Input: "Property mentioned: [Task Id](P<0190d019-3e09-7275-be36-e549858d085c>)"
  // - Output: [
  //   { type: 'text', content: 'Property mentioned: ' },
  //   { type: 'highlight', content: 'Task Id' },
  // ]

  const parts: TextPart[] = []
  let match: RegExpExecArray | null
  let lastIndex = 0

  while ((match = REGEX.exec(props.text)) !== null) {
    const index = match.index

    if (index > lastIndex) {
      parts.push({
        type: 'text',
        content: props.text.slice(lastIndex, index),
      })
    }

    const fullMatch = match[0]
    // Find the test within squared brackets
    const propertyName = fullMatch.match(/\[(.*?)\]/)?.[1]
    if (!propertyName) throw new Error('Property name not found')

    // Find the text within round brackets
    const propertyId = fullMatch.match(/\((.*?)\)/)?.[1].slice(2, -1)
    if (!propertyId) throw new Error('Property ID not found')

    parts.push({
      type: 'highlight',
      content: propertyName,
      metadata: {
        propertyId,
      },
    })

    lastIndex = index + fullMatch.length
  }

  if (lastIndex < props.text.length) {
    parts.push({
      type: 'text',
      content: props.text.slice(lastIndex),
    })
  }

  const textParts: TextPart[] = []
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].type === 'text') {
      const subLines = parts[i].content.replaceAll('**', '').split('\n\n')
      subLines.forEach((subLine, j) => {
        if (subLine.length > 0) {
          textParts.push({ type: 'text', content: subLine })
          if (j < subLines.length - 1) {
            textParts.push({ type: 'newline', content: '' })
          }
        } else {
          textParts.push({ type: 'newline', content: '' })
        }
      })
    } else {
      textParts.push(parts[i])
    }
  }

  const pNodes: TextPart[][] = []
  let currentTextParts: TextPart[] = []
  for (const textPart of textParts) {
    if (textPart.type === 'newline') {
      pNodes.push(currentTextParts)
      currentTextParts = []
    } else {
      currentTextParts.push(textPart)
    }
  }
  // At the end of this loop, we might have some text parts left in `currentTextParts`
  if (currentTextParts.length > 0) {
    pNodes.push(currentTextParts)
  }

  return pNodes
})

const projectStore = useProject()

const propertyIcon = (propertyId?: string) => {
  if (!propertyId) return 'blank'

  const property = projectStore.properties.find((p) => p.id === propertyId)
  return property?.type ? TYPE_ICON[property.type] : 'blank'
}

const askGoStore = useAskGo()

const highlightProperty = (propertyId?: string) => {
  askGoStore.highlightProperty(propertyId)
}

const onClick = (propertyId?: string) => {
  projectStore.setSelectedProperty(propertyId || null)
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <p
      v-for="(node, i) in textParts"
      :key="`node-${i}`"
      :style="{
        fontFeatureSettings: `'ss03' on, 'ss01' on, 'tnum' on`,
      }"
      class="break-words"
    >
      <template
        v-for="(part, j) in node"
        :key="`part-${j}`"
      >
        <span
          v-if="part.type === 'text'"
          class="whitespace-pre-wrap"
        >
          {{ part.content }}
        </span>
        <span
          v-else
          class="inline-flex items-center"
        >
          <BadgeItem
            class="inline-block cursor-pointer"
            :label="part.content"
            :leading-icon="propertyIcon(part.metadata?.propertyId)"
            size="xs"
            variant="blue"
            @click="onClick(part.metadata?.propertyId)"
            @mouseover="highlightProperty(part.metadata?.propertyId)"
            @mouseleave="highlightProperty()"
          />
        </span>
      </template>
    </p>
  </div>
</template>
