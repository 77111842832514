<script setup lang="ts">
import IconSprite from '@/uiKit/IconSprite.vue'
import { useGroundingToggle } from './useGroundingToggle'

const { groundingConditions } = useGroundingToggle()
</script>

<template>
  <div
    class="max-w-[300px]"
    data-test="grounding-tooltip"
  >
    <p>The following conditions must be met in order to enable AI Citations:</p>
    <ul
      class="mt-2 flex flex-col gap-1"
      data-theme="dark"
    >
      <li
        v-for="{ fulfilled, label } in groundingConditions"
        :key="label"
        class="flex items-center text-text-subtle"
        :class="{ 'text-text-success': fulfilled }"
      >
        <div class="relative size-4">
          <IconSprite
            v-if="fulfilled"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            icon="check"
          />
          <div
            v-else
            class="absolute left-1/2 top-1/2 size-[3px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-text-subtle"
          />
        </div>
        {{ label }}
      </li>
    </ul>
  </div>
</template>
