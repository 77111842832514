import { computed, type ComputedRef, type Ref } from 'vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { type Field, type Property } from './useProject'
import { useSubProject } from './useSubProject'

export type Staleness = 'stale' | 'maybe-stale' | null

/**
 * When a field is stale, users are given the option to recompute its value.
 *
 * A field is definitely stale if the field's property has changed since the field was last
 * computed.
 *
 * A field is maybe stale if the field's property has not changed, but a related project
 * has been updated since the field was last computed, where a related project could be
 * a collection subproject, or a reference property's related project.
 */
export const useFieldStaleness = (
  field: Ref<Field | undefined>,
  property: Ref<Property | undefined>,
): ComputedRef<Staleness> => {
  const relatedProjectStore = useRelatedProjectsStore()
  const subprojectStore = useSubProject()
  return computed(() => {
    if (
      !field.value ||
      !property.value ||
      field.value.manualValue !== null || // manual values can never be stale
      field.value.status !== 'complete' // only allow a recompute once the tool has completed its inference
    ) {
      return null
    }

    if (field.value.propertyHash !== property.value?.hash) {
      return 'stale'
    }

    /** IDs of all reference properties whose related projects should be checked */
    const referenceInputs = property.value.inputs.reduce<string[]>((acc, input) => {
      if (input.viaPropertyId) {
        acc.push(input.viaPropertyId)
      }
      return acc
    }, [])
    const relatedProjects = referenceInputs.map(relatedProjectStore.getRelatedProject)

    const allInputPropertyIds = property.value.inputs.map((input) => input.propertyId)
    const collectionSubprojects = Object.values(subprojectStore.projects).filter((subproject) => {
      if (!subproject) {
        return false
      }

      return subproject.properties.some((property) => allInputPropertyIds.includes(property.id))
    })

    const downstreamProjects = [...relatedProjects, ...collectionSubprojects]
    const isStaleDownstream = downstreamProjects.some(
      (p) => p && field.value?.updatedAt && p.updatedAt > field.value.updatedAt,
    )

    if (isStaleDownstream) {
      return 'maybe-stale'
    }

    return null
  })
}
