<script setup lang="ts">
import { addFilesToInput } from '@/shared/utils/file'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import { captureException } from '@sentry/vue'
import { useWelcomeTour } from './useWelcomeTour'

function onUploadData() {
  const dropzone = document.querySelector('[data-part="dropzone"]')
  if (!dropzone) return
  dropzone.dispatchEvent(new Event('click'))
}

const welcomeTour = useWelcomeTour()
function uploadSampleData(file: string) {
  welcomeTour.addExtraAnalyticsInfo({ sampleData: file })
  // Get the file input to which the file should be added. We will
  // programatically trigger an `input` event on this element.
  const input = document.querySelector('[data-part="dropzone"] input[type="file"]')
  if (!(input instanceof HTMLInputElement)) {
    captureException(new Error('Input not found when uploading sample data'))
    return
  }
  addFilesToInput(file, input)
}

type Datum = {
  img: string
  label: string
  file: string
}
const data: Datum[] = [
  {
    img: '/welcome/invoice.svg',
    label: 'Invoice',
    file: '/welcome/invoice.pdf',
  },
  {
    label: 'Bill of lading',
    img: '/welcome/bill-of-lading.svg',
    file: '/welcome/bill-of-lading.pdf',
  },
  { label: 'Legal Doc', img: '/welcome/legal-doc.svg', file: '/welcome/legal-doc.pdf' },
]
</script>

<template>
  <DarwinButton
    variant="black"
    size="sm"
    class="mt-4 w-full"
    @click="onUploadData"
  >
    Upload data
  </DarwinButton>
  <DividerLine
    class="mt-4"
    :width="1"
    color="subtle"
  >
    <span class="text-xs-11px-default text-text-subtlest">or pick sample data</span>
  </DividerLine>
  <div class="mt-4 flex items-center justify-center gap-3">
    <button
      v-for="datum in data"
      :key="datum.file"
      class="flex grow flex-col items-center gap-3 rounded-corner-8 bg-background-gray-subtlest py-4 transition hover:bg-background-gray-subtlest-hovered active:bg-background-gray-subtlest-pressed"
      @click="uploadSampleData(datum.file)"
    >
      <img
        :src="datum.img"
        alt=""
      />
      <p class="text-sm-12px-light text-text-subtle">{{ datum.label }}</p>
    </button>
  </div>
</template>
