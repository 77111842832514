import { get } from './client'
import type { BackendMeta } from './types'

export type ListToolIntegrations = BackendMeta<
  '/api/workspaces/{workspace_id}/tool_integrations',
  'get'
>

export const listToolIntegrations = (workspaceId: string) =>
  get<undefined, ListToolIntegrations['successResponse'], ListToolIntegrations['path']>(
    `/api/workspaces/${workspaceId}/tool_integrations`,
    undefined,
  )
