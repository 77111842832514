type TryUntilItWorksOptions<Fn extends () => Promise<unknown>> = {
  isValid: (res: Awaited<ReturnType<Fn>>) => boolean
  maxTries: number
  waitBetweenTries?: number
}

export async function tryUntilItWorks<Fn extends () => Promise<unknown>>(
  fn: Fn,
  options: TryUntilItWorksOptions<Fn>,
) {
  const { isValid, maxTries, waitBetweenTries } = { waitBetweenTries: 0, ...options }
  let result: Awaited<ReturnType<Fn>> | undefined = undefined

  for (let i = 0; i < maxTries; i++) {
    try {
      result = (await fn()) as Awaited<ReturnType<Fn>>
      if (isValid(result)) break
    } finally {
      await new Promise((resolve) => setTimeout(resolve, waitBetweenTries))
    }
  }

  return result
}
