import { post } from './client'
import type { BackendMeta } from './types'

export type SetIntegrationEnabled = BackendMeta<
  '/api/workspaces/{workspace_id}/tool_integrations/toggle',
  'post'
>

export const setIntegrationEnabled = ({
  workspaceId,
  provider,
  enabled,
}: {
  workspaceId: SetIntegrationEnabled['pathParams']['workspace_id']
  provider: SetIntegrationEnabled['body']['integration']
  enabled: SetIntegrationEnabled['body']['enabled']
}) =>
  post<
    SetIntegrationEnabled['body'],
    SetIntegrationEnabled['successResponse'],
    SetIntegrationEnabled['path']
  >(`/api/workspaces/${workspaceId}/tool_integrations/toggle`, {
    enabled,
    integration: provider,
  })
