<script setup lang="ts">
import { ref } from 'vue'
import type { ID } from './Filters/FilterItemMatcher.vue'
import {
  getFilterSubjectForProperty,
  type FieldValueFilter,
  type FilterableProperty,
} from './Filters/types'
import ModelInputMenuFiltersFilterTemplate from './ModelInputMenuFiltersFilterTemplate.vue'
/**
 * Renders an 'in progress' filter - one that has no property yet. When a property
 * is selected, a full filter object is emitted and this component is replaced with
 * a fully functional filter component.
 */

defineProps<{
  properties: FilterableProperty[]
}>()

const emit = defineEmits<{
  (e: 'create', filter: FieldValueFilter): void
}>()

const selectedProperty = ref<FilterableProperty | null>(null)
const matcherName: ID = 'property_any_of'

const onSelectProperty = (property: FilterableProperty) => {
  const subject = getFilterSubjectForProperty(property.type)
  const filter: FieldValueFilter = {
    subject,
    matcher: {
      name: matcherName,
      property_id: property.id,
      values: [],
    },
  }
  emit('create', filter)
}
</script>

<template>
  <ModelInputMenuFiltersFilterTemplate
    :properties="properties"
    :selected-matcher="matcherName"
    :selected-property="selectedProperty"
    @update:property="onSelectProperty"
  />
</template>
