export const useIntercom = () => {
  const showIntercom = () => {
    if (!window.Intercom) {
      throw Error('Intercom script not loaded')
    }
    window.Intercom('show')
  }

  const startTour = (tourId: number) => {
    if (window.Intercom) {
      window.Intercom('startTour', tourId)
    }
  }

  return {
    showIntercom,
    startTour,
  }
}
