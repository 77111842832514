<script setup lang="ts">
import { getEntity } from '@/backend/getEntity'
import { invariant } from '@/shared/utils/typeAssertions'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { computed, ref, useId, watch } from 'vue'
import type { Project } from '../Projects/useProjects'
import EntityGrid from './EntityGrid.vue'
import ProjectBadge from './ProjectBadge.vue'
import { useEntityGrid } from './useEntityGrid'
import { serializeEntity, type Entity, type Property } from './useProject'
import { useResolveEntityRoute } from './useResolveEntityRoute'
import { getCoverImageUrl } from './utils'

/**
 * This component renders a dialog that shows a single entity, with the entity's fields
 * being rendered in the same grid used in the project's entity view.
 */

const props = defineProps<{
  /** Is used to open the dialog at a given entity  */
  intialEntityId: string | null
  /**
   * All entities that have been loaded already by the parent. When using this, the
   * dialog will not make a backend request to load an entity if it has already been
   * loaded.
   */
  initialLoadedEntities: Entity[]
  /** IDs of all entities that the user can navigate between. */
  entityIds: string[]
  /** All properties on the project to which the entity belongs */
  properties: Property[]
  /** The project to which the entity belongs */
  project: Project
}>()

defineEmits<{
  (e: 'close'): void
}>()

/** All entities that have been loaded already. Is used for caching. */
const loadedEntities = ref<Entity[]>(props.initialLoadedEntities)
watch(
  () => props.initialLoadedEntities,
  (newEntities) => {
    loadedEntities.value = newEntities
  },
)

/** ID of the entity to show in the dialog */
const entityId = ref<string | null>(props.intialEntityId)
watch(
  () => props.intialEntityId,
  (newId) => {
    entityId.value = newId
  },
)

const { workspaceId } = useRouteParams()

/** Entity to show in the dialog */
const entity = ref<Entity | null>(null)

/**
 * When the id changes:
 * 1. If the entity is already loaded, use it without fetching from the API
 * 2. If the entity is not loaded, load it from the API
 */
watch(
  entityId,
  async (entityId) => {
    if (!entityId) {
      entity.value = null
      return
    }

    const fromLoadedEntities = loadedEntities.value.find((e) => e.id === entityId)
    if (fromLoadedEntities) {
      entity.value = fromLoadedEntities
      return
    }

    const res = await getEntity(workspaceId.value, props.project.id, entityId)
    if (!res.ok) {
      throw new Error('Failed to load full entity for reference dialog')
    }

    const serialized = serializeEntity(res.data)
    loadedEntities.value.push(serialized)
    entity.value = serialized
  },
  {
    immediate: true,
  },
)

const { layoutItems } = useEntityGrid({
  entity,
  properties: computed(() => props.properties),
  view: computed(() => (props.project.views ? props.project.views[0] : undefined)),
})

const currentEntityIndex = computed<number>(() => {
  if (!entityId.value) {
    return -1
  }

  return props.entityIds.indexOf(entityId.value)
})

const onSelectIndex = (index: number) => {
  invariant(index >= 0 && index < props.entityIds.length, 'Invalid index')
  entityId.value = props.entityIds[index]
}

const onClose = () => {
  entityId.value = null
}

const labelId = useId()

const resolveEntityRoute = useResolveEntityRoute()
</script>

<template>
  <ModalDialog
    :open="!!entity"
    background-blur
    class="go-scrollbar size-[calc(100%-48px)] max-h-[calc(100%-48px)] min-h-[calc(100%-48px)] overflow-auto bg-surface-secondary-persist"
    :aria-labelledby="labelId"
    @close.stop.prevent="onClose"
  >
    <div class="mx-2 mt-2 grid h-7 grid-cols-[1fr,auto,1fr] text-sm-12px-default text-text-subtle">
      <div class="flex items-center gap-1.5">
        <IconButton
          icon="close"
          size="lg"
          variant="transparent"
          aria-label="Close dialog"
          @click.stop.prevent="onClose"
        />
        <div
          :id="labelId"
          class="contents"
        >
          Entity view from
          <ProjectBadge
            :cover-image-url="getCoverImageUrl(project, 'low')"
            :name="project.name"
          />
        </div>
      </div>
      <div class="flex items-center gap-3">
        <template v-if="entityId && entityIds.includes(entityId)">
          <IconButton
            icon="chevron-bottom"
            size="lg"
            variant="transparent"
            aria-label="Previous entity"
            :disabled="currentEntityIndex === 0"
            @click="onSelectIndex(currentEntityIndex - 1)"
          />
          <div>{{ currentEntityIndex + 1 }}/{{ entityIds.length }}</div>
          <IconButton
            icon="chevron-top"
            size="lg"
            variant="transparent"
            aria-label="Next entity"
            :disabled="currentEntityIndex === entityIds.length - 1"
            @click="onSelectIndex(currentEntityIndex + 1)"
          />
        </template>
      </div>
      <div class="flex justify-end">
        <DarwinButton
          v-if="entityId"
          variant="transparent"
          size="sm"
          :to="
            resolveEntityRoute({
              projectId: project.id,
              entityId,
              workspaceId,
            })
          "
        >
          Open in {{ project.name }}
          <template #trailing-icon>
            <IconSprite icon="arrow-top-right" />
          </template>
        </DarwinButton>
      </div>
    </div>
    <EntityGrid
      v-if="entity"
      :entity="entity"
      :layout-items="layoutItems"
      :can-edit-layout="false"
      :project-id="project.id"
      readonly
    />
  </ModalDialog>
</template>
