import type { components } from '@/api'
import { get } from './client'

export type ListSessionsPath = `/api/workspaces/${string}/ask_go`
export type ListSessionsResponse = components['schemas']['AskGo.ListSessionsResponse']
export type ListSessionsRequest = undefined

export const listSessions = (params: { workspaceId: string }) =>
  get<ListSessionsRequest, ListSessionsResponse, ListSessionsPath>(
    `/api/workspaces/${params.workspaceId}/ask_go`,
    undefined,
  )
