import type { components, operations } from '@/api'
import { put } from './client'

export type UpdateWorkspacePath = `/api/workspaces/${string}`
export type UpdateWorkspaceRequest = components['schemas']['Workspaces.UpdateWorkspaceRequest']
export type UpdateWorkspaceResponse =
  operations['workspace-update']['responses']['200']['content']['application/json']

export const updateWorkspace = (
  workspaceId: string,
  {
    name,
    toltReferral,
  }: {
    name?: string
    toltReferral?: string
  },
) => {
  const params = {
    name,
    ...(toltReferral ? { metadata: { tolt_referral: toltReferral } } : {}),
  }
  return put<UpdateWorkspaceRequest, UpdateWorkspaceResponse, UpdateWorkspacePath>(
    `/api/workspaces/${workspaceId}`,
    params,
  )
}
