import { updateView } from '@/backend/updateView'
import { computed } from 'vue'
import { useEntity } from './useEntity'
import { useEntityGrid } from './useEntityGrid'
import { useProject, type PropertyLayout } from './useProject'

export function useEntityView() {
  const entityStore = useEntity()
  const projectStore = useProject()

  const { layoutInfo, layoutItems } = useEntityGrid({
    entity: computed(() => entityStore.entity),
    view: computed(() => projectStore.activeView?.view),
    properties: computed(() => projectStore.properties),
  })

  type UpdateItemSizeArgs = {
    propertyId: string | number
    workspaceId: string
    projectId: string
    layout?: Pick<PropertyLayout, 'x' | 'y' | 'width' | 'height'>
  }

  const updateItemSize = async ({
    propertyId,
    workspaceId,
    projectId,
    layout: newLayout,
  }: UpdateItemSizeArgs) => {
    const item = layoutInfo.value.find((i) => i.i === propertyId)
    if (!item) return

    if (!projectStore.activeView) return

    // Build new layouts list
    const newLayouts = projectStore.activeView.view.propertyLayouts.map((layout) => {
      if (newLayout) {
        return {
          ...layout,
          ...newLayout,
        }
      }

      const layoutItem = layoutItems.value.find((item) => item.property?.id === layout.propertyId)
      if (!layoutItem) {
        return layout
      }

      return {
        ...layout,
        x: layoutItem.x,
        y: layoutItem.y,
        width: layoutItem.w,
        height: layoutItem.h,
      }
    })

    await updateView({
      workspaceId,
      projectId,
      viewId: projectStore.activeView.view.id,
      name: projectStore.activeView.view.name,
      propertyIds: projectStore.activeView.view.propertyIds ?? [],
      propertyLayouts: newLayouts,
      filters: projectStore.activeView.view.filters,
      propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    })
  }

  return { layoutItems, updateItemSize }
}
