import { isHtmlTextAreaElement, isMac } from '@/shared/utils'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import type DarwinButton from '@/uiKit/DarwinButton.vue'
import type { Placement } from '@floating-ui/vue'
import { defineStore } from 'pinia'
import {
  computed,
  readonly,
  ref,
  toValue,
  watch,
  watchEffect,
  type Component,
  type MaybeRefOrGetter,
} from 'vue'
import { useRouter } from 'vue-router'
import { useAskGo } from '../Project/useAskGo'
import { useProject } from '../Project/useProject'
import { useProjects } from '../Projects/useProjects'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import SampleDataComponent from './SampleData.vue'
import type { Coordinates } from './types'
import { useCreateWelcomeProject } from './useCreateWelcomeProject'
import { useElement } from './useElement'
import { useElementCoordinates } from './useElementCoordinates'
import { useElementValue } from './useElementValue'

type Step = {
  title: string
  description: string
  next?: {
    text?: string
    action?: () => void
    disabled?: MaybeRefOrGetter<boolean>
    variant?: InstanceType<typeof DarwinButton>['$props']['variant']
  }
  init?: () => void
  coordinates: MaybeRefOrGetter<Coordinates | undefined>
  isComplete?: MaybeRefOrGetter<boolean>
  component?: Component
  onClickOutside?: () => void
  placement?: Placement
}

export type WelcomeTourStatus =
  | 'IDLE'
  | 'CREATING_PROJECT'
  | 'BEFORE_ZOOM'
  | 'ZOOMING'
  | 'IN_PROGRESS'
  | 'COMPLETED'

export const useWelcomeTour = defineStore('newOnboarding', () => {
  // Initialize composables
  const projectStore = useProject()
  const projects = useProjects()
  const {
    status: projectCreationStatus,
    createWelcomeProject,
    welcomeProject,
    reset,
  } = useCreateWelcomeProject()
  const workspaceStore = useWorkspaces()
  const router = useRouter()
  const { captureAnalyticsEvent } = useAnalytics()

  // Steps
  const selectors = {
    addProperty: '#add-property',
    firstHeader: '[role="columnheader"][aria-rowindex="1"]',
    addPropertyMenu: '[data-test="add-property-menu"]',
    textProperty: '[data-test="add-property-menu"] [role="menuitem"]:has([aria-label="Text"])',
    prompt: 'textarea[aria-label="Prompt"]',
    openAskGoTopbar: '[data-test="open-ask-go-topbar"]',
    askGoTopbar: '[data-test="ask-go-topbar"]',
    askGoSidebar: '[data-test="ask-go-sidebar"]',
  } as const

  const addPropertyMenu = useElement(selectors.addPropertyMenu)
  const askGoTopbar = useElement(selectors.askGoTopbar)
  const prompt = useElement(selectors.prompt)
  const promptValue = useElementValue(prompt)

  const tours: Record<string, Step[]> = {
    welcome: [
      {
        title: 'Upload your data',
        description:
          "File properties are used to store your data so you can power the output of an AI model. First, let's upload an example file, like a PDF, image or CSV",
        coordinates: useElementCoordinates(selectors.firstHeader, {
          override: ({ coords, rect }) => {
            return {
              ...coords,
              y1: coords.y1 + rect.height.value,
              y2: coords.y2 + rect.height.value * 3,
            }
          },
        }),
        component: SampleDataComponent,
        isComplete: () => {
          const entities = projectStore.activeView?.entities
          if (!entities) return false
          const allFileFields = entities
            .flatMap((e) => (e ? [...e.fields.entries()] : []))
            .map(([_, f]) => f)
            .filter((f) => f.type === 'file')
          return allFileFields.some((f) => f.manualValue)
        },
      },
      {
        title: 'Great! Now let’s create a new property',
        description:
          "Now, create your own property to run an AI model over the data you've uploaded",
        coordinates: useElementCoordinates(selectors.addProperty),
        isComplete: () => Boolean(addPropertyMenu.value),
      },
      {
        title: "Let's create a Text property",
        description:
          "Property types restrict the output of a model to a schema that you define. Let's start with a Text property.",
        coordinates: useElementCoordinates(selectors.textProperty),
        isComplete: () => Boolean(prompt.value),
      },
      {
        title: 'Explain what you want to do',
        description:
          'The last step is to write a prompt and configure an input property. For example, try writing "Summarise the @File", then click away to auto-save.',
        coordinates: useElementCoordinates(selectors.prompt),
        next: {
          text: 'Done',
          action: () => {
            nextStep()
            // Dismiss the menu
            setTimeout(() => {
              document.body.dispatchEvent(new Event('click'))
            }, 10)
          },
          disabled: () => {
            return !promptValue.value
          },
          variant: 'black',
        },
        onClickOutside: () => {
          if (!promptValue.value) return
          nextStep()
          // Dismiss the menu
          setTimeout(() => {
            document.body.dispatchEvent(new Event('click'))
          }, 10)
        },
        init: async () => {
          await new Promise((resolve) => setTimeout(resolve, 500))
          const el = prompt.value
          if (!isHtmlTextAreaElement(el)) return

          const toType = 'Summarise the @File '
          for (const char of toType) {
            el.value += char
            el.dispatchEvent(new Event('input'))
            await new Promise((resolve) => setTimeout(resolve, 30))
          }
        },
      },
    ],
    askGo: [
      {
        title: 'Set up your project with Ask Go',
        description: `Press ${isMac() ? '⌘' : 'Ctrl'} K to open Ask Go and ask it anything. Let's get started by asking what you want to do.`,
        coordinates: useElementCoordinates(selectors.openAskGoTopbar),
        isComplete: () => Boolean(askGoTopbar.value),
      },
      {
        title: 'Ask Go anything',
        description: `Now that Ask Go is open, you may choose one of the available suggestions, or type in what you'd like it to do. For example,
			you can ask it to "Set up a project to qualify candidates for different job postings".`,
        coordinates: useElementCoordinates(selectors.askGoTopbar),
        isComplete: () => Boolean(askGo.isOpen),
      },
      {
        title: 'Ask follow-ups',
        description: `Ask Go takes care of the rest. It may require more information, or you may want to take additional action, or even change what
			it did. It's up to you.`,
        coordinates: useElementCoordinates(selectors.askGoSidebar),
        isComplete: computed(() => {
          const currMessages = askGo.currentSession?.messages.length
          if (typeof currMessages !== 'number') return false
          // Plus 3 because the user will already have asked something in
          // the previous step, and received an answer. We wait for them
          // to ask something else, or dismiss the tour.
          return currMessages >= pastMessages + 3
        }),
        placement: 'left',
        next: {
          text: 'Done',
          action: () => {
            nextStep()
          },
        },
      },
    ] satisfies Step[],
  }
  const steps = tours.welcome

  const askGo = useAskGo()
  // Track how many askGo messages there were before,
  // to track when the user is done with the tour
  let pastMessages = -1

  // State
  const status = ref<WelcomeTourStatus>('IDLE')
  const stepIndex = ref(-1)
  const step = computed<Step | undefined>(() => steps[stepIndex.value])

  // Effects
  watchEffect(async () => {
    const isStepComplete = toValue(step.value?.isComplete)
    if (!isStepComplete || status.value !== 'IN_PROGRESS') return
    // Needed otherwise vue's reactivity system craps out.
    await new Promise((resolve) => setTimeout(resolve))
    nextStep()
  })

  let stepTimeStarted = performance.now()
  watch(projectCreationStatus, async (s) => {
    if (s === 'FAIL') {
      // If the project was unable to be created, we silently go to the first available project.
      // We still show the zoom animation, so it looks as if everything went well.
      const project = projects.projects[0]
      if (project) {
        await router.push({
          name: 'WorkspaceProject',
          params: { workspaceId: project.workspaceId, projectId: project.id },
        })
      }

      // Give time to zoom
      await new Promise((resolve) => setTimeout(resolve, 1000))
      status.value = 'BEFORE_ZOOM'
    } else if (s === 'SUCCESS') {
      const workspaceId = workspaceStore.currentWorkspace?.id
      if (status.value !== 'CREATING_PROJECT' || !welcomeProject.value || !workspaceId) return

      await router.push({
        name: 'WorkspaceProject',
        params: { workspaceId, projectId: welcomeProject.value },
      })

      status.value = 'BEFORE_ZOOM'
      stepTimeStarted = performance.now()
    }
  })

  function logStepViewed(index: number) {
    const step = steps[index]
    if (!step) return

    captureAnalyticsEvent(ANALYTICS_EVENT.WELCOME_TOUR_STEP_VIEWED, {
      step_name: step?.title,
      step_number: index + 1,
    })
  }

  function logStepCompleted(index: number) {
    const step = steps[index]
    if (!step) return

    const elapsedInSecs = (performance.now() - stepTimeStarted) / 1000
    stepTimeStarted = performance.now()

    captureAnalyticsEvent(ANALYTICS_EVENT.WELCOME_TOUR_STEP_COMPLETED, {
      step_name: step.title,
      step_number: index + 1,
      duration_on_step: elapsedInSecs,
      ...extraAnalyticsInfo,
    })
    extraAnalyticsInfo = {}
  }

  // Methods
  function nextStep() {
    logStepCompleted(stepIndex.value)
    if (stepIndex.value >= steps.length - 1) {
      status.value = 'COMPLETED'
      captureAnalyticsEvent(ANALYTICS_EVENT.WELCOME_TOUR_COMPLETED)
      stepIndex.value = -1
    } else {
      stepIndex.value++
      logStepViewed(stepIndex.value)
      steps[stepIndex.value]?.init?.()
    }
  }

  async function prepare() {
    status.value = 'CREATING_PROJECT'
    reset()
    // give enough time for vue's watch to notice the differences
    await new Promise((resolve) => setTimeout(resolve, 10))
    createWelcomeProject()
    stepIndex.value = 0
    captureAnalyticsEvent(ANALYTICS_EVENT.WELCOME_TOUR_STARTED)
  }

  async function begin() {
    if (projectCreationStatus.value === 'SUCCESS') {
      status.value = 'IN_PROGRESS'
    } else if (projectCreationStatus.value === 'FAIL') {
      status.value = 'IDLE'
    }
  }

  watch(
    () => status.value,
    (newStatus) => {
      if (newStatus === 'IN_PROGRESS') {
        logStepViewed(stepIndex.value)
      }
    },
  )

  let extraAnalyticsInfo: Record<string, unknown> = {}
  function addExtraAnalyticsInfo(obj: Record<string, unknown>) {
    extraAnalyticsInfo = { ...extraAnalyticsInfo, ...obj }
  }

  function skipTour() {
    status.value = 'COMPLETED'
    captureAnalyticsEvent(ANALYTICS_EVENT.WELCOME_TOUR_EXITED)
  }

  watchEffect(() => {
    if (status.value !== 'IN_PROGRESS' || pastMessages > -1 || !askGo.currentSession) return
    pastMessages = (askGo.currentSession?.messages ?? []).length
  })

  return {
    prepare,
    begin,
    createWelcomeProject,
    step,
    stepIndex: readonly(stepIndex),
    nextStep,
    status,
    steps,
    addExtraAnalyticsInfo,
    skipTour,
  }
})
