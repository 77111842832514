export function clamp(min: number, value: number, max: number) {
  return Math.max(min, Math.min(value, max))
}

/**
 * Round number n to d decimal places
 */
export function roundToDecimal(n: number, d: number) {
  return Math.round(n * 10 ** d) / 10 ** d
}
