import { type EntityResponse, type ProjectResponse } from '@/backend/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import {
  serializeEntity,
  serializeField,
  serializeProperty,
  type Entity,
  type Field,
  type Property,
} from './useProject'

type ParentProject = {
  name: string | null
  id: string | null
  properties: Property[]
  // feel free to extend it if we need views etc.
}

const entity = ref<Entity | null>(null)
const field = ref<Field | null>(null)
const property = ref<Property | null>(null)

export const useParentProject = defineStore('parentProject', () => {
  const project = ref<ParentProject | null>(null)

  const setParentProject = (parentProject: ProjectResponse) => {
    if (parentProject === null) return
    project.value = {
      id: parentProject.id,
      name: parentProject.name,
      properties: parentProject.properties.map(serializeProperty),
    }
  }

  const setParentProperty = (propertyId: string) => {
    property.value =
      project.value?.properties.find((property) => property.id === propertyId) || null
  }

  const setParentEntity = (newEntity: EntityResponse) => {
    entity.value = serializeEntity(newEntity)
    const slug = property.value?.slug
    if (slug && newEntity?.fields) {
      const fieldResponse = newEntity?.fields[slug] || null
      if (fieldResponse) {
        field.value = serializeField(fieldResponse)
      }
    }
  }

  const flush = () => {
    project.value = null
    entity.value = null
    field.value = null
  }

  const isParentPropertyManualCollection = computed(
    () => property.value?.type === 'collection' && property.value?.tool === 'manual',
  )

  return {
    parentProject: project,
    parentEntity: entity,
    parentField: field,
    parentProperty: property,
    setParentProject,
    setParentProperty,
    setParentEntity,
    flush,
    isParentPropertyManualCollection,
  }
})
