<script lang="ts">
export type Key =
  | 'Enter'
  | 'Space'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Meta'
  | 'BracketLeft'
  | 'BracketRight'
  | 'Control'
  | 'Shift'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z'

export type HotKeySize = 'xs' | 'sm' | 'md'
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    size?: HotKeySize
    hotkey: Key
  }>(),
  {
    size: 'md',
  },
)

function getKey(key: Key) {
  switch (key) {
    case 'Space':
      return '␣'
    case 'ArrowUp':
      return '↑'
    case 'ArrowDown':
      return '↓'
    case 'ArrowLeft':
      return '←'
    case 'ArrowRight':
      return '→'
    case 'Meta':
      return '⌘'
    case 'BracketLeft':
      return '['
    case 'BracketRight':
      return ']'
    case 'Control':
      return 'Ctrl'
    case 'Shift':
      return '⇧'
    default:
      return key
  }
}
</script>

<template>
  <!-- The control width modifier is a workaround. Ideally we use mono fonts and paddings for consistent sizing. -->
  <div
    class="relative flex items-center justify-center border-b border-b-border-subtle bg-background-gray-subtlest text-text-subtle"
    :class="[
      size === 'xs' &&
        `${hotkey === 'Control' ? 'w-6' : 'w-4'} h-4 rounded-corner-4 text-xs-11px-default`,
      size === 'sm' &&
        `${hotkey === 'Control' ? 'w-7' : 'w-5'} h-5 rounded-corner-4 text-xs-11px-default`,
      size === 'md' &&
        `${hotkey === 'Control' ? 'w-8' : 'w-6'} h-6 rounded-corner-6 text-sm-12px-default`,
    ]"
  >
    <span>
      {{ getKey(hotkey) }}
    </span>
  </div>
</template>
