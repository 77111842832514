<script setup lang="ts">
import { clamp } from '@vueuse/core'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * a value between 0 and 1 representing the position of the scroll bar relative to the containing element
     */
    offset?: number
    /**
     * the type of the scroll bar
     */
    type?: 'vertical' | 'horizontal'
    /**
     * a value between 0 and 1 representing the size of the scroll bar relative to the containing element
     */
    size?: number
  }>(),
  {
    offset: 0,
    type: 'vertical',
    size: 1,
  },
)

const emit = defineEmits<{
  (e: 'change', newValue: number): void
}>()

const container = ref<HTMLDivElement | null>(null)
const thumb = ref<HTMLDivElement | null>(null)

const maxOffset = computed(() => 1 - props.size)

const isVertical = computed(() => props.type === 'vertical')

const onMouseDown = (event: MouseEvent) => {
  const initialX = event.clientX
  const initialY = event.clientY
  const initialOffset = props.offset

  const onMouseMove = (event: MouseEvent) => {
    const deltaX = event.clientX - initialX
    const deltaY = event.clientY - initialY

    const delta = isVertical.value ? deltaY : deltaX

    const containerHeightOrWidth = isVertical.value
      ? container.value?.clientHeight
      : container.value?.clientWidth
    const thumbHeightOrWidth = isVertical.value
      ? thumb.value?.clientHeight
      : thumb.value?.clientWidth

    if (containerHeightOrWidth === undefined || thumbHeightOrWidth === undefined) return

    const newValue = delta / (containerHeightOrWidth - thumbHeightOrWidth) + initialOffset

    emit('change', clamp(newValue, 0, 1))
  }

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
  }

  window.addEventListener('mousemove', onMouseMove)
  window.addEventListener('mouseup', onMouseUp)
}
</script>

<template>
  <div
    v-if="size < 1"
    :class="[isVertical ? 'w-3 py-3' : 'h-3 px-3']"
  >
    <div
      ref="container"
      class="relative"
      :class="[isVertical ? 'h-full w-3' : 'h-3 w-full']"
    >
      <div
        ref="thumb"
        class="absolute cursor-default rounded-lg bg-background-gray-subtlest backdrop-blur-lg hover:bg-background-gray-subtle"
        :class="[isVertical ? 'w-1.5' : 'h-1.5']"
        :style="{
          [isVertical ? 'height' : 'width']: `${size * 100}%`,
          [isVertical ? 'top' : 'left']: `${offset * maxOffset * 100}%`,
        }"
        @mousedown.stop.prevent="onMouseDown"
      />
    </div>
  </div>
</template>
