import type { operations } from '@/api'
import { post } from './client'

export type AddEntityPath = `/api/workspaces/${string}/projects/${string}/entities`

export type AddEntityRequest = Exclude<
  operations['entity-create']['requestBody'],
  undefined
>['content']['application/json']
export type AddEntityResponse =
  operations['entity-create']['responses']['200']['content']['application/json']

type AddEntityParams = {
  workspaceId: string
  projectId: string
  parentEntityId?: AddEntityRequest['parent_entity_id']
}

export const addEntity = ({ parentEntityId, projectId, workspaceId }: AddEntityParams) => {
  return post<AddEntityRequest, AddEntityResponse, AddEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities`,
    {
      parent_entity_id: parentEntityId,
    },
  )
}
