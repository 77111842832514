import { get } from './client'
import type { BackendMeta } from './types'

export type ListUsers = BackendMeta<'/api/workspaces/{workspace_id}/members', 'get'>
export type ListUsersPath = ListUsers['path']
export type ListUsersResponse = ListUsers['responses']['200']['content']['application/json']
export type ListUsersRequest = undefined

export const listUsers = (workspaceId: string) =>
  get<ListUsersRequest, ListUsersResponse, ListUsersPath>(
    `/api/workspaces/${workspaceId}/members`,
    undefined,
  )
