<script setup lang="ts">
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import { TYPE_ICON } from './icons'
import type { Property } from './useProject'

defineProps<{
  properties: Property[]
  selectedIds: string[]
}>()

const emit = defineEmits<{
  (e: 'toggle:property', id: string): void
}>()
</script>

<template>
  <ListMenu
    data-test="model-input-menu-relation-properties"
    :items="properties.map((p) => ({ id: p.id, data: p }))"
    class="min-w-[240px]"
  >
    <template #item="{ active, item, key, setActiveItem }">
      <ListMenuCheckboxItem
        :icon="TYPE_ICON[item.data.type]"
        :label="item.data.name"
        :active="active"
        :aria-selected="selectedIds.includes(item.data.id)"
        :checked="selectedIds.includes(item.data.id)"
        @click="emit('toggle:property', item.data.id)"
        @mousemove="setActiveItem(key)"
      />
    </template>
  </ListMenu>
</template>
