import type { components } from '@/api'
import type { OcrPage } from '@/backend/types'
import { mergeRectanglesIntoPolygons } from '@/shared/utils/shape'
import { computed, type Ref } from 'vue'
import type { Source } from './useGroundingStore'

type BoundingBox = components['schemas']['Projects.Grounding.BoundingBox']
type GroundingInfo = {
  sources: Source[]
  ocrPages: OcrPage[]
}

/**
 * Grounding sources are returned from the backend as bounding boxes, where
 * sources that span multiple lines of text are multiple bounding boxes.
 *
 * This composable function provides utility functions to merge these
 * bounding boxes into polygons that can be rendered in an SVG.
 */
export const useGroundingPolygons = (ocrPages: Ref<OcrPage[]>) => {
  /**
   * We will render an SVG covering the entire page. This function returns
   * the viewbox for the SVG based on the page number.
   */
  const getOcrPageViewbox = (pageNumber: number) => {
    const page = ocrPages.value.find((p) => p.number === pageNumber)
    if (!page) return '0 0 0 0'

    const width = page.width
    const height = page.height
    return `0 0 ${width} ${height}`
  }

  /** Applies a fixed padding to a bounding box. */
  const applyPadding = (box: BoundingBox, padding: number) => {
    return {
      xmin: box.xmin - padding,
      xmax: box.xmax + padding,
      ymin: box.ymin - padding / 2,
      ymax: box.ymax + padding / 2,
      page: box.page,
    }
  }

  /** The amount of padding that should be applied to each polygon per page */
  const paddingFactorPerPage = computed<number[]>(() =>
    ocrPages.value.map<number>((page) => page.width * 0.008),
  )

  const strokeWidthPerPage = computed<number[]>(() =>
    ocrPages.value.map<number>((page) => page.width * 0.002),
  )

  /**
   * From a list of bounding boxes, get a list of strings that can be
   * passed to the `points` attribute of an SVG polygon. Overlapping
   * bounding boxes will be merged into a single polygon.
   */
  const getPolygonPointsFromBoxes = (boxes: BoundingBox[]): string[] => {
    const polygons = mergeRectanglesIntoPolygons(
      boxes.map((b) => {
        const paddingFactor = paddingFactorPerPage.value[b.page - 1] || 0.01

        return applyPadding(b, paddingFactor)
      }),
    )
    const points = polygons.map((polygon) =>
      polygon.reduce<string>((acc, curr) => {
        return `${acc} ${curr[0]},${curr[1]}`
      }, ''),
    )

    return points
  }

  /** Get all polygon `points` attributes from a given grounding source */
  const getPolygonPointsFromSource = (source: GroundingInfo['sources'][number], page: number) => {
    const boxes = source.boundingBoxes.filter((b) => b.page === page)
    return getPolygonPointsFromBoxes(boxes)
  }

  return {
    getPolygonPointsFromSource,
    strokeWidthPerPage,
    getOcrPageViewbox,
  }
}
