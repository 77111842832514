import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import type { Workspace } from '@/modules/Workspaces/useWorkspaces'
import { computed } from 'vue'

export const useWorkspaceOwnership = (ownedWorkspaces: Workspace[]) => {
  const maxWorkspacesFlag = useFeatureFlags(FeatureFlag.MAX_OWNED_WORKSPACES, 1)

  const maxWorkspaces = computed(() => (maxWorkspacesFlag.value ? maxWorkspacesFlag.value : 0))
  const isAllowedToCreate = computed(() => maxWorkspaces.value - ownedWorkspaces.length > 0)

  return {
    isAllowedToCreate,
  }
}
