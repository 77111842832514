export * from './is'
export * from './object'

export const GO_API_REFERENCE_URL = 'https://docs.go.v7labs.com/reference'
export const GO_DOCS_REFERENCE_URL = 'https://docs.go.v7labs.com/docs/getting-started'
export const GO_WEBHOOKS_REFERENCE_URL = 'https://docs.go.v7labs.com/reference/trigger-list'
export const GO_ZAPIER_APP_URL =
  'https://zapier.com/app/login?next=/developer/public-invite/199709/80834a609eda53a54ed95e22be26674b/'

export const goZapierUrlForCompleteField = (
  workspaceId: string,
  projectId: string,
  propertyId: string,
) =>
  `https://api.zapier.com/v1/embed/v7-go/create?steps[0][app]=V7GoCLIAPI@latest&steps[0][action]=complete_field&steps[0][params][workspace]=${workspaceId}&steps[0][params][project]=${projectId}&steps[0][params][field]=${propertyId}`

export const openURLInNewTab = (url: string) => {
  window.open(url, '_blank')
}

/**
 * Escapes a string so that it can be used as a regex input.
 * https://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript
 */
export const escapeRegex = (str: string) => {
  return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

/** Returns a new array after adding or removing the value from the array
 optionally can preserve the order of the of the all array, no duplicates are supported */
export const toggleArray = <T>(array: T[], value: T, all?: T[]): T[] => {
  const set = new Set(array)
  if (set.has(value)) {
    set.delete(value)
  } else {
    set.add(value)
  }

  return all ? all.filter((item) => set.has(item)) : Array.from(set)
}

export type ComponentProps<
  // eslint-disable-next-line no-unused-vars -- We only use args for type inference
  Component extends abstract new (...args: unknown[]) => { $props: Record<string, unknown> },
> = InstanceType<Component>['$props']

export const getMilisecondsFromUUIDv7 = (x: string) =>
  Number.parseInt(x.substring(0, 8) + x.substring(9, 14), 16)
