import { FeatureFlag } from '@/modules/App/featureFlags'
import { objectFilter, objectMap } from '@/shared/utils'
import { computed, ref, watchEffect, type ComputedRef, type Ref } from 'vue'
import { useLDClient } from './useLDClient'

const mockedFlags = ref<Partial<Record<string, unknown>>>({})

export function mockFlag(flag: string, value: unknown) {
  mockedFlags.value = {
    ...mockedFlags.value,
    [flag]: value,
  }
}

export const useFeatureFlags = <T = boolean>(
  feature: FeatureFlag,
  defaultValue?: T,
): ComputedRef<T | false> => {
  const ld = useLDClient()
  const value = ref(defaultValue ?? false) as Ref<T | false>

  watchEffect(() => {
    if (!ld.isReady) return
    value.value = ld.client?.variation(feature, defaultValue)

    function change() {
      value.value = ld.client?.variation(feature, defaultValue)
    }
    change()

    ld.client?.on(`change`, change)

    return () => {
      ld.client?.off(`change`, change)
    }
  })

  return computed(() => {
    return feature in mockedFlags.value ? (mockedFlags.value[feature] as T) : value.value
  })
}

export const useAllFeatureFlags = () => {
  const ld = useLDClient()
  const allFlags = ref({} as Partial<Record<string, unknown>>)

  watchEffect(() => {
    if (!ld.isReady) return

    function updateFlags() {
      const res = ld.client?.allFlags()
      if (!res) return

      allFlags.value = {
        ...objectMap(FeatureFlag, (_, v) => [v, false]),
        ...objectFilter(res, (k) => Object.values(FeatureFlag).includes(k as FeatureFlag)),
        ...mockedFlags.value,
      }
    }
    updateFlags()

    ld.client?.on(`change`, updateFlags)

    return () => {
      ld.client?.off(`change`, updateFlags)
    }
  })

  return computed(() => allFlags.value)
}
