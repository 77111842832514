import { computed } from 'vue'
import { useRoute } from 'vue-router'

/* This composable predicts the page type (home/settings/project) that is going to be loaded based on the current route.
It is used to show different loading skeletons for the user even before the actual components are mounted in the RouterView.
*/

export function useLoadingPrediction() {
  const route = useRoute()

  const matchedRouteNames = computed(() => {
    return route.matched ? route.matched.map((m) => m.name) : []
  })

  const predictedPage = computed(() => {
    if (
      matchedRouteNames.value.includes('WorkspaceProjects') ||
      (matchedRouteNames.value[0] === 'Home' && matchedRouteNames.value.length === 1)
    ) {
      return 'home'
    } else if (matchedRouteNames.value.includes('WorkspaceSettings')) {
      return 'settings'
    } else {
      return 'project'
    }
  })

  return { predictedPage }
}
