import { getEntity } from '@/backend/getEntity'
import { getProject } from '@/backend/getProject'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useParentProject } from '../Project/useParentProject'
import { serializeProject, useProjects } from '../Projects/useProjects'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useFileCollectionLoadingStore } from './useFileCollectionLoadingStore'
import { serializeProperty, serializeView, useProject } from './useProject'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { useTable } from './useTable'
/**
 * When the project ID changes, this composable will load the project and set it in the project Pinia
 * store. When the view ID changes, it will set the active view in the project Pinia store.
 */
export const useProjectSync = (props: {
  workspaceId: string
  projectId: string
  viewId?: string
  parentEntityId?: string
}) => {
  const currentProjectStore = useProject()
  const parentProjectStore = useParentProject()
  const allProjectsStore = useProjects()
  const projectLoader = useDataLoader(() => getProject(props.workspaceId, props.projectId))
  const relatedProjectsStore = useRelatedProjectsStore()
  const router = useRouter()
  const tableStore = useTable()
  const resolveProjectTableRoute = useResolveProjectRoute()

  const loadAndSetProject = async () => {
    currentProjectStore.setProjectId(props.projectId)

    const result = await projectLoader.load()
    if (result.ok) {
      allProjectsStore.addProject(serializeProject(result.data))
      currentProjectStore.setProperties(result.data.properties.map(serializeProperty))
      currentProjectStore.setViews(result.data.main_view_id, result.data.views.map(serializeView))
      currentProjectStore.resetDrafts()

      await loadAndSetParentProject()
      await loadAndSetParentEntity()
      await loadAndSetRelatedProjects()

      const hasMainView = result.data.main_view_id !== null
      if (!hasMainView && !props.viewId) {
        const firstView = result.data.views.at(0)
        if (!firstView) {
          throw new Error('User is unable to access any views in this project.')
        }
        // 1. If the response contains no main view, then the user does not have permission to access
        //    the main view
        // 2. If the user has not provided a viewId, then we redirect the user to the first view that
        //    they do have access to
        router.replace(
          resolveProjectTableRoute({
            workspaceId: props.workspaceId,
            projectId: props.projectId,
            viewId: firstView.id,
          }),
        )
      } else {
        currentProjectStore.setActiveViewId(props.viewId)
      }
      currentProjectStore.projectLoaded = true
    }
  }

  const loadAndSetParentProject = async () => {
    const parentPropertyId = currentProjectStore.properties.at(0)?.parentPropertyId
    if (parentPropertyId) {
      // if we have any props with parent property (eg collections), we load the parent project so we can access to their properties
      const parentProjectId = currentProjectStore.properties.at(0)?.parentProjectId
      if (!parentProjectId) throw new Error('Parent project ID is missing')

      const parentProjectLoader = useDataLoader(() =>
        getProject(props.workspaceId, parentProjectId),
      )

      const result = await parentProjectLoader.load()
      if (result.ok) {
        allProjectsStore.addProject(serializeProject(result.data))
        parentProjectStore.setParentProject(result.data)
        parentProjectStore.setParentProperty(parentPropertyId)
      } else {
        throw new Error('Unable to load parent project')
      }
    }
  }

  const loadAndSetParentEntity = async () => {
    const firstProperty = currentProjectStore.properties.at(0)
    if (!firstProperty) {
      // Prevents a bug where breadcrumb forming would read a stale parent field
      parentProjectStore.parentField = null
      return
    }

    const parentProjectId = firstProperty.parentProjectId
    const parentEntityId = props.parentEntityId

    if (!parentEntityId || !parentProjectId) {
      return
    }

    const parentEntityLoader = useDataLoader(() =>
      getEntity(props.workspaceId, parentProjectId, parentEntityId),
    )

    const result = await parentEntityLoader.load()

    if (result.ok) {
      parentProjectStore.setParentEntity(result.data)
    } else {
      throw new Error('Unable to load parent entity')
    }
  }

  const loadAndSetRelatedProjects = async () => {
    const referenceProperties = currentProjectStore.properties.filter(
      (property) => property.type === 'reference',
    )

    await relatedProjectsStore.loadRelatedProjects({
      referenceProperties,
      workspaceId: props.workspaceId,
    })
  }

  const fileCollectionLoadingStore = useFileCollectionLoadingStore()
  watch(
    () => props.projectId,
    (newId) => {
      if (newId !== currentProjectStore.projectId) {
        currentProjectStore.projectLoaded = false
        currentProjectStore.setProperties([])
        currentProjectStore.setViews(null, [])
        tableStore.clearFocusedAndSelected()
        loadAndSetProject()
        fileCollectionLoadingStore.reset()
      }
    },
    { immediate: true },
  )

  watch(
    () => props.viewId,
    (newViewId) => {
      if (newViewId !== currentProjectStore.activeView?.id) {
        currentProjectStore.setActiveViewId(newViewId)
      }
    },
    { immediate: true },
  )

  watch(
    () => props.parentEntityId,
    (newPid) => {
      currentProjectStore.setParentEntityId(newPid)
    },
    { immediate: true },
  )

  return { projectLoader }
}
