import { computed } from 'vue'
import { useWorkspaces } from './useWorkspaces'

export const useCurrentWorkspace = () => {
  const workspacesStore = useWorkspaces()
  const currentWorkspace = computed(() => {
    if (!workspacesStore.currentWorkspace) {
      throw new Error('No current workspace')
    }
    return workspacesStore.currentWorkspace
  })
  return currentWorkspace
}
