import { useRouter, type LocationQueryValue, type RouteParamsGeneric } from 'vue-router'

type ResolveSubProjectRouteParams = {
  workspaceId: RouteParamsGeneric[string]
  projectId: RouteParamsGeneric[string]
  parentProjectId?: RouteParamsGeneric[string]
  parentEntityId?: LocationQueryValue | LocationQueryValue[]
  viewId?: RouteParamsGeneric[string]
  parentViewId?: LocationQueryValue | LocationQueryValue[]
}

/**
 * Returns a function that is used to get the route for a given subproject.
 */
export const useResolveProjectRoute = () => {
  const router = useRouter()

  return ({
    parentProjectId,
    projectId,
    workspaceId,
    parentEntityId,
    viewId,
    parentViewId,
  }: ResolveSubProjectRouteParams) => {
    if (parentProjectId) {
      if (viewId) {
        return router.resolve({
          name: 'WorkspaceSubProjectTableView',
          params: {
            workspaceId,
            projectId,
            parentProjectId,
            viewId,
          },
          query: { parentEntityId, parentViewId },
        })
      }

      return router.resolve({
        name: 'WorkspaceSubProjectTable',
        params: {
          workspaceId,
          projectId,
          parentProjectId,
        },
        query: { parentEntityId, parentViewId },
      })
    }

    if (viewId) {
      return router.resolve({
        name: 'WorkspaceProjectTableView',
        params: {
          workspaceId,
          projectId,
          viewId,
        },
      })
    }

    return router.resolve({
      name: 'WorkspaceProjectTable',
      params: {
        workspaceId,
        projectId,
      },
    })
  }
}
