import { useSetFieldValue, type SaveValueParams } from '@/shared/useSetFieldValue'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import pLimit from 'p-limit'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useProject } from './useProject'
import { useTable } from './useTable'

/**
 * Provides functions to reset or save the changes made visible in
 * the table's range of preview cells.
 */
export const usePreviewChanges = () => {
  const projectStore = useProject()
  const tableStore = useTable()

  const resetPreview = () => {
    projectStore.resetDrafts()

    if (tableStore.previewRange) {
      tableStore.selectCell(
        tableStore.previewRange.start.rowIndex,
        tableStore.previewRange.start.colIndex,
      )
      tableStore.previewRange = null
    }
  }

  const workspacesStore = useWorkspaces()
  const setFieldValue = useSetFieldValue()
  const savePreviewChanges = async () => {
    const entityIds = Object.keys(projectStore.draftFields)

    tableStore.previewRange = null

    // Limit to 10 outstanding requests at a time
    const limit = pLimit(10)
    await Promise.all(
      entityIds.map((entityId) =>
        limit(async () => {
          assertIsNotNullOrUndefined(
            workspacesStore.currentWorkspace,
            'currentWorkspace is not set',
          )
          assertIsNotNullOrUndefined(projectStore.projectId, 'projectId is not set')
          assertIsNotNullOrUndefined(projectStore.activeView?.entityIdToIndex)
          assertIsNotNullOrUndefined(projectStore.draftFields[entityId])

          if (!entityId) {
            return
          }
          const entityIndex = projectStore.activeView.entityIdToIndex.get(entityId)
          if (entityIndex === undefined) {
            return
          }
          const entity = projectStore.activeView.entities
            ? projectStore.activeView.entities[entityIndex]
            : null
          if (!entity) {
            return
          }

          const propertyIds = Object.keys(projectStore.draftFields[entityId])
          const fields = propertyIds.reduce<SaveValueParams['fields']>((acc, propertyId) => {
            assertIsNotNullOrUndefined(projectStore.draftFields[entityId])
            const field = entity.fields.get(propertyId)
            const newValue = projectStore.draftFields[entityId][propertyId]
            if (!field || newValue === undefined) {
              return acc
            }

            return {
              ...acc,
              [propertyId]: {
                newValue,
                field,
              },
            }
          }, {})

          await setFieldValue({
            entityId: entityId,
            fields,
            projectId: projectStore.projectId,
            workspaceId: workspacesStore.currentWorkspace?.id,
          })
        }),
      ),
    )
  }

  return {
    resetPreview,
    savePreviewChanges,
  }
}
