import type { AppTopic } from '@/backend/websocket'
import { createSocket } from '@/backend/websocket'
import { toast } from '@/shared/toast'
import { useAuth0 } from '@auth0/auth0-vue'
import { createSharedComposable, watchOnce } from '@vueuse/core'
import type { Channel } from 'phoenix'
import { ref, watch } from 'vue'

const wait = (attempts: number) =>
  new Promise<void>((resolve) => {
    setTimeout(resolve, [1000, 5000, 10000][attempts - 1] ?? 60000)
  })

export const useWebSocket = createSharedComposable(() => {
  const socket = ref<null | ReturnType<typeof createSocket>>(null)
  const connectionTries = ref(0)

  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0()

  const socketState = ref('closed')

  const updateSocketState = () => {
    socketState.value = socket.value?.connectionState() || 'closed'

    // notify the user of the current state with toasts
    notifyUser(socketState.value, connectionTries.value)

    // when the socket comes back online, reset the counter
    if (socketState.value === 'open' && connectionTries.value > 0) {
      connectionTries.value = 0
    }
  }

  /**
   * Connect to the backend websocket endpoint
   *
   * Should be called once per full page load, usually in the main application component.
   *
   * If the conditions of the connection change (such as the user logging in or out),
   * the socket should be disconnected and reconnected.
   */
  const connect = async () => {
    if (socket.value || isLoading.value || !isAuthenticated.value) {
      return
    }

    const token = await getAccessTokenSilently()
    socket.value = createSocket(token)
    socket.value?.onOpen(updateSocketState)
    socket.value?.onClose(updateSocketState)
    socket.value?.onError(async () => {
      await new Promise<void>((resolve) => socket.value?.disconnect(resolve))
      updateSocketState()
      socket.value = null

      connectionTries.value++
      if (connectionTries.value > 5) {
        toast.error('Failed to reconnect to the server. Please try again later.')
        return Promise.reject('Failed to reconnect to the server. Please try again later.')
      }

      await wait(connectionTries.value)
      // recursively try to reconnect
      connect()
    })
    socket.value?.connect()
  }

  /**
   * Creates a promise resolving channel using the app's websocket connection.
   * Will wait for the connection to be instantiated before resolving.
   *
   * Because it's only possible to call join on a phoenix channel once, there
   * cannot be a recycled pool of channels. Instead, the channel is created on
   * every call.
   *
   * It may potentially still be possible to hold a pool of current channels,
   * in order to reuse subscriptions to topics, but that's probably an
   * overoptimisation at this point.
   */
  const getChannel = (topic: AppTopic) =>
    new Promise<Channel>((resolve) => {
      if (socket.value) {
        resolve(socket.value.channel(topic))
      }

      watchOnce(socket, (socket) => {
        if (socket) {
          resolve(socket.channel(topic))
        }
      })
    })

  watch(isLoading, async () => {
    if (!isLoading.value) {
      await connect()
    }
  })

  return {
    connect,
    socketState,
    getChannel,
  }
})

export const notifyUser = (socketState: string, attempt: number) => {
  if (attempt >= 2 && socketState === 'open') {
    // Originally I wanted to close the warning toast, but
    // after dismissing a toast an error happens and all the next toasts are.. toast..
    // Until the issue is fixed we have a bit less nice experience here.
    // toast.dismiss()
    toast.success('Connection Restored! Thank you for your patience.')
  } else if (attempt > 1 && socketState === 'closed') {
    toast.warning(
      'Server or internet issue detected. Confirm your internet connection and please wait.',
      {
        //duration: Infinity,
      },
    )
  }
}
