import type { components } from '@/api'
import { remove } from './client'

export type DeleteApiKeyPath = `/api/api_keys/${string}`
export type DeleteApiKeyResponse = components['schemas']['ApiKeys.ApiKeyResponse']
export type DeleteApiKeyRequest = undefined

export const deleteApiKey = (id: string) =>
  remove<DeleteApiKeyRequest, DeleteApiKeyResponse, DeleteApiKeyPath>(
    `/api/api_keys/${id}`,
    undefined,
  )
