<script setup lang="ts">
import HighlightedTextarea from '@/uiKit/HighlightedTextarea.vue'
import { computed, ref, watch } from 'vue'

import ProseMirror from '@/sharedComponents/ProseMirror/ProseMirror.vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import CardTextEditor from './CardTextEditor.vue'
import type { LayoutItem } from './EntityViewCard.vue'

const props = defineProps<{
  item: LayoutItem
  json: boolean
  readonly: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', val: string): void
}>()

const value = computed(() =>
  props.item.field.type === 'text' ||
  props.item.field.type === 'json' ||
  props.item.field.type === 'url'
    ? props.item.field.manualValue || props.item.field.toolValue
    : '',
)

const status = computed(() => props.item.field.status)

const infoText = computed<string | undefined>(() => {
  if (status.value === 'error') {
    return `Error: ${props.item.field.errorMessage}`
  }

  if (status.value === 'computing') {
    return 'Calculating...'
  }

  return undefined
})

const onSave = (text: string) => {
  emit('submit', text)
}

const isMarkDownFlagEnabled = useFeatureFlags(FeatureFlag.MARKDOWN_EDITOR)
const isJsonFlagEnabled = useFeatureFlags(FeatureFlag.JSON_EDITOR)

const shouldUseMarkdownEditor = computed(() => {
  return isMarkDownFlagEnabled.value && props.item.field.type === 'text'
})
const shouldUseJsonEditor = computed(() => props.json && isJsonFlagEnabled.value)
const highlightTextareaLocalValue = ref<string>('')

watch(
  () => value.value,
  () => (highlightTextareaLocalValue.value = value.value ?? ''),
  { immediate: true },
)
</script>

<template>
  <HighlightedTextarea
    v-if="shouldUseJsonEditor"
    ref="highlightedTextareaRef"
    lang="json"
    :value="highlightTextareaLocalValue"
    size="md"
    class="size-full"
    adapt-for-table-mode
    @input="highlightTextareaLocalValue = $event"
    @change="$emit('submit', $event)"
  />
  <ProseMirror
    v-else-if="shouldUseMarkdownEditor"
    class="size-full rounded-corner-6 [&>*]:px-3 [&>*]:py-1.5"
    :value="value ?? ''"
    :readonly="item.readonly || readonly"
    @save="onSave"
  />
  <CardTextEditor
    v-else
    :info-text="infoText"
    :value="value ?? ''"
    :json="json"
    :readonly="item.readonly || readonly"
    :label="item.property?.name"
    @submit="$emit('submit', $event)"
  />
</template>
