<script setup lang="ts">
import { ref } from 'vue'

import { copyToClipboard } from '@/shared/clipboard'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'

import type { ApiKey } from './useApiKeys'

const props = defineProps<{
  apiKey: ApiKey
}>()

const emit = defineEmits<{ (e: 'ack'): void }>()

const ackButton = ref<InstanceType<typeof DarwinButton>>()

const doCopyToClipboard = () => {
  if (!props.apiKey.key) {
    return
  }

  copyToClipboard('API key', props.apiKey.key)
}
</script>

<template>
  <ModalDialog
    to="#main"
    open
    @click="emit('ack')"
  >
    <template #header>
      <ModalDialogHeader
        title="API key created"
        description="Save this API key somewhere safe and accessible."
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-2">
        <p class="text-md-13px-light text-text-subtle">
          For security reasons,
          <span class="text-md-13px-bold">you won't be able to see it again</span>. If you lose this
          key, you will need to create a new one.
        </p>
        <div
          class="flex justify-between rounded-corner-8 bg-background-gray-subtlest px-2 py-1 text-text-subtle"
        >
          <pre>{{ props.apiKey.key }}</pre>
          <IconButton
            icon="copy"
            size="sm"
            aria-label="Copy API key to clipboard"
            variant="transparent"
            @click="doCopyToClipboard"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <DarwinButton
        ref="ackButton"
        variant="black"
        size="md"
        @click="emit('ack')"
      >
        OK
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
