import type { Polygon as LibraryPolygon } from 'polygon-clipping'
import polygonClipping from 'polygon-clipping'

export type Rectangle = {
  xmin: number
  ymin: number
  xmax: number
  ymax: number
}

export type Polygon = Array<[number, number]>

export const mergeRectanglesIntoPolygons = (rectangles: Rectangle[]): Polygon[] => {
  const polygons = rectangles.map<LibraryPolygon>(({ xmin, ymin, xmax, ymax }) => [
    [
      [xmin, ymin],
      [xmax, ymin],
      [xmax, ymax],
      [xmin, ymax],
    ],
  ])

  // The library supports disconnected polygons, but we do not.
  return polygonClipping.union(polygons[0], ...polygons.slice(1)).map((p) => p[0])
}
