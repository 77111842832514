import { listPlans } from '@/backend/listPlans'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { watch, type Ref } from 'vue'
import { serializePlan, serializePlanTemplate, useBilling } from './useBilling'

/**
 * Loads billing data for the current workspace and stores it in the billing store.
 * Loads new billing data when the workspace changes.
 */
export const useLoadBilling = (workspaceId: Ref<string>) => {
  const billingLoader = useDataLoader(() => listPlans(workspaceId.value))

  const billingStore = useBilling()
  /**
   * On load (or when the workspaceId changes), load the workspace plans and
   * invitations into the store.
   */
  watch(
    () => workspaceId.value,
    async () => {
      const billingResponse = await billingLoader.load()

      if (billingResponse.ok) {
        billingStore.activePlan = serializePlan(billingResponse.data.effective_plan)

        billingStore.setPlanCatalog(billingResponse.data.templates.map(serializePlanTemplate))

        billingStore.customerPortalUrl = billingResponse.data.customer_portal_url ?? null
      }
    },
    { immediate: true },
  )
}
