<script setup lang="ts">
import ProjectName from '@/modules/Project/ProjectName.vue'
import { type Project } from '@/modules/Projects/useProjects'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import ProjectCrumbMenu from '@/modules/Workspaces/Crumbs/ProjectCrumbMenu.vue'
import ProjectCrumbRenameField from '@/modules/Workspaces/Crumbs/ProjectCrumbRenameField.vue'
import { useProjectRole } from '@/modules/Workspaces/Crumbs/useProjectRole'
import DeleteProjectDialog from '@/sharedComponents/DeleteProjectDialog.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { computed, ref } from 'vue'
import { type RouteLocationRaw, useRouter } from 'vue-router'

const props = defineProps<{
  project: Project
}>()

const router = useRouter()
const { workspaceId } = useRouteParams()
const { currentProjectRole } = useProjectRole()

const isRenaming = ref(false)
const isDeleting = ref(false)

const isViewOnly = computed(() => currentProjectRole.value === 'reader')

const route = computed<RouteLocationRaw>(() => ({
  name: 'WorkspaceProjectTable',
  params: {
    workspaceId: workspaceId.value,
    projectId: props.project.id,
  },
}))

const isActive = computed(() => {
  return router.resolve(route.value).fullPath === router.currentRoute.value.fullPath
})

function onDelete() {
  isDeleting.value = false
  router.push({
    name: 'Workspace',
    params: {
      workspaceId: workspaceId.value,
    },
  })
}
</script>

<template>
  <template v-if="isRenaming">
    <ProjectCrumbRenameField
      :project="project"
      @done="isRenaming = false"
    />
  </template>
  <template v-else>
    <CrumbItem :to="route">
      <ProjectName
        :project="project"
        data-test="crumbs-project-name"
      />
    </CrumbItem>
    <BadgeItem
      v-if="isViewOnly"
      label="View only"
      variant="neutral"
      size="sm"
      class="mr-0.5"
    />
    <ProjectCrumbMenu
      v-if="isActive"
      :open="true"
      :project="project"
      @rename="isRenaming = true"
      @delete="isDeleting = true"
    />
  </template>

  <DeleteProjectDialog
    :workspace-id="workspaceId"
    :project-id="project.id"
    :open="isDeleting"
    @close="isDeleting = false"
    @delete="onDelete"
  />
</template>
