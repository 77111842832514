import { computed } from 'vue'
import { useProjects } from '../Projects/useProjects'
import { useProperty } from './useProperty'
import { getCoverImageUrl } from './utils'

/**
 * Provides a range of refs and functions that are used when working with a reference property's
 * linked project.
 */
export const useRelatedProject = () => {
  const propertyStore = useProperty()
  const projectsStore = useProjects()
  const onChangeRelatedProject = (id: string) => {
    if (propertyStore.editedProperty?.type !== 'reference') {
      throw new Error('Store has no editedProperty')
    }

    propertyStore.editedProperty.config.projectId = id
  }

  const relatedProject = computed(() => {
    if (propertyStore.editedProperty?.type !== 'reference') {
      return null
    }

    return projectsStore.getProjectById(propertyStore.editedProperty.config.projectId) || null
  })

  const relatedProjectCoverImage = computed(() =>
    relatedProject.value ? getCoverImageUrl(relatedProject.value, 'low') : null,
  )

  return {
    /** The project that is currently linked to this reference property */
    relatedProject,
    /** Cover image URL for the linked project, null if unable to load the image */
    relatedProjectCoverImage,
    /** Updates the linked project */
    onChangeRelatedProject,
  }
}
