<script setup lang="ts">
import { parseMatcherName } from '@/modules/Project/useFilters'
import { omit } from '@/shared/utils'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import type { Context as MenuContext } from '@zag-js/menu'
import { computed, ref } from 'vue'
import {
  getFilterSubjectForProperty,
  propertyHasTextLikeFilter,
  type FilterablePropertyType,
  type MatcherName,
  type SimpleFilter,
} from './types'

export type ID = Extract<
  MatcherName,
  | 'property_any_of'
  | 'property_all_of'
  | 'property_none_of'
  | 'property_not_set'
  | 'property_contains_any_of'
  | 'property_contains_none_of'
>
const props = defineProps<{
  matcherName: SimpleFilter['matcher']['name'] | 'property_not_set'
  values: string | string[]
  type: FilterablePropertyType
  /** The total number of options this property has */
  optionCount?: number
  positioning?: MenuContext['positioning']
  teleportTo?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'match', matchId: ID): void
}>()

const IDS = {
  matchAnyOf: 'property_any_of',
  matchAllOf: 'property_all_of',
  matchNoneOf: 'property_none_of',
  matchNotSet: 'property_not_set',
  matchContainsAnyOf: 'property_contains_any_of',
  matchContainsNoneOf: 'property_contains_none_of',
} as const

const onSelect = (id: ID) => {
  isOpen.value = false
  emit('match', id)
}

const amount = computed(() => {
  if (typeof props.values === 'string') return 1
  return props.values.length
})

/**
 * 'property_not_set' is not a valid backend filter matcher, so this is where we
 * swap out the 'is_none_of <all_options>' matcher for the user friendly 'is not set'
 * option.
 */
const currentFilterName = computed(() => {
  if (props.matcherName === 'property_none_of' && amount.value === props.optionCount) {
    return IDS.matchNotSet
  }

  return props.matcherName
})

const isOpen = ref(false)
</script>

<template>
  <FloatingMenu
    :positioning="positioning ?? { placement: 'top-start' }"
    :teleport-to="teleportTo"
    :open="isOpen"
    @change:open="isOpen = $event"
    @select="(id: string) => onSelect(id as ID)"
  >
    <template #trigger="{ triggerProps }">
      <button
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        class="flex cursor-pointer items-center gap-0.5 overflow-hidden text-nowrap px-1.5 py-1 text-sm-12px-default hover:bg-background-transparent-hovered"
        :class="{
          'bg-background-transparent-hovered': isOpen,
          'text-text-disabled': disabled,
          'text-text-subtle active:bg-background-transparent-pressed': !disabled,
        }"
        :disabled="disabled"
      >
        {{
          parseMatcherName({
            matcherName: currentFilterName,
            amount,
            type,
          })
        }}
      </button>
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-[242px]"
      >
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            v-if="propertyHasTextLikeFilter(type)"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchContainsAnyOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchContainsAnyOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchContainsAnyOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="propertyHasTextLikeFilter(type)"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchContainsNoneOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchContainsNoneOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchContainsNoneOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="
              propertyHasTextLikeFilter(type) ||
              getFilterSubjectForProperty(type) === 'field_select_option_value'
            "
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchAnyOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchAnyOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchAnyOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="type === 'multi_select'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchAllOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchAllOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchAllOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="
              propertyHasTextLikeFilter(type) ||
              getFilterSubjectForProperty(type) === 'field_select_option_value'
            "
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchNoneOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchNoneOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchNoneOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="getFilterSubjectForProperty(type) === 'field_select_option_value'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchNotSet }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchNotSet ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            is not set
          </ListMenuItem>
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
