import type { components } from '@/api'
import { remove } from './client'

export type DeleteWorkspacePath = `/api/workspaces/${string}`
export type DeleteWorkspaceResponse = components['schemas']['Workspaces.WorkspaceResponse']

export const deleteWorkspace = (workspaceId: string) =>
  remove<undefined, DeleteWorkspaceResponse, DeleteWorkspacePath>(
    `/api/workspaces/${workspaceId}`,
    undefined,
  )
