import type { components } from '@/api'
import { post } from './client'

export type SendSessionMessagePath = `/api/workspaces/${string}/ask_go/${string}/ask`
export type SendSessionMessageResponse = components['schemas']['AskGo.SessionResponse']
export type SendSessionMessageRequest = components['schemas']['AskGo.AskRequest']

export const sendSessionMessage = (params: {
  ask: string
  sessionId: string
  workspaceId: string
  uploadEntityIds: string[]
}) =>
  post<SendSessionMessageRequest, SendSessionMessageResponse, SendSessionMessagePath>(
    `/api/workspaces/${params.workspaceId}/ask_go/${params.sessionId}/ask`,
    {
      ask: params.ask,
      upload_entity_ids: params.uploadEntityIds,
    },
  )
