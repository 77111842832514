import { PropertyType } from '@/backend/types'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { FIELD_TYPES_OPTIONS } from '@/modules/WorkspaceSettings/propertyConfig'
import { computed, type Ref } from 'vue'

export const useFieldTypeOptions = ({ hasParentProperty }: { hasParentProperty: Ref<boolean> }) => {
  const isFileSplitterEnabled = useFeatureFlags(FeatureFlag.FILE_SPLITTER)
  const isPdfTypeEnabled = useFeatureFlags(FeatureFlag.PDF_PROPERTY_TYPE)
  const isUserSelectEnabled = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
  const isReferenceTypeEnabled = useFeatureFlags(FeatureFlag.REFERENCE_PROPERTIES)
  const nestedCollectionsEnabled = useFeatureFlags(FeatureFlag.NESTED_COLLECTIONS)

  return computed(() =>
    FIELD_TYPES_OPTIONS.map((t) => ({ id: t.type, data: t })).filter((t) => {
      if (t.data.type === PropertyType.pdf) {
        // PDF has been superseded by file_collection so hide it if file splitter is enabled
        return isPdfTypeEnabled.value && !isFileSplitterEnabled.value
      }

      if (t.data.type === PropertyType.file_collection) {
        return isFileSplitterEnabled.value
      }

      if (t.data.type === PropertyType.user_select) {
        return isUserSelectEnabled.value
      }

      if (t.data.type === PropertyType.reference) {
        return isReferenceTypeEnabled.value
      }

      if (t.data.type === PropertyType.collection && hasParentProperty.value) {
        return nestedCollectionsEnabled.value
      }

      return true
    }),
  )
}
