<script setup lang="ts">
import { randomRange } from '@/shared/utils/random'
import { clamp, useRafFn } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'
import { useTheme } from '../App/useTheme'

const { actualTheme } = useTheme()
const color = computed(() => (actualTheme.value === 'light' ? 'hsl(0 0% 0% / 80%)' : 'white'))

const svg = ref<HTMLElement>()
const rects = ref<SVGRectElement[]>([])

onMounted(() => {
  if (!svg.value) return
  const viewbox = svg.value?.getAttribute('viewBox')
  if (!viewbox) return

  const [x, y, width, height] = viewbox.split(' ').map(Number)
  rects.value = Array.from(svg.value.querySelectorAll('rect'))

  // order the rects by how close they are to the center (both horizontally and vertically)
  rects.value.sort((a, b) => {
    const aCoords = {
      x: Number(a.getAttribute('x')) + Number(a.getAttribute('width')) / 2,
      y: Number(a.getAttribute('y')) + Number(a.getAttribute('height')) / 2,
    }

    const bCoords = {
      x: Number(b.getAttribute('x')) + Number(b.getAttribute('width')) / 2,
      y: Number(b.getAttribute('y')) + Number(b.getAttribute('height')) / 2,
    }

    const aDistanceToCenter =
      Math.abs(aCoords.x - x - width / 2) + Math.abs(aCoords.y - y - height / 2)
    const bDistanceToCenter =
      Math.abs(bCoords.x - x - width / 2) + Math.abs(bCoords.y - y - height / 2)

    return aDistanceToCenter - bDistanceToCenter
  })

  let toShow = 1
  const started = performance.now()
  useRafFn(({ timestamp }) => {
    if (!rects.value || timestamp - started < 250) return

    let newlyShown = 0
    if (rects.value.some((rect) => rect.getAttribute('opacity') != '1')) {
      for (const rect of rects.value) {
        const hasBeenShown = rect.getAttribute('opacity') === '1'
        if (!hasBeenShown) {
          rect.setAttribute('opacity', '1')
          newlyShown++
        }
        if (newlyShown >= toShow) {
          toShow += 0.5
          break
        }
      }
    }

    rects.value.forEach((rect) => {
      const shouldChange = Math.random() > 0.75
      if (!shouldChange) return

      const curr = Number(rect.getAttribute('fill-opacity'))
      const min = 0.25
      const max = 0.5
      const next = clamp(curr + randomRange(-0.25, 0.25), min, max)
      rect.setAttribute('fill-opacity', String(next))
    })
  })
})
</script>

<template>
  <svg
    ref="svg"
    width="59"
    height="18"
    viewBox="0 0 59 18"
    xmlns="http://www.w3.org/2000/svg"
    :style="`color: ${color}`"
    class="[&>rect]:transition-[opacity,fill-opacity] [&>rect]:duration-[100ms,500ms]"
  >
    <rect
      v-for="i in 12"
      :key="i"
      :x="6.5 + 4 * (i - 1)"
      width="2"
      height="2"
      rx="1"
      fill="currentcolor"
      opacity="0.1"
      :fill-opacity="0.5"
    />

    <rect
      v-for="i in 16"
      :key="i"
      :x="0.5 + 4 * (i - 1)"
      y="4"
      width="2"
      height="2"
      rx="1"
      fill="currentcolor"
      opacity="0.1"
      :fill-opacity="0.5"
    />

    <rect
      v-for="i in 12"
      :key="i"
      :x="6.5 + 4 * (i - 1)"
      y="8"
      width="2"
      height="2"
      rx="1"
      fill="currentcolor"
      opacity="0.1"
      :fill-opacity="0.5"
    />

    <rect
      v-for="i in 16"
      :key="i"
      :x="0.5 + 4 * (i - 1)"
      y="12"
      width="2"
      height="2"
      rx="1"
      fill="currentcolor"
      opacity="0.1"
      :fill-opacity="0.5"
    />

    <rect
      v-for="i in 12"
      :key="i"
      :x="6.5 + 4 * (i - 1)"
      y="16"
      width="2"
      height="2"
      rx="1"
      fill="currentcolor"
      opacity="0.1"
      :fill-opacity="0.5"
    />
  </svg>
</template>
