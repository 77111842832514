<script setup lang="ts">
// Not an official figma component, but an abstraction round the following variant of the modal
// https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?type=design&node-id=1485-74961&mode=dev
// The x button top right is still missing, because figma renders it as + which is probably wrong
import { ref } from 'vue'
import DarwinButton from './DarwinButton.vue'
import ModalDialog from './ModalDialog.vue'
import ModalDialogHeader from './ModalDialogHeader.vue'

withDefaults(
  defineProps<{
    open: boolean
    title: string
    /**
     * The variant of the confirm button. The cancel button always has a 'neutral' variant.
     */
    variant?: 'black' | 'critical'
    description: string
    cancelText?: string
    confirmText?: string
    /**
     * CSS selector for the DOM element to which this dialog will be teleported.
     */
    to?: string
    /**
     * Usually the dialog will automatically be placed on top of other content
     * because of its position in the DOM. However, sometimes you might need
     * to teleport the dialog to a different container, and DOM order alone
     * might not be enough to assure its rendered on top. In these cases,
     * this prop can be used to force a specific z-index.
     */
    forceZIndex?: number
  }>(),
  {
    cancelText: 'Cancel',
    confirmText: 'Delete',
    variant: 'critical',
    to: 'body',
    forceZIndex: undefined,
  },
)
const emit = defineEmits<{ (e: 'confirm' | 'close', event: Event): void }>()

const cancelButton = ref<InstanceType<typeof DarwinButton>>()
const focusCancel = () => cancelButton.value?.$el.focus()
const confirmButton = ref<InstanceType<typeof DarwinButton>>()
const focusConfirm = () => confirmButton.value?.$el.focus()

const headerId = 'confirmation-dialog-header'
</script>

<template>
  <ModalDialog
    :to="to"
    :open="open"
    :aria-labelledby="headerId"
    :force-z-index="forceZIndex"
    @close="emit('close', $event)"
  >
    <template #header>
      <ModalDialogHeader
        :id="headerId"
        :title="title"
        :description="description"
      />
    </template>
    <template
      v-if="$slots.default"
      #body
    >
      <slot />
    </template>
    <template #footer>
      <DarwinButton
        ref="cancelButton"
        variant="neutral"
        size="md"
        tabindex="1"
        @click="emit('close', $event)"
        @keydown.right="focusConfirm"
      >
        {{ cancelText }}
      </DarwinButton>
      <DarwinButton
        ref="confirmButton"
        :variant="variant"
        size="md"
        data-test="confirm-btn"
        @click="emit('confirm', $event)"
        @keydown.left="focusCancel"
      >
        {{ confirmText }}
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
