import { createSharedComposable } from '@vueuse/core'
import { ref } from 'vue'

/**
 * Returns a computed prop that indicates whether Intercom has been loaded
 */
export const useHasLoadedIntercom = createSharedComposable(() => {
  const hasLoadedIntercom = ref(false)

  return hasLoadedIntercom
})
