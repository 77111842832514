<script setup lang="ts">
import { removeProject } from '@/backend/removeProject'
import { useRefreshUsage } from '@/modules/Billing/useRefreshUsage'
import { useProjects } from '@/modules/Projects/useProjects'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

const props = defineProps<{
  workspaceId: string
  projectId: string
  open: boolean
}>()

const emit = defineEmits<{
  (e: 'close' | 'delete'): void
}>()

const { refreshUsageNTimes } = useRefreshUsage()

const projectsStore = useProjects()

const deleteProject = async () => {
  const result = await removeProject(props.workspaceId, props.projectId)

  if (result.ok) {
    projectsStore.removeProject(props.projectId)
  }

  refreshUsageNTimes(3)

  emit('delete')
}
</script>

<template>
  <ConfirmationDialog
    :open="props?.open"
    title="Delete this project?"
    description="This project will be deleted immediately. You can't undo this action."
    @confirm="deleteProject"
    @close="emit('close')"
  />
</template>
