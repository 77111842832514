import { useRefreshUsage } from '@/modules/Billing/useRefreshUsage'
import { ref, watchEffect, type ComputedRef } from 'vue'

const hasToggledManually = ref(false)
const isOpen = ref(true)

export function useSidebar(open?: ComputedRef<boolean>) {
  const { refreshUsage, hasSkippedRefresh } = useRefreshUsage()
  function toggle() {
    isOpen.value = !isOpen.value

    if (isOpen.value && hasSkippedRefresh.value) {
      // We show plan usage in the sidebar, so refresh it when the sidebar is
      // opened to ensure it's up to date.
      refreshUsage()
    }

    hasToggledManually.value = true
  }

  watchEffect(() => {
    if (!hasToggledManually.value && open) {
      isOpen.value = open?.value
    }
  })
  return { isOpen, toggle }
}
