<script setup lang="ts">
import { PROJECT_NAME_FALLBACK } from './constants'

const props = defineProps<{
  project: { name: string | null }
}>()
</script>

<template>
  <span
    class="size-full"
    data-sentry="unmask"
  >
    {{ props.project.name ?? PROJECT_NAME_FALLBACK }}
  </span>
</template>
