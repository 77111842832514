export const isNil = <T>(value: T | null | undefined): value is null | undefined =>
  value === null || value === undefined

export const isEqual = <T>(a?: T[] | null, b?: T[] | null): boolean => {
  if (isNil(a) && isNil(b)) {
    return true
  }
  if (isNil(a) || isNil(b)) {
    return (a?.length ?? 0) === (b?.length ?? 0)
  }
  return a.length === b.length && a.every((v, i) => v === b[i])
}

export function isHtmlElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement
}

export function isHtmlInputElement(element: unknown): element is HTMLInputElement {
  return element instanceof HTMLInputElement
}

export function isHtmlTextAreaElement(element: unknown): element is HTMLTextAreaElement {
  return element instanceof HTMLTextAreaElement
}

export function isMac(): boolean {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0
}

export function isFunction(value: unknown): value is (...args: unknown[]) => unknown {
  return typeof value === 'function'
}
