import type { operations } from '@/api'
import { post } from './client'

export type BulkRemoveEntityPath =
  `/api/workspaces/${string}/projects/${string}/entities/bulk_delete`

export type BulkRemoveEntityRequest = Exclude<
  operations['entity-bulk-delete']['requestBody'],
  undefined
>['content']['application/json']

export type BulkRemoveEntityResponse =
  operations['entity-bulk-delete']['responses']['200']['content']['application/json']

export const bulkRemoveEntities = (workspaceId: string, projectId: string, entityIds: string[]) =>
  post<BulkRemoveEntityRequest, BulkRemoveEntityResponse, BulkRemoveEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/bulk_delete`,
    {
      entity_ids: entityIds,
    },
  )
