import { SUPPORTED_MIME_TYPES } from '@/backend/types'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { computed } from 'vue'

export const useSupportedMimeTypes = () => {
  const xlsxSplitEnabled = useFeatureFlags(FeatureFlag.XLSX_SPLIT, false)
  const fileSplitEnabled = useFeatureFlags(FeatureFlag.FILE_SPLITTER, false)

  const canUploadXlsx = computed(() => xlsxSplitEnabled.value || fileSplitEnabled.value)

  return computed<string[]>(() => {
    if (!canUploadXlsx.value) {
      return [...SUPPORTED_MIME_TYPES]
    }

    return [
      ...SUPPORTED_MIME_TYPES,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
  })
}
