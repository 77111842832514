<script setup lang="ts">
import { useParentProject } from '@/modules/Project/useParentProject'
import type { View } from '@/modules/Project/useProject'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import { type Project } from '@/modules/Projects/useProjects'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    parentView?: View
    project: Project
  }>(),
  {
    parentView: undefined,
  },
)

const { workspaceId, parentEntityId, parentProjectId, parentViewId } = useRouteParams()
const parentProjectStore = useParentProject()
const serializeFieldToText = useSerializeFieldToText()

const route = computed<RouteLocationRaw>(() => ({
  name: 'WorkspaceSubProjectTable',
  params: {
    workspaceId: workspaceId.value,
    parentProjectId: parentProjectId.value,
    projectId: props.project.id,
  },
  query: {
    parentEntityId: parentEntityId.value,
    parentViewId: parentViewId.value,
  },
}))

const manualCollectionName = computed(() => {
  const field = parentProjectStore.parentField
  if (!field) return

  if (field.type === 'collection') {
    return field.manualName
  } else if (field.type === 'file_collection') {
    return field.manualFilename || field.manualName
  }

  return ''
})

const parentEntityName = computed(() => {
  const mainPropId = props.parentView?.propertyIds.at(0)
  if (!mainPropId) return

  const field = parentProjectStore.parentEntity?.fields.get(mainPropId)
  return field ? serializeFieldToText(field) : ''
})

const label = computed(() => {
  if (manualCollectionName.value) {
    return manualCollectionName.value
  }

  let name = props.project.parentProperty?.name || props.project.name
  if (parentEntityName.value) {
    name += ` - ${parentEntityName.value}`
  }
  return name
})
</script>

<template>
  <CrumbItem :to="route">{{ label }}</CrumbItem>
</template>
