import type { MaybeRef } from '@vueuse/shared'
import { computed, onBeforeUnmount, watch } from 'vue'

import {
  type EntityResponse,
  type LibraryItemResponse,
  type PropertyResponse,
} from '@/backend/types'
import type { Project } from '@/modules/Projects/useProjects'

import { useWebSocketChannel } from '@/sharedComposables/useWebSocketChannel'

import type { components } from '@/api'
import type { UpdateViewResponse } from '@/backend/updateView'

import { serializeProperty, serializeView, type Property, type View } from './useProject'

type EntityOrLibraryItem = EntityResponse | LibraryItemResponse

export type ProjectChannelHandlers = {
  onCreateEntity: (entity: EntityOrLibraryItem) => void
  onDeleteEntities: (entityId: string[]) => void
  onUpdateEntity: (entity: EntityOrLibraryItem) => void
  onDeleteProperty: (propertyId: string) => void
  onUpdateProperty: (property: Property) => void
  onUpdateField: (field: components['schemas']['Projects.Entities.FieldResponse']) => void
  onDeleteView: (viewId: string) => void
  onDeleteActiveView: (params: { entityId: string; viewId: string }) => void
  onUpdateView: (view: View) => void
  onSetStale: () => void
}

export const useProjectChannel = (
  projectId: MaybeRef<Project['id'] | undefined>,
  handlers: ProjectChannelHandlers,
) => {
  const topic = computed<`project:${string}` | null>(() => {
    if (typeof projectId === 'string') {
      return `project:${projectId}`
    }

    if (!projectId?.value) {
      return null
    }

    return `project:${projectId.value}`
  })

  const { channel, channelState, leaveChannel } = useWebSocketChannel(topic)

  onBeforeUnmount(async () => {
    await leaveChannel()
  })

  watch(
    channel,
    () => {
      channel.value?.on('property:updated', (data: PropertyResponse) => {
        handlers.onUpdateProperty(serializeProperty(data))
      })
      channel.value?.on('property:deleted', (data: { property_id: string }) => {
        handlers.onDeleteProperty(data.property_id)
      })
      channel.value?.on('entity:updated', (data: EntityResponse) => {
        handlers.onUpdateEntity(data)
      })
      channel.value?.on('entity:created', (data: EntityResponse) => {
        handlers.onCreateEntity(data)
      })
      channel.value?.on('entity:deleted', (data: { entity_id: string }) => {
        handlers.onDeleteEntities([data.entity_id])
      })
      channel.value?.on('entity:bulk_deleted', (data: { entity_ids: string[] }) => {
        handlers.onDeleteEntities(data.entity_ids)
      })
      channel.value?.on(
        'entity:active_view_removed',
        (data: { entity_id: string; view_id: string }) => {
          handlers.onDeleteActiveView({ viewId: data.view_id, entityId: data.entity_id })
        },
      )
      channel.value?.on(
        'field:updated',
        (data: components['schemas']['Projects.Entities.FieldResponse']) => {
          handlers.onUpdateField(data)
        },
      )
      channel.value?.on('view:updated', (data: UpdateViewResponse) => {
        handlers.onUpdateView(serializeView(data))
      })
      channel.value?.on('view:deleted', (data: { id: string }) => {
        handlers.onDeleteView(data.id)
      })
      channel.value?.on('entities:stale', handlers.onSetStale)
    },
    { immediate: true },
  )

  return {
    channelState,
    leaveChannel,
  }
}
