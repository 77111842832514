import { listImports } from '@/backend/listImports'
import type { ImportResponse } from '@/backend/startImport'
import { objectFilter } from '@/shared/utils'
import * as sentry from '@sentry/vue'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { toast } from '@/shared/toast'
import type { ExternalToast } from '@/shared/toast/types'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { PAYWALL_EVENT, usePaywallStore } from '../Billing/paywall'
import { useCsvImportModal } from './CsvImportModal.vue'
import { useWorkspaces } from './useWorkspaces'

/**
 * This store controls the notification system for CSV Imports.
 *
 * Since CSV Imports may be long running, we make the toasts persistent
 * to ensure that the user will receive them.
 *
 * We have 3 types of toasts: Loading, Success, and Error toasts.
 * The only thing in common, besides persistence, is that they can
 * be dismissed.
 *
 * We also provide a function that initializes a watcher,
 * which will fetch the imports whenever the workspaceId changes,
 * and show the relevant toasts, which:
 *  - Are from imports that happened within 24 hours
 *  - Have not been dismissed
 *
 * Otherwise, the toasts will be triggered by websocket events.
 */
export const useImports = defineStore('imports', () => {
  const store = useWorkspaces()
  const importsLoader = useDataLoader(() => {
    if (!store.currentWorkspace?.id) throw new Error('No current workspace')
    return listImports({
      workspaceId: store.currentWorkspace?.id ?? '',
    })
  })
  const router = useRouter()
  const modal = useCsvImportModal()
  const dismissed = useLocalStorage('dismissed-imports', [] as string[])
  const paywall = usePaywallStore()
  const { captureAnalyticsEvent } = useAnalytics()

  type ToastRecord = Record<
    string,
    {
      dismiss: () => void
    }
  >
  const loadingToasts = ref<ToastRecord>({})
  const concludedToasts = ref<ToastRecord>({})

  async function addToast(importData: ImportResponse) {
    if (Object.keys(loadingToasts.value).includes(importData.id)) {
      return
    }
    await new Promise((resolve) => setTimeout(resolve, 10))

    const t = toast.loading(`Importing ${importData.project_name}`, {
      dismissible: true,
      onDismiss() {
        dismissed.value.push(importData.id)
        loadingToasts.value = objectFilter(loadingToasts.value, (k) => k !== importData.id)
      },
    })

    loadingToasts.value[importData.id] = {
      dismiss: () => {
        toast.dismiss(t)
      },
    }
  }

  async function concludeToast(importData: ImportResponse) {
    loadingToasts.value[importData.id]?.dismiss()
    if (Object.keys(concludedToasts.value).includes(importData.id)) {
      return
    }
    // Small hack to avoid triggering multiple toasts at once, which causes animation bugs
    await new Promise((resolve) => setTimeout(resolve, 500))

    let toastId: string | number = -1

    const toastOptions = {
      dismissible: true,
      duration: Infinity,
      onDismiss() {
        dismissed.value.push(importData.id)
        concludedToasts.value = objectFilter(concludedToasts.value, (k) => k !== importData.id)
      },
    } satisfies ExternalToast

    const error = importData.error_details

    if (importData.status === 'error') {
      captureAnalyticsEvent(ANALYTICS_EVENT.CSV_IMPORT_FAILURE)

      let description = error?.message ?? 'Import failed'
      let action: ExternalToast['action'] = {
        label: 'Try again',
        onClick: () => {
          toastOptions.onDismiss()
          modal.open()
        },
      }

      switch (error?.code ?? '') {
        case 'internal_server_error': {
          description = "We weren't able to import your file. Please try again."
          break
        }
        case 'billing_limits_exceeded': {
          description = 'You have reached your project limit. Please upgrade your plan.'
          action = {
            label: 'Upgrade',
            onClick: () => {
              toastOptions.onDismiss()
              paywall.open({ action: PAYWALL_EVENT.CSV_IMPORT })
            },
          }
          break
        }
        case 'system_limits_exceeded': {
          break
        }
        case 'bad_request': {
          break
        }
        case 'invalid_import_file': {
          break
        }
        default: {
          sentry.captureException(new Error('Unexpected Import error'))
          break
        }
      }

      toastId = toast.error(`Import failed`, {
        ...toastOptions,
        description,
        action,
      })
    } else {
      captureAnalyticsEvent(ANALYTICS_EVENT.CSV_IMPORT_SUCCESS)

      let description = `${importData.project_name} has been successfully imported`
      let action: ExternalToast['action'] = {
        label: 'Open',
        onClick: () => {
          toastOptions.onDismiss()
          router.push(`/${importData.workspace_id}/projects/${importData.project_id}`)
        },
      }

      if (typeof error?.message === 'string') {
        // This means the import was successful, but an error still occurred somewhere,
        // e.g. it was only partially imported due to usage limits
        description =
          importData.error_details?.message ??
          `${importData.project_name} has been imported, but had some issues while doing so.`

        if (error.code === 'billing_limits_exceeded') {
          description = `You have reached your project limit. Please upgrade your plan.`
          action = {
            label: 'Go pro',
            onClick: () => {
              toastOptions.onDismiss()
              paywall.open({ action: PAYWALL_EVENT.CSV_IMPORT })
            },
          }
        }

        toastId = toast.warning(`Import completed with errors`, {
          ...toastOptions,
          description,
          action,
        })
      } else {
        toastId = toast.success(`Import completed`, {
          ...toastOptions,
          description,
          action,
        })
      }
    }

    concludedToasts.value[importData.id] = {
      dismiss: () => toast.dismiss(toastId),
    }

    loadingToasts.value = objectFilter(loadingToasts.value, (k) => k !== importData.id)
  }

  function init() {
    watch(
      () => store.currentWorkspace?.id,
      async () => {
        const response = await importsLoader.load()
        if (!response.ok) return

        const today = new Date()
        const imports = response.data.data
        // Newer toasts should fire last, so they can be on top of older toasts
        const oldestFirst = imports.toSorted(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        )
        oldestFirst.forEach(async (importData) => {
          const isWithin24Hours =
            new Date(importData.created_at).getTime() > today.getTime() - 24 * 60 * 60 * 1000
          const hasBeenDismissed = dismissed.value.includes(importData.id)
          const alreadyToasted = [
            ...Object.keys(loadingToasts.value),
            ...Object.keys(concludedToasts.value),
          ].includes(importData.id)

          if (!isWithin24Hours || hasBeenDismissed || alreadyToasted) {
            return
          }

          if (importData.status === 'processing') {
            addToast(importData)
          } else if (importData.status === 'error' || importData.status === 'complete') {
            concludeToast(importData)
          }
        })
      },
    )
  }

  return {
    addToast,
    concludeToast,
    init,
  }
})
