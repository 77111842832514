import { remove } from '@/backend/client'
import type { BackendMeta } from '@/backend/types'

export type DeleteEntity = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/entities/{entity_id}',
  'delete'
>

type DeleteEntityParams = {
  workspaceId: string
  projectId: string
  entityId: string
}

export const deleteEntity = ({ entityId, projectId, workspaceId }: DeleteEntityParams) =>
  remove<undefined, DeleteEntity['successResponse'], DeleteEntity['path']>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}`,
    undefined,
  )
