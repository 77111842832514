import { isHtmlElement } from '@/shared/utils'
import { useEventListener } from '@vueuse/core'
import { readonly, ref, watchEffect } from 'vue'
import type { useElement } from './useElement'

export function useElementValue(el: ReturnType<typeof useElement>) {
  const value = ref('')
  useEventListener(el, 'input', (e) => {
    if (!isHtmlElement(e.target) || !('value' in e.target)) return
    value.value = (e.target as HTMLInputElement).value
  })

  watchEffect(() => {
    if (!isHtmlElement(el.value)) return
    value.value = (el.value as HTMLInputElement).value
  })

  return readonly(value)
}
