<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import type { IconName } from '@/uiKit/IconName'
import PopOver from '@/uiKit/PopOver.vue'
import FieldTooltipContent from './FieldTooltipContent.vue'
import { POPOVER_Z_INDEX } from './useTableZIndices'

defineProps<{
  fieldHasGroundTruth?: boolean
  isCorrectedModelOutput?: boolean
  reasoning?: string | null
  hasGroundingClaim?: boolean
  lastCorrectedBy?: {
    name?: string | null
    avatarUrl?: string | null
  }
  tool?: {
    name: string
    icon: IconName
  }
  propertyType: PropertyType
  rowIndex: number
  colIndex: number
}>()

defineEmits<{
  (e: 'open' | 'reset' | 'lock'): void
}>()
const labelId = 'project-table-cell-tooltip-label'
</script>

<template>
  <PopOver
    :open="true"
    :placement="{ allowedPlacements: ['top-start'] }"
    :aria-labelledby="labelId"
    teleport-to="#grid-container"
    :floating-z-index="POPOVER_Z_INDEX"
  >
    <template #content>
      <FieldTooltipContent
        class="z-1 mb-2.5 ml-1.5"
        :data-colindex="colIndex"
        :data-rowindex="rowIndex"
        :field-has-ground-truth="fieldHasGroundTruth"
        :reasoning="reasoning"
        :is-corrected-model-output="isCorrectedModelOutput"
        :has-grounding-claim="hasGroundingClaim"
        :last-corrected-by="lastCorrectedBy"
        :tool="tool"
        :property-type="propertyType"
        @open-mousedown="$emit('open')"
        @reset-mousedown="$emit('reset')"
        @lock-mousedown="$emit('lock')"
      />
    </template>
  </PopOver>
</template>
