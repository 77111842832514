import { getProjectUsage } from '@/backend/getProjectUsage'
import type {
  Limit as BackendLimit,
  LimitWithUsage as BackendLimitWithUsage,
  Plan as BackendPlan,
  PlanTemplate as BackendPlanTemplate,
} from '@/backend/types'
import type { PartialRecord } from '@/shared/types'
import { omit } from '@/shared/utils'
import { dequal } from 'dequal'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import type { Project } from '../Projects/useProjects'
import type { LimitWithUsage } from './types'
import { type Plan, type PlanCatalog, type PlanTemplate } from './types'

export const serializePlanTemplate = (plan: BackendPlanTemplate): PlanTemplate => ({
  billingPeriod: plan.billing_period,
  limits: plan.limits,
  name: plan.name,
  provider: plan.provider,
  tierOrder: plan.tier_order,
})

export const serializePlan = (plan: BackendPlan): Plan => ({
  billingPeriod: plan.billing_period,
  limits: plan.limits,
  name: plan.name,
  provider: plan.provider,
  currentPeriodEnd: plan.current_period_end,
  currentPeriodStart: plan.current_period_start,
  id: plan.id,
  status: plan.status,
})

const serializeLimit = (limit: BackendLimitWithUsage | BackendLimit): LimitWithUsage => {
  let limitUsage = 'limit_usage' in limit ? limit.limit_usage : 0
  let limitValue = limit.limit_value === 'unlimited' ? Infinity : limit.limit_value
  /**
   * In the backend, platform tokens represent an absurdly low $-value. This results
   * in the token limits being extremely high, to the point that it looks a bit
   * ridiculous to the user (e.g. 12 trillion tokens per year). To make this more
   * human-readable, we divide the limit by 1,000,000 when representing a token limit
   * in the UI.
   */
  if (limit.name === 'tool_token_usage') {
    limitUsage = Math.round(limitUsage / 1_000_000)
    limitValue = Math.round(limitValue / 1_000_000)
  }

  return {
    aggregation: limit.aggregation,
    limitUsage,
    limitValue,
    name: limit.name,
    scope: limit.scope,
    type: limit.type,
  }
}

export const getTemplateFromActivePlan = (activePlan: Plan): PlanTemplate => ({
  billingPeriod: activePlan.billingPeriod,
  limits: activePlan.limits,
  name: activePlan.name,
  provider: activePlan.provider,
  tierOrder: 0,
})

/**
 * Pinia store to hold plans and billing information. It stores:
 * - The current plan that the user is on
 * - The available plans that the user can upgrade/downgrade to
 */
export const useBilling = defineStore('billing', () => {
  /**
   * The active plan that the user is on. Will only be null before the billing data
   * is loaded - the free plan is also returned by the backend as a 'plan'.
   */
  const activePlan = ref<Plan | null>(null)

  /**
   * Pro customers will manage their accounts on Stripe. Each customer will have a
   * unique URL to their billing portal, and this is where we store that URL.
   */
  const customerPortalUrl = ref<string | null>(null)

  /**
   * Limits and usage for each project in the workspace.
   */
  const projectLimits = ref<
    PartialRecord<
      Project['id'],
      PartialRecord<
        LimitWithUsage['name'],
        PartialRecord<LimitWithUsage['aggregation'], LimitWithUsage>
      >
    >
  >({})

  /** Load and set limits for the given project. */
  const loadProjectLimits = async ({
    projectId,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
  }) => {
    const res = await getProjectUsage({
      projectId: projectId,
      workspaceId: workspaceId,
    })

    if (!res.ok) {
      return
    }

    const limits = res.data.limits.map(serializeLimit)
    projectLimits.value[projectId] = limits.reduce<
      PartialRecord<
        LimitWithUsage['name'],
        PartialRecord<LimitWithUsage['aggregation'], LimitWithUsage>
      >
    >((acc, limit) => {
      if (!acc[limit.name]) {
        acc[limit.name] = {}
      }
      return {
        ...acc,
        [limit.name]: {
          ...acc[limit.name],
          [limit.aggregation]: limit,
        },
      }
    }, {})
  }

  /**
   * The entire catalog of plans that the user can upgrade/downgrade to, as well as the
   * plan that they are currently on.
   */
  const planCatalog = ref<PlanCatalog | null>(null)
  const setPlanCatalog = (templates: PlanTemplate[]) => {
    const freePlan = templates.find((t) => t.name === 'free')
    const proMonthly = templates.find((t) => t.name === 'pro' && t.billingPeriod === 'monthly')
    const proYearly = templates.find((t) => t.name === 'pro' && t.billingPeriod === 'yearly')

    if (!freePlan || !proMonthly || !proYearly) {
      throw new Error('Product catalog is missing a required plan')
    }

    planCatalog.value = {
      free: freePlan,
      pro: {
        monthly: proMonthly,
        yearly: proYearly,
      },
    }
  }

  // For a given plan and limit name, get the value for the limit on that plan
  const getLimit = (
    planName: Extract<PlanTemplate['name'], 'free' | 'pro'>,
    billingPeriod: PlanTemplate['billingPeriod'],
    limitName: PlanTemplate['limits'][number]['name'],
    aggregation: PlanTemplate['limits'][number]['aggregation'],
  ): PlanTemplate['limits'][number]['limit_value'] => {
    if (!planCatalog.value) {
      throw new Error('Product catalog is not set - cannot get limit')
    }

    const plan = planName === 'free' ? planCatalog.value.free : planCatalog.value.pro[billingPeriod]

    let limitFromCatalog = plan.limits.find(
      (l) => l.name === limitName && l.scope === 'workspace' && l.aggregation === aggregation,
    )?.limit_value

    /**
     * In the backend, platform tokens represent an absurdly low $-value. This results
     * in the token limits being extremely high, to the point that it looks a bit
     * ridiculous to the user (e.g. 12 trillion tokens per year). To make this more
     * human-readable, we divide the limit by 1,000,000 when representing a token limit
     * in the UI.
     */
    if (limitName === 'tool_token_usage' && typeof limitFromCatalog === 'number') {
      limitFromCatalog = Math.round(limitFromCatalog / 1_000_000)
    }

    // If the limit is undefined, there is no limit (i.e. it is unlimited)
    return limitFromCatalog === undefined ? 'unlimited' : limitFromCatalog
  }

  const fieldUsage = ref<LimitWithUsage | null>(null)
  const tokenUsage = ref<LimitWithUsage | null>(null)
  const seatUsage = ref<LimitWithUsage | null>(null)

  watch(
    activePlan,
    (plan) => {
      if (!plan) {
        return
      }

      const fieldLimit = plan.limits.find((l) => l.name === 'field_count')
      if (fieldLimit) {
        const newUsage = serializeLimit(fieldLimit)
        // We only want to replace the fieldUsage in case
        // something besides the limit usage changed. Otherwise,
        // the FE already optimistically updated the limit value.
        const isSame =
          fieldUsage.value &&
          dequal(omit(fieldUsage.value, 'limitUsage'), omit(newUsage, 'limitUsage'))
        if (!fieldUsage.value || !isSame) {
          fieldUsage.value = newUsage
        }
      }

      const tokenLimit = plan.limits.find(
        (l) =>
          l.name === 'tool_token_usage' &&
          // Free plans don't have a 'billing period' aggregation
          // so we need to take the total usage
          (plan.name === 'free' ? l.aggregation === 'total' : l.aggregation === 'billing_period'),
      )
      if (tokenLimit) {
        tokenUsage.value = serializeLimit(tokenLimit)
      }

      const seatLimit = plan.limits.find((l) => l.name === 'member_count')
      if (seatLimit) {
        seatUsage.value = serializeLimit(seatLimit)
      }

      return
    },
    {
      immediate: true,
    },
  )

  return {
    activePlan,
    getLimit,

    customerPortalUrl,

    planCatalog,
    setPlanCatalog,

    projectLimits,
    loadProjectLimits,

    fieldUsage,
    tokenUsage,
    seatUsage,
  }
})
