import type { components } from '@/api'
import { post } from './client'

const PATH = '/api/workspaces'

export type CreateWorkspacePath = '/api/workspaces'
export type CreateWorkspaceResponse = components['schemas']['Workspaces.WorkspaceResponse']
export type CreateWorkspaceRequest = components['schemas']['Workspaces.CreateWorkspaceRequest']

export const createWorkspace = (name?: string) =>
  post<CreateWorkspaceRequest, CreateWorkspaceResponse>(PATH, { name })
