import { objectMapBy } from '@/shared/utils'
import { computed, type Ref } from 'vue'
import type { LayoutItem } from './EntityViewCard.vue'
import type { Entity, Property, View } from './useProject'

/**
 * Provides a grid layout for the fields of an entity, based on the view's property
 * layout.
 */
export const useEntityGrid = ({
  entity,
  properties,
  view,
}: {
  entity: Ref<Entity | undefined | null>
  view: Ref<View | undefined>
  properties: Ref<Property[]>
}) => {
  const layoutInfo = computed(() => {
    const getNewCoords = (idx: number) => {
      const x = (idx * 4) % 12
      const y = Math.floor((idx + 4) / 12)
      return { x, y, w: 4, h: 4 }
    }

    return properties.value.map((property, idx) => {
      return {
        i: property.id,
        readonly:
          view.value?.propertyOptions?.find((option) => option.property_id === property.id)
            ?.block_workers_edits ?? false,
        property,
        ...(view.value?.propertyLayouts
          .map((layout) => ({
            ...layout,
            x: layout.x,
            y: layout.y,
            w: layout.width,
            h: layout.height,
          }))
          .find((layout) => layout.propertyId === property?.id) ?? getNewCoords(idx)),
      }
    })
  })

  const layoutItems = computed<LayoutItem[]>(() => {
    const mapped = objectMapBy(layoutInfo.value, 'i')
    return Array.from(entity.value?.fields ?? [], ([propertyId, field]) => {
      const layout = mapped[propertyId]
      return {
        ...layout,
        field: field,
      }
    }).filter((item) => view.value?.propertyIds?.includes(item.property?.id ?? ''))
  })

  return {
    layoutItems,
    layoutInfo,
  }
}
