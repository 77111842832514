<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useAuth0 } from '@auth0/auth0-vue'

withDefaults(
  defineProps<{
    title?: string
    message?: string
    showButton?: boolean
    showIcon?: boolean
  }>(),
  {
    title: 'Something went wrong',
    message: undefined,
    showButton: true,
    showIcon: true,
  },
)

const { isAuthenticated } = useAuth0()
</script>

<template>
  <div
    class="grid size-full gap-px bg-border-subtle [&>*]:bg-surface-primary-persist"
    :style="{
      gridTemplateColumns: 'minmax(0, 1fr) minmax(60%, auto) minmax(0, 1fr)',
      gridTemplateRows: 'minmax(0, 1fr) auto minmax(0, 1fr)',
    }"
  >
    <div />
    <div />
    <div />
    <div />
    <div class="flex h-max min-w-[70%] flex-col items-center gap-6 p-10">
      <IconSprite
        v-if="showIcon"
        class="h-3.5 text-icon-subtle"
        size="md"
        icon="warning-fill"
      />
      <div class="text-center">
        <h1 class="mb-1 text-md-13px-bold text-text">
          {{ title }}
        </h1>
        <p
          v-if="message"
          class="text-sm-12px-light text-text-subtle"
        >
          {{ message }}
        </p>
      </div>
      <DarwinButton
        v-if="showButton"
        variant="neutral"
        size="md"
        href="/"
        >{{ isAuthenticated ? 'Back home' : 'Retry' }}</DarwinButton
      >
    </div>
    <div />
    <div />
    <div />
    <div />
  </div>
</template>
