import type { components, operations } from '@/api'
import { get } from './client'

export type ListEntityIdsPath = `/api/workspaces/${string}/projects/${string}/entity_ids`
export type ListEntityIdsResponse =
  components['schemas']['Projects.ListEntities.ListEntityIdsResponse']
export type ListEntityIdsRequest = operations['entity-id-list']['parameters']['query']

export type ListEntityIdsArgs = {
  workspaceId: string
  projectId: string
  parent_entity_id?: string
  active_view_id?: string
} & (
  | {
      after: string
      first: number
      before?: undefined
      last?: undefined
    }
  | {
      before: string
      last: number
      after?: undefined
      first?: undefined
    }
)

export const listEntityIds = ({
  workspaceId,
  projectId,
  after,
  first,
  before,
  last,
  parent_entity_id,
  active_view_id,
}: ListEntityIdsArgs) =>
  get<ListEntityIdsRequest, ListEntityIdsResponse, ListEntityIdsPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entity_ids`,
    {
      order_by: ['id'],
      after,
      first,
      before,
      last,
      parent_entity_id,
      active_view_id: active_view_id,
    },
  )
