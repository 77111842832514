import { post } from './client'
import type { BackendMeta } from './types'

export type CreateToolKey = BackendMeta<'/api/workspaces/{workspace_id}/tool_keys', 'post'>
type Body = CreateToolKey['body']

export const createToolKey = ({
  workspaceId,
  integration,
  secretValue,
  secretProjectId,
}: {
  workspaceId: string
  integration: Body['integration']
  secretValue: Body['secret_value']
  secretProjectId: Body['secret_project_id']
}) =>
  post<Body, CreateToolKey['successResponse'], CreateToolKey['path']>(
    `/api/workspaces/${workspaceId}/tool_keys`,
    {
      integration,
      secret_value: secretValue,
      secret_project_id: secretProjectId,
    },
  )
