import type { operations } from '@/api'

import { remove } from './client'

export type DeleteProjectCoverImagePath = `/api/workspaces/${string}/projects/${string}`

export type DeleteProjectCoverImageResponse =
  operations['project-remove-cover-image']['responses']['200']['content']['application/json']

export const deleteProjectCoverImage = async ({
  workspaceId,
  projectId,
}: {
  workspaceId: string
  projectId: string
}) => {
  return remove<unknown, DeleteProjectCoverImageResponse, DeleteProjectCoverImagePath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/cover_image`,
    {},
  )
}
