import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { ref } from 'vue'
import { useLibraryStore, type LibraryDocument, type LibraryItem } from './libraryStore'
import { useLibraryBackend } from './useLibraryBackend'

/**
 * This composable provides methods to create and edit library documents,
 * and to manage the state of the library document dialog. It should be
 * used whenever there is a need to create or edit library documents.
 */
export const useLibraryDocument = () => {
  const { captureAnalyticsEvent } = useAnalytics()

  const activeItem = ref<LibraryDocument | null>(null)

  const libraryStore = useLibraryStore()
  const isEditingLibraryDocument = ref(false)
  const onClickCreateDocument = () => {
    activeItem.value = null
    isEditingLibraryDocument.value = true
  }

  const libraryBackend = useLibraryBackend()
  const onSaveDocument = async ({
    content,
    title,
    workspaceId,
  }: {
    title: string
    content: string
    workspaceId: string
  }) => {
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_CREATED_DOCUMENT)

    assertIsNotNullOrUndefined(libraryStore.libraryId, 'Library is not loaded')
    let itemId = activeItem.value?.id
    if (!itemId) {
      itemId = await libraryBackend.getLibraryItemToCreateId({
        libraryId: libraryStore.libraryId,
        workspaceId,
      })
      libraryStore.setItem({
        id: itemId,
        type: 'text',
        content,
        name: title,
        status: 'complete',
        updatedAt: new Date().toISOString(),
        inputReference: [],
      })
    }

    isEditingLibraryDocument.value = false
    return libraryBackend.saveTextValue({
      content,
      id: itemId,
      workspaceId,
      name: title,
    })
  }

  const onClose = () => {
    isEditingLibraryDocument.value = false
  }

  const onEditDocument = (libraryItem: LibraryItem) => {
    if (libraryItem.type !== 'text') {
      throw new Error('Only text documents can be edited')
    }

    activeItem.value = libraryItem

    isEditingLibraryDocument.value = true
  }

  return {
    onClickCreateDocument,
    isEditingLibraryDocument,
    onSaveDocument,
    onEditDocument,
    activeItem,
    onClose,
  }
}
