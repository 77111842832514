import * as LDClient from 'launchdarkly-js-client-sdk'
import { defineStore } from 'pinia'
import { readonly, ref, watch } from 'vue'
import { useUser } from '../IdentityAndAccess/useUser'

export const useLDClient = defineStore('launch-darkly', () => {
  const VITE_LAUNCH_DARKLY_CLIENT_ID: string | undefined = import.meta.env
    .VITE_LAUNCH_DARKLY_CLIENT_ID
  const userStore = useUser()
  const client = ref<LDClient.LDClient | null>(null)
  const isReady = ref(false)

  watch(
    () => userStore.user,
    (user) => {
      if (!user || client.value || !VITE_LAUNCH_DARKLY_CLIENT_ID) return

      client.value = LDClient.initialize(VITE_LAUNCH_DARKLY_CLIENT_ID, {
        email: user.email,
        key: user.id,
        anonymous: false,
        name: [user.firstName, user.lastName].filter(Boolean).join(' '),
      })
      client.value.on('ready', () => (isReady.value = true))
    },
  )

  return { client: readonly(client), isReady: readonly(isReady) }
})
