<script lang="ts">
export type SelectItem = {
  label: string
  value: string
  icon?: IconName
}
</script>

<script setup lang="ts" generic="Item extends SelectItem">
import * as select from '@zag-js/select'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, watch } from 'vue'
import type { IconName } from '../IconName'
import IconSprite from '../IconSprite.vue'
import SelectButton from './SelectButton.vue'

const props = withDefaults(
  defineProps<{
    /** Trigger props */

    /** The text to show when no selection is made. */
    placeholder?: string
    icon?: IconName | null
    /**
     * When true, the dropdown trigger will have a transparent
     * background and hover effect.
     */
    inline?: boolean
    size?: 'md' | 'lg'

    /** Content props */

    items: Item[]
    positioning?: select.PositioningOptions

    /** General props */

    value?: Item['value']
  }>(),
  {
    placeholder: 'Add...',
    icon: 'chevron-select',
    size: 'md',
    value: undefined,
    positioning: undefined,
  },
)

const emit = defineEmits<{
  (e: 'change', value: Item['value']): void
}>()

const [state, send] = useMachine(
  select.machine({
    id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    collection: select.collection({
      items: props.items,
    }),
    value: props.value ? [props.value] : undefined,
    onValueChange: ({ value }) => {
      emit('change', value[0])
    },
    positioning: props.positioning,
  }),
)

watch(
  () => props.value,
  (newValue) => {
    if (!newValue) return
    api.value.setValue([newValue])
  },
)

const api = computed(() => select.connect(state.value, send, normalizeProps))

const selectedItem = computed(() => {
  return props.items.find((i) => i.value === api.value.value[0])
})
</script>

<template>
  <SelectButton
    :active="api.open"
    :inline="inline"
    :size="size"
    :leading-icon="selectedItem?.icon"
    :trailing-icon="icon"
    v-bind="{ ...api.getTriggerProps(), ...$attrs }"
  >
    <span class="text-text">{{ api.valueAsString || placeholder }}</span>
  </SelectButton>

  <Teleport to="body">
    <div v-bind="api.getPositionerProps()">
      <ul
        class="group min-h-0 min-w-[220px] cursor-pointer flex-col items-stretch gap-0 rounded-corner-10 bg-surface-popover p-0.5 shadow-lg outline outline-1 outline-border-subtle"
        :class="api.open ? 'flex' : 'hidden'"
        v-bind="api.getContentProps()"
      >
        <li
          v-for="item in items"
          :key="item.value"
          class="flex min-h-7 w-full flex-row items-center justify-start gap-1 self-stretch rounded-corner-8 px-1.5 py-1 text-sm-12px-default text-icon outline-none transition data-[highlighted]:bg-background-transparent-hovered"
          v-bind="api.getItemProps({ item })"
        >
          <slot
            name="item"
            :item="item"
            :api="api"
          >
            <div class="flex items-center gap-1">
              <IconSprite
                v-if="item.icon"
                :icon="item.icon"
                class="text-icon-subtle"
              />
              <span class="px-1 text-sm-12px-default">{{ item.label }}</span>
            </div>
          </slot>
        </li>
      </ul>
    </div>
  </Teleport>
</template>
