import type { operations } from '@/api'
import { get } from './client'

export type GetExportPath = `/api/workspaces/${string}/projects/${string}/exports/${string}`
export type GetExportResponse =
  operations['export-get']['responses']['200']['content']['application/json']

export const getExport = (workspaceId: string, projectId: string, exportId: string) =>
  get<undefined, GetExportResponse, GetExportPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/exports/${exportId}`,
    undefined,
  )
