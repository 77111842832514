<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
  value: string
  json: boolean
  infoText?: string
  readonly: boolean
  label?: string
}>()

const emit = defineEmits<{
  (e: 'submit' | 'enter', value: string): void
  (e: 'blur'): void
}>()

defineExpose({
  focus: () => {
    textarea.value?.focus()
  },
})

const tabIndex = ref(-1)

const localValue = ref(props.value)

watch(
  () => props.value,
  () => {
    localValue.value = props.value
  },
)

const updateLocalValue = (e: Event) => {
  localValue.value = (e.target as HTMLTextAreaElement).value
}

const onBlur = () => {
  tabIndex.value = -1

  const valueHasChanged = localValue.value !== props.value
  if (!valueHasChanged) {
    emit('blur')
    return
  }

  emit('submit', localValue.value)
}

const onEnter = () => {
  emit('enter', localValue.value)
}

const textarea = ref<HTMLTextAreaElement | null>(null)
</script>

<template>
  <div class="size-full rounded-corner-6">
    <textarea
      ref="textarea"
      :aria-label="label"
      :readonly="Boolean(infoText) || readonly"
      :tabindex="tabIndex"
      data-test="texteditor"
      :value="infoText ?? localValue"
      class="size-full resize-none bg-background-transparent px-2.5 text-md-13px-light text-text !outline-none placeholder:text-opacity-40 focus:outline-none"
      :class="{
        'font-mono': !infoText && json,
        'cursor-default': Boolean(infoText) || readonly,
      }"
      @input="updateLocalValue"
      @contextmenu.stop
      @focus="tabIndex = 0"
      @blur="onBlur"
      @keydown.esc="onBlur"
      @keydown.enter.stop="onEnter"
    />
  </div>
</template>
