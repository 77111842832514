import { createToolKey } from '@/backend/createToolKey'
import { deleteToolKey } from '@/backend/deleteToolKey'
import { listToolIntegrations } from '@/backend/listToolIntegrations'
import { listToolKeys } from '@/backend/listToolKeys'
import type { PropertyType, ToolGrounding } from '@/backend/types'
import { PropertyTool, ToolProvider, type ToolIntegration } from '@/backend/types'
import { defineStore } from 'pinia'
import { computed, ref, type ComputedRef, type Ref, type UnwrapRef } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'

type ProviderConfig = {
  id: string
  apiKeyPrefix?: string
}

type ProviderTool = {
  name: PropertyTool
  /** The types of properties that can be passed to this tool as inputs */
  supportedInputTypes: PropertyType[]
  /** The types of properties that this tool can output */
  supportedOutputTypes: PropertyType[]
  grounding: ToolGrounding | null
  maxInputCount: number | null
}

const serializeProviderTool = (backendTool: ToolIntegration): ProviderTool => {
  const maxInputCount =
    backendTool.supported_inputs.max_input_count === 'unlimited'
      ? Infinity
      : backendTool.supported_inputs.max_input_count

  const grounding = backendTool.grounding && {
    supportedInputTypes: backendTool.grounding.supported_inputs.properties,
    supportedOutputTypes: backendTool.grounding.supported_outputs.properties,
  }

  return {
    name: backendTool.tool,
    supportedInputTypes: backendTool.supported_inputs.properties,
    supportedOutputTypes: backendTool.supported_outputs.properties,
    grounding,
    maxInputCount,
  }
}

export type ModelProviderStoreReturnType = {
  /**
   * Maps each provider to a boolean value indicating whether the
   * provider's models should be available for use
   */
  providers: Ref<Record<ToolProvider, { enabled: boolean; tools: ProviderTool[] }>>
  /**
   * Maps each provider to a config for its credentials. A null value means
   * that the provider's models use V7 platform tokens.
   */
  providerConfig: Ref<Record<ToolProvider, ProviderConfig | null>>
  /**
   * A function that is used to load a the workspace's available tools.
   */
  refreshTools: (workspaceId: string) => Promise<void>
  /**
   * A function that is used to load a workspace's provider config.
   */
  refreshProviderConfig: (workspaceId: string) => Promise<void>
  /**
   * A function that is used to add credentials for a provider.
   */
  addCredentials: (args: {
    secretValue: string
    secretProjectId: string
    provider: ToolProvider
    workspaceId: string
  }) => Promise<boolean>
  /**
   * Removes credentials from a provider, so that the workspace will use Go
   * platform tokens for that provider's models.
   */
  removeCredentials: (args: { workspaceId: string; provider: ToolProvider }) => Promise<boolean>
  /**
   * A map from a tool name to its grounding configuration.
   * If a tool is not present in this map, it is not groundable.
   */
  groundingConfigs: ComputedRef<Map<PropertyTool, ToolGrounding>>
  /** All tools that are exposed by the backend */
  availableTools: ComputedRef<PropertyTool[]>
  /** Tools that are enabled in the workspace */
  enabledTools: ComputedRef<PropertyTool[]>
}

const INITIAL_PROVIDERS: UnwrapRef<ModelProviderStoreReturnType['providers']> = {
  anthropic: { enabled: false, tools: [] },
  google_ai: { enabled: false, tools: [] },
  open_ai: { enabled: false, tools: [] },
  azure_ocr: { enabled: false, tools: [] },
  internal: { enabled: false, tools: [] },
  bing_search: { enabled: false, tools: [] },
  fire_crawl: { enabled: false, tools: [] },
  azure_open_ai: { enabled: false, tools: [] },
  amazon_textract: { enabled: false, tools: [] },
  vertex_ai_claude: { enabled: false, tools: [] },
}

const INITIAL_PROVIDER_CONFIG: UnwrapRef<ModelProviderStoreReturnType['providerConfig']> = {
  anthropic: null,
  google_ai: null,
  open_ai: null,
  azure_ocr: null,
  bing_search: null,
  fire_crawl: null,
  internal: null,
  azure_open_ai: null,
  amazon_textract: null,
  vertex_ai_claude: null,
}

/**
 * Users can enable/disable the use of models from different providers, and can set up
 * provider-specific configurations for using those models. This store manages the state
 * of those settings.
 */
export const useModelProviders = defineStore<'modelProviders', ModelProviderStoreReturnType>(
  'modelProviders',
  () => {
    const providers: ModelProviderStoreReturnType['providers'] = ref({ ...INITIAL_PROVIDERS })

    const isWhisperToolEnabled = useFeatureFlags(FeatureFlag.WHISPER_TOOL)
    const isBingSearchEnabled = useFeatureFlags(FeatureFlag.BING_SEARCH_ENABLED)
    const isUrlScrapeEnabled = useFeatureFlags(FeatureFlag.URL_PROPERTY_TOOL)
    const isGoToolEnabled = useFeatureFlags(FeatureFlag.GO_TOOL_ENABLED)
    const isAwsOcrEnabled = useFeatureFlags(FeatureFlag.AMAZON_TEXTRACT)
    const isOpenAiO1Enabled = useFeatureFlags(FeatureFlag.OPENAI_O1)

    const availableTools = computed<PropertyTool[]>(() => {
      const allTools = Object.values(providers.value)
        .flatMap((p) => p.tools.map((t) => t.name))
        .filter((tool) => tool in PropertyTool)

      return allTools.filter((tool) => {
        if (tool === 'whisper' && !isWhisperToolEnabled.value) return false
        if (tool === 'bing_search' && !isBingSearchEnabled.value) return false
        if (tool === 'url_scrape' && !isUrlScrapeEnabled.value) return false
        if (tool === 'go' && !isGoToolEnabled.value) return false
        if (tool === 'aws_ocr' && !isAwsOcrEnabled.value) return false
        if (tool === 'o1' && !isOpenAiO1Enabled.value) return false
        if (tool === 'o1_mini' && !isOpenAiO1Enabled.value) return false

        // Internal BE tool that should not be visible on the FE
        if (tool === PropertyTool.file_split) return false

        /**
         * These tools perform worse in every benchmark than their 1.5 equivalents,
         * and cost the same. So we remove them from the FE. They are still on the
         * BE for backward compatibility.
         */
        if (tool === 'gemini_pro' || tool === 'gemini_pro_vision') return false

        return true
      })
    })

    const enabledTools = computed<PropertyTool[]>(() => {
      return availableTools.value.filter((tool) =>
        Object.values(providers.value).some(
          (p) => p.enabled && p.tools.some((t) => t.name === tool),
        ),
      )
    })

    const providerConfig: ModelProviderStoreReturnType['providerConfig'] = ref({
      ...INITIAL_PROVIDER_CONFIG,
    })

    const refreshTools = async (workspaceId: string) => {
      providers.value = { ...INITIAL_PROVIDERS }
      const res = await listToolIntegrations(workspaceId)
      if (!res.ok) {
        throw new Error('Failed to load tool integrations')
      }

      // this is the order in which the tools should be displayed
      const toolPriority = [
        PropertyTool.manual,
        PropertyTool.go,
        PropertyTool.o1,
        PropertyTool.o1_mini,
        PropertyTool.gpt_4o,
        PropertyTool.gpt_4o_mini,
        PropertyTool.gpt_4,
        PropertyTool.gpt_3_5,
        PropertyTool.dall_e_3,
        PropertyTool.whisper,
        PropertyTool.claude_3_5_sonnet,
        PropertyTool.claude_3_5_haiku,
        PropertyTool.claude_3_opus,
        PropertyTool.claude_3_haiku,
        PropertyTool.claude_3_sonnet,
        PropertyTool.gemini_1_5_pro,
        PropertyTool.gemini_1_5_flash,
        PropertyTool.gemini_pro,
        PropertyTool.gemini_pro_vision,
        PropertyTool.ocr,
        PropertyTool.aws_ocr,
        PropertyTool.bing_search,
        PropertyTool.url_scrape,
        PropertyTool.file_split,
        PropertyTool.code,
        PropertyTool.gpt_4o_azure,
        PropertyTool.gpt_3_5_azure,
        PropertyTool.imagen,
        PropertyTool.claude_3_5_sonnet_vertex_ai,
        PropertyTool.claude_3_5_haiku_vertex_ai,
        PropertyTool.claude_3_opus_vertex_ai,
        PropertyTool.claude_3_haiku_vertex_ai,
        PropertyTool.claude_3_sonnet_vertex_ai,
      ] as const

      const integrations = res.data.data
      integrations?.forEach(({ enabled, integration, tools }) => {
        providers.value[integration] = {
          enabled,
          tools: tools
            .map(serializeProviderTool)
            .toSorted((a, b) => toolPriority.indexOf(a.name) - toolPriority.indexOf(b.name)),
        }
      })
    }

    const refreshProviderConfig = async (workspaceId: string) => {
      providerConfig.value = { ...INITIAL_PROVIDER_CONFIG }
      const res = await listToolKeys(workspaceId)

      if (!res.ok) {
        throw new Error('Failed to load tool keys')
      }

      const integrations = res.data.data
      integrations?.forEach(({ secret_prefix, integration, id }) => {
        providerConfig.value[integration] = {
          id,
          apiKeyPrefix: secret_prefix,
        }
      })
    }

    const addCredentials: ModelProviderStoreReturnType['addCredentials'] = async ({
      secretValue,
      secretProjectId,
      provider,
      workspaceId,
    }: {
      secretValue: string
      secretProjectId: string
      provider: ToolProvider
      workspaceId: string
    }) => {
      const optimisticId = window.crypto.randomUUID()
      providerConfig.value[provider] = {
        id: optimisticId,
        apiKeyPrefix: secretValue.slice(0, 5),
      }

      const res = await createToolKey({
        workspaceId,
        integration: provider,
        secretValue: secretValue,
        secretProjectId: provider === ToolProvider.google_ai ? secretProjectId : undefined,
      })

      if (res.ok) {
        providerConfig.value[provider] = {
          id: res.data.id,
          apiKeyPrefix: secretValue.slice(0, 5),
        }
      } else {
        providerConfig.value[provider] = null
      }

      return res.ok
    }

    const removeCredentials: ModelProviderStoreReturnType['removeCredentials'] = async ({
      provider,
      workspaceId,
    }) => {
      const config = providerConfig.value[provider]
      if (!config) {
        return false
      }

      providerConfig.value[provider] = null
      const res = await deleteToolKey({ workspaceId, toolKeyId: config.id })

      if (!res.ok) {
        providerConfig.value[provider] = config
      }

      return res.ok
    }

    /**
     * A map from a tool name to its grounding configuration.
     * If a tool is not present in this map, it is not groundable.
     */
    const groundingConfigs = computed(() => {
      const entries: [PropertyTool, ToolGrounding][] = Object.values(providers.value)
        .flatMap(({ tools }) => tools)
        .filter(({ grounding }) => grounding)
        .map(({ name, grounding }) => [name, grounding as ToolGrounding])

      return new Map(entries)
    })

    return {
      providers,
      providerConfig,

      availableTools,
      enabledTools,

      groundingConfigs,

      refreshTools,
      refreshProviderConfig,

      addCredentials,
      removeCredentials,
    }
  },
)
