<script lang="ts" setup>
import { computed } from 'vue'
import ProjectTableCell from './ProjectTableCell.vue'
import TableCell from './TableCell.vue'
import TableRow from './TableRow.vue'
import TableRowCheckbox from './TableRowCheckbox.vue'
import { usePinnedColumn } from './usePinnedColumn'
import { useProject } from './useProject'
import { useTableZIndices } from './useTableZIndices'

const props = defineProps<{
  rowIndex: number
  cells: number[]
  workspaceId?: string
  projectId: string | null
  entityId?: string | null
  pluralSelection: boolean
  columnsInView: number[]
  indexColWidth: number
}>()

const emit = defineEmits<{
  (e: 'extend-selection' | 'toggle'): void
  (e: 'next', event?: KeyboardEvent): void
}>()

const projectStore = useProject()
const pinnedColumn = usePinnedColumn()
const pinnedColumns = computed(() => pinnedColumn.numPinnedProperties)

/** The number of columns that are out of the viewport to the left */
const columnsBeforeView = computed<number>(() => {
  const minInView = Math.min(...props.columnsInView)
  return props.cells.filter((cell) => cell < minInView && cell >= pinnedColumns.value).length
})

/** The number of columns that are out of the viewport to the right */
const columnsAfterView = computed<number>(() => {
  const maxInView = Math.max(...props.columnsInView)
  return props.cells.filter((cell) => cell > maxInView).length
})

const entityId = computed(() => projectStore.activeView?.entities?.[props.rowIndex]?.id || null)

const isSelected = computed(
  () => !!entityId.value && projectStore.selectedEntityIds.has(entityId.value),
)

const tableZIndex = useTableZIndices()
</script>

<template>
  <TableRow
    :is-selected="isSelected"
    :data-test="`row-${rowIndex}`"
    role="row"
    class="group contents"
    :aria-rowindex="rowIndex + 1"
  >
    <TableRowCheckbox
      :is-selected="isSelected"
      class="sticky left-0 border-b border-r border-border-subtle before:absolute before:-z-1 before:size-full group-hover/row:before:bg-background-gray-subtlest"
      :aria-colindex="1"
      :aria-rowindex="rowIndex + 2"
      :style="{ zIndex: tableZIndex.zIndex.pinnedColumn, width: indexColWidth + 'px' }"
      @mousedown.shift.prevent.stop.exact="emit('extend-selection')"
      @change="emit('toggle')"
      >{{ rowIndex + 1 }}
    </TableRowCheckbox>
    <!-- Pinned columns (currently we only allow one) -->
    <ProjectTableCell
      v-for="colIndex in [...new Array(pinnedColumns)].map((_, i) => i)"
      :key="colIndex"
      :row-is-selected="isSelected"
      class="sticky bg-surface-primary before:pointer-events-none before:absolute before:size-full last:border-l group-hover/row:before:bg-background-gray-subtlest [&:nth-last-child(2)]:border-r-0"
      :data-test="`col-${colIndex}`"
      :col-index="colIndex"
      :row-index="rowIndex"
      :style="{ ...pinnedColumn.getColStyle(colIndex, indexColWidth) }"
      @next="(e) => emit('next', e)"
    />
    <!-- Empty cells due to virtualization -->
    <TableCell
      v-if="columnsBeforeView"
      :style="{ gridColumn: `span ${columnsBeforeView} / span ${columnsBeforeView}` }"
      loading
      role="gridcell"
      :aria-rowindex="rowIndex + 2"
      :aria-colindex="2"
      :is-focused="false"
      :is-selected="false"
    />
    <!-- Actual cells in view (plus some due to overscan) -->
    <ProjectTableCell
      v-for="colIndex in columnsInView.filter((colIndex) => colIndex > pinnedColumns - 1)"
      :key="colIndex"
      :row-is-selected="isSelected"
      class="before:pointer-events-none before:absolute before:size-full last:border-l group-hover/row:before:bg-background-gray-subtlest [&:nth-last-child(2)]:border-r-0"
      :data-test="`col-${colIndex}`"
      :col-index="colIndex"
      :row-index="rowIndex"
      @next="(e) => emit('next', e)"
    />
    <!-- Empty cells due to virtualization -->
    <TableCell
      v-if="columnsAfterView"
      :style="{ gridColumn: `span ${columnsAfterView} / span ${columnsAfterView}` }"
      loading
      role="gridcell"
      :aria-rowindex="rowIndex + 2"
      :aria-colindex="Math.max(...columnsInView)"
      :is-focused="false"
      :is-selected="false"
    />
  </TableRow>
</template>
