function tokenize(str: string): string[] {
  return str.toLowerCase().split(/\s+/)
}

export function fuzzySearch(query: string, options: string[]): string[] {
  if (!query) return options

  const queryTokens = tokenize(query)

  return options
    .map((option) => {
      const optionTokens = tokenize(option)
      const matchedTokens = queryTokens.filter((qt) => optionTokens.some((ot) => ot.includes(qt)))
      return {
        option,
        score: matchedTokens.length / queryTokens.length,
      }
    })
    .filter((result) => result.score === 1) // Only perfect matches
    .map((result) => result.option)
}
