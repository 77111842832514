<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { watchImmediate } from '@vueuse/core'
import type { Parser } from 'papaparse'
import { parse } from 'papaparse'
import { onBeforeUnmount, ref } from 'vue'

const props = defineProps<{
  url: string
}>()

/** How many rows will be parsed at a time before pausing */
const pageSize = 100

/** How many rows will be parsed before aborting reads from the csv */
let rowLimit = pageSize

/** If all rows in the loaded CSV files are parsed */
const parsedAll = ref(true)

/** Data for display */
const csvData = ref<unknown[]>([])

/** Used to prevent reloading of the CSV while resizing the card */
let loadedBaseUrl = ''

/** Stored to be able to pause and resume csv parsing */
let parserInstance = null as Parser | null

let abortController = new AbortController()

const errorType = ref<'fetch' | 'parse' | null>(null)

/**
 * Reset loaded data, prepare for loading new data
 */
function reset() {
  rowLimit = pageSize
  csvData.value = []
  errorType.value = null
  loadedBaseUrl = ''
  parsedAll.value = false
}

watchImmediate(
  () => props.url,
  () => loadCSV(),
)

onBeforeUnmount(() => {
  abortController.abort('Component is unmounting')
})

async function loadCSV() {
  const baseUrl = props.url.split('?')[0]
  // New url will come in twice when the component loads, and every time it resizes
  // We can prevent fetching every time by storing the base url of the last loaded file
  if (baseUrl === loadedBaseUrl) {
    return
  }

  reset()
  abortController.abort('Loading another CSV')
  abortController = new AbortController()

  let text: string
  try {
    text = await fetchFileContent(props.url, abortController.signal)
    loadedBaseUrl = baseUrl
  } catch {
    errorType.value = 'fetch'
    return
  }

  try {
    parse(text, {
      step(results, parser) {
        if (abortController.signal.aborted) {
          parser.abort()
        }

        if (!Array.isArray(results.data) || results.data.length === 0) {
          return
        }

        csvData.value.push(results.data)

        if (csvData.value.length >= rowLimit) {
          parser.pause()
          parserInstance = parser
        }
      },
      complete() {
        parsedAll.value = true
      },
    })
  } catch {
    errorType.value = 'parse'
  }
}

async function fetchFileContent(url: string, signal: AbortSignal): Promise<string> {
  const response = await fetch(url, { signal })
  if (!response.ok) {
    throw new Error(`Failed to fetch CSV file: ${response.statusText}`)
  }
  return response.text().then((text) => text.trim())
}

function resumeParsing() {
  if (!parserInstance) return
  rowLimit = csvData.value.length + pageSize
  parserInstance.resume()
}
</script>

<template>
  <div class="relative size-full overflow-hidden p-1 pt-0">
    <div class="flex size-full flex-col">
      <div
        v-if="errorType"
        class="flex size-full flex-col items-center justify-center gap-3 text-wrap text-text-subtle"
      >
        <div>Failed to fetch CSV</div>
        <DarwinButton
          v-if="errorType === 'fetch'"
          variant="outline"
          size="md"
          @click="loadCSV"
          >Try again
        </DarwinButton>
      </div>
      <div
        v-else
        class="size-full overflow-scroll rounded-corner-6 border border-border-subtle"
      >
        <table class="w-full">
          <tr
            v-for="(row, i) in csvData"
            :key="i"
          >
            <td
              class="border-b border-border-subtle bg-surface-secondary px-1.5 text-center text-sm-12px-light text-text"
            >
              {{ i + 1 }}
            </td>
            <td
              v-for="(item, j) in row"
              :key="j"
              class="border-b border-border-subtle px-1 py-1.5 text-sm-12px-light text-text"
            >
              <template v-if="item">
                {{ item }}
              </template>
              <span
                v-else
                class="select-none text-text-subtlest"
              >
                &lt;empty&gt;
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="!parsedAll && !errorType && csvData.length"
        class="pt-1"
      >
        <div class="flex select-none items-center justify-between gap-0.5 rounded-lg p-0.5 px-1.5">
          <span class="text-sm-12px-light"> {{ csvData.length }} rows </span>
          <DarwinButton
            size="xs"
            variant="transparent"
            @click="resumeParsing"
          >
            Load more rows
          </DarwinButton>
        </div>
      </div>
    </div>
  </div>
</template>
