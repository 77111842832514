<script setup lang="ts">
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import Select from '@/uiKit/Select'
import SelectButton from '@/uiKit/Select/SelectButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import ZagPopover from '@/uiKit/ZagPopover.vue'
import { computed } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { TYPE_ICON } from '../icons'
import { useProject } from '../useProject'
import { useSorting, type SortingRule } from '../useSorting'
import AddSort from './AddSort.vue'

const projectStore = useProject()
const sortingStore = useSorting()

const getProperty = (rule: SortingRule) => {
  return projectStore.visibleProperties.find((p) => p.slug === rule.property_slug)
}

const getPropertyName = (rule: SortingRule) => {
  if (rule.property_slug === 'id') return 'Created at'

  return getProperty(rule)?.name
}

const hasCustomSort = computed(() => {
  if (sortingStore.sortingRules.length === 0) return false
  if (sortingStore.sortingRules.length === 1 && sortingStore.hasRule('id')) return false
  return true
})
</script>

<template>
  <ZagPopover :positioning="{ placement: 'top-start' }">
    <template #trigger="{ api }">
      <DarwinButton
        variant="neutral"
        size="sm"
        v-bind="{ ...api.getTriggerProps(), ...$attrs }"
        :active="api.open"
      >
        <template #leading-icon>
          <IconSprite icon="sort" />
        </template>
        <template v-if="!hasCustomSort"> Sort </template>
        <div
          v-else
          class="flex items-center"
        >
          Sorted by
          <span class="text-text"
            >&nbsp;{{ getPropertyName(sortingStore.sortingRules[0]) }}&nbsp;</span
          >
          <BadgeItem
            v-if="sortingStore.sortingRules.length > 1"
            :label="`+${sortingStore.sortingRules.length - 1}`"
            variant="neutral"
            size="sm"
          />
        </div>
      </DarwinButton>
    </template>

    <template #content="{ api }">
      <ListMenuContainer
        v-bind="api.getContentProps()"
        class="w-[400px] [&[hidden]]:!hidden"
      >
        <VueDraggable
          v-model="sortingStore.sortingRules"
          class="flex w-full flex-col gap-1 p-1"
        >
          <div
            v-for="rule in sortingStore.sortingRules"
            :key="rule.property_slug"
            class="flex w-full items-center gap-1"
          >
            <IconSprite
              class="cursor-grab rounded-corner-8 p-1.5 text-icon-subtlest transition hover:bg-background-transparent-hovered hover:text-icon-subtle active:cursor-grabbing active:bg-background-transparent-pressed"
              data-handle
              icon="drag"
            />
            <div class="flex w-full min-w-0 grow items-center">
              <AddSort
                v-slot="{ open, triggerProps }"
                @select="(v) => sortingStore.updateRuleProperty(rule.property_slug, v)"
              >
                <SelectButton
                  v-bind="triggerProps"
                  :active="open"
                  class="min-w-0 flex-1 shrink-0 grow basis-0 !rounded-r-none"
                  :leading-icon="
                    rule.property_slug === 'id'
                      ? 'calendar'
                      : TYPE_ICON[getProperty(rule)?.type || 'text']
                  "
                >
                  {{ rule.property_slug === 'id' ? 'Created at' : getProperty(rule)?.name }}
                </SelectButton>
              </AddSort>

              <DividerLine
                class="!h-[unset] self-stretch"
                direction="vertical"
                color="subtle"
              />

              <Select
                :items="
                  [
                    { label: 'Ascending', value: 'asc', icon: 'arrow-top' },
                    { label: 'Descending', value: 'desc', icon: 'arrow-bottom' },
                  ] as const
                "
                :positioning="{ placement: 'top-start' }"
                :value="rule.direction"
                class="min-w-0 flex-1 shrink-0 grow basis-0 !rounded-l-none"
                @change="(v) => sortingStore.updateRuleDirection(rule.property_slug, v)"
              />
            </div>
            <ToolTip
              title="Remove sorting rule"
              :placement="{ allowedPlacements: ['top'] }"
            >
              <IconButton
                icon="close"
                size="lg"
                variant="transparent"
                aria-label="Remove sorting rule"
                :disabled="sortingStore.sortingRules.length <= 1"
                @click="sortingStore.removeRule(rule.property_slug)"
              />
            </ToolTip>
          </div>
        </VueDraggable>

        <DividerLine
          color="subtle"
          direction="horizontal"
        />
        <div class="px-2.5 py-1">
          <AddSort
            v-slot="{ open, triggerProps }"
            @select="sortingStore.addRule"
          >
            <DarwinButton
              variant="transparent-link"
              size="sm"
              v-bind="{ ...triggerProps, ...$attrs }"
              :active="open"
            >
              <template #leading-icon>
                <IconSprite icon="plus" />
              </template>
              Add sort
            </DarwinButton>
          </AddSort>
        </div>
      </ListMenuContainer>
    </template>
  </ZagPopover>
</template>
