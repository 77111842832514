import { useUser } from '@/modules/IdentityAndAccess/useUser'
import * as sentry from '@sentry/vue'
import type { PostHog, Properties } from 'posthog-js'
import { computed, getCurrentInstance } from 'vue'

export const ANALYTICS_EVENT = {
  FIRST_LOGIN: 'First login',
  PROJECT_CREATED: 'Project created',
  PROJECT_CREATED_FROM_TEMPLATE: 'Project created from template',
  ASK_GO_PROJECT_CREATED: 'Ask Go project created',
  PROPERTY_CREATED: 'Property created',
  PROPERTY_UPDATED: 'Property updated',
  ENTITY_CREATED: 'Entity created',
  FILE_DELETED: 'File deleted',
  FILE_UPLOAD_PENDING: 'File upload pending',
  OPEN_ENTITY_VIEW: 'Opens entity view',
  OPEN_PROPERTY_SIDEBAR: 'Opens property configuration sidebar',
  OPEN_PROPERTY_MENU: 'Opens property configuration popover',
  START_SIGNUP_FORM: 'Start signup form',
  SUBMIT_SIGNUP_FORM: 'Submit signup form',
  STRIPE_CONVERSION: 'Stripe conversion',
  OPEN_SETTINGS_MENU: 'Open settings menu',
  INVITE_SENT: 'Workspace invite sent',
  API_KEY_CREATED: 'API key created',
  OPEN_HELP_CENTER: 'Open help center',
  CLICK_GO_PRO: 'Click Go Pro',
  CLICK_VIEW_TYPE_SELECTOR: 'Click view type selector',
  STALE_FIELDS_RECOMPUTED: 'Stale fields recomputed',
  INPUT_SELECTED: 'Input selected',
  INPUT_MENTIONED: 'Input mentioned',
  PROJECT_EXPORTED: 'Project exported',
  OPEN_CRUMB_MENU: 'Open crumb menu',
  OPEN_PROPERTY_VISIBILITY_MENU: 'Open property visibility menu',
  STAGE_CREATED: 'Stage created',
  TUTORIAL_VIDEO_OPENED: 'Tutorial video opened',
  AI_PROPERTY_CREATED: 'AI property created',
  AI_PROMPT_AND_INPUT_SAVED: 'AI property runs successfully',
  ADVANCED_PROPERTY_SUCCESS: 'Advanced property runs successfully',
  PAGEVIEW: '$pageview',
  PAGELEAVE: '$pageleave',
  CSV_CLICK_IMPORT: 'Click import CSV',
  CSV_BEGIN_IMPORT: 'Begin import CSV',
  CSV_IMPORT_SUCCESS: 'Import CSV success',
  CSV_IMPORT_FAILURE: 'Import CSV failure',
  GROUNDING_OPENED: 'Grounding opened',
  GROUNDING_BOX_CLICKED: 'Grounding box clicked',
  GROUNDING_CLAIM_CLICKED: 'Grounding claim clicked',
  GROUNDING_ENABLED: 'Grounding enabled',
  LIBRARY_OPENED: 'Library opened',
  LIBRARY_UPLOADED_FILE: 'Uploaded file to library',
  LIBRARY_CREATED_DOCUMENT: 'Created document in library',
  LIBRARY_INPUT_IN_PROPERTY: 'Input a library file in a property',
  WELCOME_TOUR_STARTED: 'Welcome tour started',
  WELCOME_TOUR_STEP_VIEWED: 'Welcome tour step viewed',
  WELCOME_TOUR_STEP_COMPLETED: 'Welcome tour step completed',
  WELCOME_TOUR_COMPLETED: 'Welcome tour completed',
  WELCOME_TOUR_EXITED: 'Welcome tour exited',
  ASK_GO_MESSAGE_SENT: 'Ask Go message sent',
  FIVE_ENTITIES_CREATED: '5 entities created',
  FREE_PLAN_PAYWALL: 'Paywall shown',
} as const

export type AnalyticsEvent = (typeof ANALYTICS_EVENT)[keyof typeof ANALYTICS_EVENT]

/**
 * Provides functionality used to track user behaviour.
 */
export const useAnalytics = () => {
  const instance = getCurrentInstance()
  const posthog = computed<PostHog | undefined>(
    () => instance?.appContext.config.globalProperties.$posthog,
  )

  let isUserIdentified: boolean = false

  /**
   * Sent an event to the analytics provider
   */
  const captureAnalyticsEvent = (event: AnalyticsEvent, properties?: Properties) => {
    if (!posthog.value) {
      return
    }
    try {
      identifyUser()
      posthog.value.capture(event, properties)
    } catch (e) {
      // It would suck if a PostHog error caused a function to throw. So catch it here
      // and send to Sentry.
      const message = e instanceof Error ? e.message : 'Error when capturing analytics event'
      sentry.captureException(message)
    }
  }

  const identifyUser = () => {
    const userStore = useUser()
    const user = userStore.user
    if (!posthog.value || !user || isUserIdentified) {
      return
    }
    const name = [user.firstName, user.lastName].filter(Boolean).join(' ') ?? undefined

    try {
      posthog.value.identify(user.id, {
        email: user.email,
        name,
      })
      isUserIdentified = true
    } catch (e) {
      // It would suck if a PostHog error caused a function to throw. So catch it here
      // and send to Sentry.
      const message = e instanceof Error ? e.message : 'Error when identifying user'
      sentry.captureException(message)
    }
  }

  return { captureAnalyticsEvent, identifyUser }
}
