import axios from 'axios'
import type { APIResult } from './types'

/**
 * Upload the given file to the given presigned s3 url
 */
export const uploadFile = async (
  url: string,
  file: File,
  onProgress?: (progress: number) => void,
): Promise<APIResult<null>> => {
  try {
    await axios.create().put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progress) => onProgress && onProgress(progress.progress || 0),
    })

    return { ok: true, data: null }
  } catch {
    return { ok: false, error: { code: 'S3 UPLOAD ERROR', message: 'Failed to upload file to S3' } }
  }
}
