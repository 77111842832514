import { useScroll } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const CELL_Z_INDEX = 1
export const SELECTED_CELL_Z_INDEX = 2
export const HEADER_Z_INDEX = 3
export const PINNED_CELL_Z_INDEX = 4
export const PINNED_SHADOW_Z_INDEX = 4
export const PINNED_SELECTED_CELL_Z_INDEX = 5
export const PINNED_HEADER_Z_INDEX = 6
export const ACTION_BAR_Z_INDEX = 7
export const FOOTER_Z_INDEX = 8
export const CONTEXT_MENU_Z_INDEX = 9
export const POPOVER_Z_INDEX = 9

/**
 * The table header and first row should be sticky, and should appear above the
 * rest of the grid cells. However, when a cell in the first row or second column
 * is selected, the focus ring should appear above the header and first column.
 *
 * This store tracks the scroll position of the table and provides z-index values
 * to the header and first column so that the focus ring can appear above them when
 * the table is scrolled fully to the top or left.
 */
export const useTableZIndices = defineStore('tableScroll', () => {
  const tableRef = ref<HTMLElement | null>(null)

  const scrollState = useScroll(tableRef)
  watch(tableRef, () => {
    // Scroll state only updates on scroll, not on table changing, leading
    // to stale values
    scrollState.measure()
  })

  const leftScroll = computed(() => scrollState.x.value)
  const isAtLeft = computed(() => leftScroll.value === 0)
  const isAtTop = computed(() => scrollState.y.value === 0)

  /**
   * Rules for z-index:
   * - The header should always appear above everything else
   *   - There is an exception to this - the focus ring of cells in the first row
   *     should appear above the header when the table is scrolled to the top (this is
   *     so that the top of the focus ring is not cut off)
   * - The first and second columns should always appear above regular cells
   *   - The same exception applies to the first column as the header - the focus ring
   *     of cells in the third column should appear above the first two columns when the table
   *     has not been scrolled horizontally
   */
  const zIndex = computed(() => ({
    header: isAtTop.value ? CELL_Z_INDEX : HEADER_Z_INDEX,
    pinnedHeader: isAtTop.value ? PINNED_SELECTED_CELL_Z_INDEX : PINNED_HEADER_Z_INDEX,
    pinnedColumn: isAtLeft.value ? CELL_Z_INDEX : PINNED_CELL_Z_INDEX,
  }))

  return {
    tableRef,
    zIndex,
    leftScroll,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTableZIndices, import.meta.hot))
}
