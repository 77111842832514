import type { Directive } from 'vue'

function createListener(el: HTMLElement, value: () => void) {
  return () => {
    if (!el.contains(document.activeElement)) {
      value()
    }
  }
}

const listeners = new WeakMap<HTMLElement, ReturnType<typeof createListener>>()

/**
 * Anytime an element within blurs, call the provided function
 *
 * @example
 * ```vue
 * <div v-blur-within="() => { console.log('blurred') }">
 *   <input type="text" />
 * </div>
 * ```
 */
export const vBlurWithin = {
  mounted(el: HTMLElement, { value }: { value: () => void }) {
    const listener = createListener(el, value)
    listeners.set(el, listener)
    document.addEventListener('focusout', listener)
  },
  updated(el: HTMLElement, { value }: { value: () => void }) {
    const listener = listeners.get(el)
    if (!listener) return
    document.removeEventListener('focusout', listener)
    const newListener = createListener(el, value)
    listeners.set(el, newListener)
    document.addEventListener('focusout', newListener)
  },
  unmounted(el: HTMLElement) {
    const listener = listeners.get(el)
    if (!listener) return
    document.removeEventListener('focusout', listener)
    listeners.delete(el)
  },
} satisfies Directive
