<script setup lang="ts">
import { useAttrs } from 'vue'

import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'

// Figma link: https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?type=design&node-id=178%3A632&mode=dev
withDefaults(
  defineProps<{
    checked?: boolean
    icon?: IconName | null
    size?: 'sm' | 'md' | 'lg' | 'xl'
    color?: 'blue' | 'neutral'
  }>(),
  {
    size: 'md',
    checked: false,
    icon: null,
    color: 'blue',
  },
)

defineEmits<{
  (e: 'change', value: boolean): void
}>()

const attrs: { disabled?: boolean } = useAttrs()
</script>

<template>
  <button
    class="inline-flex items-center rounded-corner-16 p-0.5 transition focus-visible:shadow-focus-ring-primary"
    :class="[
      size === 'xl' && 'h-7 w-12',
      size === 'lg' && 'h-6 w-10',
      size === 'md' && 'h-5 w-8',
      size === 'sm' && 'h-4 w-6',
      checked &&
        color === 'neutral' &&
        'bg-background-gray-subtle hover:bg-background-gray-subtle-hovered active:bg-background-gray-subtle-pressed',
      !checked &&
        color === 'neutral' &&
        'bg-background-transparent outline outline-1 outline-border-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed',
      checked && color === 'blue' && 'bg-background-blue-selected',
      !checked &&
        color === 'blue' &&
        'bg-background-gray-subtle outline outline-1 outline-border-subtle hover:bg-background-gray-subtle-hovered active:bg-background-gray-subtle-pressed',
      attrs.disabled && '!bg-background-disabled',
    ]"
    :tabindex="$attrs.disabled ? undefined : 0"
    role="checkbox"
    :disabled="attrs.disabled"
    :aria-disabled="attrs.disabled ? 'true' : 'false'"
    :aria-checked="checked ? 'true' : 'false'"
    @click.exact.prevent="$emit('change', !checked)"
  >
    <div
      class="flex translate-x-0 items-center justify-center rounded-full transition-transform duration-300"
      :class="[
        size === 'sm' && 'size-3',
        size === 'md' && 'size-4',
        size === 'lg' && 'size-5',
        size === 'xl' && 'size-6',
        !checked && color === 'neutral' && 'bg-background-gray-subtlest',
        !checked && color === 'blue' && 'bg-icon-inverted-irreversible',
        checked && 'bg-icon-inverted-irreversible',
        checked && size === 'sm' && 'translate-x-2',
        checked && size === 'md' && 'translate-x-3',
        checked && size === 'lg' && 'translate-x-4',
        checked && size === 'xl' && 'translate-x-5',
        attrs.disabled && '!bg-background-disabled',
      ]"
    >
      <IconSprite
        v-if="icon"
        :class="[checked ? 'text-icon' : 'text-icon-subtle']"
        :icon="icon"
      />
    </div>
    <input
      type="checkbox"
      class="hidden"
      :checked="checked"
      :disabled="attrs.disabled"
      :aria-disabled="$attrs.disabled ? 'true' : 'false'"
      :aria-checked="checked ? 'true' : 'false'"
    />
  </button>
</template>
