<script setup lang="ts">
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed, ref } from 'vue'
import { RouterLink, useLink, type UseLinkOptions } from 'vue-router'
/**
 * Wrapper around RouterLink for use in sidebar navigation.
 * Uses the documented way to extend RouterLink as described here:
 * https://router.vuejs.org/guide/advanced/extending-router-link.html
 *
 * Figma: https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?type=design&node-id=225%3A7238&mode=design&t=ZvZnh2rp2T2WD4XE-1
 */
const props = withDefaults(
  defineProps<{
    leadingIcon?: IconName
    leadingIconActive?: IconName
    to?: UseLinkOptions['to']
    ariaLabel?: string
    disabled?: boolean
    /**
     * Usually the active state is determined by the router, but in some cases we want to
     * force the active state.
     */
    forceActiveState?: boolean
  }>(),
  {
    leadingIcon: undefined,
    leadingIconActive: undefined,
    to: undefined,
    ariaLabel: 'Navigate to page',
    disabled: false,
    forceActiveState: undefined,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const isHovered = ref(false)
const isPressed = ref(false)

const { isActive: _isActive } = useLink({ to: props?.to ?? '/' })
const isActive = computed(() =>
  typeof props.forceActiveState === 'boolean'
    ? props.forceActiveState
    : _isActive.value && !!props.to,
)

const icon = computed(() => {
  return props.leadingIcon &&
    props.leadingIconActive &&
    (isHovered.value || isActive.value || isPressed.value)
    ? props.leadingIconActive
    : props.leadingIcon
})

const ariaCurrent = computed(() => {
  if (!isActive.value) {
    return false
  }

  if (props.to) {
    return 'page'
  }

  return 'true'
})
</script>

<template>
  <component
    :is="to ? RouterLink : 'button'"
    :to="to"
    v-bind="$attrs"
    class="flex w-full items-center gap-2 rounded-corner-8 p-1.5 text-left text-sm-12px-default"
    :class="{
      'bg-background-gray-subtlest hover:bg-background-gray-subtlest-hovered active:bg-background-gray-subtlest-pressed':
        isActive,
      'bg-background-transparent text-text-subtle has-[[data-state=\'open\']]:bg-background-transparent-hovered hover:bg-background-transparent-hovered hover:text-text-subtle active:bg-background-transparent-pressed':
        !isActive,
      flex: !!icon,
      'pointer-events-none !text-text-disabled': disabled,
    }"
    :aria-current="ariaCurrent"
    :tabindex="disabled ? -1 : 0"
    :disabled="disabled"
    @click="!disabled && emit('click')"
    @mousedown="isPressed = true"
    @mouseup="isPressed = false"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-if="icon"
      class="flex w-4 items-center justify-center"
    >
      <IconSprite :icon="icon" />
    </div>
    <div class="flex max-w-full grow truncate whitespace-nowrap">
      <slot />
    </div>
  </component>
</template>
