<script lang="ts">
export type Mentionable = {
  id: string
  label: string
  icon: IconName
  group: string
  openOnClick?: boolean
  previewPropertyOnHover?: boolean
}

export type Mode = 'markdown' | 'plaintext'
</script>

<script setup lang="ts">
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import type { IconName } from '@/uiKit/IconName'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { useResizeObserver, useStorage } from '@vueuse/core'
import { exampleSetup } from 'prosemirror-example-setup'
import { EditorState, Selection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { ref, shallowRef, toRef, triggerRef, watch } from 'vue'
import { useProseMirrorComponents } from './components/useProseMirrorComponents'
import { useMentionablePlugin } from './mentionables/useMentionablePlugin'
import { getUtilsForMode } from './modes/utils'
import { useMarkState } from './useMarkState'

const props = withDefaults(
  defineProps<{
    value: string
    readonly?: boolean
    adaptForTableMode?: boolean
    mentionableInputs?: Mentionable[]
    mode?: Mode
    /** When provided, will persist the height to localStorage using this key */
    savedHeightKey?: string
  }>(),
  { mode: 'markdown', mentionableInputs: undefined, savedHeightKey: undefined },
)

const emit = defineEmits<{
  (e: 'save' | 'input', value: string): void
  (e: 'cancel'): void
  (e: 'enter', event: KeyboardEvent): void
}>()

const state = shallowRef<EditorState>()
const view = shallowRef<EditorView>()

const editor = ref<HTMLDivElement>()

const { setMarkState } = useMarkState(view)

// tracks if the user has edited the content, since the blur event is triggered always and serialization always returns some formatting differences
const hasValueChanged = ref(false)
const shouldSkipBlur = ref(false)
const listMenuRef = ref()

const { parser, schema, serializer } = getUtilsForMode(props.mode)
const mentionable = useMentionablePlugin({
  view,
  mentionables: toRef(props, 'mentionableInputs'),
  schema,
  triggerListMenuSelectionUp: () => listMenuRef.value?.moveSelectionUp(),
  triggerListMenuSelectionDown: () => listMenuRef.value?.moveSelectionDown(),
  triggerListMenuSelect: () => listMenuRef.value?.triggerSelect(),
})

watch(
  () => [props.value, props.readonly],
  () => {
    hasValueChanged.value = false
    // updates the editor value, god this looks ugly, isn't there a better way?
    if (view.value) {
      const newState = EditorState.create({
        plugins: [
          mentionable.plugin,
          ...exampleSetup({ schema, menuBar: false, floatingMenu: false }),
        ],
        doc: parser.parse(props.value),
      })
      view.value.updateState(newState)
      setMarkState(newState)
    }
  },
)

defineExpose({
  focus() {
    view.value?.focus()
    // puts the caret at the end of the text
    if (!view.value) return
    const selection = Selection.atEnd(view.value.state.doc)
    const tr = view.value.state.tr.setSelection(selection)
    const state = view.value.state.apply(tr)
    view.value.updateState(state)
    // scrolls so that the caret is visible
    view.value.dispatch(view.value.state.tr.scrollIntoView())
  },
})

const getSerializedValue = () => {
  assertIsNotNullOrUndefined(view.value, 'No editor view available - cannot serialize value')

  return serializer.serialize(view.value.state.doc)
}
const nodeViews = props.adaptForTableMode ? undefined : useProseMirrorComponents()
// Initialise the rich text editor as soon as the editor element is available
watch(
  () => editor.value,
  () => {
    if (!editor.value) return
    state.value = EditorState.create({
      plugins: [
        mentionable.plugin,
        ...exampleSetup({ schema, menuBar: false, floatingMenu: false }),
      ],
      doc: parser.parse(props.value),
    })

    view.value = new EditorView(editor.value, {
      state: state.value,
      editable: () => !props.readonly,
      nodeViews,
      dispatchTransaction(transaction) {
        if (!view.value) {
          return
        }
        if (transaction.docChanged) {
          hasValueChanged.value = true
        }

        let newState = view.value.state.apply(transaction)

        view.value.updateState(newState)
        setMarkState(newState)
        emit('input', getSerializedValue())
      },
      handleDOMEvents: {
        focus() {
          view.value?.focus()
        },
        keydown(_e, event: KeyboardEvent) {
          if (
            !props.adaptForTableMode &&
            (event.key === 'ArrowLeft' ||
              event.key === 'ArrowRight' ||
              event.key === 'ArrowUp' ||
              event.key === 'ArrowDown')
          ) {
            // stop propagation in entity view mode and avoid navigation between previous and next entities
            event.stopPropagation()
          }

          if (!props.adaptForTableMode) return
          event.stopPropagation()

          if (event.key === 'Escape') {
            event.preventDefault()
            emit('cancel')
            shouldSkipBlur.value = true
          }

          if (event.key === 'Enter') {
            if (event.shiftKey) {
              return
            }
            event.preventDefault()
            emit('enter', event)
          }
        },
        blur(_view: EditorView) {
          mentionable.menuCoords.value = null
          if (shouldSkipBlur.value) {
            shouldSkipBlur.value = false
            return
          }
          if (!hasValueChanged.value) return

          const serialized = getSerializedValue()
          if (!serialized) return
          emit('save', serialized)
        },
      },
    })
    triggerRef(view)
  },
  { immediate: true },
)

useResizeObserver(editor, () => {
  if (!props.savedHeightKey) return
  height.value = editor.value?.getBoundingClientRect().height
})

// Persist height to localStorage
const height = useStorage(props.savedHeightKey ?? 'default-textarea-key', 150)

watch(editor, (el) => {
  if (!el || !props.savedHeightKey) return
  el.style.height = `${height.value}px`
})
</script>

<template>
  <div
    v-bind="$attrs"
    ref="editor"
    class="size-full min-w-full cursor-text overflow-y-auto [&>*:focus-visible]:outline-none [&>*]:size-full"
    :class="adaptForTableMode && 'w-max max-w-[500px]'"
    @paste.stop
  />

  <Teleport to="body">
    <ListMenu
      v-if="!!mentionable.menuCoords.value"
      ref="listMenuRef"
      class="fixed"
      :style="{
        left: `${mentionable.menuCoords.value.left + 8}px`,
        top: `${mentionable.menuCoords.value.top + 18}px`,
      }"
      :items="mentionable.filteredItems.value"
      :group-by-predicate="(item) => item.data.group"
      has-group-titles
      @select="mentionable.onSelectItem"
    >
      <template #item="{ item, active }">
        <ListMenuItem
          :active="active"
          :label="item.data.label"
          @mousedown.stop.prevent="mentionable.onSelectItem(item.data)"
        />
      </template>
    </ListMenu>
  </Teleport>
</template>

<style>
/*
  I don't know why this is needed but an element with this class is added
  after mention nodes and causes line breaks where we don't want them.
 */
.ProseMirror-separator {
  display: none;
}
</style>
