import type { components } from '@/api'
import { get } from './client'

export type ListPlansPath = `/api/workspaces/${string}/billing/plans`
export type ListPlansResponse = components['schemas']['Billing.ListPlansResponse']

/**
 * Gets all the plans in the given workspace.
 */
export const listPlans = (workspaceId: string) =>
  get<undefined, ListPlansResponse, ListPlansPath>(
    `/api/workspaces/${workspaceId}/billing/plans`,
    undefined,
  )
