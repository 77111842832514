import { getCookieByName } from '@/shared/utils/cookie'
import axios, { type AxiosResponse } from 'axios'

export type DataPayload = {
  email: string
  firstname?: string
  lastname?: string
  jobtitle?: string
  go_main_data_type?: 'Document' | 'Image' | 'Video' | 'Other'
  go_main_data_type___other?: string
  pricing_plan_button_clicked?: 'free' | 'starter' | 'business' | 'pro' | 'enterprise'
  howhearv7go?:
    | 'Search engine (Google, Bing, etc.)'
    | 'Word of mouth'
    | 'LinkedIn/X/TikTok'
    | 'YouTube'
    | 'Blog'
    | 'News site'
    | 'Email / Newsletter'
    | 'Hackathon'
    | 'Event / Conference'
    | 'Podcast'
    | 'My V7 Account Manager'
    | 'Other (please specify so we know who to thank)'
  howhearv7go_other?: string
  go_signed_up_date?: string
  go_workspace_id?: string
  go_user_id?: string
  utm_campaign?: string | null
  utm_content?: string | null
  utm_medium?: string | null
  utm_source?: string | null
  utm_term?: string | null
}

type HubspotResponse = {
  redirectUri: string
  inlineMessage: string
  errors?: string[]
}

const HUBSPOT_PORTAL_ID: string = import.meta.env.VITE_HUBSPOT_PORTAL_ID
const HUBSPOT_SIGNUP_FORM_ID: string = import.meta.env.VITE_HUBSPOT_FORM_ID
const MARKETING_SUBSCRIPTION_TYPE_ID: string = import.meta.env
  .VITE_HUBSPOT_MARKETING_SUBSCRIPTION_TYPE_ID

export const postToHubspot = async (fields: DataPayload, marketingOptin: boolean = false) => {
  const payload = {
    submittedAt: new Date().getTime(),
    context: {
      hutk: getCookieByName('hubspotutk'),
      pageUri: window.location.href,
      pageName: document.title,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I confirm I have read and accepted V7's Terms and Conditions.",
        communications: [
          {
            value: marketingOptin,
            subscriptionTypeId: MARKETING_SUBSCRIPTION_TYPE_ID,
            text: 'I agree to receive other communications from V7.',
          },
        ],
      },
    },

    fields: Object.entries(fields)
      .filter(([, value]) => value !== null && value !== undefined)
      .map(([name, value]) => ({
        name,
        value,
      })),
  }

  return axios.post<typeof payload, AxiosResponse<HubspotResponse>>(
    `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_SIGNUP_FORM_ID}`,
    payload,
  )
}
