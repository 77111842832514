/**
 * The horizontal offset is equal to the amount of space to the side of the item,
 * so that the submenu is aligned to the edge of the container, and not the edge
 * of the menu item.
 */
export const HORIZONTAL_OFFSET = 4
/**
 * The vertical offset ensures that the text of the submenu items align with the
 * text of the menu item that opens the submenu. Check with a designer after changing
 * this because it's a complicated number to arrive at and dev eyes (mine included)
 * are not the best.
 *
 * The number is calculated as MENU_ITEM_HEIGHT - SUBMENU_PADDING_TOP.
 */
export const VERTICAL_OFFSET = 26

/**
 * When creating a new project, the name is set to `null`. Displaying
 * `null` or an empty string in the UI for these projects would look
 * horrible, so we display this fallback name instead.
 */
export const PROJECT_NAME_FALLBACK = 'Untitled Project'

/**
 * An entity's 'name' is the text value of its field for the first property on
 * its project. If this field is empty, we display this fallback name instead.
 */
export const ENTITY_NAME_FALLBACK = 'Untitled entity'
