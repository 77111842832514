<script setup lang="ts">
import { isMac } from '@/shared/utils'
import { clamp } from '@/shared/utils/number'
import { fuzzySearch } from '@/shared/utils/search'
import { vAutoFocus } from '@/shared/vAutoFocus'
import HotKeyGroup from '@/uiKit/HotKeyGroup.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { vOnClickOutside } from '@vueuse/components'
import { useMagicKeys } from '@vueuse/core'
import { computed, ref, watchEffect } from 'vue'
import { useAskGo } from '../Project/useAskGo'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'

const askGo = useAskGo()
const search = ref('')
const selectedIdx = ref(0)

type Suggestion = {
  text: string
  action?: () => void
}
const suggestions: Suggestion[] = [
  { text: 'Help me set up my project' },
  { text: 'I want to extract some data' },
  { text: 'Create a new property' },
  {
    text: 'Open conversation',
    action: async () => {
      await askGo.getSession()
      askGo.open()
    },
  },
]

const isOpen = ref(false)

const welcomeTour = useWelcomeTour()
function setIsOpen(value: boolean) {
  if (welcomeTour.status === 'IN_PROGRESS' && !value) return
  isOpen.value = value
}

const { Ctrl_k, Meta_k, Escape } = useMagicKeys({
  passive: false,
  onEventFired: (e) => {
    if (e.key === 'k' && (isMac() ? e.metaKey : e.ctrlKey)) {
      e.preventDefault()
    }
  },
})
watchEffect(() => {
  if (Ctrl_k.value || Meta_k.value) {
    setIsOpen(!isOpen.value)
  } else if (Escape.value && welcomeTour.status !== 'IN_PROGRESS') {
    setIsOpen(false)
  }
})

watchEffect(() => {
  if (isOpen.value) return
  selectedIdx.value = 0
  search.value = ''
})

async function triggerSuggestion(suggestion: Suggestion) {
  // We don't use setIsOpen here because we actually want it to close
  isOpen.value = false
  if (suggestion.action) return suggestion.action()

  await askGo.newSession()
  askGo.askEvent.trigger(suggestion.text)
  askGo.open()
  setIsOpen(false)
}

function onInputKeyDown(e: KeyboardEvent) {
  if (e.key === 'ArrowDown') {
    e.preventDefault()
    if (selectedIdx.value < filteredSuggestions.value.length - 1) {
      selectedIdx.value++
    }
  } else if (e.key === 'ArrowUp') {
    e.preventDefault()
    if (selectedIdx.value > 0) {
      selectedIdx.value--
    }
  } else if (e.key === 'Enter') {
    e.preventDefault()
    triggerSuggestion(filteredSuggestions.value[selectedIdx.value])
  }
}

const filteredSuggestions = computed<Suggestion[]>(() => {
  if (!search.value) return suggestions
  const res = fuzzySearch(
    search.value,
    suggestions.map((x) => x.text),
  )
  if (res.length) return suggestions.filter((suggestion) => res.includes(suggestion.text))
  return [
    {
      text: `Ask Go "${search.value}"`,
      action: () => triggerSuggestion({ text: search.value }),
    },
  ]
})

watchEffect(() => {
  const clampedIdx = clamp(0, selectedIdx.value, filteredSuggestions.value.length - 1)
  if (clampedIdx === selectedIdx.value) return
  selectedIdx.value = clampedIdx
})
</script>

<template>
  <button
    class="flex w-[320px] items-center justify-between rounded-full bg-background-gray-subtlest p-1.5 transition hover:bg-background-gray-subtlest-hovered active:bg-background-gray-subtlest-pressed"
    v-bind="$attrs"
    aria-label="Ask Go"
    data-test="open-ask-go-topbar"
    @click="setIsOpen(!isOpen)"
  >
    <IconSprite
      size="lg"
      icon="ask-go"
    />
    <span class="text-sm-12px-default text-text-subtlest">Ask Go</span>
    <HotKeyGroup
      :keys="[isMac() ? 'Meta' : 'Control', 'K']"
      size="xs"
    />
  </button>
  <Teleport to="body">
    <Transition
      enter-from-class="scale-95 opacity-0 blur-sm"
      leave-to-class="scale-95 opacity-0 blur-s	<Down>"
      class="transition duration-200 ease-out"
    >
      <div
        v-if="isOpen"
        v-on-click-outside="() => setIsOpen(false)"
        class="fixed left-1/2 top-2 w-[640px] -translate-x-1/2 rounded-corner-12 bg-text-inverted-subtle shadow-sm backdrop-blur"
        data-theme="dark"
        data-test="ask-go-topbar"
      >
        <label class="flex items-center justify-between gap-2 p-3">
          <IconSprite
            class="text-[hsla(23,100%,50%,1)]"
            size="lg"
            icon="ask-go"
          />
          <input
            v-model="search"
            v-auto-focus
            class="grow bg-background-transparent text-text caret-link outline-none placeholder:text-text-subtlest"
            type="text"
            placeholder="What do you want to do?"
            @keydown="onInputKeyDown"
          />
          <IconButton
            variant="transparent"
            icon="close"
            size="xs"
            @click="setIsOpen(false)"
          />
        </label>
        <template v-if="filteredSuggestions.length">
          <p class="px-4 py-2 text-xs-11px-bold text-text-subtlest">Suggestions</p>
          <ul class="px-2 pb-2">
            <li
              v-for="(suggestion, i) in filteredSuggestions"
              :key="suggestion.text"
              class="group"
              :data-selected="i === selectedIdx"
            >
              <button
                class="w-full rounded-corner-8 bg-background-transparent p-2 text-start text-sm-12px-default text-text group-data-[selected=true]:bg-background-transparent-hovered"
                @mouseover="selectedIdx = i"
                @click="triggerSuggestion(suggestion)"
              >
                {{ suggestion.text }}
              </button>
            </li>
          </ul>
        </template>
      </div>
    </Transition>
  </Teleport>
</template>
