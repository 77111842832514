<script setup lang="ts">
import { ref, useAttrs, watch } from 'vue'

const props = defineProps<{
  checked?: boolean
  indeterminate?: boolean
  label?: string
}>()

const emit = defineEmits<{ (e: 'change', value: boolean, event: Event): void }>()

const inputField = ref<HTMLInputElement>()

const attrs: { disabled?: boolean } = useAttrs()

watch(
  () => props.indeterminate,
  // HTMLInputElement has a valid indeterminate prop, which allows it to render as such when set
  // this cannot be set via html, as it's not an attribute, but can be set via JS
  (indeterminate) => inputField.value && (inputField.value.indeterminate = !!indeterminate),
  { immediate: true },
)
</script>

<template>
  <label
    class="flex cursor-pointer items-center gap-1"
    @click.exact.prevent="emit('change', !checked, $event)"
    @click.shift.stop.exact
  >
    <div class="flex items-center justify-center p-px">
      <button
        class="flex size-3.5 items-center justify-center rounded-corner-4 border-[1.5px] text-icon-inverted transition disabled:border-none disabled:bg-background-disabled disabled:text-icon-disabled"
        :class="[
          !(checked || indeterminate) && [
            'border-border-input bg-background-transparent hover:bg-background-transparent-hovered focus:bg-background-transparent-hovered focus-visible:shadow-focus-ring-primary active:bg-background-transparent-pressed',
          ],

          (checked || indeterminate) && [
            'border-none bg-background-primary hover:bg-background-primary-hovered focus:bg-background-primary-hovered focus-visible:shadow-focus-ring-primary active:bg-background-primary-pressed',
          ],
        ]"
        :tabindex="$attrs.disabled ? undefined : 0"
        role="checkbox"
        :disabled="attrs.disabled"
        :aria-disabled="attrs.disabled ? 'true' : 'false'"
        :aria-checked="indeterminate ? 'mixed' : checked ? 'true' : 'false'"
        type="button"
      >
        <!-- the html checkbox is considered checked only when actually checked, not when indeterminate -->
        <input
          ref="inputField"
          type="checkbox"
          class="hidden"
          :checked="checked"
          :disabled="attrs.disabled"
          :aria-disabled="$attrs.disabled ? 'true' : 'false'"
          :aria-checked="indeterminate ? 'mixed' : checked ? 'true' : 'false'"
        />
        <svg
          v-if="checked && !indeterminate"
          data-test="checked"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.5 8.5L5.91417 10.2677C6.11588 10.5198 6.21673 10.6459 6.34007 10.6948C6.44831 10.7377 6.56812 10.7415 6.67885 10.7055C6.80502 10.6645 6.91362 10.545 7.13082 10.3061L11.5 5.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="indeterminate"
          data-test="indeterminate"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5 8H11"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div
      v-if="label"
      class="select-none text-text"
    >
      {{ label }}
    </div>
  </label>
</template>
