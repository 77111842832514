<script setup lang="ts">
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { ref } from 'vue'
import { GridItem, GridLayout } from 'vue3-grid-layout-next'
import EntityViewCard, { type LayoutItem } from './EntityViewCard.vue'
import type { Entity } from './useProject'

defineProps<{
  entity: Entity
  canEditLayout: boolean
  projectId: string
  layoutItems: LayoutItem[]
  readonly?: boolean
}>()

defineEmits<{
  (e: 'update:size', propertyId: string | number): void
}>()

const { workspaceId } = useRouteParams()

/** Used to prevent all cards from animating on first render */
const disableGridItemTransition = ref(true)
async function onLayoutReady() {
  setTimeout(() => (disableGridItemTransition.value = false))
}

const hoveredItemId = ref<string>()

const handleMouseEnter = (id: string) => {
  hoveredItemId.value = id
}

const handleMouseLeave = () => {
  hoveredItemId.value = undefined
}
</script>

<template>
  <GridLayout
    :layout="layoutItems"
    class="col-start-1 col-end-1 row-start-1 row-end-1 -mt-1"
    data-test="entity-grid-layout"
    :col-num="12"
    :row-height="30"
    :is-draggable="canEditLayout"
    :is-resizable="canEditLayout"
    :responsive="false"
    :vertical-compact="true"
    :use-css-transforms="true"
    :margin="[8, 8]"
    data-grid-layout
    :class="{ '*:!duration-0': disableGridItemTransition }"
    @layout-ready="onLayoutReady"
  >
    <GridItem
      v-for="item in layoutItems"
      :key="item.i"
      class="touch-none"
      data-test="entity-grid-item"
      drag-ignore-from="[data-test='entity-card-content']"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :min-h="3"
      :min-w="2"
      @resized="$emit('update:size', item.property.id)"
      @moved="$emit('update:size', item.property.id)"
      @mouseenter="handleMouseEnter(item.i)"
      @mouseleave="handleMouseLeave"
    >
      <EntityViewCard
        :workspace-id="workspaceId"
        :project-id="projectId"
        :item="item"
        :is-layout-locked="!canEditLayout"
        :is-hovered="hoveredItemId === item.i"
        :entity="entity"
        :readonly-entity="readonly"
      />
    </GridItem>
  </GridLayout>
</template>
