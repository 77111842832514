import { post } from './client'
import type { BackendMeta } from './types'

export type StartImport = BackendMeta<
  '/api/workspaces/{workspace_id}/imports/{import_id}/start',
  'post'
>
export type ImportResponse = StartImport['successResponse']

export const startImport = ({
  workspaceId,
  importId,
}: {
  workspaceId: string
  importId: string
}) => {
  return post<StartImport['body'], StartImport['successResponse'], StartImport['path']>(
    `/api/workspaces/${workspaceId}/imports/${importId}/start`,
    null,
  )
}
