<script setup lang="ts">
import { useAskGo } from '@/modules/Project/useAskGo'
import HotKey from '@/uiKit/HotKey.vue'

import { useHotKey } from '@/sharedComposables/useHotKey'
import IconSprite from '@/uiKit/IconSprite.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useDebounce, useDebounceFn } from '@vueuse/core'
import { computed, ref } from 'vue'

const askGo = useAskGo()

// We use this to avoid lingering tooltips during
// the toggle animation.
// https://www.loom.com/share/6ac21f00be7d4cf5bce6da6089d49833?sid=88548566-09a7-422c-bbf9-4c0e87351b93
const tooltipIsDisabled = ref(false)
const reenableTooltip = useDebounceFn(() => {
  tooltipIsDisabled.value = false
}, 300)

const toggleAskGo = async () => {
  await askGo.getSession()

  tooltipIsDisabled.value = true
  reenableTooltip()
  if (askGo.isOpen) {
    askGo.close()
  } else {
    askGo.open()
  }
}

useHotKey(']', toggleAskGo)
useHotKey('Escape', askGo.close, {
  enabled: computed(() => askGo.isOpen),
  preventInsideInputs: false,
})

const debouncedAction = useDebounce(
  computed(() => (askGo.isOpen ? 'Close' : 'Open')),
  300,
)
</script>

<template>
  <ToolTip
    :placement="{
      allowedPlacements: ['top', 'top-end', 'top-start'],
    }"
    :offset="4"
    :disabled="tooltipIsDisabled"
  >
    <button
      variant="neutral"
      class="size-7 cursor-pointer rounded-t-corner-16 rounded-bl-corner-16 rounded-br-corner-4 bg-background-transparent hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      :class="askGo.isOpen ? 'shadow-inset' : 'shadow-xs'"
      @click="toggleAskGo"
    >
      <IconSprite
        class="p-1.5"
        size="xl"
        :icon="askGo.isOpen ? 'close' : 'ask-go'"
      />
    </button>
    <template #content>
      <div class="flex items-center gap-1.5">
        <span>{{ debouncedAction }} Ask Go</span>
        <HotKey
          hotkey="BracketRight"
          data-theme="dark"
          size="xs"
        />
      </div>
    </template>
  </ToolTip>
</template>
