import { put } from './client'
import type { BackendMeta } from './types'

export type UpdateLibraryItem = BackendMeta<
  '/api/workspaces/{workspace_id}/library/entities/{entity_id}',
  'put'
>

export type UpdateLibraryItemParams = {
  workspaceId: string
  libraryItemId: string
  name: string | null
}

export const updateLibraryItem = ({ libraryItemId, workspaceId, name }: UpdateLibraryItemParams) =>
  put<UpdateLibraryItem['body'], UpdateLibraryItem['successResponse'], UpdateLibraryItem['path']>(
    `/api/workspaces/${workspaceId}/library/entities/${libraryItemId}`,
    { name },
  )
