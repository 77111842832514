<script setup lang="ts">
import { BaseEdge, EdgeLabelRenderer, type EdgeProps } from '@vue-flow/core'
import { computed, ref } from 'vue'

import BadgeItem from '@/uiKit/BadgeItem.vue'
import { useElementSize } from '@vueuse/core'

type Coordinate = {
  x: number
  y: number
}

const props = defineProps<EdgeProps>()

// Space between the line's start/end and the label/nodes
const EDGE_MARGIN = 10

// Start coordinates of this edge
const sourceX = computed(() => props.sourceX + EDGE_MARGIN)
const sourceY = computed(() => props.sourceY)

// End coordinates of this edge
const targetX = computed(() => props.targetX - EDGE_MARGIN)
const targetY = computed(() => props.targetY)

const labelContainerRef = ref<HTMLDivElement>()
const { width: labelWidth, height: labelHeight } = useElementSize(labelContainerRef)

/**
 * Where to place the label. The label is centred on the line between the source and target.
 */
const labelCoordinates = computed<{ start: Coordinate; centre: Coordinate; end: Coordinate }>(
  () => ({
    start: {
      x: (targetX.value + sourceX.value - labelWidth.value) / 2 - EDGE_MARGIN,
      y: (targetY.value + sourceY.value - labelHeight.value) / 2,
    },
    centre: {
      x: (targetX.value + sourceX.value) / 2,
      y: (targetY.value + sourceY.value) / 2,
    },
    end: {
      x: (targetX.value + sourceX.value + labelWidth.value) / 2 + EDGE_MARGIN,
      y: (targetY.value + sourceY.value + labelHeight.value) / 2,
    },
  }),
)

/**
 * The path has an ʃ shape. This value defines the steepness of the curve.

 */
const curveControlFactor = computed(() => (targetX.value - sourceX.value) / 6)

/**
 * SVG path for this edge. It is an ʃ-shaped path from the source node to the target
 * node, with the label in its centre.
 */
const path = computed(
  () =>
    `M ${sourceX.value} ${sourceY.value} C ${sourceX.value + curveControlFactor.value} ${sourceY.value} ${labelCoordinates.value.start.x - curveControlFactor.value} ${labelCoordinates.value.centre.y} ${labelCoordinates.value.start.x} ${labelCoordinates.value.centre.y} L ${labelCoordinates.value.end.x} ${labelCoordinates.value.centre.y} C ${labelCoordinates.value.end.x + curveControlFactor.value} ${labelCoordinates.value.centre.y} ${targetX.value - curveControlFactor.value} ${targetY.value} ${targetX.value} ${targetY.value}`,
)
</script>

<template>
  <BaseEdge
    :id="id"
    :style="{
      ...style,
      stroke: 'var(--color-text-rainbow-17)',
    }"
    :path="path"
    :marker-end="markerEnd"
  />

  <EdgeLabelRenderer>
    <div
      ref="labelContainerRef"
      class="flex max-w-[292px] flex-col justify-center gap-1.5 bg-surface-primary p-1"
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${labelCoordinates.centre.x}px,${labelCoordinates.centre.y}px)`,
      }"
    >
      <div
        v-for="(propertyName, i) of data.propertyNames"
        :key="`${i}`"
        class="flex flex-col justify-center gap-1.5"
      >
        <div class="flex items-center gap-1.5 overflow-hidden px-1.5">
          <BadgeItem
            leading-icon="check-circle"
            :label="propertyName"
            class="w-fit"
            size="sm"
            variant="neutral"
            non-clickable
          />
          <p class="text-sm-12px-default">is</p>
          <BadgeItem
            :label="data.optionValues[i]"
            :rainbow-color="data.optionColors[i]"
            class="w-fit"
            size="sm"
            variant="transparent"
          />
        </div>
      </div>
    </div>
  </EdgeLabelRenderer>
</template>
