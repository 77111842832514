import { createEventHook } from '@vueuse/core'
import { ref } from 'vue'
import { ANALYTICS_EVENT, useAnalytics } from './useAnalytics'

const clickClaimPill = createEventHook<{ claimId: number; sourceId: number }>()
const clickSourcePill = createEventHook<{ sourceId: number }>()

type UseGroundingInteractionsArgs = {
  onClaimPillClick?: (args: { claimId: number; sourceId: number }) => void
  onSourcePillClick?: (args: { sourceId: number }) => void
}

const selected = ref<{ claimId?: number; sourceId?: number } | null>(null)

export function useGroundingInteractions({
  onClaimPillClick,
  onSourcePillClick,
}: UseGroundingInteractionsArgs = {}) {
  const { captureAnalyticsEvent } = useAnalytics()

  if (onClaimPillClick) {
    clickClaimPill.on(onClaimPillClick)
  }
  if (onSourcePillClick) {
    clickSourcePill.on(onSourcePillClick)
  }

  return {
    clickClaimPill: (claimId: number, sourceId: number) => {
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_CLAIM_CLICKED)
      return clickClaimPill.trigger({ claimId, sourceId })
    },
    clickSourcePill: (sourceId: number) => {
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_BOX_CLICKED)
      return clickSourcePill.trigger({ sourceId })
    },
    selected,
  }
}
