<script setup lang="ts">
import { usePrevious } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
  step: number
}>()

const prevStep = usePrevious(
  computed(() => props.step),
  props.step,
)
const isForward = computed(() => props.step > prevStep.value)
</script>

<template>
  <Transition
    :enter-from-class="`${isForward ? 'translate-x-[16px]' : 'translate-x-[-16px]'} opacity-0 `"
    enter-active-class="delay-[200ms,200ms] duration-[200ms,200ms]"
    :leave-to-class="`w-full ${isForward ? 'translate-x-[-16px]' : 'translate-x-[16px]'} opacity-0`"
    leave-active-class="absolute w-full pointer-events-none top-0 duration-[200ms]"
    class="transition-[transform,opacity]"
  >
    <slot />
  </Transition>
</template>
