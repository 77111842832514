import type { components } from '@/api'
import { remove } from './client'

export type RemovePropertyPath = `/api/workspaces/${string}/projects/${string}/properties/${string}`
export type RemovePropertyResponse = components['schemas']['Common.DeleteResponse']

export const removeProperty = (workspaceId: string, projectId: string, propertyId: string) =>
  remove<undefined, RemovePropertyResponse, RemovePropertyPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/properties/${propertyId}`,
    undefined,
  )
