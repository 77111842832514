import type { components } from '@/api'

import { post } from './client'

export type ListFilteredEntityIdsPath =
  `/api/workspaces/${string}/projects/${string}/entity_ids/filter`
export type ListFilteredEntityIdsResponse =
  components['schemas']['Projects.ListEntities.ListEntityIdsResponse']
export type ListFilteredEntityIdsRequest = components['schemas']['Projects.ListEntitiesRequest']

export type ListFilteredEntityIdsArgs = {
  workspaceId: string
  projectId: string
} & ListFilteredEntityIdsRequest

export const listFilteredEntityIds = ({
  workspaceId,
  projectId,
  ...requestBody
}: ListFilteredEntityIdsArgs) =>
  post<ListFilteredEntityIdsRequest, ListFilteredEntityIdsResponse, ListFilteredEntityIdsPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entity_ids/filter`,
    requestBody,
  )
