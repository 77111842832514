import { exportProject } from '@/backend/exportProject'
import { getExport } from '@/backend/getExport'
import * as sentry from '@sentry/vue'

/** Export a project and navigate to the export URL when it's ready
 * @param workspaceId - The workspace ID
 * @param projectId - The project ID
 * @param maxRetries - The maximum number of retries x 1 second
 */

export const doExportProject = async (
  workspaceId: string,
  projectId: string,
  maxRetries: number,
  sleepBetweenRetries: number,
  format: 'csv' | 'xlsx',
) => {
  const RANDOM_NAME = window.crypto.randomUUID()

  const result = await exportProject(workspaceId, projectId, RANDOM_NAME, format)

  if (result.ok) {
    let retries = 0
    while (retries < maxRetries) {
      const exportResult = await getExport(workspaceId, projectId, result.data.id)

      if (!exportResult.ok) {
        break // exit if the export request fails
      }

      if (exportResult.data.status === 'complete') {
        if (exportResult.data.url) {
          window.location.href = exportResult.data.url // navigate to a url
        }
        break
      } else if (exportResult.data.status !== 'pending') {
        break // break in any other case than complete or pending
      }

      retries++
      // sleep for 3 second
      await new Promise((r) => setTimeout(r, sleepBetweenRetries))
    }

    if (retries >= maxRetries) {
      sentry.captureException(new Error('Export failed after ' + maxRetries))
    }
  }
}
