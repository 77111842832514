<script setup lang="ts">
import { toast } from '@/shared/toast'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useFuzzySearch } from '@/sharedComposables/useFuzzySearch'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import TextField from '@/uiKit/TextField.vue'
import { useScroll } from '@vueuse/core'
import { computed, ref, toRef } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import LibraryDialogListViewItem from './LibraryDialogListViewItem.vue'
import LibraryDocument from './LibraryDocument.vue'
import type { LibraryItem } from './libraryStore'
import { useLibraryStore } from './libraryStore'
import { useLibraryBackend } from './useLibraryBackend'
import { useLibraryDocument } from './useLibraryDocument'
import { useUploadLibraryFile } from './useUploadLibraryFile'

const props = defineProps<{
  workspaceId: string
}>()

const libraryStore = useLibraryStore()

const scrollContainer = ref<HTMLElement | null>(null)
const containerScrollState = useScroll(scrollContainer)

const searchValue = ref('')
const filteredItems = useFuzzySearch<LibraryItem>({
  items: computed(() => libraryStore.libraryItems),
  keys: ['name'],
  searchTerm: searchValue,
})

const workspacesStore = useWorkspaces()
const { deleteItem, renameItem } = useLibraryBackend()

const itemToDelete = ref<LibraryItem | null>(null)
const inputReferenceCount = computed<{ properties: number; projects: number } | null>(() => {
  if (!itemToDelete.value) return null
  const propertyIds = new Set<string>(
    itemToDelete.value.inputReference.map((ref) => ref.propertyId),
  )
  const projectIds = new Set<string>(itemToDelete.value.inputReference.map((ref) => ref.projectId))
  return {
    properties: propertyIds.size,
    projects: projectIds.size,
  }
})
const onMaybeDeleteItem = (itemId: string) => {
  const item = libraryStore.getItem(itemId)
  assertIsNotNullOrUndefined(item, 'No item to delete')

  if (item.inputReference.length > 0) {
    itemToDelete.value = item
  } else {
    onConfirmDeleteItem(item.id)
  }
}
const onConfirmDeleteItem = async (id?: string) => {
  assertIsNotNullOrUndefined(id, 'No item to delete')
  assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')

  itemToDelete.value = null
  const res = await deleteItem({
    itemId: id,
    workspaceId: workspacesStore.currentWorkspace.id,
  })
  if (!res.ok) {
    toast.error('Failed to delete library item')
  }
}

const { onClickUpload } = useUploadLibraryFile(toRef(props, 'workspaceId'))
const {
  isEditingLibraryDocument,
  onClickCreateDocument,
  onSaveDocument,
  activeItem,
  onEditDocument,
  onClose,
} = useLibraryDocument()
</script>

<template>
  <div class="flex max-h-full grow flex-col">
    <div class="p-4">
      <h2 class="mb-2 text-sm-12px-bold text-text">Library</h2>
      <p class="mb-3 max-w-[400px] text-sm-12px-light text-text-subtle">
        Upload or create universal files (PDFs, CSVs, text) that provide guidelines for AI models.
        Reference these across all of your projects.
      </p>
      <TextField
        size="lg"
        aria-label="Search for library files"
        placeholder="Search"
        autofocus
        :value="searchValue"
        type="search"
        @input="searchValue = $event"
      >
        <template #leading-icon>
          <IconSprite
            icon="search"
            class="text-icon-subtle"
          />
        </template>
      </TextField>
    </div>
    <div class="relative min-h-0 grow">
      <div
        class="pointer-events-none absolute top-0 z-1 h-16 w-full bg-gradient-to-b from-surface-popover to-background-transparent transition-opacity duration-250"
        :class="containerScrollState.arrivedState.top ? 'opacity-0' : 'opacity-1'"
      />
      <div
        ref="scrollContainer"
        class="h-full overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <ul class="grid min-h-0 grid-cols-3 px-3">
          <LibraryDialogListViewItem
            v-for="item in filteredItems"
            :key="item.id"
            :item="item"
            :upload-progress="libraryStore.uploadProgressMap[item.id]"
            class="min-h-[144px]"
            @rename="renameItem({ id: item.id, newName: $event, workspaceId })"
            @delete="onMaybeDeleteItem(item.id)"
            @open="onEditDocument(item)"
          />
        </ul>
      </div>
      <div
        class="pointer-events-none absolute bottom-0 z-1 h-16 w-full bg-gradient-to-b from-background-transparent to-surface-popover transition-opacity duration-250"
        :class="containerScrollState.arrivedState.bottom ? 'opacity-0' : 'opacity-1'"
      />
    </div>
    <div class="flex justify-end gap-2 border-t border-border-subtle p-2">
      <DarwinButton
        size="sm"
        variant="neutral"
        @click="onClickCreateDocument"
      >
        <template #leading-icon>
          <IconSprite icon="plus" />
        </template>
        Create document</DarwinButton
      >
      <DarwinButton
        size="sm"
        variant="black"
        @click="onClickUpload"
      >
        <template #leading-icon>
          <IconSprite icon="upload" />
        </template>
        Upload file</DarwinButton
      >
    </div>
  </div>
  <LibraryDocument
    :aria-label="activeItem ? `Edit ${activeItem.name}` : 'Create new library document'"
    :open="isEditingLibraryDocument"
    :initial-title="activeItem?.name"
    :initial-content="activeItem?.content"
    @close="onClose"
    @save="onSaveDocument({ content: $event.content, title: $event.title, workspaceId })"
  />
  <ConfirmationDialog
    data-library-child
    :open="!!itemToDelete"
    title="Delete this Library file?"
    :description="
      inputReferenceCount
        ? `This file is used across ${inputReferenceCount.properties} propert${inputReferenceCount.properties > 1 ? 'ies' : 'y'} in ${inputReferenceCount.projects} project${inputReferenceCount.projects > 1 ? 's' : ''}. Fields for ${inputReferenceCount.properties > 1 ? 'those properties' : 'that property'} will become stale if you proceed with file deletion.`
        : ''
    "
    confirm-text="Yes, delete file"
    cancel-text="Cancel"
    @confirm="onConfirmDeleteItem(itemToDelete?.id)"
    @close="itemToDelete = null"
  />
</template>
