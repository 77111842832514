<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { isCollectionProperty } from '@/shared/utils/typeGuards'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import PopupMenu, { FLOATING_PLACEMENT_PROVIDE_KEY } from '@/uiKit/PopupMenu.vue'
import type { Placement } from '@floating-ui/vue'
import { computed, inject, onMounted, ref, useId, type Ref } from 'vue'
import { useFeatureFlags } from '../App/useFeatureFlags'
import LibraryListMenuItem from '../Library/LibraryListMenuItem.vue'
import { isFilterableProperty, type FilterableProperty } from './Filters/types'
import { TYPE_ICON } from './icons'
import ModelInputMenuFilters from './ModelInputMenuFilters.vue'
import ModelInputMenuRelationProperties from './ModelInputMenuRelationProperties.vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { type ModelInputItem } from './useModelInputs'
import type { Property, PropertyInput } from './useProject'
import { useSubProject } from './useSubProject'
/**
 * This component renders a list of items that can be selected as inputs to a model.
 */

const props = defineProps<{
  items: ModelInputItem[]
  selectedInputs: PropertyInput[]
  openWithFilterPropertyId?: string | null
}>()

const emit = defineEmits<{
  (e: 'toggle:property', args: { propertyId: string; viaPropertyId?: string }): void
  (e: 'toggle:library-item', id: string): void
  (
    e: 'update:filters',
    payload: { propertyId: string; filters: PropertyInput['filters'] | null },
  ): void
  (e: 'open:library'): void
}>()

type ItemGroup = ModelInputItem['data']['group'] | 'Selected'
type Item = {
  id: string
  data: ModelInputItem['data'] & { selectedGroup: ItemGroup }
}

const selectedPropertyIds = computed(() => props.selectedInputs.map((input) => input.propertyId))
const selectedEntityIds = computed(() =>
  props.selectedInputs.map((input) => input.entityId).filter((id): id is string => !!id),
)
/**
 * Returns true if an item is selected. Library items are identified by their
 * entity ID, while properties are identified by their property ID.
 */
const itemIsSelected = (item: ModelInputItem) =>
  item.data.group === 'Library'
    ? selectedEntityIds.value.includes(item.data.id)
    : selectedPropertyIds.value.includes(item.data.id)

/**
 * In the UI, we want to group selected items separately, so update an item's group to
 * 'Selected' if it is selected.
 */
const groupedItems = computed<Item[]>(() =>
  props.items
    .filter((item) => !(item.data.group === 'Properties' && item.data.viaPropertyId)) // filter out properties that belong to other projects
    .map((item) => ({
      ...item,
      data: {
        ...item.data,
        selectedGroup: itemIsSelected(item) ? 'Selected' : item.data.group,
      },
    })),
)

const onToggleItem = (id: string) => {
  const item = props.items.find((item) => item.data.id === id)
  if (!item) return

  if (item.data.group === 'Library') {
    emit('toggle:library-item', id)
  } else {
    emit('toggle:property', { propertyId: id })
  }
}

type FilterProperty = {
  property: Property
  filters: PropertyInput['filters']
  subprojectProperties: FilterableProperty[]
} | null

const filterProperty = ref<FilterProperty>(null)

/**
 * For a given sub-property, get all the sibling properties that are filterable
 * subproperties of it.
 */
const getSubprojectProperties = (parentId: string) =>
  subprojectStore.properties
    .filter((p) => p.parentPropertyId === parentId)
    .filter(isFilterableProperty)
    .map((p) => ({
      ...p,
      // Subproject properties are prefixed with the parent property name, so
      // remove it for display.
      name: p.name.split('/')[1],
    }))

const subprojectStore = useSubProject()
const onOpenFilterMenu = (item: ModelInputItem) => {
  if (item.data.group === 'Properties') {
    filterProperty.value = {
      property: item.data,
      filters: item.data.filters,
      subprojectProperties: item.data.parentPropertyId
        ? getSubprojectProperties(item.data.parentPropertyId)
        : [],
    }
  }
}

const isCollectionSubProperty = (property: Property | undefined): property is Property =>
  !!property && !!property.parentPropertyId

/**
 * When the component is mounted, check if it should open the collection property filter menu for a specific property.
 */
onMounted(() => {
  if (props.openWithFilterPropertyId) {
    const item = props.items.find((item) => item.data.id === props.openWithFilterPropertyId)
    if (!item) return
    onOpenFilterMenu(item)
  }
})

const isManualCollectionsEnabled = useFeatureFlags(FeatureFlag.MANUAL_COLLECTIONS)

const onUpdateFilters = (filters: PropertyInput['filters'] | null) => {
  assertIsNotNullOrUndefined(filterProperty.value, 'No property set when updating filters')

  emit('update:filters', {
    propertyId: filterProperty.value.property.id,
    filters,
  })
}

const selectedReferenceProperty = ref<string | null>(null)
const onToggleReferenceMenu = (id: string) => {
  if (selectedReferenceProperty.value === id) {
    selectedReferenceProperty.value = null
  } else {
    selectedReferenceProperty.value = id
  }
}
const relatedProjectStore = useRelatedProjectsStore()

/**
 * For a given reference property, returns whether:
 * - All of its related properties are selected
 * - Some of its related properties are selected
 * - None of its related properties are selected
 */
const getReferencePropertyState = (property: Property<'reference'>): 'some' | 'all' | 'none' => {
  // All properties that are on the related project
  const relatedProperties = relatedProjectStore.getPropertiesForReference(property.id)

  // All related properties that are selected
  const filteredInputs = props.selectedInputs.filter((input) => input.viaPropertyId === property.id)

  if (filteredInputs.length === 0) {
    return 'none'
  }

  if (filteredInputs.length === relatedProperties.length) {
    return 'all'
  }

  return 'some'
}

/**
 * Deselects all related properties if they are all selected, otherwise
 * selects all related properties.
 */
const onToggleAllRelatedProperties = (referenceProperty: Property<'reference'>, event: Event) => {
  event.stopPropagation()
  const currentState = getReferencePropertyState(referenceProperty)
  const relatedProperties = relatedProjectStore.getPropertiesForReference(referenceProperty.id)

  const idsToToggle = relatedProperties
    .filter((p) => {
      if (currentState === 'all' || currentState === 'none') {
        return true
      }

      // If some related properties are selected, we want the checkbox to act
      // as a select all - so toggle all unselected properties.
      return !selectedPropertyIds.value.includes(p.id)
    })
    .map((p) => p.id)

  idsToToggle.forEach((id) =>
    emit('toggle:property', { propertyId: id, viaPropertyId: referenceProperty.id }),
  )
}

const parentPlacement = inject<Ref<Placement>>(FLOATING_PLACEMENT_PROVIDE_KEY)
const menuId = useId()
</script>

<template>
  <ModelInputMenuFilters
    v-if="filterProperty"
    :property="filterProperty.property"
    :filters="filterProperty.filters"
    :subproject-properties="filterProperty.subprojectProperties"
    @close="filterProperty = null"
    @update="onUpdateFilters"
  />
  <ListMenu
    v-else
    :id="menuId"
    role="listbox"
    class="w-[280px]"
    aria-label="Select input properties"
    :items="groupedItems"
    search-by-field="name"
    :group-by-predicate="(item) => item.data.selectedGroup"
    :group-order="['Selected', 'Properties', 'Library']"
    :scroll-level="'group'"
    :has-group-titles="true"
    @select="onToggleItem($event.id)"
  >
    <template #group-title="{ group }">
      <div v-if="group.key === 'Selected'" />
      <div
        v-else
        class="flex h-7 items-center justify-between px-1.5 py-0.5"
      >
        <h4 class="text-xs-11px-bold text-text-subtlest">
          {{ group.key }}
        </h4>
        <IconButton
          v-if="group.key === 'Library'"
          icon="folder"
          size="xs"
          variant="transparent"
          aria-label="Open library"
          @click="$emit('open:library')"
        />
      </div>
    </template>
    <template #item="{ key, item, active, setActiveItem }">
      <LibraryListMenuItem
        v-if="item.data.group === 'Library'"
        :item="item.data"
        :checked="itemIsSelected(item)"
        :aria-selected="itemIsSelected(item)"
        :hide-date="itemIsSelected(item)"
        default-hover-disabled
        :active="active"
        @mousemove="setActiveItem(key)"
        @select="onToggleItem(item.id)"
      />
      <ListMenuCheckboxItem
        v-else-if="!isCollectionProperty(item.data) && item.data.type !== 'reference'"
        :label="item.data.name"
        :active="active"
        :checked="itemIsSelected(item)"
        :indeterminate="item.data.filterCount > 0"
        :aria-selected="itemIsSelected(item)"
        :icon="item.data.group === 'Properties' ? TYPE_ICON[item.data.type] : undefined"
        default-hover-disabled
        class="w-full"
        @mousemove="setActiveItem(key)"
        @select="onToggleItem(item.id)"
      >
        <template #suffix>
          <DarwinButton
            v-if="
              isCollectionSubProperty(item.data) &&
              isManualCollectionsEnabled &&
              (active || (item.data && item.data?.filterCount > 0))
            "
            variant="transparent"
            size="xxs"
            class="w-10"
            @click="onOpenFilterMenu(item)"
          >
            {{
              item.data && item.data?.filterCount > 0
                ? `${item.data.filterCount} filter${item.data.filterCount > 1 ? 's' : ''}`
                : 'Filter'
            }}
          </DarwinButton>
        </template>
      </ListMenuCheckboxItem>
      <PopupMenu
        v-else-if="item.data.type === 'reference'"
        :open="selectedReferenceProperty === item.id"
        trigger-element="div"
        :placement="parentPlacement"
        :offset="{ mainAxis: 2, alignmentAxis: -2 }"
        :teleport-to="`#${menuId}`"
        @change:open="onToggleReferenceMenu(item.id)"
      >
        <template #trigger>
          <ListMenuCheckboxItem
            :icon="TYPE_ICON[item.data.type]"
            :active="selectedReferenceProperty === item.id || active"
            :checked="getReferencePropertyState(item.data) === 'all'"
            :indeterminate="getReferencePropertyState(item.data) === 'some'"
            :label="item.data.name"
            @mousemove="setActiveItem(key)"
            @select="onToggleReferenceMenu(item.id)"
            @click:checkbox="
              (_, event) => {
                if (item.data.type !== 'reference') {
                  return
                }
                onToggleAllRelatedProperties(item.data, event)
              }
            "
          >
            <template #suffix>
              <IconSprite
                icon="chevron-right"
                size="sm"
                class="text-icon-subtle"
              />
            </template>
          </ListMenuCheckboxItem>
        </template>
        <template #dropdown>
          <ModelInputMenuRelationProperties
            :selected-ids="selectedPropertyIds"
            :properties="relatedProjectStore.getPropertiesForReference(item.data.id)"
            @toggle:property="
              $emit('toggle:property', { propertyId: $event, viaPropertyId: item.data.id })
            "
          />
        </template>
      </PopupMenu>
    </template>
  </ListMenu>
</template>
