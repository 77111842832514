export const Color = [
  'rainbow-01',
  'rainbow-02',
  'rainbow-03',
  'rainbow-04',
  'rainbow-05',
  'rainbow-06',
  'rainbow-07',
  'rainbow-08',
  'rainbow-09',
  'rainbow-10',
  'rainbow-11',
  'rainbow-12',
  'rainbow-13',
  'rainbow-14',
  'rainbow-15',
  'rainbow-17',
] as const
