import type { RouteLocationNormalized } from 'vue-router'

type UTMParams = {
  utmSource: string | null
  utmMedium: string | null
  utmCampaign: string | null
  utmTerm: string | null
  utmContent: string | null
}

const UTM_PARAMS_KEY = 'utm-query-params'

export const useUtmParams = () => {
  const setUtmParams = (to: RouteLocationNormalized): void => {
    const utmParams: UTMParams = {
      utmSource: to.query.utm_source || null,
      utmMedium: to.query.utm_medium || null,
      utmCampaign: to.query.utm_campaign || null,
      utmTerm: to.query.utm_term || null,
      utmContent: to.query.utm_content || null,
    } as UTMParams

    if (Object.values(utmParams).some((value) => value !== null)) {
      localStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(utmParams))
    }
  }

  /** Either get the UTM params from a cookie (coming from the marketing site)
  or local storage or fallback to null */
  const getUtmParams = (): UTMParams | null => {
    const fromCookie = readUtmFromCookie()
    if (fromCookie) {
      return fromCookie
    }
    const item = localStorage.getItem(UTM_PARAMS_KEY)
    return item ? (JSON.parse(item) as UTMParams) : null
  }

  const readUtmFromCookie = (): UTMParams | null => {
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(UTM_PARAMS_KEY))
    if (cookie) {
      const utmParams = cookie.split('=')[1]
      return JSON.parse(utmParams) as UTMParams
    }
    return null
  }

  return {
    setUtmParams,
    getUtmParams,
  }
}
