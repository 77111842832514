import { defineStore } from 'pinia'
import { createHighlighter } from 'shiki'
import { ref } from 'vue'

export const useShikiHighlighter = defineStore('shiki-highlighter', () => {
  const highlighter = ref<Awaited<ReturnType<typeof createHighlighter>>>()

  const promise = createHighlighter({
    themes: ['nord', 'min-light'],
    langs: ['json', 'python'],
  }).then((h) => {
    highlighter.value = h
  })

  return {
    highlighter,
    promise,
  }
})
