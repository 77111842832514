import type { PickPartial } from '@/shared/types'
import type { AutoPlacementOptions } from '@floating-ui/vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

type Popover = {
  /** CSS selector of the element that the popover is anchored to */
  targetSelector: string
  /** Text to display in the popover */
  text: string
  /** Where to place the popover in relation to its anchor */
  placement?: AutoPlacementOptions
  variant: 'askGo' | 'app'
}

type SettablePopover = PickPartial<Popover, 'variant'>

/**
 * We sometimes need to render a popover over an arbitrary DOM element, in response to a reactive
 * state change, or a network response (e.g. when Ask Go creates a new property, we render a
 * popover over the property header in the project table).
 *
 * This store provides a way to set the target element and the text to display in the popover.
 */
export const usePopoverStore = defineStore('popover', () => {
  const popover = ref<Popover | null>(null)

  const setPopover = (newPopover: SettablePopover | null) => {
    if (!newPopover) {
      popover.value = null
      return
    }

    popover.value = {
      targetSelector: newPopover.targetSelector,
      text: newPopover.text,
      placement: newPopover.placement,
      variant: newPopover.variant || 'app',
    }
  }

  return {
    popover,
    setPopover,
  }
})
