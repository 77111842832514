<script lang="ts" setup>
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { computed } from 'vue'
import { TYPE_ICON } from './icons'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import type { ModelInputItem } from './useModelInputs'
/**
 * This component renders a badge to represent an input to a model.
 */

const props = defineProps<{
  input: ModelInputItem['data']
}>()

defineEmits<{
  (e: 'click:filter'): void
}>()

const relatedProjectStore = useRelatedProjectsStore()
const label = computed(() => {
  if (props.input.group === 'Properties' && props.input.viaPropertyId) {
    // If the viaPropertyId is set, we know this is a property from a different project
    const relatedProject = relatedProjectStore.getRelatedProject(props.input.viaPropertyId)
    return `${relatedProject.name} › ${props.input.name}`
  }

  return props.input.name
})
</script>

<template>
  <BadgeItem
    :key="input.id"
    :label="label"
    variant="selected"
    size="sm"
    :leading-icon="input.group === 'Properties' ? TYPE_ICON[input.type] : undefined"
  >
    <template
      v-if="input.group === 'Properties' && input.filterCount > 0"
      #trailing-icon
    >
      <button
        :aria-label="`${input.filterCount} filters`"
        @click="$emit('click:filter')"
      >
        <BadgeItem
          variant="selected"
          size="xs"
          leading-icon="filter"
          :label="input.filterCount.toString()"
        />
      </button>
    </template>
  </BadgeItem>
</template>
