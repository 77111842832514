<script setup lang="ts">
import CsvPlaceholder from '@/illustrations/library-csv.svg'
import ImagePlaceholder from '@/illustrations/library-image.svg'
import PdfPlaceholder from '@/illustrations/library-pdf.svg'
import { getAgoString } from '@/shared/utils/date'
import CheckBox from '@/uiKit/CheckBox.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed } from 'vue'
import type { LibraryItem } from './libraryStore'

/**
 * This component represents a library item as it appears in dropdowns across
 * the frontend. It is used when selecting a library item to be an input to
 * a model.
 */

const props = withDefaults(
  defineProps<{
    item: LibraryItem
    checked?: boolean
    active: boolean
    hideDate?: boolean
  }>(),
  { checked: undefined },
)

const lastUpdated = computed(() => getAgoString(props.item.updatedAt))

const extension = computed(() =>
  props.item.type === 'file' ? props.item.filename.split('.').pop() : 'Document',
)
</script>

<template>
  <ListMenuItem
    :label="item.name"
    class="group"
    :active="active"
  >
    <template #prefix>
      <CheckBox
        v-if="typeof checked === 'boolean'"
        :checked="checked"
        class="pointer-events-none transition"
        :class="[checked || active ? 'opacity-100' : 'opacity-0']"
      />
      <div class="flex size-5 items-center justify-center p-0.5">
        <CsvPlaceholder v-if="extension === 'csv'" />
        <PdfPlaceholder v-else-if="extension === 'pdf' || extension === 'Document'" />
        <ImagePlaceholder v-else />
      </div>
    </template>
    <template #suffix>
      <!--
        Set the opacity to 0 instead of removing the element so that this list
        item (and therefore its parent) keep the same width in the DOM, and
        the menu doesn't jump around.
      -->
      <div
        class="min-w-max text-text-subtlest"
        :class="hideDate ? 'opacity-0' : 'ml-3'"
        :aria-hidden="hideDate"
      >
        {{ lastUpdated }}
      </div>
    </template>
  </ListMenuItem>
</template>
