import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { getMilisecondsFromUUIDv7 } from '@/shared/utils'
import { useProject, type Entity, type Field } from './useProject'

type Node = {
  id: string
  entity?: Entity
}

// we store loaded 'entities' in an array
// those entities are separate from the one stored inside the project store
// on the entity view we don't have the total count and the concept of range and how big the table is etc
// it's supposed to be a lightweight store that only contains the entities that you have loaded
// the items here are inserted based on the getPrev, getNext and getEntity queries
export const useEntity = defineStore('entity', () => {
  const projectStore = useProject()
  const entityId = ref<string>()
  const entities = ref<Node[]>([])
  const areEntitiesStale = ref(false)

  const setEntityId = (id?: Entity['id']) => {
    entityId.value = id
  }

  const setEntitiesStale = (stale: boolean) => (areEntitiesStale.value = stale)

  const entityIdx = computed<number>(() => entities.value.findIndex((e) => e.id === entityId.value))
  const entity = computed<Entity | undefined>(() => entities.value[entityIdx.value]?.entity)

  const prevNode = computed<Node | undefined>(() => entities.value[entityIdx.value - 1])
  const prev = computed<Entity | undefined>(() => prevNode.value?.entity)
  const prevId = computed<string | undefined>(() => prevNode.value?.id)

  const nextNode = computed<Node | undefined>(() => entities.value[entityIdx.value + 1])
  const next = computed<Entity | undefined>(() => nextNode.value?.entity)
  const nextId = computed<string | undefined>(() => nextNode.value?.id)

  const insertIds = (ids: string[], entityId: string, after: boolean) => {
    const index = entities.value.findIndex((e) => e.id === entityId)
    if (index === -1) return
    entities.value.splice(index + (after ? 1 : 0), 0, ...ids.map((id) => ({ id })))
  }

  const insertPrevIds = (ids: string[], entityId: string) => insertIds(ids, entityId, false)
  const insertNextIds = (ids: string[], entityId: string) => insertIds(ids, entityId, true)

  const resetEntities = () => {
    entities.value = []
    areEntitiesStale.value = false
  }

  const updateEntity = (updatedEntity: Entity) => {
    if (!entityId.value) return
    const isPartOfTheCurrentView = updatedEntity.activeViewIds?.includes(
      projectStore.activeView?.id ?? '',
    )

    const index = entities.value.findIndex((e) => e.id === updatedEntity.id)
    if (index > -1) {
      if (isPartOfTheCurrentView) {
        // we update the entity if it's part of the current view
        entities.value[index] = { id: updatedEntity.id, entity: updatedEntity }
      } else {
        // we remove the entity if it's not part of the current view anymore
        if (entityId.value === updatedEntity.id) {
          const prevOrNextId = prev.value?.id ?? next.value?.id
          if (prevOrNextId) {
            setEntityId(prevOrNextId)
          }
        }
        entities.value.splice(index, 1)
      }
      // handle cases where activeViewIds got updated
    } else {
      if (!isPartOfTheCurrentView) return
      // if there is no next entity and it's newer than the current entity we push it
      if (
        !nextId.value &&
        getMilisecondsFromUUIDv7(updatedEntity.id) > getMilisecondsFromUUIDv7(entityId.value)
      ) {
        entities.value.push({ id: updatedEntity.id, entity: updatedEntity })
        return
      }
      // if there is no prev entity and it's older than the current entity we insert it first
      if (
        !prevId.value &&
        getMilisecondsFromUUIDv7(updatedEntity.id) < getMilisecondsFromUUIDv7(entityId.value)
      ) {
        entities.value.unshift({ id: updatedEntity.id, entity: updatedEntity })
        return
      }
      // if it's between two loaded entities we insert it in between
      const sortedEntities = entities.value
        .concat({ id: updatedEntity.id, entity: updatedEntity })
        .toSorted((a, b) => getMilisecondsFromUUIDv7(a.id) - getMilisecondsFromUUIDv7(b.id))
      const index = sortedEntities.findIndex((e) => e.id === updatedEntity.id)
      const leftNeightbour = sortedEntities[index - 1]
      const rightNeightbour = sortedEntities[index + 1]
      const leftNeightbourIndex = entities.value.findIndex((e) => e.id === leftNeightbour?.id)
      const rightNeightbourIndex = entities.value.findIndex((e) => e.id === rightNeightbour?.id)
      const ifNeighboursNextToEachOther =
        leftNeightbourIndex > -1 &&
        rightNeightbourIndex > -1 &&
        leftNeightbourIndex + 1 === rightNeightbourIndex
      if (ifNeighboursNextToEachOther) {
        entities.value.splice(rightNeightbourIndex, 0, {
          id: updatedEntity.id,
          entity: updatedEntity,
        })
      }
    }
  }

  const removeEntities = (ids: string[]) => {
    entities.value = entities.value.filter((e) => !ids.includes(e.id))
  }

  const updateField = (field: Field) => {
    const index = entities.value.findIndex((e) => e.id === field.entityId)
    entities.value[index]?.entity?.fields.set(field.propertyId, field)
  }

  return {
    areEntitiesStale,
    entities,
    entity,
    entityId,
    insertNextIds,
    insertPrevIds,
    next,
    nextId,
    prev,
    prevId,
    removeEntities,
    resetEntities,
    setEntitiesStale,
    setEntityId,
    updateEntity,
    updateField,
  }
})
