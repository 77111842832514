import { defineStore } from 'pinia'

import type { ApiKeyResponse } from '@/backend/types'
import { ref } from 'vue'
export type ApiKey = ApiKeyResponse

/** Serializes a backend apikey object into a frontend apikey object */
export const serializeApiKey = (payload: ApiKeyResponse): ApiKey => ({
  id: payload.id,
  name: payload.name,
  expires_at: payload.expires_at,
  prefix: payload.prefix,
  key: payload.key,
  user_id: payload.user_id,
  last_used_at: payload.last_used_at,
})

export const useApiKeys = defineStore('apiKeys', () => {
  const apiKeys = ref<ApiKey[]>([])
  const setApiKeys = (newApiKeys: ApiKey[]) => {
    apiKeys.value = newApiKeys
  }

  const removeApiKey = (id: string) => {
    apiKeys.value = apiKeys.value.filter((apikey) => apikey.id !== id)
  }

  const addApiKey = (apikey: ApiKey) => {
    apiKeys.value.push(apikey)
  }

  return {
    apiKeys,
    addApiKey,
    setApiKeys,
    removeApiKey,
  }
})
