export function assertIsNotNullOrUndefined<T>(
  value: T | null | undefined,
  error?: string,
): asserts value is T {
  if (value === null || value === undefined) {
    throw new Error(error ?? 'Value is null or undefined')
  }
}

/**
 * Is used to assert that we've covered all possible cases in switch/if-else
 * statements. If we've missed a case:
 * - Typescript will error if our types are well-defined
 * - We will get a runtime error if our types are not well-defined
 */
export const exhaustiveGuard = (value: never, message: string): never => {
  throw new Error(message)
}

/**
 * Will throw an error if the condition is not met, providing assurances to the
 * TypeScript compiler that the condition is true.
 */
export function invariant<T>(condition: T, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg)
  }
}
