<script setup lang="ts">
import { ref, useAttrs } from 'vue'

defineProps<{
  checked?: boolean
  label?: string
}>()

const emit = defineEmits<{ (e: 'change', value: boolean): void }>()

const inputField = ref<HTMLInputElement>()

const attrs: { disabled?: boolean } = useAttrs()
</script>

<template>
  <label
    class="flex items-center gap-1"
    :class="[
      attrs.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      !!label && ['rounded-corner-16 px-3 py-2'],
      !!label &&
        checked && [
          'bg-background-selected',
          'hover:bg-background-selected-hovered',
          'active:bg-background-selected-pressed',
        ],
      !!label &&
        !checked && [
          'bg-background-gray-sunken',
          'hover:bg-background-gray-sunken-hovered',
          'active:bg-background-gray-sunken-pressed',
        ],
    ]"
    @click.exact.prevent="emit('change', !checked)"
    @click.shift.stop.exact
  >
    <div class="flex items-center justify-center p-px">
      <button
        class="flex size-3.5 items-center justify-center rounded-corner-8 border-[1.5px] text-icon-inverted transition disabled:border-none disabled:bg-background-disabled disabled:text-icon-disabled"
        :class="[
          !checked && [
            'border-border-input bg-background-transparent hover:bg-background-transparent-hovered focus:bg-background-transparent-hovered focus-visible:shadow-focus-ring-primary focus-visible:outline-none active:bg-background-transparent-pressed',
          ],

          checked &&
            '!border-[3px] border-background-primary hover:border-background-primary-hovered focus:border-background-primary-hovered focus-visible:shadow-focus-ring-primary focus-visible:outline-none active:border-background-primary-pressed [&_circle]:fill-icon-inverted',
          ,
        ]"
        :tabindex="$attrs.disabled ? undefined : 0"
        role="checkbox"
        :disabled="attrs.disabled"
        :aria-disabled="attrs.disabled ? 'true' : 'false'"
        :aria-checked="checked ? 'true' : 'false'"
        type="button"
      >
        <input
          ref="inputField"
          type="radio"
          class="hidden"
          :checked="checked"
          :disabled="attrs.disabled"
          :aria-disabled="$attrs.disabled ? 'true' : 'false'"
          :aria-checked="checked ? 'true' : 'false'"
        />
      </button>
    </div>
    <div
      v-if="label"
      class="select-none text-text"
    >
      {{ label }}
    </div>
  </label>
</template>
