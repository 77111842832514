import type { components } from '@/api'
import { get } from './client'

const PATH = '/api/workspaces'

export type ListWorkspacesPath = `/api/workspaces`
export type ListWorkspacesResponse = components['schemas']['Workspaces.ListWorkspacesResponse']
export type ListWorkspacesRequest = undefined

export const listWorkspaces = () =>
  get<ListWorkspacesRequest, ListWorkspacesResponse, ListWorkspacesPath>(PATH, undefined)
