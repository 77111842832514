/**
 * We want the following formats:
 * 1 minute ago → 59 minutes ago
 * 1 hour ago → 23 hours ago
 * 1 day ago → 6 days ago
 * 1 week ago → 3 weeks ago
 * 1 month ago → 11 months ago
 * 1 year ago, 2 years ago, 3 years ago, etc
 */
export const getAgoString = (date: string): string => {
  const timestamp = new Date(date).getTime()
  const now = new Date().getTime()

  const secondsAgo = Math.floor((now - timestamp) / 1000)
  if (secondsAgo < 60) {
    return `${secondsAgo} second${secondsAgo > 1 ? 's' : ''} ago`
  }

  const minutesAgo = Math.round(secondsAgo / 60)
  if (minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`
  }

  const hoursAgo = Math.round(minutesAgo / 60)
  if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`
  }

  const daysAgo = Math.round(hoursAgo / 24)
  if (daysAgo < 7) {
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`
  }

  const weeksAgo = Math.round(daysAgo / 7)
  if (weeksAgo < 4) {
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`
  }

  const monthsAgo = Math.round(daysAgo / 30)
  if (monthsAgo < 12) {
    return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`
  }

  const yearsAgo = Math.round(daysAgo / 365)
  return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`
}
