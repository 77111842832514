import { computed, watch, type Ref } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useModelProviders } from './modelProvidersStore'

export const useLoadModelProviders = (workspaceId: Ref<string>) => {
  const modelProvidersStore = useModelProviders()
  const permissionsStore = usePermissionsStore()
  const canManageTools = computed(
    () => permissionsStore.workspacePermissions.manage_tool_integrations,
  )

  watch(
    () => [workspaceId.value, canManageTools.value] as const,
    (newValues, oldValues) => {
      const [newWorkspaceId, hasPermission] = newValues
      const oldWorkspaceId = oldValues ? oldValues[0] : undefined

      if (newWorkspaceId !== oldWorkspaceId) {
        modelProvidersStore.refreshTools(newWorkspaceId)
      }

      if (hasPermission) {
        modelProvidersStore.refreshProviderConfig(newWorkspaceId)
      }
    },
    { immediate: true },
  )
}
