<script setup lang="ts">
import IconButton from '@/uiKit/IconButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import type { OffsetOptions } from '@floating-ui/vue'
import { computed, ref } from 'vue'
import { getMemberName, useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { useProperty } from './useProperty'

const workspaceMembersStore = useWorkspaceMembers()

type UserItem = {
  id: string
  data: {
    id: string
    label: string
  }
}

/** All users in the current workspace */
const userOptions = computed<UserItem[]>(() => {
  return workspaceMembersStore.workspaceMembers.map((member) => ({
    id: member.id,
    data: {
      id: member.id,
      label: getMemberName(member),
    },
  }))
})

const propertyStore = useProperty()
/** The users that are currently selected */
const selectedUsers = computed(() => {
  return propertyStore.visibleOptions.map((option) => {
    const member = workspaceMembersStore.workspaceMembers.find(
      (member) => member.id === option.value,
    )

    return {
      value: member ? getMemberName(member) : option.value,
      color: option.color,
    }
  })
})

const submenuOffset: OffsetOptions = {
  alignmentAxis: -2,
  mainAxis: 4,
}

const menuIsOpen = ref(false)

const USER_SUBMENU_ID = 'property-configuration-user-submenu'

/** Will return true if the workspace member is assignable to fields using this property */
const userIsSelected = (user: UserItem): boolean =>
  selectedUsers.value.some((selectedUser) => selectedUser.value === user.data.label)

/** The default user. All new entities will be created with this value set. */
const defaultUserId = computed(() => {
  if (propertyStore.editedProperty?.type !== 'user_select') {
    return null
  }

  if (propertyStore.editedProperty.config.defaultOption !== undefined) {
    return propertyStore.editedProperty.config.defaultOption
  }

  if (propertyStore.property && !('config' in propertyStore.property)) return null

  if (propertyStore.property?.config && 'defaultOption' in propertyStore.property.config) {
    return propertyStore.property.config.defaultOption
  }

  return null
})

/** Toggles this user as the default user */
const onToggleDefault = (user: UserItem) => {
  const newDefault = defaultUserId.value === user.id ? null : user.id

  propertyStore.markOptionAsDefault(newDefault)
}
</script>

<template>
  <PopupMenu
    placement="right-start"
    :offset="submenuOffset"
    class="w-full"
    :open="menuIsOpen"
    @change:open="menuIsOpen = $event"
  >
    <template #trigger>
      <slot
        name="trigger"
        :is-open="menuIsOpen"
        :submenu-id="USER_SUBMENU_ID"
        :selected-users="selectedUsers"
      />
    </template>
    <template #dropdown>
      <ListMenu
        :id="USER_SUBMENU_ID"
        role="listbox"
        class="max-h-[500px] min-w-[240px]"
        aria-label="Select users"
        :items="userOptions"
        :all-items="userOptions"
        search-by-field="label"
        @select="propertyStore.toggleSelectOption({ value: $event.id })"
      >
        <template #item="{ key, item, active, setActiveItem }">
          <ListMenuCheckboxItem
            :label="item.data.label"
            :active="active"
            :checked="userIsSelected(item)"
            :aria-selected="active"
            icon="user"
            default-hover-disabled
            @mousemove="setActiveItem(key)"
            @select="propertyStore.toggleSelectOption({ value: item.id })"
          >
            <template
              v-if="userIsSelected(item)"
              #suffix
            >
              <IconButton
                size="sm"
                :aria-label="`Mark ${item.data.label} as default`"
                variant="transparent"
                :icon="defaultUserId === item.id ? 'rating-fill' : 'rating'"
                @click.stop="onToggleDefault(item)"
              />
            </template>
          </ListMenuCheckboxItem>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
