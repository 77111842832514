import { useDebounce } from '@vueuse/core'
import { computed, ref, type Ref } from 'vue'

/**
 * Our PopOver component is intentionally unopinionated about how and when its
 * content is displayed. However, in many cases we want the behaviour to be
 * typical of a tooltip, i.e. to show the content when the user hovers over a
 * trigger element, and hide it when the user moves the cursor away.
 *
 * This composable provides state and handlers to attach to the <PopOver> and
 * its trigger/content elements to achieve this behaviour.
 */
export const useTooltip = ({ disabled }: { disabled?: Ref<boolean> } = {}) => {
  const isOpen = ref(false)
  const setOpen = () => (isOpen.value = true)
  const setClosed = () => (isOpen.value = false)

  const debounceMs = computed(() => (isOpen.value ? 300 : 100))
  const debouncedValue = computed(() => debounceMs.value)
  const debouncedIsOpen = useDebounce(isOpen, debouncedValue)

  // Same as debouncedIsOpen, but allows for props.disabled to override it
  const overridenIsOpen = computed(() => debouncedIsOpen.value && !disabled?.value)

  const trigger = {
    onMouseOver: setOpen,
    onMouseLeave: setClosed,
    onFocusIn: setOpen,
    onFocusOut: setClosed,
    onClick: setClosed,
  }

  const popover = {
    onMouseOver: setOpen,
    onMouseLeave: setClosed,
  }

  return {
    isOpen: overridenIsOpen,
    trigger,
    popover,
    debounceMs,
  }
}
