<script setup lang="ts">
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useDebounceFn, useElementBounding, useElementSize } from '@vueuse/core'
import { computed, nextTick, onUnmounted, ref, toRef, watch } from 'vue'
import { useRoute } from 'vue-router'

import type { components } from '@/api'
import { clamp, roundToDecimal } from '@/shared/utils/number'
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import ScaleButtons from './ScaleButtons.vue'
import { useGroundingPolygons } from './useGroundingPolygons'
import { useGroundingStore, type Source } from './useGroundingStore'

type BoundingBox = components['schemas']['Projects.Grounding.BoundingBox']
type OcrPage = components['schemas']['Projects.Entities.OCRPage']
type GroundingInfo = {
  sources: Source[]
  ocrPages: OcrPage[]
}

const props = withDefaults(
  defineProps<{
    filePath: string
    storagePrefix?: string
    groundingInfo?: GroundingInfo
    showSidebar?: boolean
  }>(),
  {
    storagePrefix: 'entityview-scale',
    groundingInfo: () => ({ sources: [], ocrPages: [] }),
    showPages: false,
  },
)

/**
 * Max scaling factor that we allow.
 *
 * We can't animate transform of the PDF renderer, so we do it on the parent container.
 * Zooming in via CSS transform ends up with a blurry, CSS-upscaled PDF.
 * PDF itself needs to be rendered at a larger scaling factor.
 *
 * We can always render the PDF at max allowed scale.
 * Otherwise, it's either rendered at lower scale and gets blurry when you zoom in,
 * or you adjust the pdf scale dynamically, but then you can't animate its sizing.
 */
const maxScale = 2

const route = useRoute()
const projectId = [route.params.projectId].flat()[0]
const propertyId = [route.params.propertyId].flat()[0]
const { pdf, pages, download } = usePDF(computed(() => props.filePath))

defineExpose({
  download,
})

const storageKey = computed(() => `${props.storagePrefix}-proj-${projectId}-prop-${propertyId}`)
const {
  onZoom,
  scrollContainer: pdfContainer,
  scrollContainerChild: pdfContainerChild,
  scale,
  setScale,
} = useNaturalZoom(storageKey)

const { width: containerWidth, height: containerHeight } = useElementSize(pdfContainer)
const { width: childWidth, height: childHeight } = useElementBounding(pdfContainerChild)
const actualScale = computed(() => {
  if (typeof scale.value === 'number') return scale.value

  if (
    !containerWidth.value ||
    !containerHeight.value ||
    !childWidth.value ||
    !childHeight.value ||
    !pdfContainerChild.value
  )
    return 1
  const styleScale = Number(getComputedStyle(pdfContainerChild.value).scale)
  const currentScale = Number.isNaN(styleScale) ? 1 : styleScale

  const actualWidth = childWidth.value / currentScale
  const actualHeight = childHeight.value / currentScale

  const res = Math.min(containerWidth.value / actualWidth, containerHeight.value / actualHeight)
  return roundToDecimal(res, 2)
})

const pdfInfo = ref<
  {
    scale: number
    rawDims: {
      pageWidth: number
      pageHeight: number
    }
  }[]
>([])

const pagesContainer = ref<HTMLDivElement | null>(null)
const { width: pagesWidth } = useElementSize(pagesContainer)

type GetBoxCoordsArgs = {
  scale: number
  box: BoundingBox
  page: number
}

function getBoxCoords({ scale, box, page }: GetBoxCoordsArgs) {
  const ocrPage = props.groundingInfo.ocrPages.find((p) => p.number === page)
  if (!ocrPage) return

  // percentage based values
  const left = (box.xmin / ocrPage.width) * 100
  const top = (box.ymin / ocrPage.height) * 100
  const right = (box.xmax / ocrPage.width) * 100
  const bottom = (box.ymax / ocrPage.height) * 100
  const width = right - left
  const height = bottom - top

  const coords = {
    width: `${width}%`,
    height: `${height}%`,
    left: `${left}%`,
    top: `${top}%`,
    'border-width': `${scale * 1}px`,
    'border-radius': `${scale * 8}px`,
  }

  return coords
}

const sourcesWithCoords = computed(() => {
  return props.groundingInfo?.sources.map((source) => {
    const boxes = source.boundingBoxes.map((b) => {
      const s = scale.value === 'fit' ? (pdfInfo.value[b.page - 1]?.scale ?? 1) : scale.value

      return {
        ...b,
        coords: getBoxCoords({
          scale: s,
          box: b,
          page: b.page,
        }),
      }
    })

    const claimCoords = boxes[0]?.coords
      ? {
          left: boxes[0]?.coords?.left,
          top: boxes[0]?.coords?.top,
        }
      : {}

    return {
      ...source,
      boxes,
      claimCoords,
    }
  })
})

function getPageSources(page: number) {
  return sourcesWithCoords.value.filter((s) => s.boxes.some((b) => b.page === page))
}

const zooming = ref(false)
const resetZoom = useDebounceFn(() => {
  zooming.value = false
}, 300)

function zoom() {
  zooming.value = true
  resetZoom()
}

const groundingStore = useGroundingStore()
const { clickSourcePill, selected } = useGroundingInteractions({
  onClaimPillClick: async ({ sourceId }) => {
    // When zooming into a grounding claim, we can't animate from `fit` to <actualScale> number.
    // If we don't do this, and scale is `fit`, it would take 2 clicks on a claim to move
    // the document to a right position on screen.
    if (scale.value === 'fit') {
      scale.value = actualScale.value
    }
    groundingStore.clickSource(sourceId)
    await nextTick()
    if (!pdfContainer.value || !pdfContainerChild.value) return

    const source = sourcesWithCoords.value.find((s) => s.id === sourceId)
    const page = source?.boundingBoxes[0].page
    const sourceEl = pdfContainer.value.querySelector(`[data-source="${sourceId}"]`)
    if (!sourceEl || !page) return

    const viewbox = getOcrPageViewbox(page)
    const [vw, vh] = viewbox.split(' ').map(Number).slice(2)
    const points = getPolygonPointsFromSource(source, page).flatMap((polygons) => {
      return polygons
        .trim()
        .split(' ')
        .flatMap((points) => {
          const [x, y] = points.split(',').map(Number)
          return { x, y }
        })
    })

    const [minX, minY, maxX, maxY] = points.reduce(
      ([minX, minY, maxX, maxY], { x, y }) => [
        Math.min(minX, x),
        Math.min(minY, y),
        Math.max(maxX, x),
        Math.max(maxY, y),
      ],
      [Infinity, Infinity, -Infinity, -Infinity],
    )

    const leftPercentage = minX / vw
    const rightPercentage = maxX / vw
    const topPercentage = minY / vh
    const bottomPercentage = maxY / vh
    const widthPercentage = rightPercentage - leftPercentage
    const heightPercentage = bottomPercentage - topPercentage

    const centerXPercentage = leftPercentage + widthPercentage / 2
    const centerYPercentage = topPercentage + heightPercentage / 2

    // Figure out appropriate zoom level
    const zoomLevel = clamp(0.25, 1 / Math.max(widthPercentage, heightPercentage), maxScale)

    const currentZoom = actualScale.value
    const svg = pdfContainerChild.value.querySelector('svg')
    if (!svg) return
    const imgRect = svg.getBoundingClientRect()

    const actualWidth = imgRect.width / currentZoom
    const newWidth = actualWidth * zoomLevel
    const actualHeight = imgRect.height / currentZoom
    const newHeight = actualHeight * zoomLevel

    const gap = 16
    const newGap = gap * zoomLevel
    await nextTick()
    const desiredYAtCenter = centerYPercentage * newHeight + (page - 1) * (newHeight + newGap) + 16
    const pdfCenter = pdfContainer.value.getBoundingClientRect().height / 2

    zoom()
    setScale(zoomLevel)
    translate.value = {
      x: (0.5 - centerXPercentage) * newWidth,
      y: pdfCenter - desiredYAtCenter,
    }
  },
})

function goToPage(page: number) {
  if (!pdfContainer.value) return
  const box = pdfContainer.value.querySelector(`[data-page="${page}"]`)
  // scroll box and put it in center of viewport
  if (!box || !(box instanceof HTMLElement)) return

  const top = box.getBoundingClientRect().top - translate.value.y - 100

  translate.value.x = 0
  translate.value.y = -top
}

const activePage = ref(1)

const translate = ref({ x: 0, y: 0 })

function onScroll(e: WheelEvent) {
  if (!pdfContainer.value) return

  const isX = e.shiftKey
  translate.value = {
    x: translate.value.x - (isX ? e.deltaY : 0),
    y: translate.value.y - (isX ? 0 : e.deltaY),
  }
}

watch(
  () => translate.value.y,
  async () => {
    const container = pdfContainer.value
    if (!container) return

    await new Promise((resolve) => setTimeout(resolve, 200))

    // if at top, the page is 1
    if (translate.value.y > 100) {
      activePage.value = 1
      return
    }

    const pagesArray = Array.from(container.querySelectorAll('[data-page]')) as HTMLElement[]

    const containerRect = container.getBoundingClientRect()

    function getPagePercentage(page: HTMLElement) {
      const rect = page.getBoundingClientRect()
      const cutFromTop = Math.abs(Math.min(rect.top - containerRect.top, 0))
      const cutFromBottom = Math.abs(Math.min(containerRect.bottom - rect.bottom, 0))
      return (rect.height - cutFromTop - cutFromBottom) / rect.height
    }

    const mostVisiblePages = pagesArray.toSorted((a, b) => {
      return getPagePercentage(b) - getPagePercentage(a)
    })

    const closestPage = mostVisiblePages[0]

    const closestPageNum = pagesArray.findIndex((page) => page === closestPage) + 1

    if (activePage.value !== closestPageNum) {
      activePage.value = closestPageNum
    }
  },
)

const pagesScrollTo = useDebounceFn((top: number) => {
  if (!pagesContainer.value) return
  pagesContainer.value.scrollTo({ top, behavior: 'smooth' })
}, 200)

watch(activePage, () => {
  if (!pagesContainer.value) return
  const pageEl = pagesContainer.value.querySelector(`[data-page="${activePage.value}"]`)
  if (!(pageEl instanceof HTMLElement)) return

  const lowerBound = pagesContainer.value.scrollTop
  const upperBound = lowerBound + pagesContainer.value.clientHeight

  if (pageEl.offsetTop < lowerBound) {
    pagesScrollTo(pageEl.offsetTop - 10)
  }

  if (pageEl.offsetTop + pageEl.clientHeight > upperBound) {
    pagesScrollTo(pageEl.offsetTop + pageEl.clientHeight - pagesContainer.value.clientHeight + 10)
  }
})

onUnmounted(() => {
  pdf.value?.destroy()
})

const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  toRef(props.groundingInfo, 'ocrPages'),
)
</script>

<template v-if="filePath">
  <div
    class="relative flex size-full overflow-hidden pt-0"
    @wheel.ctrl.exact.prevent.stop="onZoom($event)"
    @wheel.meta.exact.prevent.stop="onZoom($event)"
  >
    <div
      v-if="showSidebar && pages > 1"
      ref="pagesContainer"
      class="go-scrollbar z-10 flex size-full w-[100px] flex-col gap-2 overflow-y-auto bg-surface-tertiary p-4 [contain:strict]"
    >
      <button
        v-for="page in pages"
        :key="page"
        class="relative flex scroll-my-2 flex-col items-center gap-1"
        :data-page="page"
        @click="goToPage(page)"
      >
        <VuePDF
          :page="page"
          :pdf="pdf"
          :width="pagesWidth"
          fit-parent
          :source="filePath"
          class="w-fit overscroll-none rounded-corner-2 outline outline-2 transition-all last:border-none"
          :class="page === activePage ? 'outline-border-focused' : 'outline-background-transparent'"
        />
        <span
          class="text-xxs-8px-default"
          :class="page === activePage ? 'text-text-selected' : 'text-text'"
        >
          {{ String(page).padStart(2, '0') }}
        </span>
        <div
          v-if="sourcesWithCoords?.find((s) => s.boxes.some((b) => b.page === page))"
          class="absolute right-1 top-1 size-1.5 rounded-full bg-background-stages-model-pressed"
          aria-label="Claimed"
        />
      </button>
    </div>

    <div class="size-full overflow-hidden rounded-corner-6">
      <div
        id="pdf-container"
        ref="pdfContainer"
        class="go-scrollbar relative flex size-full flex-col flex-wrap items-center px-8 py-4"
        @wheel.shift.exact.prevent.stop="onScroll"
        @wheel.exact.prevent.stop="onScroll"
      >
        <div
          id="pdf-container-child"
          ref="pdfContainerChild"
          class="inline-flex flex-col gap-4"
          :class="zooming ? 'transition-all duration-200' : ''"
          :style="{
            translate: ` ${translate.x}px ${translate.y}px`,
            transformOrigin: 'top center',
            scale: actualScale / (scale === 'fit' ? 1 : maxScale),
          }"
        >
          <div
            v-for="page in pages"
            :key="page"
            class="relative scroll-my-10"
            :class="{
              'opacity-50': page !== activePage,
            }"
            :data-page="page"
          >
            <VuePDF
              :page="page"
              :pdf="pdf"
              text-layer
              :source="filePath"
              class="relative w-fit overscroll-none transition"
              :width="containerWidth"
              :scale="maxScale"
              @loaded="
                (e) => {
                  pdfInfo[page - 1] = e as any
                  pdfInfo = [...pdfInfo]
                }
              "
            />
            <div
              v-for="source in getPageSources(page)"
              :key="source.id"
              class="pointer-events-none absolute inset-0 transition"
              :class="{
                'opacity-0': selected && selected.sourceId !== source.id,
              }"
            >
              <ClaimIndicator
                :data-source="source.id"
                class="pointer-events-auto absolute z-10 -translate-x-2 -translate-y-4 scroll-m-6"
                :source-id="source.id"
                :style="source.claimCoords"
                @click="clickSourcePill(source.id)"
              />
              <svg
                :viewBox="getOcrPageViewbox(page)"
                class="absolute left-0 top-0 size-full"
              >
                <polygon
                  v-for="(points, index) in getPolygonPointsFromSource(source, page)"
                  :key="index"
                  :points="points"
                  class="fill-background-stages-model-subtle stroke-background-stages-model"
                  :style="{
                    strokeWidth: String(strokeWidthPerPage[page - 1]),
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                  }"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute bottom-2 right-2 ml-auto">
      <ScaleButtons
        class="shadow-sm"
        :scale="scale"
        @update:scale="
          (e) => {
            zoom()
            setScale(e)
            translate = { x: 0, y: 0 }
          }
        "
      />
    </div>
  </div>
</template>
