import { post } from './client'
import type { BackendMeta } from './types'

export type GetUploadUrl = BackendMeta<
  '/api/workspaces/{workspace_id}/imports/{import_id}/sign_upload',
  'post'
>

export const getUploadUrl = ({
  workspaceId,
  importId,
}: {
  workspaceId: string
  importId: string
}) => {
  return post<GetUploadUrl['body'], GetUploadUrl['successResponse'], GetUploadUrl['path']>(
    `/api/workspaces/${workspaceId}/imports/${importId}/sign_upload`,
    null,
  )
}
