import type { components } from '@/api'
import { post } from './client'

export type CreateApiKeyPath = '/api/api_keys'
export type CreateApiKeyResponse = components['schemas']['ApiKeys.ApiKeyResponse']
export type CreateApiKeyRequest = components['schemas']['ApiKeys.CreateApiKeyRequest']

export const createApiKey = (daysValid: number | null, name?: string) =>
  post<CreateApiKeyRequest, CreateApiKeyResponse>(`/api/api_keys`, {
    name: name ?? null,
    days_valid: daysValid,
  })
