import { get } from './client'
import type { BackendMeta } from './types'

export type ListInvitations = BackendMeta<'/api/workspaces/{workspace_id}/invitations', 'get'>
export type ListInvitationsPath = ListInvitations['path']
export type ListInvitationsResponse = ListInvitations['successResponse']
export type ListInvitationsRequest = ListInvitations['queryParams']

export const listInvitations = (workspaceId: string, params?: ListInvitationsRequest) =>
  get<ListInvitationsRequest, ListInvitationsResponse, ListInvitationsPath>(
    `/api/workspaces/${workspaceId}/invitations`,
    params ?? {},
  )
