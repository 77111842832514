import { useRouter, type LocationQueryValue, type RouteParamsGeneric } from 'vue-router'

type ResolveSubProjectRouteParams = {
  workspaceId: RouteParamsGeneric[string]
  projectId: RouteParamsGeneric[string]
  entityId: RouteParamsGeneric[string]
  parentProjectId?: RouteParamsGeneric[string]
  parentEntityId?: LocationQueryValue | LocationQueryValue[]
  viewId?: RouteParamsGeneric[string]
  parentViewId?: LocationQueryValue | LocationQueryValue[]
}

/**
 * Returns a function that is used to get the route for a given entity.
 */
export const useResolveEntityRoute = () => {
  const router = useRouter()
  return ({
    parentProjectId,
    projectId,
    workspaceId,
    parentEntityId,
    viewId,
    entityId,
  }: ResolveSubProjectRouteParams) => {
    if (viewId && parentProjectId) {
      return router.resolve({
        name: 'WorkspaceSubProjectEntitySubView',
        params: {
          workspaceId,
          projectId,
          viewId,
          parentProjectId,
          entityId,
        },
        query: { parentEntityId },
      })
    }

    if (parentProjectId) {
      return router.resolve({
        name: 'WorkspaceSubProjectEntityView',
        params: {
          workspaceId,
          projectId,
          parentProjectId,
          entityId,
        },
        query: { parentEntityId },
      })
    }

    if (viewId) {
      return router.resolve({
        name: 'WorkspaceProjectEntitySubView',
        params: {
          workspaceId,
          projectId,
          viewId,
          entityId,
        },
      })
    }

    return router.resolve({
      name: 'WorkspaceProjectEntityView',
      params: {
        workspaceId,
        projectId,
        entityId,
      },
    })
  }
}
