import type { EmptyObject } from '@/shared/types'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import type { Field, Property } from '../useProject'
import {
  getFilterSubjectForProperty,
  type Filter,
  type FilterablePropertyType,
  type SimpleFilter,
} from './types'

export const getCaseInsensitiveValue = (
  v: Exclude<Field['manualValue'], Filter>,
): Exclude<Field['manualValue'], Filter> => {
  if (v === null) {
    return v
  }

  if (Array.isArray(v)) {
    return v.map((s) => s.toLowerCase())
  }

  return v.toLowerCase()
}

/** Returns the total number of filters contained within a recursive filter object */
export const countFilters = (filter: Filter | EmptyObject | undefined): number => {
  if (!filter || isEmptyObject(filter)) {
    return 0
  }

  // If the filter has a conjunction, then it's a group filter so we must count
  // the number of filters in all of its children.
  if ('conjunction' in filter) {
    return filter.filters.reduce((acc, f) => acc + countFilters(f), 0)
  }

  // If we're here, the filter is a SimpleFilter, so has one condition.
  return 1
}

/**
 * Given a property and a search term, will return a simple filter that can
 * be used to filter entities based on that search term.
 */
export const getSimpleFilterForProperty = (
  property: Property<FilterablePropertyType>,
  searchValue: string,
): SimpleFilter => {
  const subject = getFilterSubjectForProperty(property.type)

  if (subject === 'field_select_option_value') {
    return {
      subject,
      matcher: {
        name: 'property_any_of',
        property_id: property.id,
        values: [searchValue],
        case_sensitive: false,
      },
    }
  }

  return {
    subject,
    matcher: {
      name: 'property_contains_any_of',
      property_id: property.id,
      values: [searchValue],
      case_sensitive: false,
    },
  }
}
