<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'xs' | 'sm' | 'md' | 'lg'
    /**
     * Value between 0 and 1. If undefined, the spinner will be indeterminate
     * and will spin forever.
     */
    value?: number
    color?: 'blue' | 'neutral' | 'white' | 'critical' | 'inverted'
  }>(),
  {
    size: 'md',
    value: undefined,
    color: 'neutral',
  },
)

const strokeWidth = computed(() => {
  return {
    xs: 2,
    sm: 2.5,
    md: 3,
    lg: 3.5,
  }[props.size]
})
const viewboxSize = computed(() => {
  return {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 28,
  }[props.size]
})
const radius = computed(() => {
  return {
    xs: (viewboxSize.value - 6) / 2,
    sm: (viewboxSize.value - 5) / 2,
    md: (viewboxSize.value - 6) / 2,
    lg: (viewboxSize.value - 7) / 2,
  }[props.size]
})

const strokeDashArray = computed(() => {
  const circumference = 2 * Math.PI * radius.value
  return `${circumference} ${circumference}`
})

const strokeDashOffset = computed(() => {
  const circumference = 2 * Math.PI * radius.value

  if (props.value === undefined) {
    return circumference * 0.75
  }

  return circumference * (1 - props.value)
})

const styleAnimation = computed(() => {
  return {
    transition: 'stroke-dashoffset 0.35s',
  }
})
</script>

<template>
  <div
    :class="[
      size === 'xs' && 'size-4',
      size === 'sm' && 'size-5',
      size === 'md' && 'size-6',
      size === 'lg' && 'size-7',
    ]"
    class="grid place-items-center"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="1"
    :aria-valuenow="value"
  >
    <svg
      width="auto"
      height="auto"
      :viewBox="`0 0 ${viewboxSize} ${viewboxSize}`"
      style="transform: rotate(-90deg)"
    >
      <circle
        :class="[
          color === 'neutral' && 'stroke-icon-gray-subtle',
          color === 'blue' && 'stroke-icon-gray-subtle',
          color === 'critical' && 'stroke-background-critical-subtle',
          color === 'white' && 'stroke-icon-inverted-subtle-irreversible',
          color === 'inverted' && 'stroke-icon-inverted',
        ]"
        :r="radius"
        :cx="viewboxSize / 2"
        :cy="viewboxSize / 2"
        fill="transparent"
        :stroke-width="strokeWidth"
        :stroke-opacity="color === 'inverted' ? 0.5 : 1"
      ></circle>
      <circle
        :class="[
          color === 'neutral' && 'stroke-icon-subtle',
          color === 'blue' && 'stroke-icon-primary',
          color === 'critical' && 'stroke-icon-critical',
          color === 'white' && 'stroke-icon-inverted-irreversible',
          color === 'inverted' && 'stroke-icon-inverted',
          value === undefined && 'origin-center animate-spin',
        ]"
        :r="radius"
        :cx="viewboxSize / 2"
        :cy="viewboxSize / 2"
        fill="transparent"
        stroke-linecap="round"
        :stroke-width="strokeWidth"
        :stroke-dasharray="strokeDashArray"
        :stroke-dashoffset="strokeDashOffset"
        :style="styleAnimation"
      ></circle>
    </svg>
  </div>
</template>
