import type { components } from '@/api'
import { put } from './client'

export type LockFieldPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/properties/${string}/ground_truth`
export type LockFieldRequest = components['schemas']['Projects.SetFieldGroundTruth']
export type LockFieldResponse = components['schemas']['Projects.Entities.FieldResponse']

export const lockField = ({
  workspaceId,
  projectId,
  entityId,
  propertyId,
  groundTruth,
}: {
  workspaceId: string
  projectId: string
  entityId: string
  propertyId: string
  groundTruth: boolean
}) => {
  return put<LockFieldRequest, LockFieldResponse, LockFieldPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/properties/${propertyId}/ground_truth`,

    { ground_truth: groundTruth },
  )
}
