<script setup lang="ts">
import { Handle, Position, type NodeProps } from '@vue-flow/core'
import { computed } from 'vue'

import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

const props = defineProps<NodeProps>()

const label = computed(() => {
  return props.data.label || props.label
})
</script>

<template>
  <div>
    <Handle
      v-if="type !== 'output'"
      type="source"
      :connectable="false"
      :position="Position.Right"
    />
    <Handle
      v-if="type !== 'input'"
      type="target"
      :connectable="false"
      :position="Position.Left"
    />
    <div class="flex flex-col gap-1">
      <div
        v-if="type === 'input'"
        class="flex h-3 gap-0.5 text-xxs-8px-bold text-text-subtlest"
      >
        <IconSprite
          class="text-icon-subtlest"
          size="xxxs"
          icon="source"
        />
        SOURCE
      </div>
      <div
        v-else
        class="flex h-3 gap-0.5 text-xxs-8px-bold text-text-subtlest"
      >
        <IconSprite
          class="text-icon-subtlest"
          size="xxxs"
          icon="table-fill"
        />
        STAGE
      </div>
      <div
        class="flex flex-col gap-2 rounded-corner-10 border border-icon-gray-subtle bg-surface-popover p-2 shadow-focus-ring-gray"
      >
        <div class="flex flex-1 items-center justify-between gap-1">
          <div class="flex items-center gap-2 overflow-hidden">
            <div
              class="flex size-[22px] items-center justify-center rounded-corner-4 border bg-background-gray-subtlest p-1"
              :style="{ backgroundColor: data.backgroundColor, borderColor: data.backgroundColor }"
            >
              <IconSprite
                class="text-icon-subtlest"
                size="sm"
                icon="table"
                :style="{ color: data.color }"
              />
            </div>
            <div class="truncate text-sm-12px-bold">
              {{ label === 'main' ? 'Main' : label }}
            </div>
          </div>
          <BadgeItem
            :label="String(data.numEntities)"
            class="w-fit px-0.5"
            size="sm"
            variant="neutral"
            non-clickable
          />
        </div>
      </div>
      <div class="h-3" />
    </div>
  </div>
</template>
