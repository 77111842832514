import type { operations } from '@/api'

import { remove } from './client'

export type DeleteWorkspaceIconPath = `/api/workspaces/${string}`

export type DeleteWorkspaceIconResponse =
  operations['workspace-remove-icon']['responses']['200']['content']['application/json']

export const deleteWorkspaceIcon = async (workspaceId: string) => {
  return remove<Record<string, never>, DeleteWorkspaceIconResponse, DeleteWorkspaceIconPath>(
    `/api/workspaces/${workspaceId}/icon`,
    {},
  )
}
