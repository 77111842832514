<script setup lang="ts">
import ProjectList from '@/modules/Projects/ProjectList.vue'
import { useRecentProjects } from '@/modules/Projects/useRecentProjects'
import DividerLine from '@/uiKit/DividerLine.vue'
import { computed, onMounted, watch } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useUser } from '../IdentityAndAccess/useUser'
import { useAskGo } from '../Project/useAskGo'
import { useProjects } from '../Projects/useProjects'
import TutorialsDropdown from './TutorialsDropdown.vue'
import WorkspaceHomepageEmpty from './WorkspaceHomepageEmpty.vue'
import WorkspaceHomepageGreeting from './WorkspaceHomepageGreeting.vue'
import WorkspaceHomepageTutorials from './WorkspaceHomepageTutorials.vue'

const props = defineProps<{ workspaceId: string }>()

const recentProjectsStore = useRecentProjects()
const projectsStore = useProjects()
const userStore = useUser()
const askGo = useAskGo()

onMounted(() => {
  askGo.close()
})

watch(
  () => props.workspaceId,
  async () => {
    await recentProjectsStore.refresh(props.workspaceId)
  },
  { immediate: true },
)

const permissionStore = usePermissionsStore()
const canViewTutorials = computed(
  () => permissionStore.workspaceRole && permissionStore.workspaceRole !== 'worker',
)
</script>

<template>
  <div
    class="flex h-full flex-col gap-6 px-20 py-12"
    data-test="recent-projects-container"
  >
    <div class="flex items-center justify-between px-4">
      <WorkspaceHomepageGreeting :number-of-projects="projectsStore.mainProjects.length" />
      <TutorialsDropdown v-if="canViewTutorials" />
    </div>

    <WorkspaceHomepageTutorials v-if="userStore.user?.showTutorials && canViewTutorials" />
    <DividerLine
      color="subtle"
      class="px-4 py-2"
    />
    <WorkspaceHomepageEmpty
      v-if="
        recentProjectsStore.status === 'loaded' && recentProjectsStore.recentProjects.length === 0
      "
      :workspace-id="workspaceId"
    />
    <ProjectList
      v-else
      :loading="recentProjectsStore.status === 'loading'"
      :workspace-id="workspaceId"
      :projects="recentProjectsStore.recentProjects"
    />
  </div>
</template>
