import type { operations } from '@/api'
import { post } from './client'

export type ConfirmFieldFileUploadPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/properties/${string}/confirm_file_upload`
export type ConfirmFieldFileUploadRequest = undefined
export type ConfirmFieldFileUploadResponse =
  operations['entity-confirm-file-upload']['responses']['200']['content']['application/json']

/**
 * Confirms that a file has been uploaded to the s3 url stored in the given field's input value
 */
export const confirmFieldFileUpload = async (url: string) => {
  const apiPath = url.replace(/^.+?(\/api\/)/, '$1')
  if (!isConfirmFieldFileEndpoint(apiPath)) {
    throw new Error('url is not a valid confirmFieldFileUpload endpoint.')
  }
  return post<
    ConfirmFieldFileUploadRequest,
    ConfirmFieldFileUploadResponse,
    ConfirmFieldFileUploadPath
  >(apiPath, undefined)
}

function isConfirmFieldFileEndpoint(path: string): path is ConfirmFieldFileUploadPath {
  const validPattern =
    /^\/api\/workspaces\/[^/]+\/projects\/[^/]+\/entities\/[^/]+\/properties\/[^/]+\/confirm_file_upload$/
  return validPattern.test(path)
}
