<script setup lang="ts">
import { isHtmlElement } from '@/shared/utils'
import { ref, type Directive } from 'vue'

defineProps<{
  value: number | string
}>()

let fontSize = ref(12)

const vTransform: Directive<HTMLElement, number> = (node, binding) => {
  const childNode = [...node.children].find((child) => child.innerHTML === String(binding.value))
  if (!childNode) return
  fontSize.value = Number(getComputedStyle(childNode).fontSize.replace('px', ''))

  const charWidth = childNode.getBoundingClientRect().width * 1.05
  node.style.transform = `translateY(-${binding.value * 100}%)`
  node.style.width = `${charWidth}px`
}
</script>

<template>
  <!-- The my-[-10px] py-[10px] combo is to give the mask gradient some space -->
  <div
    class="relative my-[-10px] inline-flex overflow-hidden py-[10px]"
    :style="`
    mask-image: linear-gradient(
      to bottom,
      transparent,
      transparent 27.5%,
      #171717 35%,
      #171717 65%,
      transparent 72.5%,
      transparent
    );`"
    :aria-label="value.toString()"
  >
    <TransitionGroup
      :on-enter="
        async (el) => {
          if (!isHtmlElement(el)) return
          // get width
          const width = el.getBoundingClientRect().width
          // set to 0
          el.style.width = '0'
          el.style.opacity = '0'
          // trigger reflow
          void el.offsetWidth
          // set props
          el.style.width = `${width}px`
          el.style.opacity = '1'
        }
      "
      :on-after-enter="
        (el) => {
          if (!isHtmlElement(el)) return
          el.style.width = 'unset'
        }
      "
      :on-before-leave="
        (el) => {
          if (!isHtmlElement(el)) return
          const width = el.getBoundingClientRect().width
          el.style.width = `${width}px`
          // trigger reflow
          void el.offsetWidth
        }
      "
      leave-active-class="!w-0 !opacity-0"
    >
      <div
        v-for="(char, index) in value.toString()"
        :key="index"
        class="transition-all duration-300"
        aria-hidden
      >
        <span v-if="char.trim().length === 0 || Number.isNaN(Number(char))">{{ char }}</span>
        <div
          v-else
          v-transform="Number(char)"
          class="flex list-none flex-col items-center p-0 transition-all duration-1000"
          :style="{
            height: `${fontSize + 2}px`,
            lineHeight: `${fontSize + 2}px`,
          }"
        >
          <span
            v-for="i in 10"
            :key="i"
            >{{ i - 1 }}</span
          >
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>
