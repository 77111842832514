<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'

const emit = defineEmits<{
  close: []
}>()
</script>

<template>
  <div
    class="relative flex flex-col justify-between gap-3 rounded-lg bg-surface-primary-persist p-3"
  >
    <img
      class="size-8"
      srcset="
        @/assets/images/nell@1x.png,
        @/assets/images/nell@2x.png 2x,
        @/assets/images/nell@3x.png 3x
      "
      src="@/assets/images/nell@3x.png"
      alt="V7 workflow expert"
    />
    <IconButton
      size="sm"
      variant="transparent"
      class="absolute right-2 top-2 text-icon-subtlest"
      rounded
      icon="close"
      @click="emit('close')"
    />
    <div class="leading-4">
      <p class="font-medium text-text">Let us build a workflow for you</p>
      <p class="text-xs-11px-default text-text-subtlest">
        Find out in 30 minutes if your use case can be solved with GenAI
      </p>
    </div>
    <div>
      <DarwinButton
        target="_blank"
        href="https://www.v7labs.com/go/talk-to-sales?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upg[%E2%80%A6]rm=Talk%20to%20an%20expert&utm_content=in-product-demo-banner"
        size="sm"
        variant="black"
        rounded
        class="w-full"
      >
        Talk to an expert
      </DarwinButton>
    </div>
  </div>
</template>
