import type { operations } from '@/api'
import { post } from './client'

export type RecalculateEntitiesPath =
  `/api/workspaces/${string}/projects/${string}/entities/recalculate`
export type RecalculateEntitiesRequest = Exclude<
  operations['entity-recalculate-all']['requestBody'],
  undefined
>['content']['application/json']
export type RecalculateEntitiesResponse = Exclude<
  operations['entity-recalculate-all']['responses']['200'],
  undefined
>['content']['application/json']

export const recalculateEntitites = (
  workspaceId: string,
  projectId: string,
  propertyIds: string[],
) =>
  post<RecalculateEntitiesRequest, RecalculateEntitiesResponse, RecalculateEntitiesPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/recalculate`,
    {
      property_ids: propertyIds,
      force: false,
    },
  )
