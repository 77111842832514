import { createPlanUpgrade } from '@/backend/createPlanUpgrade'
import type { PlanTemplate } from '@/modules/Billing/types'
import { onMounted, ref } from 'vue'

/**
 * The URL for a Stripe checkout is generated on the backend when the user
 * clicks the 'Upgrade to Pro' button. This composable is used to start the
 * checkout process, waiting for a response from the backend and then
 * redirecting the user to the Stripe checkout page. It also contains
 * a ref used to indicate that we are waiting for the backend, so that
 * we can show a loading spinner on the trigger button.
 */
export const useStripeCheckout = () => {
  const isLoadingCheckoutUrl = ref(false)
  onMounted(() => {
    // When navigating back to this page from Stripe, the browser might
    // use bfcache and restore the state of the page from memory. In this
    // case, we want to reset the state of the loading spinner so that the
    // button isn't stuck in a loading state.
    window.onpageshow = () => {
      isLoadingCheckoutUrl.value = false
    }
  })

  const startStripeCheckout = async ({
    billingPeriod,
    workspaceId,
  }: {
    workspaceId: string
    billingPeriod: PlanTemplate['billingPeriod']
  }) => {
    isLoadingCheckoutUrl.value = true
    const res = await createPlanUpgrade({
      billingPeriod: billingPeriod,
      planName: 'pro',
      workspaceId: workspaceId,
    })

    if (res.ok) {
      // Don't set the loading state to false here because it takes some amount
      // of time for the Stripe checkout page to load, and we want to show the
      // loading spinner for this entire time.
      window.location.assign(res.data.upgrade_url)
    } else {
      isLoadingCheckoutUrl.value = false
    }
  }

  return { startStripeCheckout, isLoadingCheckoutUrl }
}
