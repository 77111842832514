import type { operations } from '@/api'

import { post } from './client'

export type UploadWorkspaceIconPath = `/api/workspaces/${string}`

export type UpdateWorkspaceIconResponse =
  operations['workspace-update-icon']['responses']['200']['content']['application/json']

export type UpdateWorkspaceIconRequest = Exclude<
  operations['workspace-update-icon']['requestBody'],
  undefined
>['content']['multipart/form-data']

export const uploadWorkspaceIcon = async ({
  workspaceId,
  formData,
}: {
  workspaceId: string
  formData: FormData
}) => {
  return post<unknown, UpdateWorkspaceIconResponse, UploadWorkspaceIconPath>(
    `/api/workspaces/${workspaceId}/icon`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
}
