import { get } from './client'
import type { BackendMeta } from './types'

export type GetProjectUsage = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/billing/usage',
  'get'
>

export const getProjectUsage = ({
  projectId,
  workspaceId,
}: {
  workspaceId: string
  projectId: string
}) =>
  get<GetProjectUsage['body'], GetProjectUsage['successResponse'], GetProjectUsage['path']>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/billing/usage`,
    undefined,
  )
