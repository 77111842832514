import type { operations } from '@/api'
import { remove } from './client'

export type RemoveProjectPath = `/api/workspaces/${string}/projects/${string}`
export type RemoveProjectResponse =
  operations['project-delete']['responses']['200']['content']['application/json']

export const removeProject = (workspaceId: string, projectId: string) =>
  remove<undefined, RemoveProjectResponse, RemoveProjectPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}`,
    undefined,
  )
