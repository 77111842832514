import { remove } from './client'
import type { BackendMeta } from './types'

export type DeleteToolKey = BackendMeta<
  '/api/workspaces/{workspace_id}/tool_keys/{tool_key_id}',
  'delete'
>
export const deleteToolKey = ({
  workspaceId,
  toolKeyId,
}: {
  workspaceId: string
  toolKeyId: string
}) =>
  remove<undefined, DeleteToolKey['successResponse'], DeleteToolKey['path']>(
    `/api/workspaces/${workspaceId}/tool_keys/${toolKeyId}`,
    undefined,
  )
