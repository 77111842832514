<script setup lang="ts">
import { computed } from 'vue'
import type { IconName } from '../IconName'
import IconSprite from '../IconSprite.vue'

const props = withDefaults(
  defineProps<{
    active?: boolean
    inline?: boolean
    size?: 'md' | 'lg'
    trailingIcon?: IconName | null
    leadingIcon?: IconName | null
  }>(),
  {
    active: false,
    inline: false,
    size: 'md',
    leadingIcon: null,
    trailingIcon: 'chevron-select',
  },
)

const backgroundColor = computed<'active' | 'inactive' | 'active:inline' | 'inactive:inline'>(
  () => {
    if (props.active) {
      return props.inline ? 'active:inline' : 'active'
    } else {
      return props.inline ? 'inactive:inline' : 'inactive'
    }
  },
)
</script>

<template>
  <button
    class="flex h-7 select-none items-center gap-1 rounded-corner-8 text-sm-12px-default outline-none focus-within:shadow-focus-ring-primary"
    :class="[
      backgroundColor === 'active' && 'bg-background-gray-subtlest-hovered',
      backgroundColor === 'inactive' &&
        'bg-background-gray-subtlest hover:bg-background-gray-subtlest-hovered',
      backgroundColor === 'active:inline' && 'bg-background-transparent-hovered',
      backgroundColor === 'inactive:inline' &&
        'bg-background-transparent hover:bg-background-transparent-hovered',
      size === 'md' ? 'p-1 pr-1.5' : 'p-1.5 pr-2',
    ]"
  >
    <div
      v-if="leadingIcon"
      class="flex min-h-5 items-center justify-center"
    >
      <IconSprite
        :icon="leadingIcon"
        class="px-0.5 text-icon-subtle"
      />
    </div>
    <div
      class="flex min-h-5 min-w-0 flex-1 shrink grow basis-0 flex-row items-center gap-1 truncate px-1 leading-tight"
    >
      <slot />
    </div>
    <div
      v-if="trailingIcon"
      class="flex min-h-5 items-center justify-center text-icon-subtlest"
    >
      <IconSprite :icon="trailingIcon" />
    </div>
  </button>
</template>
