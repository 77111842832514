<script setup lang="ts">
import { createWorkspace } from '@/backend/createWorkspace'
import { toast } from '@/shared/toast'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const onCreate = async () => {
  const result = await createWorkspace()
  if (result.ok) {
    toast.success('Workspace created')
    router.go(0)
  } else {
    toast.error('Failed to create workspace')
  }
}
</script>

<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <div class="flex h-screen w-max flex-col items-center justify-center">
      <h1 class="mx-4 mb-1 mt-16 text-xl-18px-bold text-text">No workspace available</h1>

      <DarwinButton
        class="w-[200px] flex-none"
        size="sm"
        variant="black"
        @click="onCreate"
        >Create new Workspace</DarwinButton
      >
    </div>
  </div>
</template>
