import type { components } from '@/api'
import { post } from './client'

export type CreateProjectPath = `/api/workspaces/${string}/projects`
export type CreateProjectResponse = components['schemas']['Projects.ProjectResponse']
export type CreateProjectRequest = components['schemas']['Projects.CreateProjectRequest']

export const createProject = (name: string | null, workspaceId: string) =>
  post<CreateProjectRequest, CreateProjectResponse, CreateProjectPath>(
    `/api/workspaces/${workspaceId}/projects`,
    {
      name,
    },
  )
