import { watch } from 'vue'
import {
  usePaginatedLoader,
  type LoadMoreArgs,
  type PaginatedArgs,
  type PaginatedFn,
} from './usePaginatedLoader'

type Setter<T> = (value: T[]) => void

/**
 * Lots of APIs return paginated results, but sometimes we want to load all
 * the results into the frontend. This composable will call a paginated function
 * repeatedly until all results are loaded, calling a setter function when each
 * page of results is loaded.
 *
 * @param loader A callack to load a page of results when given a cursor and page size.
 * @param setter A function to call when each page of results is loaded.
 * @param pageSize The number of results to load per page.
 */
export const useLoadAllPaginatedResults = <
  Args extends PaginatedArgs,
  ResponseType,
  SerializedType,
>(
  loader: PaginatedFn<Args, ResponseType>,
  serializer: (data: ResponseType) => SerializedType,
  setter: Setter<SerializedType>,
  pageSize?: number,
) => {
  const { data, loadMore, reset } = usePaginatedLoader<Args, ResponseType, SerializedType>(loader, {
    limit: pageSize,
    serializer,
  })

  const loadAllData = async (...args: LoadMoreArgs<Args>) => {
    reset()
    let metadata = await loadMore(...args)
    while (metadata?.has_next_page) {
      metadata = await loadMore(...args)
    }
  }

  watch(data, setter)

  return loadAllData
}
