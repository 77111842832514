import * as dagre from '@dagrejs/dagre'
import { Position, useVueFlow, type Edge, type Node } from '@vue-flow/core'
import { ref } from 'vue'

export type LayoutDirection = 'LR' | 'TB'

export const useLayout = () => {
  const { findNode } = useVueFlow()

  const graph = ref(new dagre.graphlib.Graph())

  const previousDirection = ref('LR')

  const layout = (nodes: Node[], edges: Edge[], direction: LayoutDirection) => {
    // we create a new graph instance, in case some nodes/edges were removed, otherwise dagre would act as if they were still there
    const dagreGraph = new dagre.graphlib.Graph()

    graph.value = dagreGraph

    dagreGraph.setDefaultEdgeLabel(() => ({}))

    const isHorizontal = direction === 'LR'
    dagreGraph.setGraph({ rankdir: direction, ranksep: 400, nodesep: 100 })

    previousDirection.value = direction

    for (const node of nodes) {
      // if you need width+height of nodes for your layout, you can use the dimensions property of the internal node (`GraphNode` type)
      const graphNode = findNode(node.id)
      if (!graphNode) {
        throw new Error(`Node with id ${node.id} not found in the graph`)
      }

      dagreGraph.setNode(node.id, {
        width: graphNode.dimensions.width || 150,
        height: graphNode.dimensions.height || 50,
      })
    }

    for (const edge of edges) {
      const numberOfProperties = edge.data.propertyNames.length as number
      dagreGraph.setEdge(edge.source, edge.target, {
        // We need to set the label's dimensions so that the layout algorithm
        // takes the label into account when positioning the nodes. The height
        // increases with the number of properties (plus fixed padding), and
        // we can assume the width to be fixed (it will only ever vary by a small
        // amount).
        height: numberOfProperties * 28 + 48,
        width: 200,
      })
    }

    dagre.layout(dagreGraph)

    // set nodes with updated positions
    return nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id)

      return {
        ...node,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        position: { x: nodeWithPosition.x, y: nodeWithPosition.y },
      }
    })
  }

  return { graph, layout, previousDirection }
}
