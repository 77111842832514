<script setup lang="ts">
import AudioPlayer from '@/sharedComponents/AudioPlayer.vue'
import { computedAsync, useScroll } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  url: string
  stagedFilePreview?: File | null
  transcription?: string
}>()

const container = ref<HTMLDivElement | null>(null)

const loading = ref(false)

const asyncAudioObjectUrl = computedAsync(
  async () => {
    if (props.url) {
      const response = await fetch(props.url)
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    }
    return null
  },
  null,
  loading,
)

watch(
  () => props.stagedFilePreview,
  () => {
    asyncAudioObjectUrl.value = null
  },
)

const audioSrc = computed(
  () =>
    (props.stagedFilePreview && URL.createObjectURL(props.stagedFilePreview)) ??
    asyncAudioObjectUrl.value,
)

const containerRef = ref<HTMLDivElement | null>(null)
const { arrivedState } = useScroll(containerRef)
</script>

<template>
  <div
    ref="container"
    class="group relative flex size-full flex-col items-center justify-between overflow-hidden [contain:strict]"
  >
    <div
      ref="containerRef"
      class="no-scrollbar w-full overflow-scroll text-wrap px-2.5 pt-2 text-text"
    >
      {{ transcription }}
    </div>
    <AudioPlayer
      v-if="audioSrc"
      :audio-src="audioSrc"
      :arrived-state="arrivedState"
    />
  </div>
</template>
