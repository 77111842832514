import { put } from './client'
import type { BackendMeta } from './types'

type BackendUpdateWorkspaceMember = BackendMeta<
  '/api/workspaces/{workspace_id}/members/{user_id}',
  'put'
>

export type UpdateWorkspaceMemberParams = {
  workspaceId: string
  userId: string
  role: Exclude<BackendUpdateWorkspaceMember['body']['role'], undefined>
}

export const updateWorkspaceMember = ({ role, userId, workspaceId }: UpdateWorkspaceMemberParams) =>
  put<
    BackendUpdateWorkspaceMember['body'],
    BackendUpdateWorkspaceMember['successResponse'],
    BackendUpdateWorkspaceMember['path']
  >(`/api/workspaces/${workspaceId}/members/${userId}`, { role })
