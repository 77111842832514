import { defaultMarkdownParser, defaultMarkdownSerializer } from 'prosemirror-markdown'
import { Schema } from 'prosemirror-model'
import {
  addMentionNodes,
  addMentionsToMarkdownParser,
  addMentionsToMarkdownSerializer,
} from '../mentionables'
import type { Mode } from '../ProseMirror.vue'
import { markdownSchema } from './markdown'
import { getPlaintextParser, plaintextSchema, plaintextSerializer } from './plaintext'

export const getUtilsForMode = (mode: Mode) => {
  const schemas: Record<Mode, Schema> = {
    plaintext: plaintextSchema,
    markdown: new Schema({
      ...markdownSchema,
      nodes: addMentionNodes(markdownSchema.spec.nodes),
      marks: markdownSchema.spec.marks,
    }),
  }
  const schema = schemas[mode]

  const serializers = {
    plaintext: plaintextSerializer,
    markdown: addMentionsToMarkdownSerializer(defaultMarkdownSerializer),
  }
  const serializer = serializers[mode]

  const parser =
    mode === 'plaintext'
      ? getPlaintextParser(schema)
      : addMentionsToMarkdownParser(defaultMarkdownParser, schema)

  return {
    /**
     * The schema enumerates the accepted nodes for this document and
     * tells the editor how it should convert ProseMirror nodes
     * to and from
     * the DOM.
     *
     * @see https://prosemirror.net/examples/schema/
     */
    schema,

    /**
     * The serializer converts a ProseMirror document into a markdown document.
     * Mentions aren't part of the default markdown serializer, so we need to
     * extend the default serializer so that it can convert ProseMirror mention
     * Nodes into a serialized string recognised by the backend.
     */
    serializer,

    /**
     * The parser converts a markdown document into a ProseMirror document.
     * Mentions aren't part of the default markdown parser, so we need to
     * extend the default parser so that it can convert serialized mentions
     * into ProseMirror mention Nodes.
     */
    parser,
  }
}
