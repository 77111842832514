import type { Limit } from '@/backend/types'

const numberFormatter = new Intl.NumberFormat()
/**
 * Formats a limit value for display in the UI.
 * @param limitValue The limit value to format.
 * @param short Whether to use a short format, e.g. 2M instead of 2 Million.
 */
export const formatLimitValue = (limitValue: Limit['limit_value'] | string, short?: boolean) => {
  if (limitValue === 'unlimited') {
    return 'Unlimited'
  }

  if (typeof limitValue !== 'number') {
    return limitValue
  }

  if (limitValue > 999_999_999_999) {
    const howManyTrillions = limitValue / 1_000_000_000_000
    const unit = short ? 'T' : ' Trillion'
    return `${numberFormatter.format(howManyTrillions)}${unit}`
  }

  if (limitValue > 999_999_999) {
    const howManyBillions = limitValue / 1_000_000_000
    const unit = short ? 'B' : ' Billion'
    return `${numberFormatter.format(howManyBillions)}${unit}`
  }

  if (limitValue > 999_999) {
    const howManyMillions = limitValue / 1_000_000
    const unit = short ? 'M' : ' Million'
    return `${numberFormatter.format(howManyMillions)}${unit}`
  }

  if (limitValue > 999) {
    const howManyThousands = limitValue / 1_000
    const unit = short ? 'K' : ' Thousand'
    return `${numberFormatter.format(howManyThousands)}${unit}`
  }

  return numberFormatter.format(limitValue)
}
