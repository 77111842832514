import { defineStore } from 'pinia'

import type { components } from '@/api'
import { useLocalStorage } from '@vueuse/core'
import { computed, watch } from 'vue'
import { useProject } from './useProject'

type SortingDirection = NonNullable<components['schemas']['Pagination.OrderDirections']>[number]

export type SortingRule = {
  property_slug: string
  direction: SortingDirection
}

/**
 * The project table can show an ordered list of entities on that project. This
 * store holds the state of the sorting rules that are currently applied, and provides
 * functions to add/remove/update said rules.
 */
export const useSorting = defineStore('sorting', () => {
  const rulesPerProject = useLocalStorage<Record<string, SortingRule[]>>(
    'sorting-rules',
    {},
    {
      deep: true,
      listenToStorageChanges: false,
      writeDefaults: true,
      mergeDefaults: true,
    },
  )
  const project = useProject()

  const sortingRules = computed({
    get() {
      return rulesPerProject.value[project.projectId ?? ''] ?? []
    },
    set(v) {
      if (!project.projectId) return
      rulesPerProject.value[project.projectId] = v
    },
  })

  watch(
    () => sortingRules.value,
    () => {
      if (sortingRules.value.length) {
        return
      }
      sortingRules.value = [
        {
          property_slug: 'id',
          direction: 'asc',
        },
      ]
    },
    { immediate: true },
  )

  function hasRule(propertySlug: string) {
    return sortingRules.value.some((r) => r.property_slug === propertySlug)
  }

  function removeRule(propertySlug: string) {
    sortingRules.value = sortingRules.value.filter((r) => r.property_slug !== propertySlug)
  }

  function addRule(propertySlug: string, direction?: 'desc' | 'asc') {
    sortingRules.value = [
      { property_slug: propertySlug, direction: direction ?? 'desc' },
      ...sortingRules.value,
    ]
  }

  function updateRuleDirection(propertySlug: string, direction: SortingDirection) {
    sortingRules.value = sortingRules.value.map((r) =>
      r.property_slug === propertySlug ? { ...r, direction } : r,
    )
  }

  function updateRuleProperty(propertySlug: string, newPropertySlug: string) {
    sortingRules.value = sortingRules.value.map((r) =>
      r.property_slug === propertySlug ? { ...r, property_slug: newPropertySlug } : r,
    )
  }

  watch(
    () => sortingRules.value,
    () => {
      // This ensures entities are reloaded on the project table
      project.setEntitiesStale(true)
    },
    { deep: true },
  )

  return { sortingRules, hasRule, removeRule, addRule, updateRuleDirection, updateRuleProperty }
})
