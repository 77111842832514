import { post } from './client'
import type { BackendMeta } from './types'

export type PrepareImport = BackendMeta<'/api/workspaces/{workspace_id}/imports', 'post'>

export const prepareImport = ({
  workspaceId,
  projectName,
}: {
  workspaceId: string
  projectName: string
}) => {
  return post<PrepareImport['body'], PrepareImport['successResponse'], PrepareImport['path']>(
    `/api/workspaces/${workspaceId}/imports`,
    {
      project_name: projectName,
      format: 'csv',
      format_options: {
        separator: ',',
        first_row_header: true,
      },
    },
  )
}
