import type { operations } from '@/api'
import { post } from './client'

export type StartFieldFileUploadPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/properties/${string}/start_file_upload`
export type StartFieldFileUploadRequest = Exclude<
  operations['entity-start-file-upload']['requestBody'],
  undefined
>['content']['application/json']

export type StartFieldFileUploadResponse =
  operations['entity-start-file-upload']['responses']['200']['content']['application/json']

/**
 * Fires a network request to he backend to provide a file url to upload a file to
 * The backend action will generate a presigned url for the file and return it,
 * and also save it onto the manual_value of the given field.
 */
export const startFieldFileUpload = (
  workspaceId: string,
  projectId: string,
  entityId: string,
  propertyId: string,
  filename: string,
) =>
  post<StartFieldFileUploadRequest, StartFieldFileUploadResponse, StartFieldFileUploadPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/properties/${propertyId}/start_file_upload`,
    { filename },
  )
