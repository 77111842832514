import { useStorage } from '@vueuse/core'
import type { GroupFilter } from './types'

/**
 * We store filters in local storage so that they persist across page reloads.
 * This composable provides a way to get and set the filters stored in local
 * storage.
 */
export const useLocalFilter = () => {
  const localFilter = useStorage<Record<string, GroupFilter>>('localFilter', {})

  const setFilter = (filter: GroupFilter, viewId: string) => {
    localFilter.value[viewId] = filter
  }

  return {
    /** Map view IDs to filters that should be applied when loading the view. */
    localFilter,
    /** Set a filter in storage for the given view */
    setFilter,
  }
}
