import { computed, type Ref } from 'vue'
import { useProject, type Field } from './useProject'

/**
 * Given a field, this composable will return the value of the field as it should be rendered
 * into a cell. The value is determined by the following order of precedence:
 * 1. Draft value
 * 2. Manual value
 * 3. Tool value
 *
 * TODO: GO-2974 - Update this function to be typed in a way that infers the return value
 * from the input field type. i.e. if we pass a text field in, we should know that the
 * value is `string | null`, and not `string[] | string | null`.
 */
export const useFieldValue = (field: Ref<Field | undefined>): Ref<Field['manualValue']> => {
  const projectStore = useProject()
  const draftValue = computed(
    () => field.value && projectStore.draftFields[field.value.entityId]?.[field.value.propertyId],
  )

  return computed(
    () => draftValue.value || field.value?.manualValue || field.value?.toolValue || null,
  )
}
