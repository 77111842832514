import type { components } from '@/api'
import { remove } from './client'

export type RemoveInvitationPath = `/api/workspaces/${string}/invitations/${string}`
export type RemoveInvitationResponse = components['schemas']['Common.DeleteResponse']
export type RemoveInvitationRequest = undefined

type RemoveInvitationParams = {
  workspaceId: string
  invitationId: string
}

export const removeInvitation = ({ invitationId, workspaceId }: RemoveInvitationParams) =>
  remove<RemoveInvitationRequest, RemoveInvitationResponse, RemoveInvitationPath>(
    `/api/workspaces/${workspaceId}/invitations/${invitationId}`,
    undefined,
  )
