export const Expiration = {
  day: '1 day',
  week: '7 days',
  month: '30 days',
  quarter: '90 days',
  never: 'Never expire',
}

export type Expiration = (typeof Expiration)[keyof typeof Expiration]

export const expirationToDays = (expiration: Expiration) => {
  switch (expiration) {
    case Expiration.day:
      return 1
    case Expiration.week:
      return 7
    case Expiration.month:
      return 30
    case Expiration.quarter:
      return 90
    case Expiration.never:
      return null
    default:
      return 7
  }
}
