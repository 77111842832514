import { get } from './client'
import type { BackendMeta } from './types'

type ListEntitiesMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/entities',
  'get'
>
export type ListEntitiesPath = ListEntitiesMeta['path']
export type ListEntitiesResponse = ListEntitiesMeta['successResponse']
export type ListEntitiesRequest = ListEntitiesMeta['queryParams']

export const listEntities = (
  workspaceId: string,
  projectId: string,
  start: number,
  end: number,
  parent_entity_id?: string,
  viewId?: string,
  order_by?: string[],
  order_directions?: ListEntitiesRequest['order_directions'],
) => {
  return get<ListEntitiesRequest, ListEntitiesResponse, ListEntitiesPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities`,
    {
      order_by: order_by,
      limit: start === end ? undefined : end - start + 1,
      offset: start,
      parent_entity_id,
      active_view_ids: viewId !== undefined ? [viewId] : undefined,
      order_directions,
    },
  )
}
