import type { Channel } from 'phoenix'
import { Socket } from 'phoenix'

/**
 * Enumerates all ws channel topic formats the app supports
 */
export type AppTopic = `ask_go:${string}` | `project:${string}` | `workspace:${string}`

export type AppSocket = Socket & {
  channel: (topic: AppTopic) => Channel
}

const getEndpoint = () => {
  const isDev = import.meta.env.MODE === 'development'

  const { hostname, protocol, port } =
    import.meta.env.VITE_API_HOSTNAME && !isDev
      ? new URL(import.meta.env.VITE_API_HOSTNAME)
      : window.location
  const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:'
  const wsPort = getPort(port, protocol)
  return `${wsProtocol}//${hostname}:${wsPort}/socket`
}

const getPort = (port: string, protocol: string) => {
  if (port === '') {
    return protocol === 'https:' ? '443' : '80'
  }

  return port
}

export const createSocket = (token: string): Socket =>
  new Socket(getEndpoint(), { params: { token }, heartbeatIntervalMs: 15000 })
