import { objectMap } from '@/shared/utils/object'
import { computed, reactive } from 'vue'

/* Types */
type SchemaEntry<ValueType extends string | boolean = string | boolean> =
  | {
      required?: boolean | string
      validate?: (value: ValueType) => string | undefined
      value: ValueType
    }
  | ValueType

// This utility type is needed, because false/true does not get inferred as boolean :(
type ParseValue<Value> = Value extends false ? boolean : Value extends true ? boolean : Value
type SchemaValue<S extends SchemaEntry> = S extends { value: infer T } ? ParseValue<T> : string

type FieldProps = {
  required?: boolean
  onBlur?: () => void
  onInput?: (value: string) => void
  onChange?: (value: string | boolean) => void
}

type FieldState = {
  validationEvent: 'blur' | 'input'
}

/**
 * Form composable for smart validation.
 * Inspiration from Ben Holmes (https://twitter.com/BHolmesDev/status/1746911677440774274)
 *
 * Only triggers errors when inputs are blurred, or on input in case they already
 * failed validation.
 *
 * @export
 */
export function useForm<Schema extends Record<string, SchemaEntry>>(schema: Schema) {
  const states = reactive(objectMap(schema, (k) => [k, { validationEvent: 'blur' }])) as Record<
    keyof Schema,
    FieldState
  > // This type cast is needed due to wrong inference

  const values = reactive(
    objectMap(schema, (k, v) => [k, typeof v === 'object' ? v.value : v]),
  ) as {
    [K in keyof Schema]: SchemaValue<Schema[K]>
  } // This type cast is needed due to wrong inference
  const errors = reactive(objectMap(schema, (k) => [k, undefined])) as Record<
    keyof Schema,
    string | undefined
  > // This type cast is needed due to wrong inference

  const hasErrors = computed(() => Object.values(errors).some(Boolean))
  const hasInvalid = computed(() =>
    Object.keys(states).some((key) => !!getError(key as keyof Schema)),
  )

  function getError(key: keyof Schema) {
    const value = values[key]
    const schemaEntry = schema[key]

    if (typeof schemaEntry !== 'object') {
      return null
    }

    if (schemaEntry.required && !value) {
      return typeof schemaEntry.required === 'string'
        ? schemaEntry.required
        : 'This field is required'
    }

    if (schemaEntry.validate) {
      return schemaEntry.validate(value) || null
    }

    return null
  }

  function validateField(key: keyof Schema, event: 'blur' | 'input') {
    const error = getError(key)
    if (error) {
      errors[key] = error
      states[key].validationEvent = 'input'
      return
    }

    errors[key] = undefined
    if (event === 'blur') {
      states[key].validationEvent = 'blur'
    }
  }

  function getFieldProps(key: keyof Schema): FieldProps {
    const schemaEntry = schema[key]
    const base = {
      onInput(value: string) {
        if (typeof values[key] !== 'string') return
        // We already type checked above, so as any is fine here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values[key] = value as any
      },
      onChange(value: string | boolean) {
        if (typeof values[key] !== typeof value) return
        // We already type checked above, so as any is fine here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values[key] = value as any
      },
    }

    if (typeof schemaEntry !== 'object') {
      return base
    }

    return {
      required: !!schemaEntry.required,
      onBlur: () => {
        validateField(key, 'blur')
      },
      onChange: base.onChange,
      onInput: (value: string) => {
        base.onInput(value)
        if (states[key].validationEvent === 'input') {
          validateField(key, 'input')
        }
      },
    }
  }

  return {
    values,
    errors,
    getFieldProps,
    hasErrors,
    hasInvalid,
  }
}
