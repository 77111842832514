<script setup lang="ts">
import CheckBox from '@/uiKit/CheckBox.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

defineProps<{
  isSelected: boolean
}>()

defineEmits<{
  (e: 'change'): void
}>()

const permissionsStore = usePermissionsStore()
</script>

<template>
  <!-- Need to have solid `bg-surface-primary` under `bg-background-selected` because otherwise -->
  <!-- selected cells would be semi-transparent and show row text content underneath -->
  <div class="h-8 bg-surface-primary last:border-r-0 hover:cursor-pointer">
    <div
      class="inline-flex size-full items-center justify-center px-2 py-1.5"
      :class="{ 'bg-background-selected': isSelected }"
    >
      <div
        :class="
          !isSelected &&
          'shrink grow basis-0 overflow-hidden text-ellipsis text-center text-sm-12px-light text-text-subtlest group-hover/row:flex group-hover/row:justify-center'
        "
        click.stop
      >
        <span
          :class="[
            isSelected && 'hidden',
            permissionsStore.currentProjectPermissions.update_entities && 'group-hover/row:hidden',
          ]"
        >
          <slot />
        </span>
        <CheckBox
          v-if="permissionsStore.currentProjectPermissions.update_entities"
          :checked="isSelected"
          :class="isSelected ? 'block' : 'hidden group-hover/row:block'"
          @change="$emit('change')"
        />
      </div>
    </div>
  </div>
</template>
