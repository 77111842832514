import { createAuth0 } from '@auth0/auth0-vue'

const getOauthRedirectUri = (): string =>
  import.meta.env.VITE_OAUTH_REDIRECT_URI || window.location.origin

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_OAUTH_DOMAIN,
  clientId: import.meta.env.VITE_OAUTH_CLIENT_ID,
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: getOauthRedirectUri(),
    audience: import.meta.env.VITE_OAUTH_AUDIENCE,
  },
})
