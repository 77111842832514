import { useEventListener, type MaybeRef } from '@vueuse/core'
import { unref } from 'vue'

export type UseHotKeyOptions = {
  preventInsideInputs?: boolean
  enabled?: MaybeRef<boolean>
}

export function useHotKey(key: string, callback: () => void, options: UseHotKeyOptions = {}) {
  const { preventInsideInputs, enabled }: UseHotKeyOptions = {
    preventInsideInputs: true,
    enabled: true,
    ...options,
  }

  const onKeypress = async (event: KeyboardEvent) => {
    const isEnabled = unref(enabled)
    if (!isEnabled) return

    if (!preventInsideInputs) {
      if (event.key !== key) return

      event.preventDefault()
      event.stopPropagation()
      callback()
      return
    }

    // Wait in case the keypress focuses an editable element
    await new Promise((resolve) => setTimeout(resolve, 1))

    const el = document.activeElement as HTMLElement
    if (
      el.tagName === 'INPUT' ||
      el.tagName === 'TEXTAREA' ||
      el.isContentEditable ||
      event.key !== key
    ) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    callback()
  }

  useEventListener(window, 'keydown', onKeypress)
}
