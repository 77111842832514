<script setup lang="ts">
import { computed, ref } from 'vue'

import { TYPE_ICON } from '@/modules/Project/icons'
import { useFilters } from '@/modules/Project/useFilters'
import { useProject } from '@/modules/Project/useProject'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import { useStorage } from '@vueuse/core'

import { isSelectProperty } from '@/shared/utils/typeGuards'
import FilterBarTextItemValue from './FilterItemTextValue.vue'
import {
  getFilterSubjectForProperty,
  isFilterableProperty,
  isTextLikeFilterSubject,
  propertyHasTextLikeFilter,
  type FilterablePropertyType,
  type GroupFilter,
  type SelectOptionValueFilter,
  type TextLikeFilter,
} from './types'
/**
 * This component renders the set of menus and dialogs that allow users to add
 * new filters to the current view.
 */

const filtersStore = useFilters()
const projectStore = useProject()
const workspaceMemberStore = useWorkspaceMembers()

const localFilter = useStorage<Record<string, GroupFilter>>('localFilter', {})

type PropertyItem = {
  id: string
  label: string
  type: FilterablePropertyType
  options: { color?: string | null; value: string }[]
}

/** The set of properties to render at the first level of the menu */
const items = computed<Array<{ id: string; data: PropertyItem }>>(() =>
  projectStore.properties.reduce<Array<{ id: string; data: PropertyItem }>>((acc, curr) => {
    if (isFilterableProperty(curr)) {
      acc.push({
        id: curr.id,
        data: {
          id: curr.id,
          label: curr.name,
          type: curr.type,
          options: isSelectProperty(curr) ? curr.config.options : [],
        },
      })
    }

    return acc
  }, []),
)

/**
 * The property (if any) that has been selected at the first level of the menu.
 * Some properties trigger submenus (e.g. select properties), while others
 * will trigger a dialog (e.g. text properties).
 */
const activePropertyItem = ref<PropertyItem | null>(null)

const onUpdate = (data: { caseSensitive: boolean; matcherName: string; values: string[] }) => {
  assertIsNotNullOrUndefined(projectStore.activeView, 'activeView is not set')

  if (!activePropertyItem.value) return

  const filterSubject = getFilterSubjectForProperty(activePropertyItem.value.type)
  if (isTextLikeFilterSubject(filterSubject)) {
    // A default filter for any subjects whose matchers require text values
    const newFilter: TextLikeFilter = {
      matcher: {
        case_sensitive: data.caseSensitive,
        name: data.matcherName as TextLikeFilter['matcher']['name'],
        property_id: activePropertyItem.value.id,
        values: data.values,
      },
      subject: filterSubject,
    }

    if (projectStore.activeView.id in localFilter.value) {
      // If the local filter already has a value for the current project, add the new filter to the list
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          ...localFilter.value[projectStore.activeView.id],
          filters: [...localFilter.value[projectStore.activeView.id].filters, newFilter],
        },
      }
    } else {
      // If the filter was set, but has no value for the current project, set the new filter for it
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          conjunction: 'or',
          filters: [newFilter],
        },
      }
    }
  }

  filtersStore.viewFilters = true
  activePropertyItem.value = null
}

const onSelectFilter = (property: PropertyItem, item: PropertyItem['options'][number]) => {
  assertIsNotNullOrUndefined(projectStore.activeView, 'activeView is not set')

  if (
    property.type === 'single_select' ||
    property.type === 'multi_select' ||
    property.type === 'user_select'
  ) {
    // The default select filter to be added will have no specified value
    const newFilter: SelectOptionValueFilter = {
      matcher: {
        name: 'property_any_of',
        property_id: property.id,
        values: [item.value],
      },
      subject: 'field_select_option_value',
    }

    if (projectStore.activeView.id in localFilter.value) {
      // If the local filter already has a value for the current project, add the new filter to the list
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          ...localFilter.value[projectStore.activeView.id],
          filters: [...localFilter.value[projectStore.activeView.id].filters, newFilter],
        },
      }
    } else {
      // If the filter was set, but has no value for the current project, set the new filter for it
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          conjunction: 'or',
          filters: [newFilter],
        },
      }
    }
  }

  filtersStore.viewFilters = true
  activePropertyItem.value = null
  isOpen.value = false
}

/** Handle the user selecting a property at the first menu level */
const onSelectProperty = (propertyItem: PropertyItem) => {
  activePropertyItem.value = propertyItem

  // If the property's filter requires a user-inputted text value, open the text modal
  if (propertyHasTextLikeFilter(propertyItem.type)) {
    isOpen.value = false
    isTextModalOpen.value = true
  }
}

const isOpen = ref(false)
const onOpenChange = (open: boolean) => {
  if (!open) {
    activePropertyItem.value = null
  }

  isOpen.value = open
}

const isTextModalOpen = ref(false)

const property = computed(() => {
  if (!activePropertyItem.value) return

  const propertyId = activePropertyItem.value.id
  return projectStore.properties.find((p) => p.id === propertyId)
})

/** The filter subject for the selected property */
const propertyFilterSubject = computed(() => {
  if (!property.value || !isFilterableProperty(property.value)) {
    return null
  }

  return getFilterSubjectForProperty(property.value.type)
})
</script>

<template>
  <PopupMenu
    :open="isOpen"
    placement="top-start"
    trigger-element="div"
    @change:open="onOpenChange"
  >
    <template #trigger>
      <slot
        name="trigger"
        :is-open="isOpen"
        :on-click="() => onOpenChange(!isOpen)"
      />
    </template>
    <template #dropdown>
      <ListMenu
        v-if="
          activePropertyItem &&
          getFilterSubjectForProperty(activePropertyItem.type) === 'field_select_option_value'
        "
        :items="
          activePropertyItem.options.map((option) => ({
            id: option.value,
            data: option,
          }))
        "
        search-by-field="value"
        :search-placeholder="`Filter ${activePropertyItem.label}`"
        @select="onSelectFilter(activePropertyItem, $event)"
      >
        <template #item="{ key, item, active, setActiveItem }">
          <ListMenuItem
            v-if="activePropertyItem.type === 'multi_select'"
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onSelectFilter(activePropertyItem, item.data)"
          />
          <ListMenuItem
            v-else
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onSelectFilter(activePropertyItem, item.data)"
          >
            <BadgeItem
              size="sm"
              :label="
                activePropertyItem.type === 'user_select'
                  ? workspaceMemberStore.getMemberNameFromId(item.data.value)
                  : item.data.value
              "
              variant="blue"
              :rainbow-color="item.data.color"
            />
          </ListMenuItem>
        </template>
      </ListMenu>
      <ListMenu
        v-else
        class="max-h-[500px] max-w-[320px]"
        :items="items"
        search-by-field="label"
        @select="onSelectProperty"
      >
        <template #item="{ key, item, active, setActiveItem }">
          <ListMenuItem
            :label="item.data.label"
            :active="active"
            :aria-selected="active"
            :checked="projectStore.activeView?.view.propertyIds?.includes(item.data.id) ?? false"
            :icon="TYPE_ICON[item.data.type]"
            :always-visible="true"
            default-hover-disabled
            @select="onSelectProperty(item.data)"
            @mousemove="setActiveItem(key)"
          />
        </template>
      </ListMenu>
    </template>
  </PopupMenu>

  <FilterBarTextItemValue
    v-if="property && isTextLikeFilterSubject(propertyFilterSubject)"
    :filter="{
      matcher: {
        name: 'property_contains_any_of',
        property_id: property.id,
        values: [''],
      },
      subject: propertyFilterSubject,
    }"
    :is-open="isTextModalOpen"
    :property-name="property.name"
    @close="isTextModalOpen = false"
    @update="onUpdate"
  />
</template>
