import type { operations } from '@/api'
import { get } from './client'

export type GetEntityPath = `/api/workspaces/${string}/projects/${string}/entities/${string}`
export type GetEntityRequest = undefined
export type GetEntityResponse =
  operations['entity-get']['responses']['200']['content']['application/json']

export const getEntity = (workspaceId: string, projectId: string, entityId: string) =>
  get<GetEntityRequest, GetEntityResponse, GetEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}`,
    undefined,
  )
