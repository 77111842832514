<script setup lang="ts">
import { clamp, computedAsync, useElementSize } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

import type { ScaleType } from '@/sharedComposables/useNaturalZoom'
import ScaleButtons from './ScaleButtons.vue'
import ScrollBar from './ScrollBar.vue'

const props = defineProps<{
  url: string
  stagedFilePreview?: File | null
}>()

const img = ref<HTMLImageElement | null>(null)
const container = ref<HTMLDivElement | null>(null)

const zoom = ref(1)
const translateX = ref(0)
const translateY = ref(0)

const loading = ref(false)

// Separate ref so that the url is not
// unnecessarily updated when the aws signature
// changes, only when the actual file changes.
const imageUrl = ref(props.url)
watch(
  () => props.url,
  async (curr, prev) => {
    const currWithoutQuery = curr?.split('?')[0]
    const prevWithoutQuery = prev?.split('?')[0]
    if (currWithoutQuery === prevWithoutQuery || !curr) return
    imageUrl.value = curr
  },
  { immediate: true },
)

const asyncImageObjectUrl = computedAsync(
  async () => {
    if (imageUrl.value) {
      const response = await fetch(imageUrl.value)
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    }
    return null
  },
  null,
  loading,
)

watch(
  () => props.stagedFilePreview,
  () => {
    asyncImageObjectUrl.value = null
  },
)

const imageSrc = computed(
  () =>
    (props.stagedFilePreview && URL.createObjectURL(props.stagedFilePreview)) ??
    asyncImageObjectUrl.value,
)

const { height: containerHeight, width: containerWidth } = useElementSize(container)
const { height: imgHeight, width: imgWidth } = useElementSize(img)

const maxTranslateX = computed(() => imgWidth.value / 2 - containerWidth.value / zoom.value / 2)
const maxTranslateY = computed(() => imgHeight.value / 2 - containerHeight.value / zoom.value / 2)

const horizontalScrollOffset = computed(
  () => 1 - (translateX.value + maxTranslateX.value) / (2 * maxTranslateX.value),
)
const verticalScrollOffset = computed(
  () => 1 - (translateY.value + maxTranslateY.value) / (2 * maxTranslateY.value),
)

const scrollSizeX = computed(() => containerWidth.value / imgWidth.value / zoom.value)
const scrollSizeY = computed(() => containerHeight.value / imgHeight.value / zoom.value)

const maxZoomOut = computed(() =>
  Math.min(containerWidth.value / imgWidth.value, containerHeight.value / imgHeight.value, 1),
)

watch(maxZoomOut, (newValue) => (zoom.value = newValue))

const clampedOrCenteredXY = (x: number, y: number) => {
  const ifWidthFits = imgWidth.value * zoom.value <= containerWidth.value
  const ifHeightFits = imgHeight.value * zoom.value <= containerHeight.value
  return [
    ifWidthFits ? 0 : clamp(x, -maxTranslateX.value, maxTranslateX.value),
    ifHeightFits ? 0 : clamp(y, -maxTranslateY.value, maxTranslateY.value),
  ]
}

// zooms into the point where the mouse is
// this is either cmd/ctrl + mousescroll or pinch to zoom in/out
const onScroll = (e: WheelEvent) => {
  if (!container.value) return
  e.preventDefault()

  const mitigatedDeltaY = clamp(e.deltaY, -10, 10)
  const newZoom = zoom.value * (1 - mitigatedDeltaY / 30)

  const containerRect = container.value.getBoundingClientRect()
  const ratio = 1 - newZoom / zoom.value
  const dx = (e.clientX - containerRect.left - containerWidth.value / 2) / zoom.value
  const dy = (e.clientY - containerRect.top - containerHeight.value / 2) / zoom.value

  zoom.value = clamp(newZoom, maxZoomOut.value, 1)
  ;[translateX.value, translateY.value] = clampedOrCenteredXY(
    translateX.value + dx * ratio,
    translateY.value + dy * ratio,
  )
}

const onPan = (e: WheelEvent) => {
  if (!img.value || !container.value) return
  e.preventDefault()

  const dx = e.deltaX / zoom.value
  const dy = e.deltaY / zoom.value

  ;[translateX.value, translateY.value] = clampedOrCenteredXY(
    translateX.value - dx,
    translateY.value - dy,
  )
}

const onMouseDown = (mde: MouseEvent) => {
  if (!img.value || !container.value) return
  mde.preventDefault()
  const startTranslateX = translateX.value
  const startTranslateY = translateY.value

  const onMouseMove = (mme: MouseEvent) => {
    if (!img.value || !container.value) return
    const dx = (mde.clientX - mme.clientX) / zoom.value
    const dy = (mde.clientY - mme.clientY) / zoom.value

    ;[translateX.value, translateY.value] = clampedOrCenteredXY(
      startTranslateX - dx,
      startTranslateY - dy,
    )
  }

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
}

// zooms into the center of the image and fits it to the container
const onScaleChange = (newScale: ScaleType) => {
  zoom.value = typeof newScale === 'number' ? newScale : 1
  ;[translateX.value, translateY.value] = clampedOrCenteredXY(translateX.value, translateY.value)
}

const onVerticalScrollChange = (newValue: number) => {
  translateY.value = (1 - newValue) * 2 * maxTranslateY.value - maxTranslateY.value
}

const onHorizontalScrollChange = (newValue: number) => {
  translateX.value = (1 - newValue) * 2 * maxTranslateX.value - maxTranslateX.value
}
</script>

<template>
  <div class="flex size-full flex-col gap-1 p-1 pt-0">
    <div
      ref="container"
      class="group flex size-full cursor-grab items-center justify-center overflow-hidden rounded-corner-6 border border-border-subtle [contain:strict] active:cursor-grabbing"
      @wheel.ctrl.exact="onScroll"
      @wheel.meta.exact="onScroll"
      @wheel.exact="onPan"
      @wheel.shift.exact="onPan"
      @mousedown="onMouseDown"
    >
      <ScrollBar
        class="absolute inset-y-0 right-0 z-10"
        :offset="verticalScrollOffset"
        :size="scrollSizeY"
        type="vertical"
        @change="onVerticalScrollChange"
      />
      <ScrollBar
        class="absolute inset-x-0 bottom-0 z-10"
        :offset="horizontalScrollOffset"
        :size="scrollSizeX"
        type="horizontal"
        @change="onHorizontalScrollChange"
      />
      <img
        v-if="imageSrc"
        ref="img"
        :src="imageSrc"
        class="size-max max-w-none shrink-0 object-none"
        :class="loading && 'opacity-5'"
        :style="`transform: scale(${zoom}) translate(${translateX}px, ${translateY}px)`"
      />
      <div
        v-if="loading"
        class="absolute flex flex-col gap-9 whitespace-normal"
      >
        Loading...
      </div>
    </div>

    <ScaleButtons
      :scale="zoom"
      :min-scale="maxZoomOut"
      :max-scale="1"
      class="grid grid-cols-3 p-1"
      full-width-mode
      disable-fit
      @update:scale="onScaleChange"
    />
  </div>
</template>
