import { serializeProperty } from '@/modules/Project/useProject'
import type { Project } from '@/modules/Projects/useProjects'
import { useCanSeeProject } from '@/sharedComposables/useCanSeeProject'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { recentProjectsLoader } from './useRecentProjectsDataLoader'

export const useRecentProjects = defineStore('recentProjects', () => {
  const projects = ref<Project[]>([])

  // this should happen on the backend or the api should allow to filter by parent_property
  const recentProjects = computed(() =>
    projects.value.filter((p) => p.parentProperty === undefined),
  )

  const canSeeProject = useCanSeeProject()

  const refresh = async (workspaceId: string) => {
    const result = await recentProjectsLoader.load(workspaceId)

    if (result.ok) {
      projects.value = result.data.data.filter(canSeeProject).map((p) => ({
        id: p.id,
        name: p.name,
        workspaceId: p.workspace_id,
        propertyCount: p.properties.length,
        parentProperty: p.parent_property ? serializeProperty(p.parent_property) : undefined,
        coverImageUrls: p.cover_image_urls,
        updatedAt: p.updated_at,
      }))
    }
  }

  const setCoverImageDownloadError = (id: string, value: boolean) => {
    const project = projects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageDownloadError = value
    }
  }

  const setCoverImageUploading = (id: string, value: boolean) => {
    resetCoverImagesUploading()

    const project = projects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageUploading = value
    }
  }

  const resetCoverImagesUploading = () => {
    for (let i = 0; i < projects.value.length; i++) {
      projects.value[i].coverImageUploading = false
    }
  }

  return {
    status: recentProjectsLoader.status,
    projects,
    refresh,
    recentProjects,
    setCoverImageDownloadError,
    setCoverImageUploading,
  }
})
