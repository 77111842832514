import { ref, watch } from 'vue'

import { confirmFieldFileUpload } from '@/backend/confirmFieldFileUpload'
import { startFieldFileUpload } from '@/backend/startFieldFileUpload'
import { uploadFile } from '@/backend/uploadFile'

import { useLimitedAction } from '../Billing/useLimitedAction'
import { useFieldUpload } from './useFieldUpload'
import { serializeEntity, useProject } from './useProject'

/**
 * Queue-like handler of pending uploads
 *
 * It performs two jobs
 *
 * - creates entities for uploads that don't have one yet
 * - perform uploads of files that are already associated with an entity
 *
 * Both of these jobs run concurrently, `queueSize` at a time
 *
 * That means with a `queueSize` of 2, at any givem time
 *
 * - up to 2 files will be getting uploaded
 * - up to 2 files will be getting created
 *
 * The given queue size is non-reactive as we don't really expect to change it. If at some
 * point we wan to provide a UI for the user to change the number of concurrent uploads,
 * we can turn it into a ref.
 */
export const useFieldUploadQueue = (queueSize: number) => {
  const uploadStore = useFieldUpload()
  const projectStore = useProject()
  const { addEntity } = useLimitedAction()

  const uploadCount = ref(0)

  watch(
    [() => uploadStore.nextReadyUpload, () => uploadCount.value < queueSize],
    async ([upload, canRun]) => {
      if (!upload || !canRun) {
        return
      }

      uploadCount.value += 1

      const { file, workspaceId, projectId, entityId, propertyId } = upload

      uploadStore.setUploadGettingUrl(workspaceId, projectId, entityId, propertyId)
      const result = await startFieldFileUpload(
        workspaceId,
        projectId,
        entityId,
        propertyId,
        file.name,
      )

      if (!result.ok) {
        uploadStore.setUploadError(
          workspaceId,
          projectId,
          entityId,
          propertyId,
          result.error.code === 'unsupported_file_extension'
            ? 'File extension not supported'
            : 'Failed to upload file',
        )
        return
      }
      projectStore.setFileFieldValue({
        entityId,
        propertyId,
        value: result.data.file_upload_url,
        filename: file.name,
      })

      uploadStore.setUploadUploading(
        workspaceId,
        projectId,
        entityId,
        propertyId,
        result.data.file_upload_url,
      )

      const uploadResult = await uploadFile(result.data.file_upload_url, file, (progress) =>
        uploadStore.setUploadProgress(workspaceId, projectId, entityId, propertyId, progress),
      )

      if (!uploadResult.ok) {
        uploadStore.setUploadError(
          workspaceId,
          projectId,
          entityId,
          propertyId,
          'Failed to upload file',
        )
        return
      }

      uploadStore.setUploadProgress(workspaceId, projectId, entityId, propertyId, 1)
      uploadStore.setUploadConfirming(workspaceId, projectId, entityId, propertyId)
      const confirmResult = await confirmFieldFileUpload(result.data.confirm_upload_url)
      if (!confirmResult.ok) {
        uploadStore.setUploadError(
          workspaceId,
          projectId,
          entityId,
          propertyId,
          'Failed to confirm upload of file',
        )
        return
      }

      uploadStore.setUploadDone(workspaceId, projectId, entityId, propertyId)

      uploadCount.value -= 1
    },
    { immediate: true },
  )

  watch(
    () => uploadStore.nextDoneUpload,
    (upload) => {
      if (!upload) {
        return
      }
      uploadStore.removeUpload(
        upload.workspaceId,
        upload.projectId,
        upload.entityId,
        upload.propertyId,
      )
    },
    { immediate: true },
  )

  const pendingCount = ref(0)

  watch(
    [() => uploadStore.nextPendingUpload, () => pendingCount.value < queueSize],
    async ([upload, canRun]) => {
      if (!upload || !canRun) {
        return
      }
      pendingCount.value += 1

      uploadStore.setPendingUploadCreatingEntity(upload.id)
      const result = await addEntity({
        workspaceId: upload.workspaceId,
        projectId: upload.projectId,
      })
      if (result.ok) {
        projectStore.upsertEntity(serializeEntity(result.data))
        uploadStore.upgradePendingUpload(upload.id, result.data.id)
      }

      pendingCount.value -= 1
    },
    { immediate: true },
  )
}
