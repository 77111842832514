<script setup lang="ts">
import CollectionCrumb from '@/modules/Workspaces/Crumbs/CollectionCrumb.vue'
import EntityCrumb from '@/modules/Workspaces/Crumbs/EntityCrumb.vue'
import ProjectCrumb from '@/modules/Workspaces/Crumbs/ProjectCrumb.vue'
import { useCrumbItems } from '@/modules/Workspaces/Crumbs/useCrumbItems'
import ViewCrumb from '@/modules/Workspaces/Crumbs/ViewCrumb.vue'
import WorkspaceCrumb from '@/modules/Workspaces/Crumbs/WorkspaceCrumb.vue'
import CrumbChevron from '@/sharedComponents/CrumbChevron.vue'

const { collection, entity, onlyProject, parentProject, parentView, view } = useCrumbItems()
</script>

<template>
  <nav
    class="flex flex-row items-center justify-start overflow-hidden [&>*]:grow-0"
    aria-label="crumbs"
  >
    <WorkspaceCrumb />
    <template v-if="onlyProject">
      <CrumbChevron />
      <ProjectCrumb :project="onlyProject" />
    </template>
    <template v-if="parentProject">
      <CrumbChevron />
      <ProjectCrumb :project="parentProject" />
    </template>
    <template v-if="collection">
      <CrumbChevron />
      <CollectionCrumb
        :project="collection"
        :parent-view="parentView"
      />
    </template>
    <template v-if="view && view.name !== 'main'">
      <CrumbChevron />
      <ViewCrumb :view="view" />
    </template>
    <template v-if="entity">
      <CrumbChevron />
      <EntityCrumb :entity="entity" />
    </template>
  </nav>
</template>
