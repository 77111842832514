import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { TriggerResponse } from '@/backend/types'

export type Trigger = {
  id: TriggerResponse['id']
  projectId: TriggerResponse['project_id']
  workspaceId: TriggerResponse['workspace_id']
  action: TriggerResponse['action']
  events: TriggerResponse['events']
}

export const useTriggers = defineStore('triggers', () => {
  const triggers = ref<Trigger[]>([])

  const setTriggers = (newTriggers: Trigger[]) => {
    triggers.value = newTriggers
  }

  const addTrigger = (trigger: Trigger) => triggers.value.push(trigger)

  const removeTrigger = (id: string) => {
    triggers.value = triggers.value.filter((t) => t.id !== id)
  }

  return {
    addTrigger,
    removeTrigger,
    setTriggers,
    triggers,
  }
})

export const serializeTrigger = (trigger: TriggerResponse): Trigger => ({
  id: trigger.id,
  projectId: trigger.project_id,
  workspaceId: trigger.workspace_id,
  action: trigger.action,
  events: trigger.events,
})
