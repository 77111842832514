<script setup lang="ts">
import { useTemplateModal } from '@/modules/Workspaces/TemplateModal.vue'
import { omit } from '@/shared/utils'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { ref } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useCsvImportModal } from '../Workspaces/CsvImportModal.vue'
import { useTemplates } from './useTemplates'

const props = defineProps<{ workspaceId: string; size?: 'md' | 'xs' | 'sm' | 'lg' }>()

const templateModal = useTemplateModal()
const csvModal = useCsvImportModal()
const templateStore = useTemplates()

const isCreating = ref(false)

const IDS = {
  exploreTemplates: 'explore-templates',
  startFromScratch: 'start-from-scratch',
  importCsv: 'import-csv',
}

const onSelect = (id: string) => {
  switch (id) {
    case IDS.exploreTemplates: {
      templateModal.open()
      break
    }
    case IDS.startFromScratch: {
      isCreating.value = true
      templateStore.startFromScratch(props.workspaceId).finally(() => {
        isCreating.value = false
      })
      break
    }
    case IDS.importCsv: {
      csvModal.open()
      break
    }
    default:
      break
  }
}

const permissionsStore = usePermissionsStore()
</script>

<template>
  <FloatingMenu
    v-if="permissionsStore.workspacePermissions.create_projects"
    v-bind="$attrs"
    @select="onSelect"
  >
    <template #trigger="{ triggerProps }">
      <DarwinButton
        variant="black"
        data-test="new-project-button-homepage"
        :size="size || 'sm'"
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        :loading="isCreating"
        :disabled="isCreating"
      >
        <template #leading-icon><IconSprite icon="plus" /></template>
        <template #trailing-icon><IconSprite icon="chevron-bottom" /></template>
        New project
      </DarwinButton>
    </template>
    <template
      v-if="!isCreating"
      #content="{ contentProps, getItemProps }"
    >
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-[242px]"
      >
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            class="gap-1 px-1.5 py-1"
            element="button"
            data-test="explore-templates"
            v-bind="omit(getItemProps({ value: IDS.exploreTemplates }), ['onSelect'])"
          >
            <template #prefix>
              <div class="flex size-5 items-center justify-center text-icon-subtle">
                <IconSprite icon="templates" />
              </div>
            </template>
            Explore templates
          </ListMenuItem>
          <ListMenuItem
            class="gap-1 px-1.5 py-1"
            element="button"
            data-test="import-csv"
            v-bind="omit(getItemProps({ value: IDS.importCsv }), ['onSelect'])"
          >
            <template #prefix>
              <div class="flex size-5 items-center justify-center text-icon-subtle">
                <IconSprite icon="table" />
              </div>
            </template>
            Import CSV File
          </ListMenuItem>
        </div>
        <DividerLine color="subtle" />
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            class="justify-between gap-1 px-1.5 py-1"
            element="button"
            data-test="start-from-scratch"
            v-bind="omit(getItemProps({ value: IDS.startFromScratch }), ['onSelect'])"
          >
            <p class="px-1">Start from scratch</p>
          </ListMenuItem>
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
