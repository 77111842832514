import type { Field } from './useProject'

export const resolveFilename = (
  field: Field<'file' | 'pdf' | 'file_collection'>,
): string | null => {
  /**
   * File collection fields can have multiple files, so the concept of 'a filename' doesn't
   * really apply. But throughout the UI, we render the first file's name as a preview, so that
   * is the value we return from this block.
   */
  if (field.type === 'file_collection') {
    return field.manualFilename || field.toolFilename || null
  }

  const fromData = field.manualFilename || field.toolFilename
  if (fromData) {
    return fromData
  }

  const urlString = field.manualValue || field.toolValue
  if (!urlString) {
    return null
  }

  if (isValidUrl(urlString)) {
    return new URL(urlString).pathname.split('/').pop() || null
  }

  return null
}

const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch {
    return false
  }
}
