import type { components } from '@/api'
import { get } from './client'

export type GetProjectPath = `/api/workspaces/${string}/projects/${string}`
export type GetProjectResponse = components['schemas']['Projects.ProjectResponse']

export const getProject = (workspaceId: string, projectId: string) =>
  get<undefined, GetProjectResponse, GetProjectPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}`,
    undefined,
  )
