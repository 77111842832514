import { isHtmlElement } from '@/shared/utils'
import { useMutationObserver } from '@vueuse/core'
import { ref } from 'vue'

/**
 * WARNING: Do not use outside of the WelcomeTour!
 *
 * This serves to have access to the to the element with the given selector,
 * without having access to the element itself in the template.
 */
export function useElement(selector: string) {
  const element = ref<HTMLElement | null>(null)
  useMutationObserver(
    document.body,
    () => {
      const newEl = document.querySelector(selector)
      element.value = isHtmlElement(newEl) ? newEl : null
    },
    { childList: true },
  )

  return element
}
