<script setup lang="ts">
import * as fileUpload from '@zag-js/file-upload'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed } from 'vue'

/**
 * Generic file upload component that uses the @zag-js/file-upload module.
 *
 * Figma: https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/V7-Go?type=design&node-id=10496-564024&mode=design&t=Sazue0Mz8Sg6wCfR-4
 *
 **/

const props = withDefaults(
  defineProps<{
    accept?: string
    maxFiles?: number
    placeholder?: string
  }>(),
  {
    accept: undefined,
    maxFiles: 1,
    placeholder: 'Drag your files here',
  },
)

const emit = defineEmits<{
  (e: 'change', value: File[]): void
  (e: 'reject', value: fileUpload.FileRejectDetails): void
}>()

const [state, send] = useMachine(
  fileUpload.machine({
    id: window.crypto.randomUUID(),
    onFileAccept(details) {
      emit('change', details.files)
    },
    onFileReject(details) {
      if (!details.files.length) return
      emit('reject', details)
    },
  }),
  {
    context: computed(() => ({
      accept: props.accept,
      maxFiles: props.maxFiles,
    })),
  },
)

const api = computed(() => fileUpload.connect(state.value, send, normalizeProps))

defineExpose({
  open: computed(() => api.value.openFilePicker),
  api: computed(() => api.value),
  clearFiles: () => api.value.clearFiles(),
})
</script>

<template>
  <slot
    :dropzone-props="api.getDropzoneProps()"
    :hidden-input-props="api.getHiddenInputProps()"
    :is-dragging="api.dragging"
    :trigger-props="{
      ...api.getTriggerProps(),
      onClick: api.openFilePicker,
    }"
    :clear-files="api.clearFiles"
    :remove-file="api.deleteFile"
    :root-props="api.getRootProps()"
  >
    <div v-bind="api.getRootProps()">
      <div
        v-bind="api.getDropzoneProps()"
        class="rounded-corner-10 border border-dashed border-border-subtle bg-background-gray-subtlest py-[2.55rem] text-center hover:bg-background-gray-subtlest-hovered"
        :class="api.dragging && 'border-border-focused bg-background-gray-subtlest-hovered'"
      >
        <input
          data-test="file-upload"
          v-bind="api.getHiddenInputProps()"
        />
        <span class="text-sm-12px-default text-text-subtlest">{{ placeholder }}</span>
      </div>
    </div>
  </slot>
</template>
