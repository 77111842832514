import { useEntity } from '@/modules/Project/useEntity'
import { useProject } from '@/modules/Project/useProject'
import { useProjects } from '@/modules/Projects/useProjects'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed } from 'vue'

export function useCrumbItems() {
  const projects = useProjects()
  const entityStore = useEntity()
  const projectStore = useProject()

  const { viewId, entityId, parentProjectId, projectId, parentViewId } = useRouteParams()

  const view = computed(() => {
    return projectStore.views.find((v) => v.id === viewId.value)
  })

  const entity = computed(() => {
    return entityStore.entities.find((item) => item.id === entityId.value)?.entity
  })

  const parentProject = computed(() => {
    return projects.getProjectById(parentProjectId.value || '')
  })

  const project = computed(() => {
    return projects.getProjectById(projectId.value || '')
  })

  const collection = computed(() => {
    if (!parentProjectId.value) return
    return project.value
  })

  const parentView = computed(() => {
    return parentProject.value?.views?.find((v) => v.id === parentViewId.value)
  })

  const onlyProject = computed(() => {
    return collection.value ? undefined : project.value
  })

  return {
    collection,
    entity,
    onlyProject,
    parentProject,
    parentView,
    view,
  }
}
