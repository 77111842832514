<script setup lang="ts">
import NumberInput from '@/uiKit/NumberInput.vue'
/**
 * Renders a number input component, with min/max/size hardcoded so
 * that we don't go out of sync when setting the entity limit value
 * between the dropdown and sidebar.
 */

defineProps<{
  /** ID of a DOM element containing the label */
  labelId: string
}>()

const model = defineModel<number>({ default: 1 })
const onChange = (value: number) => {
  model.value = value
}
</script>

<template>
  <NumberInput
    :label="{ ariaLabelledBy: `#${labelId}` }"
    :min="1"
    :max="999"
    :value="model"
    name="entity-limit"
    size="xs"
    @change="onChange"
  />
</template>
