import type { operations } from '@/api'
import { post } from './client'

export type AddPropertyPath = `/api/workspaces/${string}/projects/${string}/properties`

export type AddPropertyRequest = Exclude<
  operations['project-add-property']['requestBody'],
  undefined
>['content']['application/json']

export type AddPropertyResponse =
  operations['project-add-property']['responses']['200']['content']['application/json']

const addPropertyConfig = (
  type: AddPropertyRequest['type'],
  { projectId, entityLimit }: { projectId?: string; entityLimit?: number } = {},
) => {
  if (type === 'single_select' || type === 'multi_select' || type === 'user_select') {
    return { type, config: { options: [] } }
  }
  if (type === 'pdf') {
    return { type, config: { splitter: 'page_split' as const } }
  }
  if (type === 'reference') {
    if (!projectId) {
      throw new Error('projectId is required for reference property')
    }
    return {
      type,
      config: {
        entity_limit: entityLimit || 1,
        project_id: projectId,
      },
    }
  }
  return { type }
}

export const addProperty = (
  workspaceId: string,
  projectId: string,
  params: {
    name: string
    type: AddPropertyRequest['type']
    tool: AddPropertyRequest['tool']
    isGrounded: AddPropertyRequest['is_grounded']
    description: AddPropertyRequest['description']
    inputIds: AddPropertyRequest['input_ids']
    viewId?: AddPropertyRequest['default_view_id']
    projectId?: string
    entityLimit?: number
  },
) =>
  post<AddPropertyRequest, AddPropertyResponse, AddPropertyPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/properties`,
    {
      name: params.name,
      tool: params.tool,
      is_grounded: params.isGrounded,
      description: params.description,
      input_ids: params.inputIds,
      default_view_id: params.viewId,
      ...addPropertyConfig(params.type, {
        projectId: params.projectId,
        entityLimit: params.entityLimit,
      }),
    },
  )
