import { post } from './client'
import type { BackendMeta } from './types'

export type AddProjectPermission = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/members/{user_id}',
  'post'
>

export type AddProjectPermissionParams = {
  workspaceId: AddProjectPermission['pathParams']['workspace_id']
  projectId: AddProjectPermission['pathParams']['project_id']
  role: AddProjectPermission['body']['role']
} & ({ userId: string } | { group: 'anyone_in_workspace' | 'anyone_in_folder' })

export const addProjectPermission = ({
  projectId,
  workspaceId,
  role,
  ...rest
}: AddProjectPermissionParams) => {
  const userId = 'userId' in rest ? rest.userId : rest.group

  return post<
    AddProjectPermission['body'],
    AddProjectPermission['successResponse'],
    AddProjectPermission['path']
  >(`/api/workspaces/${workspaceId}/projects/${projectId}/members/${userId}`, { role })
}
