<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { onMounted } from 'vue'
/**
 * Small QOL component to log out the user when mounted. Is used when a user
 * changes their password in Auth0, so they can be redirected back to the app
 * to login again with their new password.
 */
const { logout } = useAuth0()
onMounted(() => {
  logout()
})
</script>

<template><div /></template>
