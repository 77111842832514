import type { components } from '@/api'
import { post } from './client'

export type CreateSessionPath = `/api/workspaces/${string}/ask_go`
export type CreateSessionResponse = components['schemas']['AskGo.SessionResponse']
export type CreateSessionsRequest = components['schemas']['AskGo.CreateSessionRequest']

export const createSession = (params: { ask?: string; projectId: string; workspaceId: string }) =>
  post<CreateSessionsRequest, CreateSessionResponse, CreateSessionPath>(
    `/api/workspaces/${params.workspaceId}/ask_go`,
    { ask: params.ask, project_id: params.projectId },
  )
