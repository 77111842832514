import type { components } from '@/api'
import { remove } from './client'

export type RemoveMemberPath = `/api/workspaces/${string}/members/${string}`
export type RemoveMemberResponse = components['schemas']['Common.DeleteResponse']
export type RemoveMemberRequest = undefined

type RemoveMemberParams = {
  workspaceId: string
  userId: string
}

export const removeMember = ({ userId, workspaceId }: RemoveMemberParams) =>
  remove<RemoveMemberRequest, RemoveMemberResponse, RemoveMemberPath>(
    `/api/workspaces/${workspaceId}/members/${userId}`,
    undefined,
  )
