<script setup lang="ts">
import { RouterLink } from 'vue-router'

import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconButton from '@/uiKit/IconButton.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useResolveProjectRoute } from './useResolveProjectRoute'

const props = defineProps<{
  isLayoutLocked: boolean
  projectId: string
  hasPrevious?: boolean
  hasNext?: boolean
  viewId?: string
}>()

defineEmits<{
  (e: 'update:isLayoutLocked', value: boolean): void
  (e: 'prev' | 'next'): void
}>()

const permissionsStore = usePermissionsStore()

const { parentEntityId, parentProjectId, parentViewId, workspaceId } = useRouteParams()
const resolveProjectRoute = useResolveProjectRoute()
const closeRouteTo = computed(() =>
  resolveProjectRoute({
    parentEntityId: parentEntityId.value,
    parentProjectId: parentProjectId.value,
    projectId: props.projectId,
    workspaceId: workspaceId.value,
    parentViewId: parentViewId.value,
    viewId: props.viewId,
  }),
)
</script>

<template>
  <div class="grid grid-cols-3 items-center gap-1 p-2">
    <div>
      <RouterLink
        :to="closeRouteTo"
        class="inline-block"
        tabindex="-1"
      >
        <IconButton
          size="lg"
          variant="transparent"
          icon="close"
          data-test="close-entity-button"
        />
      </RouterLink>
    </div>
    <div class="flex grow justify-center">
      <div class="inline-flex items-center justify-start gap-1.5">
        <IconButton
          icon="chevron-top"
          size="lg"
          variant="transparent"
          data-test="previous-entity-button"
          :disabled="!hasPrevious"
          aria-label="Previous entity"
          @click="$emit('prev')"
        />
        <IconButton
          icon="chevron-bottom"
          size="lg"
          variant="transparent"
          data-test="next-entity-button"
          aria-label="Next entity"
          :disabled="!hasNext"
          @click="$emit('next')"
        />
      </div>
    </div>
    <div class="flex items-center justify-end">
      <ToolTip
        :arrow="true"
        :placement="{ allowedPlacements: ['left'] }"
        :title="isLayoutLocked ? 'Unlock layout' : 'Lock layout'"
      >
        <SwitchButton
          v-if="permissionsStore.currentProjectPermissions.manage_views"
          color="neutral"
          :checked="isLayoutLocked"
          :icon="isLayoutLocked ? 'lock-fill' : 'unlock'"
          aria-label="Lock layout"
          size="xl"
          @change="$emit('update:isLayoutLocked', $event)"
        />
      </ToolTip>
    </div>
  </div>
</template>
