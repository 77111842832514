<script setup lang="ts">
import { ref } from 'vue'

import type { ResourceRole } from '@/backend/types'
import MultiEmailInput from '@/modules/IdentityAndAccess/MultiEmailInput.vue'
import { toast } from '@/shared/toast'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import { roleLabelMap } from '../IdentityAndAccess/roleConfig'
import { useAllowedRoles } from '../IdentityAndAccess/useAllowedRoles'
import { useWorkspaceMembers } from './useWorkspaceMembers'
import WorkspaceSettingsRoleDropdown from './WorkspaceSettingsRoleDropdown.vue'

const props = defineProps<{
  defaultRole: 'admin' | 'editor'
}>()

const emails = ref<string[]>([])
const validEmails = ref<string[]>([])

const allowedRoles = useAllowedRoles({
  permissionName: 'invite_members',
  scope: 'workspace',
  rolesSubset: ['admin', 'worker', 'editor'],
})

const role = ref<Exclude<ResourceRole, 'owner' | 'reader'>>(props.defaultRole)

const workspaceMembersStore = useWorkspaceMembers()
const onSubmit = async (e: Event) => {
  e.preventDefault()
  const uniqueEmails = Array.from(new Set(validEmails.value))

  const newEmails = uniqueEmails.filter((email) => {
    const isExistingMember = workspaceMembersStore.workspaceMembers.some(
      (member) => member.email === email,
    )
    const isExistingInvitation = workspaceMembersStore.invitations.some(
      (invitation) => invitation.email === email,
    )

    return !isExistingMember && !isExistingInvitation
  })

  const membersToInviteCount = newEmails.length
  if (membersToInviteCount > 0) {
    await workspaceMembersStore.createInvitations(
      validEmails.value.map((email) => ({ email, role: role.value })),
    )

    const successfulInviteCount = workspaceMembersStore.invitations.filter((i) =>
      validEmails.value.includes(i.email),
    )
    if (successfulInviteCount.length > 0) {
      captureAnalyticsEvent(ANALYTICS_EVENT.INVITE_SENT)
      toast.success(
        `Invited ${successfulInviteCount.length} new member${successfulInviteCount.length > 1 ? 's' : ''}.`,
      )
    }
  } else {
    toast.error(`There are no new users on your list. Please check your input`)
  }

  emails.value = []
  validEmails.value = []
}

const onEmailsChanged = (newEmails: Array<string>) => {
  validEmails.value = newEmails
}

const { captureAnalyticsEvent } = useAnalytics()
</script>

<template>
  <form
    class="flex gap-2"
    @submit="onSubmit"
  >
    <MultiEmailInput
      ref="multiEmailInput"
      :value="emails"
      @change="onEmailsChanged"
    />
    <WorkspaceSettingsRoleDropdown
      class="w-40"
      :value="role"
      :offset="{ mainAxis: 4 }"
      :allowed-roles="allowedRoles"
      @change="role = $event"
    >
      <template #trigger>
        <SelectDropdownTrigger v-bind="$attrs">
          <div class="px-1">
            {{ roleLabelMap[role] }}
          </div>
        </SelectDropdownTrigger>
      </template>
    </WorkspaceSettingsRoleDropdown>
    <div class="flex flex-col justify-end">
      <DarwinButton
        variant="black"
        data-test="invite-button"
        size="sm"
        >Send Invite
        <template #trailing-icon>
          <IconSprite
            icon="arrow-bottom-circle-fill"
            size="sm"
          />
        </template>
      </DarwinButton>
    </div>
  </form>
</template>
