import type { components } from '@/api'
import { put } from './client'

export type UpdatePropertyPath = `/api/workspaces/${string}/projects/${string}/properties/${string}`
export type UpdatePropertyRequest = components['schemas']['Projects.UpdatePropertyRequest']
export type UpdatePropertyResponse = components['schemas']['Projects.PropertyResponse']

export type PropertyConfig = {
  defaultOption?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['default_option']
  maxSelected?: components['schemas']['Projects.SelectMaxSelected']
  removeOptions?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['remove_options']
  upsertOptions?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['upsert_options']
  splitter?: components['schemas']['Projects.UpdatePDFPropertyRequest']['config']['splitter']
  upsertCollectionProperties?: components['schemas']['Projects.UpdateCollectionPropertyConfig']['upsert_properties']
  removeCollectionProperties?: components['schemas']['Projects.UpdateCollectionPropertyConfig']['remove_properties']
  entityLimit?: components['schemas']['Projects.UpdateReferencePropertyRequest']['config']['entity_limit']
}

const updatePropertyConfig = (type: UpdatePropertyRequest['type'], config?: PropertyConfig) => {
  if (type === 'single_select' || type === 'multi_select' || type === 'user_select') {
    return {
      type,
      config: {
        default_option: config?.defaultOption,
        max_selected: config?.maxSelected,
        remove_options: config?.removeOptions ?? [],
        upsert_options: config?.upsertOptions ?? [],
      },
    }
  }

  if (type === 'pdf') {
    return { type, config: { splitter: config?.splitter ?? 'page_split' } }
  }

  if (type === 'collection') {
    return {
      type,
      config: {
        upsert_properties: config?.upsertCollectionProperties ?? [],
        remove_properties: config?.removeCollectionProperties ?? [],
      },
    }
  }

  if (type === 'reference') {
    return {
      type,
      config: {
        entity_limit: config?.entityLimit ?? 1,
      },
    }
  }

  return { type }
}

export const updateProperty = (
  workspaceId: string,
  projectId: string,
  propertyId: string,
  params: {
    name: string
    type: UpdatePropertyRequest['type']
    tool: UpdatePropertyRequest['tool']
    isGrounded: UpdatePropertyRequest['is_grounded']
    description: string | null
    inputs: Exclude<UpdatePropertyRequest['inputs'], undefined | null>
    config?: PropertyConfig
  },
) =>
  put<UpdatePropertyRequest, UpdatePropertyResponse, UpdatePropertyPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/properties/${propertyId}`,
    {
      name: params.name,
      description: params.description,
      inputs: params.inputs,
      tool: params.tool,
      is_grounded: params.isGrounded,
      ...updatePropertyConfig(params.type, params.config),
    },
  )
