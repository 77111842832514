import { listProjects } from '@/backend/listProjects'
import { useLoadAllPaginatedResults } from '@/sharedComposables/useLoadAllPaginatedResults'
import pLimit from 'p-limit'
import { ref, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { useBilling } from '../Billing/useBilling'
import { serializePermissions, usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { PROJECT_NAME_FALLBACK } from '../Project/constants'
import { useProjects, type Project } from './useProjects'

export const useLoadAllProjects = (workspaceId: Ref<string>) => {
  const alphabeticalProjects = ref<Project[]>([])
  const loadingState = ref<'idle' | 'loading' | 'loaded'>('idle')
  const permissionsStore = usePermissionsStore()

  const router = useRouter()
  const loadAllProjects = useLoadAllPaginatedResults(
    async ({ after, first }) => {
      const res = await listProjects(workspaceId.value, {
        after,
        first,
        include_membership_info: true,
        only_parents: true,
        order_by: ['name', 'id'],
      })

      if (!res.ok) {
        if (res.error.code === 'not_found') {
          router.push({
            name: 'ErrorPage',
            query: {
              title: 'Workspace not found',
              message: 'We could not find the page you’re looking for.',
            },
          })
        }
      }

      return res
    },
    (backendProject) => backendProject,
    (projects) => {
      projects.forEach((project) => {
        if (!project.membership_info) {
          return
        }
        permissionsStore.allProjectPermissions[project.id] = serializePermissions(
          project.membership_info,
        )
      })
      alphabeticalProjects.value = projects.map<Project>((p) => ({
        id: p.id,
        name: p.name,
        workspaceId: p.workspace_id,
        propertyCount: p.properties.length,
        coverImageUrls: p.cover_image_urls,
        updatedAt: p.updated_at,
      }))
    },
    20,
  )

  const projectsStore = useProjects()
  const billingStore = useBilling()
  // Clear the projects and load new projects when the workspace changes
  watch(
    () => workspaceId.value,
    async () => {
      projectsStore.setProjects([])
      loadingState.value = 'loading'
      await loadAllProjects()
      // Limit to 10 outstanding requests at a time
      const limit = pLimit(10)
      await Promise.all(
        projectsStore.projects.map((project) =>
          limit(async () =>
            billingStore.loadProjectLimits({
              projectId: project.id,
              workspaceId: workspaceId.value,
            }),
          ),
        ),
      )
      loadingState.value = 'loaded'
    },
    { immediate: true },
  )

  /**
   * Watch as projects are added or removed from the store and update the paginated state accordingly.
   */
  watch(
    () => projectsStore.mainProjects,
    async (newProjects, oldProjects) => {
      const hasRemovedProjects = oldProjects.length > newProjects.length
      const newIds = newProjects.map((p) => p.id)
      const oldIds = oldProjects.map((p) => p?.id)
      if (hasRemovedProjects) {
        // If a project is removed from the store, remove it from the paginated state
        const removedIds = oldIds.filter((id) => !newIds.includes(id))
        removedIds.forEach((id) => {
          alphabeticalProjects.value = alphabeticalProjects.value.filter((p) => p?.id !== id)
        })

        return
      }

      const addedIds = newIds.filter((id) => !oldIds.includes(id))

      addedIds.every((id) =>
        billingStore.loadProjectLimits({
          projectId: id,
          workspaceId: workspaceId.value,
        }),
      )

      /**
       * If a project has been added to the Pinia store and we already have it here
       * then there is nothing to do.
       */
      if (
        addedIds.length > 0 &&
        addedIds.every((id) => alphabeticalProjects.value.some((p) => p?.id === id))
      ) {
        return
      }

      /**
       * If we are here then a project has either been added or updated.
       * In both cases:
       * 1. If we have loaded all projects we are able to reset the
       *    alphabetical list by sorting the store projects
       * 2. If we haven't loaded all projects, then we need to load
       *    the current range from the backend (because we don't know
       *    which position in the list the new project should have)
       */
      if (alphabeticalProjects.value.every(Boolean)) {
        // If all projects are loaded, we can just sort the projects
        // from the store
        alphabeticalProjects.value = projectsStore.mainProjects.toSorted((a, b) =>
          (a.name || PROJECT_NAME_FALLBACK).localeCompare(b.name || PROJECT_NAME_FALLBACK),
        )
        return
      }

      // There are unloaded projects, so we need to load the current
      // 'page' of alphabetically sorted projects from the backend.
      await loadAllProjects()
    },
  )

  // When we fetch new paginated projects, add them to the store
  watch(
    () => alphabeticalProjects.value,
    (projects) => {
      const definedProjects = projects.filter((p): p is Project => !!p)
      const projectIds = definedProjects.map((p) => p.id)
      const storeIds = projectsStore.projects.map((p) => p.id)
      if (projectIds.every((id, i) => id === storeIds[i])) {
        return
      }
      projectsStore.addProjects(definedProjects)
    },
  )

  return {
    sidebarProjects: alphabeticalProjects,
    loadingState,
  }
}
