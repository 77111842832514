import { isMac } from '@/shared/utils'

/**
 * Checks whether keyboard event is exactly cmdOrCtrl+a for select all action
 * Should be called on a keydown event before browser selects all text on the page.
 */
export function isSelectAllKeybinding(e: KeyboardEvent) {
  return (
    e.key.toLowerCase() === 'a' &&
    e.metaKey === isMac() &&
    e.ctrlKey === !isMac() &&
    !e.altKey &&
    !e.shiftKey
  )
}
