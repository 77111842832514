import { PropertyTool, PropertyType } from '@/backend/types'
import { IconName } from '@/uiKit/IconName'

export const TOOL_ICON: Record<PropertyTool, IconName> = {
  [PropertyTool.o1]: 'company-openai',
  [PropertyTool.o1_mini]: 'company-openai',
  [PropertyTool.gpt_3_5]: 'company-openai',
  [PropertyTool.gpt_4]: 'company-openai',
  [PropertyTool.gpt_4o]: 'company-openai',
  [PropertyTool.gpt_4o_mini]: 'company-openai',
  [PropertyTool.go]: 'company-v7',
  [PropertyTool.whisper]: 'company-openai',
  [PropertyTool.manual]: 'human-task',
  [PropertyTool.ocr]: 'ocr',
  [PropertyTool.dall_e_3]: 'company-openai',
  [PropertyTool.gemini_pro]: 'company-google',
  [PropertyTool.gemini_pro_vision]: 'company-google',
  [PropertyTool.gemini_1_5_pro]: 'company-google',
  [PropertyTool.gemini_1_5_flash]: 'company-google',
  [PropertyTool.file_split]: 'process',
  [PropertyTool.claude_3_opus]: 'company-anthropic',
  [PropertyTool.claude_3_5_sonnet]: 'company-anthropic',
  [PropertyTool.claude_3_sonnet]: 'company-anthropic',
  [PropertyTool.claude_3_haiku]: 'company-anthropic',
  [PropertyTool.claude_3_5_haiku]: 'company-anthropic',
  [PropertyTool.bing_search]: 'bing-logo',
  [PropertyTool.url_scrape]: 'link',
  [PropertyTool.code]: 'python-language',
  [PropertyTool.gpt_3_5_azure]: 'company-openai',
  [PropertyTool.gpt_4o_azure]: 'company-openai',
  [PropertyTool.aws_ocr]: 'ocr',
  [PropertyTool.imagen]: 'company-google',
  [PropertyTool.claude_3_5_sonnet_vertex_ai]: 'company-anthropic',
  [PropertyTool.claude_3_opus_vertex_ai]: 'company-anthropic',
  [PropertyTool.claude_3_haiku_vertex_ai]: 'company-anthropic',
  [PropertyTool.claude_3_5_haiku_vertex_ai]: 'company-anthropic',
  [PropertyTool.claude_3_sonnet_vertex_ai]: 'company-anthropic',
}

export const TYPE_ICON: Record<PropertyType, IconName> = {
  [PropertyType.text]: IconName.text,
  [PropertyType.file]: IconName.file,
  [PropertyType.file_collection]: IconName['multi-page-file'],
  [PropertyType.single_select]: IconName['single-select'],
  [PropertyType.multi_select]: IconName['multi-select'],
  [PropertyType.pdf]: IconName.search,
  [PropertyType.json]: IconName.json,
  [PropertyType.collection]: IconName.collection,
  [PropertyType.url]: IconName['link'],
  [PropertyType.user_select]: IconName['user'],
  [PropertyType.reference]: IconName['arrow-top-right'],
}
