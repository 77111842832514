<script setup lang="ts">
import { confirmFieldFileUpload } from '@/backend/confirmFieldFileUpload'
import { startFieldFileUpload } from '@/backend/startFieldFileUpload'
import { SUPPORTED_FILE_EXTENSIONS } from '@/backend/types'
import { uploadFile } from '@/backend/uploadFile'
import { toast } from '@/shared/toast'
import { useFileType } from '@/sharedComposables/useFileType'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useDropZone, useFileDialog } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import AudioFileContent from './AudioFileContent.vue'
import CsvFileContent from './CsvFileContent.vue'
import ImageFileContent from './ImageFileContent.vue'
import PdfFileContent from './PdfFileContent.vue'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'

const props = defineProps<{
  url?: string | null
  filename?: string | null
  workspaceId: string
  projectId: string
  entityId: string
  propertyId?: string
  readonly: boolean
  transcription: { text: string } | null
}>()

const emit = defineEmits<{
  (e: 'fileUploaded', url: string): void
  (e: 'fileClear'): void
}>()

const dropZoneRef = ref<HTMLElement>()

const fileType = useFileType(computed(() => props.filename))

const stagedFile = ref<File | null>(null)
const uploadProgress = ref(0)

const supportedMimeTypes = useSupportedMimeTypes()
const supportedFileExtensions = [...SUPPORTED_FILE_EXTENSIONS]

const startUpload = async (file: File) => {
  if (!props.propertyId) return
  if (!supportedMimeTypes.value.includes(file.type)) {
    toast.warning(
      [
        'The file you tried to upload is not supported, so the upload was skipped.',
        'We only support the following file extensions:',
        supportedFileExtensions.join(', ').concat('.'),
      ].join(' '),
    )
    return
  }

  stagedFile.value = file

  uploadProgress.value = 0.01

  const result = await startFieldFileUpload(
    props.workspaceId,
    props.projectId,
    props.entityId,
    props.propertyId,
    file.name,
  )

  if (!result.ok) {
    toast.error('Failed to fetch url to upload to')
    return
  }

  const uploadResult = await uploadFile(
    result.data.file_upload_url,
    file,
    (progress) => (uploadProgress.value = Math.min(0.99, progress)),
  )

  if (!uploadResult.ok) {
    toast.error('Failed to upload file to S3')
    return
  }

  const confirmResult = await confirmFieldFileUpload(result.data.confirm_upload_url)
  if (!confirmResult.ok) {
    toast.error('Failed to confirm upload of file')
    return
  }

  if (
    confirmResult.data.manual_value.value &&
    typeof confirmResult.data.manual_value.value === 'string'
  ) {
    emit('fileUploaded', confirmResult.data.manual_value.value)
  }

  uploadProgress.value = 1
  stagedFile.value = null
}

const {
  open,
  reset,
  files: dialogFiles,
} = useFileDialog({ accept: supportedMimeTypes.value.join(','), multiple: false })

watch(dialogFiles, () => dialogFiles.value?.[0] && startUpload(dialogFiles.value[0]))

// The files ref returned by the dropzone does not actually get changed by the composable
// we have to do it ourselves. However, using it means we can mock it in tests.
const { isOverDropZone, files: dropZoneFiles } = useDropZone(dropZoneRef, {
  // note: The composable has a dataTypes option, but it doesn't seem to work
  onDrop: (files) => {
    dropZoneFiles.value = files
  },
})

const openDialog = () => {
  // we need to clear previous dialog state so that a change event is fired if selecting the same file
  reset()
  open()
}

const isUploading = computed(() => 0 < uploadProgress.value && uploadProgress.value < 1)

watch(dropZoneFiles, () => {
  if ((dropZoneFiles.value?.length ?? 0) > 1) {
    toast.warning('Only one file can be uploaded at a time')
    dropZoneFiles.value = null
    return
  }
  if (dropZoneFiles.value?.[0]) {
    startUpload(dropZoneFiles.value[0])
  }
})

const uploadingFileName = computed(() => stagedFile.value?.name)

const clearFile = () => {
  dialogFiles.value = null
  dropZoneFiles.value = null
  emit('fileClear')
}

const pdfFileContentRef = ref<typeof PdfFileContent | null>(null)

defineExpose({
  download: (name: string) => {
    if (pdfFileContentRef.value) {
      pdfFileContentRef.value.download(name)
    }
  },
})
</script>

<template>
  <div
    ref="dropZoneRef"
    class="group relative flex size-full flex-row truncate text-md-13px-light text-text"
  >
    <div
      v-if="isUploading"
      class="absolute left-1/2 top-1/2 z-10 inline-flex h-5 -translate-x-1/2 -translate-y-1/2 items-center justify-center gap-1 truncate rounded-md bg-background-gray-subtlest px-1"
    >
      <CircularProgress
        class="shrink-0"
        size="sm"
        :value="uploadProgress"
      />
      <div class="shrink truncate text-text-subtle">{{ uploadingFileName }} uploading...</div>
      <div
        class="inline-flex size-3.5 items-center justify-center hover:cursor-pointer"
        data-test="file-upload-clear"
        @click.stop="clearFile"
      >
        <IconSprite icon="close" />
      </div>
    </div>

    <div
      v-if="isOverDropZone && !readonly"
      class="m-1 grid w-full place-items-center truncate rounded-corner-6 bg-background-blue-subtle text-sm-12px-default text-text-selected outline outline-2 outline-border-focused"
    >
      Drop to upload
    </div>

    <div
      v-else-if="!url && !readonly"
      class="m-1 grid w-full place-items-center truncate rounded-corner-6 border border-border-subtle text-sm-12px-default text-text-subtlest"
      @click="openDialog"
    >
      Add file or drag and drop
    </div>

    <PdfFileContent
      v-else-if="fileType === 'pdf' && url"
      ref="pdfFileContentRef"
      :file-path="url"
      :class="isUploading && 'opacity-10'"
    />

    <AudioFileContent
      v-else-if="fileType === 'audio' && url"
      :url="url"
      :staged-file-preview="stagedFile"
      :class="isUploading && 'opacity-10'"
      :transcription="transcription?.text || ''"
    />

    <ImageFileContent
      v-else-if="fileType === 'image' && url"
      :url="url"
      :staged-file-preview="stagedFile"
      :class="isUploading && 'opacity-10'"
    />

    <CsvFileContent
      v-else-if="fileType === 'csv' && url"
      :url="url"
      :class="isUploading && 'opacity-10'"
    />
  </div>
</template>
