<script setup lang="ts">
import WarningIllustration from '@/illustrations/mobile-warning.svg'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { useElementSize, useWindowSize } from '@vueuse/core'
import { computed, ref } from 'vue'

defineEmits<{
  (event: 'dismiss'): void
}>()

/**
 * Mobile devices overlay navigation controls above the content, and this
 * isn't accounted for by the CSS `bottom: Npx` rule. So we need to calculate
 * the position here. *
 */
const { height: screenHeight } = useWindowSize()
const buttonRef = ref<HTMLElement | null>(null)
const { height: buttonHeight } = useElementSize(buttonRef)
const buttonTop = computed(() => screenHeight.value - buttonHeight.value - 48)
</script>

<template>
  <div
    class="fixed right-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-surface-primary p-3"
  >
    <div class="flex flex-col items-center text-center">
      <WarningIllustration class="mb-6" />
      <h1 class="mb-1 text-lg-15px-bold text-text">V7 Go is not optimised for mobile</h1>
      <p class="mb-6 text-md-13px-light text-text-subtle">
        V7 Go is desktop-first for now. You can still access it on mobile, but we recommend you use
        a computer for the best experience.
      </p>
    </div>
    <DarwinButton
      ref="buttonRef"
      variant="neutral"
      class="absolute"
      :style="{ top: `${buttonTop}px` }"
      size="lg"
      rounded
      @click="$emit('dismiss')"
    >
      Access V7 Go anyway
    </DarwinButton>
  </div>
</template>
