<script setup lang="ts" generic="FilterType extends TextLikeFilter">
import IconSprite from '@/uiKit/IconSprite.vue'

import { computed, ref } from 'vue'
import { TYPE_ICON } from '../icons'
import FilterBarItemMatcher, { type ID } from './FilterItemMatcher.vue'
import FilterItemTextValue from './FilterItemTextValue.vue'
import {
  assertIsValidTextMatcher,
  type FilterableProperty,
  type MatcherName,
  type TextLikeFilter,
} from './types'

const props = defineProps<{
  filter: FilterType
  property: FilterableProperty
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', filter: FilterType): void
}>()

const onNewMatcher = (matchId: ID) => {
  assertIsValidTextMatcher(matchId)

  const newFilter: FilterType = {
    ...props.filter,
    matcher: {
      ...props.filter.matcher,
      name: matchId,
    },
  }
  emit('update', newFilter)
}

const onUpdate = (data: { caseSensitive: boolean; matcherName: MatcherName; values: string[] }) => {
  const newFilter: FilterType = {
    ...props.filter,
    matcher: {
      ...props.filter.matcher,
      case_sensitive: data.caseSensitive,
      name: data.matcherName as FilterType['matcher']['name'],
      values: data.values,
    },
  }
  emit('update', newFilter)
}

const isTextModalOpen = ref(false)

const truncatedValue = computed(() => {
  const maxLength = 12
  const value = props.filter.matcher.values[0]
  if (!value) return ''

  return value.length > maxLength
    ? `${value.slice(0, maxLength / 2)}...${value.slice(-maxLength / 2)}`
    : value
})
</script>

<template>
  <div
    class="flex h-6 shrink-0 cursor-default items-center rounded-corner-6 border border-border-subtle bg-surface-primary"
  >
    <div class="flex gap-0.5 px-1.5 py-1">
      <IconSprite
        class="text-icon-subtle"
        :icon="TYPE_ICON[property.type]"
      />
      <p class="text-sm-12px-default text-text-subtle">{{ property.name }}</p>
    </div>
    <FilterBarItemMatcher
      :matcher-name="filter.matcher.name"
      :values="filter.matcher.values"
      :type="property.type"
      @match="onNewMatcher"
    />
    <button
      class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      :class="{ 'bg-background-transparent-hovered': isTextModalOpen }"
      @click="isTextModalOpen = true"
    >
      "{{ truncatedValue }}"
    </button>
    <FilterItemTextValue
      :filter="filter"
      :is-open="isTextModalOpen"
      :property-name="property.name"
      @close="isTextModalOpen = false"
      @update="onUpdate"
    />
    <button
      class="cursor-pointer overflow-hidden rounded-corner-6 rounded-l-none p-1.5 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      aria-label="Remove filter"
      @click="$emit('delete')"
    >
      <IconSprite
        class="text-icon-subtle"
        icon="close"
        size="xs"
      />
    </button>
  </div>
</template>
