import { computed, type ComputedRef, type Ref } from 'vue'

type MeterColor<DefaultColor extends 'blue' | 'neutral'> = 'critical' | 'warning' | DefaultColor

/**
 * Meter elements across the app are coloured according to their usage. This composable provides
 * the color for the meter element based on the current and maximum values.
 */
export const useMeterColor = <DefaultColor extends 'blue' | 'neutral'>({
  max,
  current,
  defaultColor,
}: {
  max: Ref<number>
  current: Ref<number>
  defaultColor: DefaultColor
}): ComputedRef<MeterColor<DefaultColor>> => {
  return computed<MeterColor<DefaultColor>>(() => {
    if (current.value >= max.value) {
      return 'critical'
    }

    if (current.value / max.value > 0.75) {
      return 'warning'
    }

    return defaultColor
  })
}
