<script setup lang="ts">
import {
  assertIsValidSelectMatcher,
  type SelectOptionValueFilter,
} from '@/modules/Project/Filters/types'
import { TYPE_ICON } from '@/modules/Project/icons'
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/useProject'
import IconSprite from '@/uiKit/IconSprite.vue'

import { computed } from 'vue'
import FilterBarItemMatcher, { type ID } from './FilterItemMatcher.vue'
import FilterBarSelectItemValuePicker from './FilterItemSelectValuePicker.vue'

const props = defineProps<{
  filter: SelectOptionValueFilter
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', filter: SelectOptionValueFilter): void
}>()

const onNewMatcher = (matchId: ID) => {
  const name = matchId === 'property_not_set' ? 'property_none_of' : matchId
  assertIsValidSelectMatcher(name)

  const newFilter: SelectOptionValueFilter = {
    ...props.filter,
    matcher: {
      ...props.filter.matcher,
      // 'property_not_set' is not a valid backend filter matcher, so we swap it out for
      // 'property_none_of' with all options selected.
      name,
      values:
        matchId === 'property_not_set'
          ? props.property.config.options.map((option) => option.value) || []
          : props.filter.matcher.values,
    },
  }
  emit('update', newFilter)
}

const optionCount = computed<number | undefined>(() => props.property.config.options.length)
const hasSelectedIsNotSet = computed<boolean>(() => {
  return (
    'matcher' in props.filter &&
    props.filter.matcher.name === 'property_none_of' &&
    props.filter.matcher.values.length === optionCount.value
  )
})

const onNewValues = (values: string[]) => {
  const newFilter: SelectOptionValueFilter = {
    ...props.filter,
    matcher: { ...props.filter.matcher, values },
  }
  emit('update', newFilter)
}
</script>

<template>
  <div
    class="flex h-6 shrink-0 cursor-default items-center rounded-corner-6 border border-border-subtle bg-surface-primary"
  >
    <div class="flex gap-0.5 px-1.5 py-1">
      <IconSprite
        class="text-icon-subtle"
        :icon="TYPE_ICON[property.type]"
      />
      <p class="text-sm-12px-default text-text-subtle">{{ property.name }}</p>
    </div>
    <FilterBarItemMatcher
      :filter="filter"
      :matcher-name="filter.matcher.name"
      :values="filter.matcher.values"
      :type="property.type"
      :option-count="optionCount"
      @match="onNewMatcher"
    />
    <FilterBarSelectItemValuePicker
      v-if="!hasSelectedIsNotSet"
      :filter="filter"
      :property="property"
      @delete="$emit('delete')"
      @values="(values: string[]) => onNewValues(values)"
    />
    <button
      class="cursor-pointer overflow-hidden rounded-corner-6 rounded-l-none p-1.5 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      aria-label="Remove filter"
      @click="$emit('delete')"
    >
      <IconSprite
        class="text-icon-subtle"
        icon="close"
        size="xs"
      />
    </button>
  </div>
</template>
