import { get } from './client'
import type { BackendMeta } from './types'

export type ListImports = BackendMeta<'/api/workspaces/{workspace_id}/imports', 'get'>

export const listImports = ({ workspaceId }: { workspaceId: string }) => {
  return get<ListImports['queryParams'], ListImports['successResponse'], ListImports['path']>(
    `/api/workspaces/${workspaceId}/imports`,
    {
      limit: 100,
    },
  )
}
