import { getUserProjectPermissions } from '@/backend/getUserProjectPermissions'
import { getUserWorkspacePermissions } from '@/backend/getUserWorkspacePermissions'
import type {
  Condition,
  Permission as PermissionName,
  ResourceMember,
  ResourceRole,
} from '@/backend/types'
import type { PartialRecord } from '@/shared/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

/**
 * `true` - permission is granted.
 * `false` - permission is denied.
 * `Condition` - permission is granted if the condition is met.
 */
export type PermissionValue = boolean | Condition

export type PermissionMap = PartialRecord<PermissionName, PermissionValue>

export const serializePermissions = (
  backendPermissionsResponse: Pick<ResourceMember, 'conditions' | 'permissions'>,
): PermissionMap => {
  const permissions: PermissionMap = {}

  backendPermissionsResponse.permissions.forEach((permission) => {
    const matchingCondition = backendPermissionsResponse.conditions.find(
      (c) => c.permission === permission,
    )
    if (matchingCondition) {
      permissions[permission] = matchingCondition
    } else {
      permissions[permission] = true
    }
  })

  return permissions
}

/**
 * Store for managing the user's permissions, where permissions are scoped
 * at either the project or workspace level.
 */
export const usePermissionsStore = defineStore('permissions', () => {
  /** Map each project ID to the users permissions on it */
  const allProjectPermissions = ref<PartialRecord<string, PermissionMap>>({})

  /**
   * We can't use the project ID from the project store because of subprojects
   * - permissions only apply at the project root level, and the other project
   * stores don't have any concept of a root project.
   */
  const currentProjectId = ref<string | null>(null)

  const currentProjectPermissions = computed(() =>
    currentProjectId.value ? (allProjectPermissions.value[currentProjectId.value] ?? {}) : {},
  )

  const workspacePermissions = ref<PermissionMap>({})
  const workspaceRole = ref<ResourceRole>()
  const workspacePermissionsLoadingState = ref<'idle' | 'loading' | 'loaded' | 'errored'>('idle')

  const loadProjectPermissions = async ({
    projectId,
    userId,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
    userId: string
  }) => {
    const res = await getUserProjectPermissions({ workspaceId, projectId, userId })

    if (!res.ok) {
      throw new Error('Failed to load project permissions')
    }

    allProjectPermissions.value[projectId] = serializePermissions(res.data)
  }

  const loadWorkspacePermissions = async ({
    workspaceId,
    userId,
  }: {
    workspaceId: string
    userId: string
  }) => {
    allProjectPermissions.value = {}
    workspacePermissions.value = {}
    workspacePermissionsLoadingState.value = 'loading'
    const res = await getUserWorkspacePermissions({ workspaceId, userId })

    if (!res.ok) {
      workspacePermissionsLoadingState.value = 'errored'
      throw new Error('Failed to load workspace permissions')
    }

    workspacePermissionsLoadingState.value = 'loaded'
    workspaceRole.value = res.data.role
    workspacePermissions.value = serializePermissions(res.data)
  }

  return {
    allProjectPermissions,
    loadProjectPermissions,

    workspaceRole,
    workspacePermissions,
    loadWorkspacePermissions,
    workspacePermissionsLoadingState,

    currentProjectId,
    currentProjectPermissions,

    hasAskGoPermission: computed(
      () =>
        currentProjectPermissions.value.use_ask_go ??
        workspacePermissions.value.use_ask_go ??
        false,
    ),
    hasWorkspaceUpdatePermission: computed(
      () => workspacePermissions.value.update_workspaces ?? false,
    ),
    hasWorkspaceDeletePermission: computed(
      () => workspacePermissions.value.delete_workspaces ?? false,
    ),
  }
})
