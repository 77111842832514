import type { components } from '@/api'
import type { UserMeResponse } from '@/backend/types'
import { put } from './client'

export const PATH = '/api/users/me' as const

export type UpdateUserResponse = UserMeResponse
export type UpdateUserRequest = components['schemas']['Users.UserMeUpdateRequest']

export const updateUser = ({
  firstName,
  lastName,
  acceptedTermsAndConditions,
  marketingOptin,
  signupCompleted,
  showTutorials,
}: {
  firstName?: string
  lastName?: string
  acceptedTermsAndConditions?: true
  marketingOptin?: boolean
  signupCompleted?: true
  showTutorials?: boolean
}) =>
  put<UpdateUserRequest, UpdateUserResponse, typeof PATH>(PATH, {
    first_name: firstName,
    last_name: lastName,
    accepted_terms_and_conditions: acceptedTermsAndConditions,
    marketing_optin: marketingOptin,
    signup_completed: signupCompleted,
    show_tutorials: showTutorials,
  })
