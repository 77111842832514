import type { Node } from 'prosemirror-model'
import { Schema } from 'prosemirror-model'
import { mentionNodeSpec } from '../mentionables'

export const plaintextSchema = new Schema({
  nodes: {
    text: {
      group: 'inline',
    },
    mention: mentionNodeSpec,
    paragraph: {
      group: 'block',
      content: 'inline*',
      toDOM() {
        return ['p', 0]
      },
      parseDOM: [{ tag: 'p' }],
    },
    doc: { content: 'block*' },
  },
})

/**
 * Parses a plaintext string into a top-level ProseMirror
 * doc node.
 */
export const getPlaintextParser = (schema: Schema) => ({
  parse: (value: string): Node => {
    const paragraphs = value.split('\n').filter(Boolean)
    return schema.nodeFromJSON({
      type: 'doc',
      content: [
        ...paragraphs.map((text) => ({
          type: 'paragraph',
          content: [{ type: 'text', text: text }],
        })),
      ],
    })
  },
})

/**
 * Converts a ProseMirror doc node into a plaintext string.
 * The doc should follow the plaintext schema because the
 * serializer won't be able to convert other nodes to text.
 */
export const plaintextSerializer = {
  serialize: (node: Node): string => {
    let asString = ''

    if (node.type.name === 'text') {
      asString += node.text
    } else if (node.type.name === 'mention') {
      asString += `@<${node.attrs.id}>`
    } else {
      node.content.forEach((child) => {
        asString += plaintextSerializer.serialize(child)
      })
    }

    if (node.isBlock && node.type.name !== 'doc') {
      asString += '\n'
    }

    return asString
  },
}
