import { isHtmlElement } from '@/shared/utils'
import { useElementBounding, useIntervalFn } from '@vueuse/core'
import { computed, type ComputedRef } from 'vue'
import type { Coordinates } from './types'
import { useElement } from './useElement'

type UseElementCoordinatesOptions = {
  padding?: number
  override?: (args: {
    coords: Coordinates
    rect: ReturnType<typeof useElementBounding>
  }) => Coordinates
}

const defaults = {
  padding: 0,
  override({ coords }) {
    return coords
  },
} satisfies Partial<UseElementCoordinatesOptions>

export function useElementCoordinates(
  selector: string,
  options: UseElementCoordinatesOptions = {},
): ComputedRef<Coordinates | undefined> {
  const { padding, override } = { ...defaults, ...options }
  const element = useElement(selector)
  const rect = useElementBounding(element)
  useIntervalFn(() => {
    rect.update()
  }, 500)

  return computed(() => {
    if (!isHtmlElement(element.value)) return
    const computedStyle = getComputedStyle(element.value)

    return override({
      coords: {
        x1: rect.x.value - padding,
        x2: rect.x.value + rect.width.value + padding,
        y1: rect.y.value - padding,
        y2: rect.y.value + rect.height.value + padding,
        radius:
          computedStyle.borderRadius === '0px' ? undefined : parseFloat(computedStyle.borderRadius),
      },
      rect,
    })
  })
}
