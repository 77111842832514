<script setup lang="ts">
import { watch } from 'vue'

import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import FilterBarAddFilter from './Filters/FilterBarAddFilter.vue'
import { useLocalFilter } from './Filters/useLocalFilter'
import { useFilters } from './useFilters'
import { useProject } from './useProject'

const projectStore = useProject()
const filtersStore = useFilters()

const { localFilter } = useLocalFilter()

// If local filter is set to a value !== undefined, set the current filter to that value
// Also, if the active view changes, set the current filter to the local filter value for that view
watch(
  () => [localFilter.value, projectStore.activeView] as const,
  ([filter, view]) => {
    if (!view) return
    filtersStore.setCurrentFilter(filter[view.id])
  },
  { immediate: true },
)
</script>

<template>
  <FilterBarAddFilter v-if="!filtersStore.currentFilter?.filters?.length">
    <template #trigger="{ isOpen, onClick }">
      <DarwinButton
        size="sm"
        aria-label="Add a filter"
        variant="outline"
        icon="filter"
        :active="isOpen"
        @click="onClick"
      >
        <template #leading-icon>
          <IconSprite icon="filter" />
        </template>
        Filter
      </DarwinButton>
    </template>
  </FilterBarAddFilter>
</template>
