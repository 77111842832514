<script setup lang="ts">
import CheckBox from './CheckBox.vue'
import type { IconName } from './IconName'
import ListMenuItem from './ListMenuItem.vue'

defineProps<{
  label: string
  icon?: IconName
  iconColor?: string
  active: boolean
  checked: boolean
  indeterminate?: boolean
  alwaysVisible?: boolean
}>()

const emit = defineEmits<{
  (e: 'click:checkbox', value: boolean, event: Event): void
}>()

const onCheckboxChange = (checked: boolean, event: Event) => {
  emit('click:checkbox', checked, event)
}
</script>

<template>
  <ListMenuItem v-bind="$props">
    <template #prefix>
      <CheckBox
        :checked="checked"
        :indeterminate="indeterminate"
        :class="[
          !alwaysVisible && (checked || indeterminate || active) ? 'opacity-100' : 'opacity-0',
          alwaysVisible && 'opacity-100',
        ]"
        class="transition"
        @change="onCheckboxChange"
      />
      <slot name="prefix" />
    </template>
    <slot />
    <template #suffix>
      <slot name="suffix" />
    </template>
  </ListMenuItem>
</template>
