import { toast } from '@/shared/toast'
import { useRouter } from 'vue-router'

import { cloneProject as cloneProjectApi } from '@/backend/cloneProject'
import { useRefreshUsage } from '@/modules/Billing/useRefreshUsage'
import { serializeProperty, serializeView, useProject } from '@/modules/Project/useProject'
import { useProjects } from '@/modules/Projects/useProjects'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useTemplates } from './useTemplates'

/*
 * This composable can be used to clone a project.
 * It uses the `cloneProject` be api to clone a project and then adds it to the projects store.
 * It also captures an analytics event when a the project is cloned.
 * @param workspaceId - The id of the workspace in which the project is to be created.
 * @returns - id of the newly created project or null (when the creation failed)
 */
export function useCloneProject() {
  const router = useRouter()
  const projectStore = useProject()
  const projectsStore = useProjects()
  const { captureAnalyticsEvent } = useAnalytics()
  const { refreshUsageNTimes } = useRefreshUsage()
  const templateStore = useTemplates()

  const cloneProject = async (
    templateId: string,
    workspaceId: string,
    as: 'empty' | 'template',
  ) => {
    const template = templateStore.templates.find((t) => t.id === templateId)
    const result = await cloneProjectApi({
      name: template?.name?.replace(/\[.*\]/, '') ?? null,
      workspaceId,
      templateId,
    })
    if (!result.ok) {
      toast.error(result.error.message)
      return null
    }

    if (as === 'empty') {
      captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_CREATED)
    } else if (as === 'template') {
      captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_CREATED_FROM_TEMPLATE, {
        workspaceId,
        templateId,
        templateName: result.data.name,
      })
    }

    projectsStore.addProject({
      id: result.data.id,
      name: result.data.name,
      workspaceId: workspaceId,
      updatedAt: '2021-10-06T14:23:13.000000Z',
      propertyCount: result.data.properties.length,
      coverImageUrls: result.data.cover_image_urls,
      views: result.data.views.map(serializeView),
    })

    projectStore.setProjectId(result.data.id)
    projectStore.setProperties(result.data.properties.map(serializeProperty))
    projectStore.projectLoaded = true
    projectStore.setActiveViewId(result.data.views[0].id)
    projectStore.setViews(result.data.views[0].id ?? null, result.data.views.map(serializeView))

    refreshUsageNTimes(3)

    await router.push({
      name: 'WorkspaceProject',
      params: { workspaceId, projectId: result.data.id },
    })
  }

  return { cloneProject }
}
