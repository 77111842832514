<script setup lang="ts">
// Figma: https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?type=design&node-id=4797-621286&mode=design&t=3mwPi9xYS0BXpc7J-0
import { PropertyType } from '@/backend/types'
import type { PropertyConfig } from '@/backend/updateProperty'
import { useFieldToolOptions } from '@/modules/Project/useFieldToolOptions'
import { useFieldTypeOptions } from '@/modules/Project/useFieldTypeOptions'
import {
  FIELD_TOOL_LABELS,
  FIELD_TOOL_OUTPUT_TYPES,
  FIELD_TYPES_LABELS,
} from '@/modules/WorkspaceSettings/propertyConfig'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import LabelValueMenuItem from '@/sharedComponents/LabelValueMenuItem.vue'
import MentionableTextInput from '@/sharedComponents/MentionableTextInput.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { getModalDialogAncestor } from '@/uiKit/ModalDialog.vue'
import PopOver from '@/uiKit/PopOver.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'

import { usePopoverStore } from '@/sharedComposables/popoverStore'
import { type OffsetOptions } from '@floating-ui/vue'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import { computed, onMounted, ref, useId, watch } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useLibraryStore } from '../Library/libraryStore'
import { useProjects } from '../Projects/useProjects'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import CollectionTypeProperties from './CollectionTypeProperties.vue'
import { HORIZONTAL_OFFSET, VERTICAL_OFFSET } from './constants'
import GroundingTooltip from './GroundingTooltip.vue'
import { TOOL_ICON, TYPE_ICON } from './icons'
import ModelInputBadge from './ModelInputBadge.vue'
import ModelInputMenu from './ModelInputMenu.vue'
import ProjectBadge from './ProjectBadge.vue'
import ProjectRelationMenu from './ProjectRelationMenu.vue'
import PropertyTypeTooltip from './PropertyTypeTooltip.vue'
import RelationEntityLimit from './RelationEntityLimit.vue'
import SelectTypeOptions from './SelectTypeOptions.vue'
import ToolMenu from './ToolMenu.vue'
import { useFilters } from './useFilters'
import { useGroundingToggle } from './useGroundingToggle'
import { useMentionableInputs } from './useMentionableInputs'
import { useModelInputs } from './useModelInputs'
import { usePinnedColumn } from './usePinnedColumn'
import { type Property, useProject } from './useProject'
import { useProperty } from './useProperty'
import { usePropertyMeta } from './usePropertyMeta'
import { useRelatedProject } from './useRelatedProject'
import UserListMenu from './UserListMenu.vue'
import { useSorting } from './useSorting'

type NotReferenceProperty = Exclude<Property, Property<'reference'>>

export type UpdatedProperty = Pick<
  NotReferenceProperty,
  'name' | 'type' | 'tool' | 'isGrounded' | 'inputs' | 'description'
>

const emit = defineEmits<{
  (e: 'delete' | 'hide' | 'close'): void
  (
    e: 'update',
    v: { updatedProperty: Property; config: PropertyConfig; keepMenuOpen?: boolean },
  ): void
  (e: 'reprocess', v: { updatedProperty: Property; config: PropertyConfig }): void
  (name: 'click:outside', event: PointerEvent): void
}>()

const welcomeTour = useWelcomeTour()
const propertyStore = useProperty()
const pinnedColumn = usePinnedColumn()
const projectStore = useProject()
const projectsStore = useProjects()
const permissionStore = usePermissionsStore()

const project = computed(() =>
  projectStore.projectId ? projectsStore.getProjectById(projectStore.projectId) : null,
)

const fieldTypesOptions = useFieldTypeOptions({
  hasParentProperty: computed(() => !!project.value?.parentProperty),
})
const fieldToolOptions = useFieldToolOptions()

const updateValue = (keepMenuOpen?: boolean) => {
  if (!propertyStore.editedProperty) {
    throw new Error('Store has no editedProperty')
  }

  emit('update', {
    updatedProperty: propertyStore.editedProperty,
    keepMenuOpen,
    config: {
      removeOptions: propertyStore.removeOptions,
      upsertOptions: propertyStore.upsertOptions,
      upsertCollectionProperties: propertyStore.upsertCollectionProperties,
      removeCollectionProperties: propertyStore.removeCollectionProperties,
      defaultOption: isSelectProperty(propertyStore.editedProperty)
        ? propertyStore.editedProperty.config.defaultOption
        : undefined,
    },
  })
}

// The dropdowns use <Teleport> to render outside of the component, so we need
// to store refs to each to ensure that we only close the menu when the user
// clicks outside ALL of the menus.
const rootElement = ref()
const toolSubmenu = ref()
const typeSubmenu = ref()
const inputSubmenu = ref()

const reprocessColumnConfirmationOpen = ref(false)

const toolSubmenuIsOpen = ref(false)
const typeSubmenuIsOpen = ref(false)
const inputSubmenuIsOpen = ref(false)
const projectRelationSubmenuIsOpen = ref(false)
const someSubmenuIsOpen = computed(
  () =>
    toolSubmenuIsOpen.value ||
    typeSubmenuIsOpen.value ||
    inputSubmenuIsOpen.value ||
    projectRelationSubmenuIsOpen.value,
)

const canCloseCollectionMenu = computed(() => {
  return (
    PropertyType.collection !== propertyStore.editedProperty?.type ||
    (PropertyType.collection === propertyStore.editedProperty?.type &&
      propertyStore.editedProperty?.tool === 'manual') ||
    propertyStore.visibleProperties.length
  )
})

/** The user must have selected a related project in order to save a reference property. */
const canSaveReferenceProperty = computed(() => {
  if (propertyStore.editedProperty?.type !== 'reference') {
    return true
  }

  return !!propertyStore.editedProperty.config.projectId
})
const popoverStore = usePopoverStore()
const relatedProjectMenuItemId = useId()
watch(canSaveReferenceProperty, (canSave) => {
  if (canSave && popoverStore.popover?.targetSelector === `#${relatedProjectMenuItemId}`) {
    popoverStore.setPopover(null)
  }
})

const collectionError = ref(false)

const typeTooltipIsOpen = ref<Record<PropertyType, boolean>>({
  url: false,
  single_select: false,
  multi_select: false,
  user_select: false,
  text: false,
  file: false,
  json: false,
  pdf: false,
  collection: false,
  file_collection: false,
  reference: false,
})

watch(canCloseCollectionMenu, () => {
  if (canCloseCollectionMenu.value) collectionError.value = false
})

onClickOutside(
  rootElement,
  (e) => {
    // Keep the menu open if clicking inside a ModalDialog component
    if (getModalDialogAncestor(e)) {
      return
    }

    if (welcomeTour.status === 'IN_PROGRESS') return
    // If one of the submenus is open, a click outside of the entire nested
    // menu structure should close the submenu, but not the entire menu.
    if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
      toolSubmenuIsOpen.value = false
      typeSubmenuIsOpen.value = false
      inputSubmenuIsOpen.value = false
      projectRelationSubmenuIsOpen.value = false
      reprocessColumnConfirmationOpen.value = false
      e.stopPropagation()
      return
    }

    if (!canCloseCollectionMenu.value) {
      collectionError.value = true
      return
    }

    if (!canSaveReferenceProperty.value) {
      popoverStore.setPopover({
        text: 'Please select a source',
        placement: {
          allowedPlacements: ['left', 'right'],
        },
        targetSelector: `#${relatedProjectMenuItemId}`,
        variant: 'app',
      })
      return
    }

    if (
      propertyStore.editedProperty?.type === 'reference' &&
      !propertyStore.editedProperty.config.projectId
    ) {
      projectRelationSubmenuIsOpen.value = true
      return
    }

    // Save the unsaved changes and close the dialog
    if (propertyStore.isDirty) {
      updateValue(false)
      return
    }

    emit('click:outside', e)
  },
  {
    ignore: [
      toolSubmenu,
      typeSubmenu,
      inputSubmenu,
      '#color-preset-menu',
      '#reprocess-property-confirmation',
    ],
  },
)

const { inputIdOptions, selectedInputIdOptions } = useModelInputs()

onKeyStroke('Escape', () => {
  if (welcomeTour.status === 'IN_PROGRESS') return
  if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
    toolSubmenuIsOpen.value = false
    typeSubmenuIsOpen.value = false
    inputSubmenuIsOpen.value = false
    projectRelationSubmenuIsOpen.value = false
    reprocessColumnConfirmationOpen.value = false
    return
  }

  if (!canCloseCollectionMenu.value) {
    collectionError.value = true
    return
  }

  emit('close')
})

const onOpenSidebar = () => {
  propertyStore.sidebarIsOpen = true
}

const onDelete = () => {
  emit('delete')
}

const validTools = computed(() =>
  fieldToolOptions.value.filter(
    (t) =>
      propertyStore.editedProperty &&
      FIELD_TOOL_OUTPUT_TYPES[t.tool].includes(propertyStore.editedProperty.type),
  ),
)

const submenuOffset: OffsetOptions = ({ rects }) => ({
  alignmentAxis: -rects.reference.height + VERTICAL_OFFSET,
  mainAxis: HORIZONTAL_OFFSET,
})

const TYPE_SUBMENU_ID = 'property-configuration-type-submenu'
const TOOL_SUBMENU_ID = 'property-configuration-tool-submenu'
const INPUT_SUBMENU_ID = 'property-configuration-input-submenu'

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_MENU)
})

const onRecomputeStaleFields = () => {
  reprocessColumnConfirmationOpen.value = true

  captureAnalyticsEvent(ANALYTICS_EVENT.STALE_FIELDS_RECOMPUTED)
}

const { isRecomputeEnabled } = usePropertyMeta()
const isPropertyEditorOpensInSidebar = useFeatureFlags(FeatureFlag.AB_PROPERTY_EDITOR)

const show = computed(() => {
  return {
    group: {
      selectOptions:
        PropertyType.single_select === propertyStore.editedProperty?.type ||
        PropertyType.multi_select === propertyStore.editedProperty?.type,
      typeProperties:
        PropertyType.collection === propertyStore.editedProperty?.type &&
        propertyStore.editedProperty?.tool !== 'manual',
      recompute:
        isRecomputeEnabled.value && permissionStore.currentProjectPermissions.recalculate_entities,
      userSelect: PropertyType.user_select === propertyStore.editedProperty?.type,
    },
  }
})

const libraryStore = useLibraryStore()
const onOpenLibrary = () => {
  libraryStore.dialogIsOpen = true
  emit('close')
}

const onToggleLibraryItem = (itemId: string) => {
  assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

  const type = libraryStore.getItemType(itemId)
  const propertyId =
    type === 'file' ? libraryStore.library.fileProperty.id : libraryStore.library.textProperty.id

  propertyStore.toggleInputId({ propertyId, entityId: itemId })

  if (selectedInputIdOptions.value.some((o) => o.id === itemId)) {
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_INPUT_IN_PROPERTY)
  }
}

const selectedFilterId = ref<string | null>(null)

const onOpenFilterMenu = (property: Property) => {
  selectedFilterId.value = property.id
}

const onInputSubmenuChange = ($event: boolean) => {
  inputSubmenuIsOpen.value = $event
  if (!$event) {
    selectedFilterId.value = null
  }
}

const { onAddMention, onRemoveMention } = useMentionableInputs()

const { isGroundingEnabled, setGroundingState, areConditionsFilled } = useGroundingToggle()

// this will adjust the max height to the menu withouth event listeners. The use of constant is intentional
const MENU_HEIGHT_OFFSET = 196
const menuStyle = ref('max-height: calc(100vh - ' + MENU_HEIGHT_OFFSET + 'px)')

const sortingStore = useSorting()
const filterStore = useFilters()
function sort(direction: 'asc' | 'desc') {
  const slug = propertyStore.property?.slug
  if (!slug) return

  if (sortingStore.hasRule(slug)) {
    sortingStore.updateRuleDirection(slug, direction)
  } else {
    sortingStore.addRule(slug, direction)
  }
  filterStore.viewFilters = true
}

const onReprocess = () => {
  if (!propertyStore.editedProperty) {
    throw new Error('Store has no editedProperty')
  }

  emit('reprocess', {
    updatedProperty: propertyStore.editedProperty,
    config: {
      removeOptions: propertyStore.removeOptions,
      upsertOptions: propertyStore.upsertOptions,
      upsertCollectionProperties: propertyStore.upsertCollectionProperties,
      removeCollectionProperties: propertyStore.removeCollectionProperties,
      defaultOption: isSelectProperty(propertyStore.editedProperty)
        ? propertyStore.editedProperty.config.defaultOption
        : undefined,
    },
  })
}

const { onChangeRelatedProject, relatedProject, relatedProjectCoverImage } = useRelatedProject()
</script>

<template>
  <ListMenuContainer
    v-if="propertyStore.editedProperty"
    ref="rootElement"
    class="no-scrollbar flex min-w-[360px] max-w-[400px] origin-top flex-col overflow-y-scroll"
    :style="menuStyle"
    v-bind="$attrs"
  >
    <div
      class="contents"
      role="form"
    >
      <div class="w-full p-1">
        <InlineTextField
          class="w-full"
          placeholder="Name"
          size="sm"
          :value="propertyStore.editedProperty.name"
          aria-label="Name"
          @input="propertyStore.editedProperty.name = $event"
          @submit="updateValue(true)"
        />
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <PopupMenu
          class="w-full"
          :placement="'right-start'"
          :offset="submenuOffset"
          :open="typeSubmenuIsOpen"
          @change:open="typeSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="type-combobox"
              label="Type"
              :class="{ 'bg-background-transparent-hovered': typeSubmenuIsOpen }"
              :value="FIELD_TYPES_LABELS[propertyStore.editedProperty?.type]"
              :icon="TYPE_ICON[propertyStore.editedProperty?.type]"
              :submenu="{
                isOpen: typeSubmenuIsOpen,
                id: TYPE_SUBMENU_ID,
              }"
            />
          </template>
          <template #dropdown>
            <ListMenu
              :id="TYPE_SUBMENU_ID"
              ref="typeSubmenu"
              class="w-[240px]"
              role="listbox"
              aria-label="Select a field type"
              :items="fieldTypesOptions"
              :initial-active-item-predicate="
                (item) => item.data.type === propertyStore.editedProperty?.type
              "
            >
              <template #item="{ item, active, key, setActiveItem }">
                <ListMenuItem
                  :id="`field-type-menu-item-${key}`"
                  :label="item.data.label"
                  :active="active"
                  :aria-selected="active"
                  default-hover-disabled
                  title=""
                  :icon="TYPE_ICON[item.data.type]"
                  @mouseenter="typeTooltipIsOpen[item.data.type] = true"
                  @mouseleave="typeTooltipIsOpen[item.data.type] = false"
                  @mousemove="setActiveItem(key)"
                  @select="
                    () => {
                      if (!propertyStore.editedProperty) return
                      propertyStore.editedProperty.type = item.data.type
                      typeSubmenuIsOpen = false
                      if (
                        !validTools.map((t) => t.tool).includes(propertyStore.editedProperty.tool)
                      ) {
                        propertyStore.editedProperty.tool = 'manual'
                      }
                    }
                  "
                >
                  <template #suffix>
                    <PopOver
                      :open="typeTooltipIsOpen[item.data.type]"
                      :placement="{ allowedPlacements: ['right-start', 'left-start'] }"
                      :target-selector="`#field-type-menu-item-${key}`"
                    >
                      <template #content>
                        <PropertyTypeTooltip :type="item.data.type" />
                      </template>
                    </PopOver>
                  </template>
                  <template #prefix>
                    <div class="flex size-5 items-center justify-center">
                      <IconSprite
                        :icon="
                          item.data.type === propertyStore.editedProperty?.type ? 'check' : 'blank'
                        "
                        class="text-icon-subtle"
                      />
                    </div>
                  </template>
                </ListMenuItem>
              </template>
            </ListMenu>
          </template>
        </PopupMenu>
        <template v-if="propertyStore.editedProperty.type === 'reference'">
          <PopupMenu
            placement="right-start"
            :offset="submenuOffset"
            class="w-full"
            :open="projectRelationSubmenuIsOpen && !!propertyStore.editedProperty.isOptimistic"
            @change:open="
              propertyStore.editedProperty.isOptimistic && (projectRelationSubmenuIsOpen = $event)
            "
          >
            <template #trigger>
              <LabelValueMenuItem
                :id="relatedProjectMenuItemId"
                label="Related to"
                :class="{ 'bg-background-transparent-hovered': toolSubmenuIsOpen }"
                :submenu="
                  propertyStore.editedProperty.isOptimistic
                    ? {
                        isOpen: toolSubmenuIsOpen,
                        id: 'related-project-dropdown',
                      }
                    : null
                "
                placeholder="Select a source"
                :readonly="!propertyStore.editedProperty.isOptimistic"
              >
                <ProjectBadge
                  v-if="relatedProject"
                  :name="relatedProject.name"
                  :cover-image-url="relatedProjectCoverImage"
                  :readonly="!propertyStore.editedProperty.isOptimistic"
                />
                <div
                  v-else
                  class="text-sm-12px-default text-text-subtlest"
                >
                  Select a source
                </div>
              </LabelValueMenuItem>
            </template>
            <template #dropdown>
              <ProjectRelationMenu
                :value="propertyStore.editedProperty.config.projectId"
                @change="
                  (id) => {
                    onChangeRelatedProject(id)
                    projectRelationSubmenuIsOpen = false
                  }
                "
              />
            </template>
          </PopupMenu>
          <LabelValueMenuItem
            id="reference-property-limit"
            label="Limit"
            class="!py-0.5"
            :submenu="null"
          >
            <template #label="{ labelId }">
              <div
                :id="labelId"
                class="grow text-text"
              >
                Limit
              </div>
            </template>
            <template #default="{ labelId }">
              <RelationEntityLimit
                v-model="propertyStore.editedProperty.config.entityLimit"
                :label-id="labelId"
              />
            </template>
          </LabelValueMenuItem>
        </template>
        <PopupMenu
          placement="right-start"
          :offset="submenuOffset"
          class="w-full"
          :open="toolSubmenuIsOpen"
          @change:open="toolSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="tool-combobox"
              label="Tool"
              :class="{ 'bg-background-transparent-hovered': toolSubmenuIsOpen }"
              :value="FIELD_TOOL_LABELS[propertyStore.editedProperty.tool]"
              :icon="TOOL_ICON[propertyStore.editedProperty.tool]"
              :submenu="{
                isOpen: toolSubmenuIsOpen,
                id: TOOL_SUBMENU_ID,
              }"
            />
          </template>
          <template #dropdown>
            <ToolMenu
              :id="TOOL_SUBMENU_ID"
              ref="toolSubmenu"
              :active-tool="propertyStore.editedProperty.tool"
              :active-type="propertyStore.editedProperty?.type"
              class="min-w-[240px]"
              :grounded="isGroundingEnabled"
              @change="
                (value) => {
                  if (!propertyStore.editedProperty) return
                  propertyStore.editedProperty.tool = value
                  toolSubmenuIsOpen = false
                }
              "
            />
          </template>
        </PopupMenu>
        <LabelValueMenuItem
          id="grounding-toggle-listitem"
          label="AI citations"
          :submenu="null"
        >
          <ToolTip
            :disabled="areConditionsFilled"
            :placement="{ allowedPlacements: ['top-end'] }"
          >
            <SwitchButton
              :checked="isGroundingEnabled"
              size="sm"
              color="blue"
              aria-label="AI citations"
              :disabled="!areConditionsFilled"
              @change="setGroundingState"
            />
            <template #content><GroundingTooltip /></template>
          </ToolTip>
        </LabelValueMenuItem>
        <PopupMenu
          v-if="propertyStore.editedProperty?.tool !== 'manual'"
          placement="right-start"
          :offset="{ alignmentAxis: -2, mainAxis: 4 }"
          class="w-full"
          cover-trigger
          :open="inputSubmenuIsOpen"
          :menu-attrs="{ class: 'z-10' }"
          @change:open="onInputSubmenuChange"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="input-combobox"
              ref="inputTrigger"
              label="Inputs"
              alignment="top"
              :class="{
                'bg-background-transparent-hovered': inputSubmenuIsOpen,
              }"
              :submenu="{
                isOpen: inputSubmenuIsOpen,
                id: INPUT_SUBMENU_ID,
              }"
            >
              <div
                v-if="selectedInputIdOptions.length > 0"
                class="flex w-full flex-wrap justify-end gap-1"
              >
                <ModelInputBadge
                  v-for="o in selectedInputIdOptions"
                  :key="o.id"
                  :input="o.data"
                  @click:filter="o.data.group === 'Properties' && onOpenFilterMenu(o.data)"
                />
              </div>
              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select inputs
              </div>
            </LabelValueMenuItem>
          </template>
          <template #dropdown>
            <ModelInputMenu
              :items="inputIdOptions"
              :open-with-filter-property-id="selectedFilterId"
              :selected-inputs="propertyStore.editedProperty.inputs"
              @toggle:property="propertyStore.toggleInputId"
              @toggle:library-item="onToggleLibraryItem"
              @open:library="onOpenLibrary"
              @update:filters="propertyStore.setInput"
            />
          </template>
        </PopupMenu>
        <div
          v-if="
            !['manual', 'ocr', 'whisper', 'bing_search'].includes(propertyStore.editedProperty.tool)
          "
          class="w-full py-0.5"
        >
          <MentionableTextInput
            :code-style="propertyStore.editedProperty?.tool === 'code'"
            :value="propertyStore.editedProperty?.description || ''"
            :items="
              inputIdOptions.map((option) => ({
                ...option.data,
                icon: option.data.group === 'Properties' ? TYPE_ICON[option.data.type] : undefined,
              }))
            "
            @update:text="propertyStore.editedProperty.description = $event"
            @add:mention="onAddMention"
            @remove:mention="onRemoveMention"
            @open:library="onOpenLibrary"
          />
        </div>
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div
        v-if="Object.values(show.group).some(Boolean)"
        class="flex w-full flex-col p-1"
      >
        <UserListMenu v-if="show.group.userSelect">
          <template #trigger="{ isOpen, submenuId, selectedUsers }">
            <LabelValueMenuItem
              id="user-combobox"
              ref="userTrigger"
              label="Users"
              alignment="top"
              :class="{
                'bg-background-transparent-hovered': isOpen,
              }"
              :submenu="{
                isOpen,
                id: submenuId,
              }"
            >
              <div
                v-if="selectedUsers.length > 0"
                class="flex w-full flex-wrap justify-end gap-1 self-start"
              >
                <BadgeItem
                  v-for="o in selectedUsers"
                  :key="o.value"
                  :label="o.value"
                  variant="selected"
                  size="sm"
                  leading-icon="user"
                />
              </div>
              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select users
              </div>
            </LabelValueMenuItem>
          </template>
        </UserListMenu>
        <SelectTypeOptions v-if="show.group.selectOptions" />
        <CollectionTypeProperties
          v-if="show.group.typeProperties"
          :error="collectionError"
        />
        <DarwinButton
          v-if="show.group.recompute"
          variant="neutral"
          size="sm"
          class="w-full"
          type="button"
          @click="onRecomputeStaleFields"
        >
          <template #leading-icon>
            <IconSprite icon="process" />
          </template>
          Recompute all stale fields
        </DarwinButton>
      </div>

      <DividerLine
        v-if="Object.values(show.group).some(Boolean)"
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <ListMenuItem
          icon="arrow-top"
          label="Sort ascending"
          role="button"
          @select="sort('asc')"
        />
        <ListMenuItem
          icon="arrow-bottom"
          label="Sort descending"
          role="button"
          @select="sort('desc')"
        />
      </div>

      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <ListMenuItem
          v-if="pinnedColumn.canPin"
          :icon="pinnedColumn.isPinned ? 'pin-fill' : 'pin'"
          :label="pinnedColumn.isPinned ? 'Unpin' : 'Pin'"
          role="button"
          @select="pinnedColumn.togglePin"
        />

        <ToolTip
          :arrow="true"
          :placement="{ allowedPlacements: ['right', 'left'] }"
          title="Automations, API, property IDs, and other advanced settings are here."
        >
          <ListMenuItem
            icon="settings"
            label="More settings"
            role="button"
            @select="onOpenSidebar"
          />
        </ToolTip>
        <ListMenuItem
          v-if="!isPropertyEditorOpensInSidebar"
          icon="hide"
          label="Hide from view"
          role="button"
          @select="$emit('hide')"
        />
        <ListMenuItem
          icon="trash"
          label="Delete Property"
          critical
          role="button"
          @select="onDelete"
        />
      </div>
    </div>
  </ListMenuContainer>
  <ConfirmationDialog
    id="reprocess-property-confirmation"
    :open="reprocessColumnConfirmationOpen"
    title="Recompute all stale fields for this property?"
    description="This may take a while and become quite costly."
    variant="black"
    confirm-text="Confirm"
    @confirm="onReprocess"
    @close="reprocessColumnConfirmationOpen = false"
  />
</template>
