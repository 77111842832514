import { get } from './client'
import type { BackendMeta } from './types'

export type GetLibrary = BackendMeta<'/api/workspaces/{workspace_id}/library', 'get'>

/**
 * Get a Library project for a workspace
 * @returns a workspace-wide Library project. This project always exists
 */
export const getLibrary = async (workspaceId: string) =>
  get<undefined, GetLibrary['successResponse'], GetLibrary['path']>(
    `/api/workspaces/${workspaceId}/library`,
    undefined,
  )
