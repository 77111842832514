import '@/assets/iconFont/go-icon.css'
import '@/assets/main.scss'
import type { HttpHandler } from 'msw'
import { HttpResponse, http } from 'msw'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { auth0 } from '@/auth0'
import posthog from '@/shared/infrastructure/analytics'
import { initTelemetry } from '@/shared/infrastructure/telemetry'

import App from './modules/App/App.vue'
import { router } from './router'

const app = createApp(App)

initTelemetry(app)

app.use(createPinia())

app.use(posthog)

/**
 * When using MSWJS, we need to start the worker before mounting the app and
 * registering the router. This function will start the mock service worker if
 * the correct environment variable is set, otherwise it will return a resolved
 * promise immediately.
 */
async function prepareApp() {
  if (import.meta.env.VITE_USE_MSWJS === 'true') {
    const { worker } = await import('./backend/mockHandlers')

    // Mock LaunchDarkly flags in CI
    if (import.meta.env.VITE_CI) {
      const storedFlags = localStorage.getItem('go:ldFlags')
      const parsedFlags = storedFlags ? JSON.parse(storedFlags) : {}
      // We make the API call to LaunchDarkly to fetch flags when the app first
      // loads, so we can't create this handler in our tests, as it would be too
      // late. Instead, the test will set the flags in localStorage and reload the
      // page, and when the page reloads it will pick up the stored flags.
      const ldHandler: HttpHandler = http.all('*.launchdarkly.com/*', async () => {
        return HttpResponse.json(
          Object.fromEntries(Object.entries(parsedFlags).map(([key, value]) => [key, { value }])),
        )
      })
      worker.use(ldHandler)
    }

    window.mswjs = { worker, http, HttpResponse }

    return worker.start({
      onUnhandledRequest(req, print) {
        // Only warn about unhandled requests if they are requests to the Go API
        if (req.url.match(/http:\/\/localhost:\d+\/api\//)) {
          print.error()
        }
      },
      waitUntilReady: true,
    })
  }

  return Promise.resolve()
}

prepareApp().then(() => {
  /**
   * THIS IS IMPORTANT.
   *
   * The order of these two lines matters. The router must be registered before auth0, or deep
   * links will not work - after authenticating you will be redirected to the root path instead
   * of the deep link.
   *
   * See https://developer.auth0.com/resources/guides/spa/vue/basic-authentication
   * This page contains the quote:
   *      NOTE: 🚨 The order in which you register the Router and Auth0 Vue.js plugin with your Vue.js
   *      instance is important. You must register the Vue.js Router before the Auth0 Vue.js plugin, or
   *      you might experience unexpected behavior. 🚨.
   */
  app.use(router)
  app.use(auth0)

  app.mount('#app')
})
// Prevent drag and drop from opening a file in the browser
window.addEventListener('dragover', (e) => e.preventDefault())
window.addEventListener('drop', (e) => e.preventDefault())
