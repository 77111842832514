<script lang="ts" setup>
import { useId } from 'vue'
import ListMenuItem, { type ListMenuItemProps } from '../ListMenuItem.vue'
import { MenuCtx } from './Root.vue'

const props = defineProps<Omit<ListMenuItemProps, 'element'>>()

const emit = defineEmits<{
  (e: 'select'): void
}>()

defineOptions({
  name: 'MenuItem',
})

const { initItem, menu } = MenuCtx.get()
const id = useId()
initItem({ onSelect: () => emit('select'), id })
</script>

<template>
  <ListMenuItem
    element="button"
    v-bind="{ ...props, ...menu.getItemProps({ value: id }) }"
  >
    <slot />
  </ListMenuItem>
</template>
