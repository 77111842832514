import { router } from '@/router'
import { browserTracingIntegration, init as initSentry, replayIntegration } from '@sentry/vue'
import type { App } from 'vue'
import { version } from '../../../package.json'

const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production'

export const initTelemetry = (app: App<Element>) => {
  initSentry({
    app,
    release: 'agidb-frontend@' + version + '+' + import.meta.env.VITE_COMMIT_SHA,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      browserTracingIntegration({
        router,
      }),
      replayIntegration({
        /* https://docs.sentry.io/platforms/javascript/session-replay/configuration/ */
        unmask: ['[data-sentry="unmask"]'],
      }),
    ],
    tracesSampleRate: isProduction ? 0.1 : 1,
    replaysSessionSampleRate: isProduction ? 0.1 : 1,
    replaysOnErrorSampleRate: isProduction ? 1 : 0.1,
  })
}
