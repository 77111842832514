<script setup lang="ts">
import * as popover from '@zag-js/popover'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    open?: boolean
    positioning?: popover.PositioningOptions
  }>(),
  {
    open: false,
    positioning: undefined,
  },
)

const emit = defineEmits<{
  (e: 'change:open', open: boolean): void
}>()

const [state, send] = useMachine(
  popover.machine({
    id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    positioning: props.positioning,
    onOpenChange: (e) => {
      emit('change:open', e.open)
    },
  }),
)

const api = computed(() => popover.connect(state.value, send, normalizeProps))

watch(
  () => props.open,
  (newOpen) => {
    if (!newOpen) return
    api.value.setOpen(newOpen)
  },
)
</script>

<template>
  <slot
    name="trigger"
    :api="api"
  />

  <Teleport to="body">
    <div v-bind="api.getPositionerProps()">
      <slot
        name="content"
        :api="api"
      />
    </div>
  </Teleport>
</template>
