import type { MaybeRef } from '@vueuse/shared'
import { computed, onBeforeUnmount, watch } from 'vue'

import type { SessionResponse } from '@/backend/types'
import { usePopoverStore } from '@/sharedComposables/popoverStore'
import { useWebSocketChannel } from '@/sharedComposables/useWebSocketChannel'
import { serializeSession, useAskGo, type Action, type Session } from './useAskGo'

export const useAskGoChannel = (sessionId: MaybeRef<Session['id']>) => {
  const askGoStore = useAskGo()
  const popoverStore = usePopoverStore()

  const collectAndAnchorActions = (actions: Action[]) => {
    const renameProjectAction = actions.find((a) => a.action === 'rename_project')
    if (renameProjectAction) {
      setPopover('#project-name', `I have renamed your project based on your intent.`)
    }

    let tooltipText = ''

    const addedPropertyCount = actions.filter((a) => a.action === 'add_property').length
    if (addedPropertyCount > 0) {
      tooltipText += `I have added ${addedPropertyCount} new ${addedPropertyCount === 1 ? 'property' : 'properties'}.\n`
    }

    const editedPropertyCount = actions.filter((a) => a.action === 'edit_property').length
    if (editedPropertyCount > 0) {
      tooltipText += `I have ${addedPropertyCount > 0 ? 'also' : ''} updated ${editedPropertyCount} ${editedPropertyCount === 1 ? 'property' : 'properties'}.\n`
    }

    const deletedPropertyCount = actions.filter((a) => a.action === 'delete_property').length
    if (deletedPropertyCount > 0) {
      tooltipText += `${editedPropertyCount + addedPropertyCount > 0 ? 'Finally, ' : ''}I have hidden ${deletedPropertyCount} ${deletedPropertyCount === 1 ? 'property' : 'properties'}.\n`
    }

    if (tooltipText === '') return
    setPopover('[aria-label="Configure project"]', tooltipText)
  }

  const setPopover = (targetSelector: string, text: string) => {
    popoverStore.setPopover({
      targetSelector,
      text: text,
      placement: { allowedPlacements: ['bottom'] },
      variant: 'askGo',
    })
  }

  const topic = computed<`ask_go:${string}`>(
    () => `ask_go:${typeof sessionId === 'string' ? sessionId : sessionId.value}`,
  )

  const { channel, channelState, leaveChannel } = useWebSocketChannel(topic)

  onBeforeUnmount(async () => {
    await leaveChannel()
  })

  watch(
    channel,
    () => {
      channel.value?.on('ask_go:new_message', (data: SessionResponse) => {
        askGoStore.updateSession(serializeSession(data))

        const messages = askGoStore.currentSession?.messages
        if (!messages) return

        const lastMessage = messages[messages.length - 1]

        // If last message is authored by a human real user, do nothing
        if (lastMessage.authorId) return

        if (lastMessage.actions === undefined || lastMessage.actions.length === 0) return

        collectAndAnchorActions(lastMessage.actions)
      })
    },
    { immediate: true },
  )

  return {
    channelState,
    leaveChannel,
  }
}
