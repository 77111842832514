<script setup lang="ts">
import { PropertyTool } from '@/backend/types'
import { toast } from '@/shared/toast'
import { isMac } from '@/shared/utils'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import type { Key } from '@/uiKit/HotKey.vue'
import HotKeyGroup from '@/uiKit/HotKeyGroup.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import ProjectTableActionBarSetValues from './ProjectTableActionBarSetValues.vue'
import { useProject } from './useProject'
import { useSelectedFields } from './useSelectedFields'
import { useTable, type CellLocation } from './useTable'
import { CONTEXT_MENU_Z_INDEX } from './useTableZIndices'
/**
 * The context menu that appears when right-clicking on a table cell. This component has gone all
 * in on being a smart component - it relies on lots of stores to decide what range of cells
 * its actions relate to, and it will call backend functions and update stores when the user
 * clicks its menu items.
 */

const props = defineProps<{
  /**
   * x-coordinate (relative to the table container) of where the menu should
   * be displayed
   */
  x: number
  /**
   * y-coordinate (relative to the table container) of where the menu should
   * be displayed
   */
  y: number
  /**
   * The cell that was right-clicked to open the context menu
   */
  cell: CellLocation | null
}>()

const emit = defineEmits<{
  (e: 'close' | 'entities:delete'): void
}>()

const permissionsStore = usePermissionsStore()
const projectStore = useProject()
const tableStore = useTable()
const router = useRouter()
const { workspaceId, parentEntityId } = useRouteParams()

const {
  onCopyToCsv,
  onCopyToSpreadsheet,
  recalculateSelectedFields,
  resetSelectedFields,
  selectedFields,
  selectedProperties,
  selectedEntities,
  onSetFieldValues,
} = useSelectedFields()

const element = ref()
onClickOutside(element, () => {
  emit('close')
})
onKeyStroke(
  'Escape',
  (event) => {
    event.stopImmediatePropagation()
    emit('close')
  },
  { target: element },
)

/**
 * Details the selected set of entities/fields
 */
const selection = computed<{ count: number; type: 'entities' | 'fields' }>(() => {
  if (
    props.cell &&
    tableStore.selectedRange &&
    tableStore.isCellInSelectedRange(props.cell) &&
    tableStore.hasSelectedMultipleCells
  ) {
    const numberOfColumns =
      tableStore.selectedRange.end.colIndex - tableStore.selectedRange.start.colIndex + 1
    const numberOfRows =
      tableStore.selectedRange.end.rowIndex - tableStore.selectedRange.start.rowIndex + 1
    return {
      type: 'fields',
      count: numberOfColumns * numberOfRows,
    }
  }

  return {
    type: 'entities',
    count: projectStore.selectedEntityIds.size,
  }
})

/** Is true if and only if the selection contains >1 entity/field */
const isPlural = computed(() => selection.value.count > 1)

type ListItem = {
  id: string
  data: {
    label: string
    group: string
    icon?: IconName
    critical?: boolean
    hotkeys?: Key[]
    onSelect: () => void
  }
}
const COPY_ID = 'copy-selection'
const COPY_CSV_ID = 'copy-as-csv'

/**
 * The list of items to display in the context menu
 */
const listItems = computed<ListItem[]>(() => {
  const items: ListItem[] = []
  if (!props.cell) {
    return items
  }

  if (selection.value.type === 'fields') {
    items.push({
      data: {
        label: 'Copy selection',
        group: 'clipboard',
        onSelect: () => {
          onCopyToSpreadsheet()
          toast.info('Copied to clipboard')
        },
        hotkeys: [isMac() ? 'Meta' : 'Control', 'C'],
      },
      id: COPY_ID,
    })
    items.push({
      data: {
        label: 'Copy as CSV',
        group: 'clipboard',
        onSelect: () => {
          onCopyToCsv()
          toast.info('Copied as CSV')
        },
        hotkeys: [isMac() ? 'Meta' : 'Control', 'Shift', 'C'],
      },
      id: COPY_CSV_ID,
    })

    // Will be true if there is a field whose hash does not match the property hash
    const canRecompute = selectedFields.value.some((field) => {
      if (!permissionsStore.currentProjectPermissions.recalculate_entities) {
        return false
      }

      const property = selectedProperties.value.find((p) => p.id === field.propertyId)
      if (!property) {
        return false
      }

      const isAiProperty = property.tool !== PropertyTool.manual
      const isStale = field.propertyHash !== property.hash
      const hasError = field.errorMessage

      return isAiProperty && (isStale || hasError)
    })
    if (canRecompute) {
      items.push({
        data: {
          label: 'Recompute',
          group: 'field-value',
          onSelect: recalculateSelectedFields,
        },
        id: 'recompute',
      })
    }

    const canBulkSetValues = selectedProperties.value.some(
      (property) =>
        property.type === 'single_select' ||
        property.type === 'multi_select' ||
        property.type === 'user_select',
    )
    if (permissionsStore.currentProjectPermissions.update_entities && canBulkSetValues) {
      items.push({
        data: {
          label: 'Set value',
          group: 'field-value',
          onSelect: () => {},
        },
        id: 'set-value',
      })
    }

    // Will be true if a field has had its tool value overwritten by a manual value
    const hasOverwrittenField = selectedFields.value.some(
      (field) => field.toolValue && field.manualValue,
    )
    if (permissionsStore.currentProjectPermissions.update_entities && hasOverwrittenField) {
      items.push({
        data: {
          label: 'Reset value',
          group: 'field-value',
          onSelect: resetSelectedFields,
          critical: true,
        },
        id: 'edit-fields',
      })
    }

    return items
  }

  if (!isPlural.value) {
    items.push({
      data: {
        label: 'Open Entity',
        group: 'open',
        icon: 'expand',
        onSelect: () => {
          assertIsNotNullOrUndefined(props.cell)
          const entity = projectStore.activeView?.entities?.[props.cell.rowIndex]
          assertIsNotNullOrUndefined(entity)

          router.push({
            name: 'WorkspaceProjectEntityView',
            params: {
              workspaceId: workspaceId.value,
              projectId: projectStore.projectId,
              entityId: entity.id,
            },
            query: { parentEntityId: parentEntityId.value },
          })
        },
      },
      id: 'expand',
    })
  }

  if (permissionsStore.currentProjectPermissions.delete_entities) {
    items.push({
      data: {
        label: `Delete ${isPlural.value ? 'Entities' : 'Entity'}`,
        group: 'delete',
        icon: 'trash',
        critical: true,
        onSelect: () => emit('entities:delete'),
      },
      id: 'delete',
    })
  }

  return items
})

const onSelect = (item: ListItem) => {
  item.data.onSelect()
  if (item.id === 'set-value') {
    return
  }
  emit('close')
}

useFocusTrap(element, { immediate: true })

onKeyStroke(
  'c',
  (event) => {
    // ctrl+shift+c has the default behaviour of inspecting element
    event.preventDefault()
    const modifier = isMac() ? event.metaKey : event.ctrlKey
    if (!modifier) {
      return
    }

    const shift = event.getModifierState('Shift')
    // It seems like overkill to find the copy items by ID and call the select function on them,
    // but it's the best way to make sure that the action when pressing the keyboard shortcut
    // stays in sync with the action when clicking the menu item.
    const item = listItems.value.find((item) => item.id === (shift ? COPY_CSV_ID : COPY_ID))
    if (item) {
      onSelect(item)
    }
  },
  { target: element },
)

const LIST_MENU_ID = 'table-row-context-menu'
</script>

<template>
  <ListMenu
    :id="LIST_MENU_ID"
    ref="element"
    class="absolute w-[256px] min-w-max"
    :style="{
      zIndex: CONTEXT_MENU_Z_INDEX,
      transform: `translate(${x}px, ${y}px)`,
    }"
    data-test="table-row-context-menu"
    :items="listItems"
    :group-by-predicate="(item) => item.data.group"
    :header="selection.type === 'fields' ? `${selection.count} fields selected` : undefined"
    :initial-active-item-predicate="(item) => item.id === listItems[0].id"
  >
    <template #item="{ item, key, active, setActiveItem }">
      <ProjectTableActionBarSetValues
        v-if="item.id === 'set-value'"
        :entities="selectedEntities"
        :properties="selectedProperties.filter(isSelectProperty)"
        :placement="{ allowedPlacements: ['right-start', 'left-start'] }"
        :offset="{ mainAxis: 3, alignmentAxis: -2 }"
        :teleport-to="`#${LIST_MENU_ID}`"
        @set-value="onSetFieldValues"
      >
        <template #trigger="{ isOpen, toggleOpen }">
          <ListMenuItem
            :key="key"
            :active="active || isOpen"
            :aria-selected="active"
            :item="item"
            :label="item.data.label"
            :icon="item.data.icon"
            :critical="item.data.critical"
            @mousemove="setActiveItem(key)"
            @select="toggleOpen"
            @keydown.escape.stop="$emit('close')"
          >
            <template #suffix>
              <IconSprite
                icon="chevron-right"
                class="text-icon-subtle"
              />
            </template>
          </ListMenuItem>
        </template>
      </ProjectTableActionBarSetValues>
      <ListMenuItem
        v-else
        :key="key"
        :active="active"
        :aria-selected="active"
        :item="item"
        :label="item.data.label"
        :icon="item.data.icon"
        :critical="item.data.critical"
        @mousemove="setActiveItem(key)"
        @select="onSelect(item)"
        @keydown.escape.stop="$emit('close')"
      >
        <template
          v-if="item.data.hotkeys"
          #suffix
        >
          <HotKeyGroup
            :keys="item.data.hotkeys"
            size="xs"
          />
        </template>
      </ListMenuItem>
    </template>
  </ListMenu>
</template>
