import type { components } from '@/api'
import { post } from './client'

export type CreateViewPath = `/api/workspaces/${string}/projects/${string}/views`
export type CreateViewResponse = components['schemas']['Projects.ViewResponse']
export type CreateViewRequest = components['schemas']['Projects.CreateViewRequest']

export const createView = ({
  workspaceId,
  projectId,
  name,
  propertyIds,
  filters,
  propertyOptions,
}: {
  workspaceId: string
  projectId: string
  name: string
  filters?: CreateViewRequest['filters']
  propertyIds?: CreateViewRequest['property_ids']
  propertyOptions?: CreateViewRequest['property_options']
}) =>
  post<CreateViewRequest, CreateViewResponse, CreateViewPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/views`,
    {
      name,
      property_ids: propertyIds ?? [],
      property_layouts: [],
      filters: filters ?? [],
      property_options: propertyOptions ?? [],
    },
  )
