import { get } from './client'
import type { BackendMeta } from './types'

type ListTemplatesMeta = BackendMeta<'/api/project_templates', 'get'>
export type ListTemplatesPath = ListTemplatesMeta['path']
export type ListTemplatesResponse = ListTemplatesMeta['successResponse']
export type ListTemplatesRequest = ListTemplatesMeta['queryParams']

export const listTemplates = () => {
  return get<ListTemplatesRequest, ListTemplatesResponse, ListTemplatesPath>(
    '/api/project_templates',
    {
      limit: 100,
    },
  )
}
