import type { components } from '@/api'
import { post } from './client'

export type CreatePlanUpgradePath = `/api/workspaces/${string}/billing/plans`
export type CreatePlanUpgradeResponse = components['schemas']['Billing.PlanUpgradeResponse']
export type CreatePlanUpgradeRequest = components['schemas']['Billing.PlanUpgradeRequest']

export type CreatePlanUpgradeParams = {
  workspaceId: string
  billingPeriod: CreatePlanUpgradeRequest['billing_period']
  planName: CreatePlanUpgradeRequest['plan_name']
}

export const createPlanUpgrade = ({
  workspaceId,
  billingPeriod,
  planName,
}: CreatePlanUpgradeParams) =>
  post<CreatePlanUpgradeRequest, CreatePlanUpgradeResponse, CreatePlanUpgradePath>(
    `/api/workspaces/${workspaceId}/billing/plans`,
    {
      billing_period: billingPeriod,
      plan_name: planName,
    },
  )
