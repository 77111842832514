import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import type { Plan } from '@/modules/Billing/types'
import { useBilling } from '@/modules/Billing/useBilling'
import { computed } from 'vue'
import { useHasLoadedIntercom } from './useHasLoadedIntercom'

/**
 * Composable function that returns whether the current user has access to the
 * Intercom chat widget.
 */
export const useHasIntercomChat = () => {
  const hasLoadedIntercom = useHasLoadedIntercom()
  const billingStore = useBilling()
  const allUsersCanChat = useFeatureFlags(FeatureFlag.INTERCOM_FOR_ALL_USERS)

  return computed(() => {
    if (!hasLoadedIntercom.value) {
      return false
    }

    if (allUsersCanChat.value) {
      return true
    }

    const paidPlans: Plan['name'][] = ['pro', 'enterprise']
    return billingStore.activePlan && paidPlans.includes(billingStore.activePlan.name)
  })
}
