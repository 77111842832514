import { MarkdownParser, MarkdownSerializer } from 'prosemirror-markdown'
import type { NodeSpec, Schema } from 'prosemirror-model'
import type { Mentionable } from '../ProseMirror.vue'
/**
 * This file was forked from https://github.com/quartzy/prosemirror-suggestions/blob/master/src/mentions.js
 * with some fairly significant changes to support how we serialize mentions
 * in Go.
 */

/**
 * Describes how ProseMirror should treat a property mention node.
 */
export const mentionNodeSpec: NodeSpec = {
  // These are used when serializing to our custom markdown format,
  // and also to provide props to the `<BadgeItem>` we use to render
  // the mention in the editor (via a ProseMirror NodeView).
  attrs: {
    label: {},
    group: {},
    icon: {},
    id: {},
    openOnClick: { default: false },
    previewPropertyOnHover: { default: false },
  } satisfies { [T in keyof Mentionable]: { default?: Mentionable[T] } },

  toDOM: ({ attrs }) => {
    return [
      'span',
      {
        class: 'mention',
        'data-label': attrs.label,
        'data-group': attrs.group,
        'data-icon': attrs.icon,
        'data-id': attrs.id,
        'data-open-on-click': attrs.openOnClick,
        'preview-property-on-hover': attrs.previewPropertyOnHover,
      },
      `@${attrs.label}`,
    ]
  },

  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
} as const

/**
 * Adds our property mention Node to the provided schema and returns
 * a new schema with the Node added.
 */
export function addMentionNodes(nodes: Schema['spec']['nodes']) {
  return nodes.append({
    mention: mentionNodeSpec,
  })
}

/**
 * Given a markdown serializer, returns a new serializer that is capable
 * of serializing our custom property mention Node.
 */
export function addMentionsToMarkdownSerializer(serializer: MarkdownSerializer) {
  return new MarkdownSerializer(
    {
      ...serializer.nodes,
      mention: (state, node) => {
        const id = node.attrs.id
        if (typeof id !== 'string') {
          throw new Error('Mention Node must have string id')
        }
        state.write(`@<${id}>`)
      },
    },
    serializer.marks,
  )
}

export function markdownParser() {
  return {
    node: 'mention',
    getAttrs: ({ mention: { id, group, icon, label } }: typeof mentionNodeSpec) => ({
      id,
      label,
      group,
      icon,
    }),
  }
}

export function addMentionsToMarkdownParser(parser: MarkdownParser, schema: Schema) {
  return new MarkdownParser(schema, parser.tokenizer, {
    ...parser.tokens,
    mention: {
      node: 'mention',
      getAttrs: (token) => ({
        id: token.attrGet('id'),
        icon: token.attrGet('icon'),
        label: token.attrGet('label'),
        group: token.attrGet('group'),
      }),
    },
  })
}
