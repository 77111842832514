import type { RouteLocation } from 'vue-router'

/**
 * When a user updates their billing details via the Stripe portal, they are
 * redirected back to the /plan_management route with a query parameter indicating
 * the workspace. We should redirect them to the billing page for that workspace.
 */
export const stripePortalRedirect = (to: RouteLocation) => {
  const workspaceId = to.query.workspace_id
  if (typeof workspaceId !== 'string') {
    return { name: 'Home' }
  }

  return {
    name: 'WorkspaceSettingsBilling',
    params: {
      workspaceId: workspaceId,
    },
  }
}
