<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import LibraryListMenuItem from '@/modules/Library/LibraryListMenuItem.vue'
import type { LibraryItem } from '@/modules/Library/libraryStore'
import { TYPE_ICON } from '@/modules/Project/icons'
import type { ModelInputItem } from '@/modules/Project/useModelInputs'
import { useProperty } from '@/modules/Project/useProperty'
import { escapeRegex } from '@/shared/utils'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import TextArea from '@/uiKit/TextArea.vue'
import { ProsemirrorAdapterProvider } from '@prosemirror-adapter/vue'
import { computed, inject, ref, watch } from 'vue'
import MentionableWrapper from './MentionableWrapper.vue'
import ProseMirror, { type Mentionable } from './ProseMirror/ProseMirror.vue'

export type Item = ModelInputItem['data'] & {
  icon?: IconName
}

const props = defineProps<{
  codeStyle?: boolean
  items: Item[]
  value: string
}>()

const emit = defineEmits<{
  (e: 'update:text', text: string): void
  (e: 'add:mention' | 'remove:mention', item: Item): void
  (e: 'open:library'): void
}>()

/**
 * When saving a mention, we serialize it as @<P${id}>. This function returns a
 * regex that can be used to find all serialized mentions of a particular ID, so
 * that they can be replaced with a user-friendly label.
 */
const getRegexFromItem = (item: Item) => {
  if (item.group === 'Library') {
    return new RegExp(`@<P${item.propertyId}:E${item.id}>`, 'g')
  }

  return new RegExp(`@<P${item.id}>`, 'g')
}

/**
 * Deserialize the stored version of the text into a form that end users can
 * understand, replacing IDs with labels.
 */
const deserializeText = (text: string) => {
  const regexes = props.items.map(getRegexFromItem)
  let newText = text
  regexes.forEach((regex, index) => {
    newText = newText.replace(regex, `@${props.items[index].name}`)
  })

  return newText
}

/**
 * Serialize the user-friendly text into a form that backend systems can
 * understand, replacing labels with IDs.
 */
const serializeText = (text: string) => {
  const sortedItems = [...props.items].sort((a, b) => b.name.length - a.name.length)
  const regexes = sortedItems.map((item) => ({
    item,
    regex: new RegExp(`@${escapeRegex(item.name)}`, 'gi'),
  }))

  let newText = text
  regexes.forEach(({ regex, item }) => {
    if (item.group === 'Properties') {
      newText = newText.replace(regex, `@<P${item.id}>`)
    } else {
      // Library items need to have both their entity ID and property ID serialized
      newText = newText.replace(regex, `@<P${item.propertyId}:E${item.id}>`)
    }
  })

  return newText
}

/**
 * Gets all IDs that have been mentioned in the text.
 */
const getMentions = (text: string) => {
  const regexes = props.items.map(getRegexFromItem)
  const mentions: Item[] = []
  regexes.forEach((regex, index) => {
    if (regex.test(text)) {
      mentions.push(props.items[index])
    }
  })

  return mentions
}

/**
 * The value as seen by users, with IDs replaced by labels.

 */
const internalValue = computed(() => deserializeText(props.value))

const mentionedItems = ref<Item[]>(getMentions(props.value))

/**
 * When the user types into the text area, emit the serialized value
 */
const onInput = (text: string) => {
  const serializedText = serializeText(text)
  emit('update:text', serializedText)
  mentionedItems.value = getMentions(serializedText)
}

watch(mentionedItems, (newItems, oldItems) => {
  newItems.forEach((item) => {
    if (!oldItems.some((i) => i.id === item.id)) {
      emit('add:mention', item)
    }
  })

  oldItems.forEach((item) => {
    if (!newItems.some((i) => i.id === item.id)) {
      emit('remove:mention', item)
    }
  })
})

const getLibraryItem = (id: string): LibraryItem => {
  const matchingItem = props.items.find((item) => item.id === id)
  if (!matchingItem) {
    throw new Error('Could not find item with ID')
  }

  if (matchingItem.group !== 'Library') {
    throw new Error('Item is not a library item')
  }

  return matchingItem
}

const customCaretPosition = ref<{ top: number; left: number; height: number } | null>(null)

const inSidebar = inject('in-sidebar', false)

const onSaveProseMirror = (value: string) => {
  emit('update:text', value)
  mentionedItems.value = getMentions(value)
}

const propertyStore = useProperty()
const proseMirrorMentionables = computed<Mentionable[]>(() =>
  props.items.map((item) => ({
    id: item.group === 'Library' ? `P${item.propertyId}:E${item.id}` : `P${item.id}`,
    label: item.name,
    icon: TYPE_ICON[item.type],
    group: item.group,
  })),
)

const isMentionableV2Enabled = useFeatureFlags(FeatureFlag.MENTIONABLE_V2)
const isNotUsingPythonTool = computed(() => propertyStore.property?.tool !== 'code')
</script>

<template>
  <ProsemirrorAdapterProvider v-if="isMentionableV2Enabled && isNotUsingPythonTool">
    <ProseMirror
      :value="propertyStore.property?.description ?? ''"
      class="max-h-[600px] min-h-[50px] resize-y rounded-corner-8 bg-background-gray-subtlest px-2 py-1.5"
      :saved-height-key="inSidebar ? 'sidebar-prompt-height' : 'prompt-height'"
      :mentionable-inputs="proseMirrorMentionables"
      aria-label="Prompt"
      @input="onSaveProseMirror"
  /></ProsemirrorAdapterProvider>
  <MentionableWrapper
    v-else
    :keys="['@']"
    :items="items.map((i) => ({ icon: i.icon, id: i.id, label: i.name, group: i.group }))"
    :map-insert="(item) => item.label ?? item.id"
    :require-preceeding-space="codeStyle"
    :custom-caret-position="customCaretPosition"
  >
    <TextArea
      class="w-full"
      :value="internalValue"
      placeholder="Set a prompt (Press @ to mention an input)"
      aria-label="Prompt"
      size="md"
      :code-style="codeStyle"
      :saved-height-key="inSidebar ? 'sidebar-prompt-height' : 'prompt-height'"
      @input="onInput"
      @custom-caret-position="customCaretPosition = $event"
    />
    <template #group-title="{ group }">
      <div class="flex h-7 items-center justify-between px-1.5 py-0.5">
        <h4 class="text-xs-11px-bold text-text-subtlest">
          {{ group.key }}
        </h4>
        <IconButton
          v-if="group.key === 'Library'"
          icon="folder"
          size="xs"
          variant="transparent"
          @mousedown.stop.prevent="$emit('open:library')"
        />
      </div>
    </template>
    <template #mentionable-item="{ item, applyMention, key, active, setActive }">
      <LibraryListMenuItem
        v-if="item.group === 'Library'"
        :item="getLibraryItem(item.id)"
        :checked="undefined"
        :active="active"
        :aria-label="getLibraryItem(item.id).name"
        @mouseover="setActive"
        @mousedown.stop.prevent="applyMention(key)"
      />
      <ListMenuItem
        v-else
        :label="item.label ?? item.id"
        :active="active"
        :icon="item.icon"
        @mouseover="setActive"
        @mousedown.stop.prevent="applyMention(key)"
      />
    </template>
  </MentionableWrapper>
</template>
