<script setup lang="ts">
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import PopOver from '@/uiKit/PopOver.vue'
import { useInfiniteScroll } from '@vueuse/core'
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue'
import { useUser } from '../IdentityAndAccess/useUser'
import { ENTITY_NAME_FALLBACK } from './constants'
import EntityDialog from './EntityDialog.vue'
import { isFilterableProperty } from './Filters/types'
import ReferenceCellEntityPreview from './ReferenceEntityPreview.vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import type { Field, Property } from './useProject'
import { useReferenceField, type ReferenceEntityListItem } from './useReferenceField'

const props = defineProps<{
  field: Field<'reference'>
  property: Property<'reference'>
  projectId: string
}>()

const relatedProjectStore = useRelatedProjectsStore()

const userStore = useUser()
const localField = ref(props.field)
watch(
  () => props.field,
  (newField) => {
    /**
     * Only update the local field if it was updated by somebody else. This is
     * because the user's updates in this component will cause websocket
     * updates to the field. We want to ignore those updates because they
     * cause the order of items to jump around.
     */
    if (newField.manualValueUpdatedBy !== userStore.user?.id) {
      localField.value = newField
    }
  },
)

const {
  debouncedListItemHoverElement,
  entityPreviewPopover,
  listItems,
  loadMoreEntities,
  nameProperty,
  onMouseleaveListItem,
  onMouseoverListItem,
  onToggleEntity,
  searchText,
  updateFieldValue,
  relatedProject,
  listItemHoverEntity,
  localEntityIds,
  hasReachedEntityLimit,
  entityLimit,
  getPreviewOffset,
  allLoadedEntities,
} = useReferenceField({
  field: localField,
  property: computed(() => props.property),
})

onMounted(() => {
  loadMoreEntities()
})

const toggleEntity = async (item: ReferenceEntityListItem) => {
  onToggleEntity(item)
  onMouseleaveListItem()
  await updateFieldValue()
}

const list = useTemplateRef('list')
useInfiniteScroll(() => list.value?.listRef, loadMoreEntities, { distance: 200 })

const entityPreviewRef = useTemplateRef('entityPreview')

const dialogEntityId = ref<string | null>(null)
</script>

<template>
  <div
    class="flex size-full flex-col"
    v-bind="$attrs"
  >
    <ListMenu
      ref="list"
      class="go-scrollbar box-border flex h-auto max-h-[500px] min-h-[70px] shrink grow-0 select-none flex-col overflow-y-auto rounded-none border-t border-border-subtle shadow-none"
      :items="listItems"
      :external-search="isFilterableProperty(nameProperty)"
      :group-by-predicate="(item) => item.data.group"
      :group-order="['selected', 'unselected']"
      :aria-label="`Entities from ${relatedProject.name}`"
      deselect-on-mouse-leave
      @update:search="searchText = $event"
      @scroll="onMouseleaveListItem"
    >
      <template #group-title="{ group }">
        <div
          v-if="group.key === 'selected'"
          class="flex h-7 items-center justify-between pl-[9px] pr-2.5 text-xs-11px-default text-text-subtlest"
        >
          <div>Related Entities</div>
          <div>{{ localEntityIds.length }}/{{ entityLimit }}</div>
        </div>
      </template>
      <template #item="{ item, active, key, setActiveItem }">
        <ListMenuCheckboxItem
          :key="key"
          :data-entity-id="item.data.id"
          :active="active"
          :checked="item.data.group === 'selected'"
          :label="item.data.name"
          :disabled="hasReachedEntityLimit"
          @mousemove="setActiveItem(key)"
          @mouseover="onMouseoverListItem(item.data)"
          @mouseleave="onMouseleaveListItem"
          @select="toggleEntity(item)"
        >
          <template #default>
            <div class="line-clamp-1 w-max">
              {{ item.data.name || ENTITY_NAME_FALLBACK }}
              <span
                v-if="!item.data.name"
                class="text-text-subtle"
              >
                {{ item.data.id }}
              </span>
            </div>
          </template></ListMenuCheckboxItem
        >
      </template>
    </ListMenu>
  </div>
  <PopOver
    v-if="debouncedListItemHoverElement"
    :open="entityPreviewPopover.isOpen.value"
    :target-selector="debouncedListItemHoverElement"
    :placement="{
      allowedPlacements: ['right-start', 'left-start', 'right', 'left', 'right-end', 'right-start'],
    }"
    :offset="({ placement }) => getPreviewOffset(placement, list?.listRef || undefined)"
    class="contents"
    :aria-labelledby="entityPreviewRef?.labelId"
  >
    <template #content>
      <ReferenceCellEntityPreview
        v-if="listItemHoverEntity && debouncedListItemHoverElement"
        ref="entityPreview"
        :entity="listItemHoverEntity.data"
        :name-property-id="nameProperty.id"
        :project="relatedProject"
        :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
        @mouseover="onMouseoverListItem(listItemHoverEntity.data)"
        @mouseleave="onMouseleaveListItem"
        @open:entity="dialogEntityId = listItemHoverEntity.data.id"
      />
    </template>
  </PopOver>
  <EntityDialog
    :intialEntityId="dialogEntityId"
    :initialLoadedEntities="allLoadedEntities"
    :entity-ids="localEntityIds"
    :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
    :project="relatedProject"
  />
</template>
