import {
  filterByFieldName,
  filterbyFilename,
  filterByTextValue,
} from '@/modules/Project/Filters/entityPassesFilters'
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'
import type { Field } from '../useProject'
import type { Filter, MatcherName } from './types'
import { getCaseInsensitiveValue } from './utils'

/**
 * Filters are typically applied to entities, but when we receive a 'field:updated'
 * websocket event, we want to check if there's a chance that the change in field
 * value could affect whether the entity passes the current filters.
 *
 * If this function returns false, then we can be sure that the new field value
 * would result in the entity not passing the current filters. If it returns true,
 * then we can't be sure, and the entity may or may not pass the filters. In this
 * case, we would need to load the full entity to check.
 */
export const fieldMaybePassesFilters = (field: Field, filter: Filter): boolean => {
  if ('matcher' in filter) {
    switch (filter.subject) {
      case 'field_select_option_value':
      case 'select_option_value':
        if (filter.matcher.property_id !== field.propertyId) {
          return true
        }

        return matchSelectValue(field, {
          matcherName: filter.matcher.name,
          values: filter.matcher.values,
          caseSensitive:
            filter.matcher.name === 'property_all_of' ? false : filter.matcher.case_sensitive,
        })
      case 'field_status':
      case 'status':
        return (
          filter.matcher.property_id === field.propertyId &&
          filter.matcher.values.includes(field.status)
        )
      case 'entity_id':
      case 'id':
        return filterByEntityId(field.entityId, filter.matcher.name, filter.matcher.values)
      /**
       * We don't have enough information to determine if the field passes these
       * filters, so we err on the side of caution and return true.
       */
      case 'field_text_value':
      case 'text_value':
        return filterByTextValue(field, {
          matcherName: filter.matcher.name,
          values: filter.matcher.values,
          caseSensitive: filter.matcher.case_sensitive,
        })
      case 'active_view_id':
      case 'parent_entity_id':
        return true
      case 'field_file_name': {
        invariant(field && 'manualFilename' in field, 'Expected field to be a file field')
        return filterbyFilename(field, filter.matcher)
      }
      case 'field_metadata_field_name': {
        invariant(field && 'manualName' in field, 'Field must have a manualName property')
        return filterByFieldName(field, filter.matcher)
      }
      default: {
        // If TS is complaining here, then there's a chance that a new subject
        // has been added to the Filter type, and you need to add a switch case.
        exhaustiveGuard(filter, 'Unhandled filter subject')
        return false
      }
    }
  }
  if (filter.conjunction === 'or') {
    return filter.filters.some((f) => fieldMaybePassesFilters(field, f))
  }

  return filter.filters.every((f) => fieldMaybePassesFilters(field, f))
}

export const matchSelectValue = (
  field: Field,
  {
    matcherName,
    values: _values,
    caseSensitive = true,
  }: {
    matcherName: Extract<MatcherName, 'property_any_of' | 'property_none_of' | 'property_all_of'>
    values: string[]
    caseSensitive?: boolean
  },
) => {
  const isSelectField =
    field.type === 'single_select' || field.type === 'multi_select' || field.type === 'user_select'
  if (!isSelectField) {
    return false
  }

  const manualValue = caseSensitive ? field.manualValue : getCaseInsensitiveValue(field.manualValue)
  const toolValue = caseSensitive ? field.toolValue : getCaseInsensitiveValue(field.toolValue)
  const values = _values.map((v) => (caseSensitive ? v : v.toLowerCase()))

  // helper function - returns true if and only if the field has the given value
  const fieldHasValue = (v: string) =>
    manualValue ? manualValue.includes(v) : toolValue?.includes(v)

  if (matcherName === 'property_any_of') {
    return values.some(fieldHasValue)
  }

  if (matcherName === 'property_none_of') {
    return !values.some(fieldHasValue)
  }

  if (matcherName === 'property_all_of') {
    return values.every(fieldHasValue)
  }

  return false
}

const filterByEntityId = (
  entityId: string,
  matcherName: Extract<MatcherName, 'any_of' | 'none_of'>,
  values: string[],
) => {
  if (matcherName === 'any_of') {
    return values.includes(entityId)
  }

  return !values.includes(entityId)
}
