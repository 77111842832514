<script setup lang="ts">
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import {
  CLOSE_LIBRARY_DOCUMENT_DIALOG_ID,
  EDIT_DOCUMENT_DIALOG_ID,
  LIBRARY_DIALOG_ID,
} from './constants'
import LibraryDialogEmptyView from './LibraryDialogEmptyView.vue'
import LibraryDialogListView from './LibraryDialogListView.vue'
import { useLibraryStore } from './libraryStore'

defineProps<{ workspaceId: string }>()

const libraryStore = useLibraryStore()

const onCloseDialog = (event: Event) => {
  /**
   * The library dialog opens a subdialog to create/edit library documents. We
   * want to make sure that the library document doesn't close when the user
   * clicks inside the subdialog.
   */
  const target = event.target
  if (
    target instanceof HTMLElement &&
    (target.closest(`#${EDIT_DOCUMENT_DIALOG_ID}`) ||
      target.closest('[data-library-child]') ||
      target.querySelector(`#${CLOSE_LIBRARY_DOCUMENT_DIALOG_ID}`))
  ) {
    return
  }
  // setTimeout(callback, 0) is a hack so that the dialog doesn't open
  // again in the case that the user clicks the trigger again
  setTimeout(() => (libraryStore.dialogIsOpen = false), 0)
}
</script>

<template>
  <ModalDialog
    :open="libraryStore.dialogIsOpen"
    disable-focus-trap
    placement="left"
    outline
    to="#page-container"
    aria-label="Library dialog"
    :force-z-index="3"
    @close="onCloseDialog"
  >
    <div
      :id="LIBRARY_DIALOG_ID"
      class="relative flex h-screen w-[481px] max-w-full flex-col"
    >
      <IconButton
        icon="close"
        aria-label="Close library dialog"
        rounded
        class="absolute right-2 top-2"
        variant="transparent"
        size="lg"
        @click="libraryStore.dialogIsOpen = false"
      />

      <LibraryDialogEmptyView
        v-if="libraryStore.libraryItems.length === 0"
        :workspace-id="workspaceId"
      />
      <LibraryDialogListView
        v-else
        :workspace-id="workspaceId"
      />
    </div>
  </ModalDialog>
</template>
