<script setup lang="ts">
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  workspaceId: string
  status: string
}>()

const { captureAnalyticsEvent } = useAnalytics()
const router = useRouter()

/**
 * This logic can't be in a redirect function because it needs access to
 * the posthog instance to send an analytics event, and posthog is only
 * available once the app mounts.
 */
onMounted(() => {
  if (props.status === 'subscribed') {
    captureAnalyticsEvent(ANALYTICS_EVENT.STRIPE_CONVERSION)
    router.push({
      name: 'WorkspaceSettingsBilling',
      params: {
        workspaceId: props.workspaceId,
      },
    })
  } else if (props.status === 'canceled') {
    router.push({
      name: 'WorkspaceSettingsPlans',
      params: {
        workspaceId: props.workspaceId,
      },
    })
  } else {
    router.push({ name: 'Home' })
  }
})
</script>

<template>
  <div></div>
</template>
