<script lang="ts" setup>
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { ref } from 'vue'

const tutorials = [
  {
    thumbnail_url: '/tutorial-thumbnail-1.jpg',
    video_embed_url:
      'https://www.loom.com/embed/8b8b091ca34e4284accc563e3a69f130?sid=3d2d0107-6b56-4a61-a305-adbc33bb0ec6',
    title: 'Basic Properties',
    description: 'Learn how to configure a property that runs an AI model on your data.',
  },
  {
    thumbnail_url: '/tutorial-thumbnail-2.jpg',
    video_embed_url:
      'https://www.loom.com/embed/d3305090b184456896118dd89381b6dc?sid=6e96dc7d-a0bb-49d0-9645-6032731259ca',
    title: 'Advanced Properties',
    description: 'Learn how to use Single Selects, and Collections for tabular data extraction.',
  },
  {
    thumbnail_url: '/tutorial-thumbnail-3.jpg',
    video_embed_url:
      'https://www.loom.com/embed/29e4076ca24d4b16a352ef6bc6b1d488?sid=f01a3831-d73e-4179-831e-949dee1d62d9',
    title: 'Workflows',
    description:
      'Learn how to trigger extra AI steps for files passing or failing custom criteria.',
  },
]

const { captureAnalyticsEvent } = useAnalytics()
const onSetActiveTutorial = (tutorial: (typeof tutorials)[number]) => {
  captureAnalyticsEvent(ANALYTICS_EVENT.TUTORIAL_VIDEO_OPENED, { tutorial: tutorial.title })
  activeTutorial.value = tutorial
}

/**
 * The currently active tutorial, if any. The video for this tutorial will be
 * displayed in a modal dialog.
 */
const activeTutorial = ref<(typeof tutorials)[number] | null>(null)

/**
 * The tutorial videos run in an iframe, which is only hidden visually when the
 * user closes the dialog, so that we don't have a noticeable loading time when
 * first opening the dialog. This means that when 'closing' the dialog, the
 * video is still running, and audio is still playing.
 *
 * To stop audio when the dialog is closed, we increment this key.
 */
const key = ref(0)
const onClearActiveTutorial = () => {
  if (!activeTutorial.value) {
    return
  }

  key.value++
  activeTutorial.value = null
}
</script>

<template>
  <div class="flex flex-col gap-3 px-4 lg:flex-row">
    <button
      v-for="tutorial in tutorials"
      :key="tutorial.title"
      class="flex min-w-[200px] max-w-80 gap-3 rounded-corner-10 border border-border-subtle bg-background-transparent p-1 text-left hover:bg-background-transparent-hovered focus:outline-none focus-visible:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      @click="onSetActiveTutorial(tutorial)"
    >
      <div
        class="flex h-16 min-w-24 items-center justify-center rounded-corner-6 !bg-contain shadow-sm hover:shadow-md active:shadow-md"
        :style="{
          background: `url(${tutorial.thumbnail_url})`,
        }"
      >
        <div
          class="flex size-full items-center justify-center rounded-corner-6 backdrop-brightness-[0.6]"
        >
          <IconSprite
            class="text-icon-inverted-irreversible"
            icon="play-fill"
          />
        </div>
      </div>
      <div class="flex h-full flex-col justify-center gap-0.5">
        <div class="line-clamp-1 text-sm-12px-default text-text">{{ tutorial.title }}</div>
        <p class="line-clamp-2 text-xs-11px-light text-text-subtle">{{ tutorial.description }}</p>
      </div>
      <!--
        Render one dialog per tutorial, always open but hidden, so that we don't have a noticeable
        loading time when the user clicks on a tutorial.
      -->
      <ModalDialog
        open
        disable-focus-trap
        :outline="false"
        :hidden="activeTutorial?.video_embed_url !== tutorial.video_embed_url"
        :aria-label="tutorial.title"
        :aria-hidden="activeTutorial?.video_embed_url !== tutorial.video_embed_url"
        @close="onClearActiveTutorial"
      >
        <div
          class="flex flex-col overflow-hidden rounded-corner-10 border-0 bg-surface-popover shadow-lg"
          data-test="tutorial-video-modal"
        >
          <iframe
            :key="key"
            :width="978"
            :height="556"
            :src="tutorial.video_embed_url"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        </div>
      </ModalDialog>
    </button>
  </div>
</template>
