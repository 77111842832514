import { schema as defaultMarkdownSchema } from 'prosemirror-markdown'
import type { DOMOutputSpec, Node } from 'prosemirror-model'

const paragraphToDom = (): DOMOutputSpec => {
  return ['p', { class: 'text-sm-12px-light my-0.5' }, 0]
}

const headingToDom = (node: Node): DOMOutputSpec => {
  const { level } = node.attrs
  switch (level) {
    case 1:
      return ['h1', { class: 'text-display-lg-36px-bold mt-8 mb-2' }, 0]
    case 2:
      return ['h2', { class: 'text-display-md-28px-default mt-6 mb-0.5' }, 0]
    case 3:
      return ['h3', { class: 'text-display-xs-20px-bold mt-4 mb-0.5' }, 0]
    case 4:
      return ['h4', { class: 'xl-18px-bold mt-2 mb-px' }, 0]
    default:
      return paragraphToDom()
  }
}

const bulletListToDom = (): DOMOutputSpec => {
  return ['ul', { class: 'text-sm-12px-default list-disc ml-6' }, 0]
}

const orderedListToDom = (): DOMOutputSpec => {
  return ['ol', { class: 'text-sm-12px-default list-decimal ml-6' }, 0]
}

const blockQuoteToDom = (): DOMOutputSpec => {
  return [
    'blockquote',
    {
      class:
        'text-sm-12px-light bg-background-gray-subtlest my-1 pl-4 border-l-4 border-border rounded-corner-6',
    },
    0,
  ]
}

const codeBlockToDom = (): DOMOutputSpec => {
  return [
    'pre',
    { class: 'bg-background-gray-subtlest p-2 my-1 rounded-corner-6 w-max min-w-full' },
    ['code', 0],
  ]
}

defaultMarkdownSchema.nodes.heading.spec.toDOM = headingToDom
defaultMarkdownSchema.nodes.bullet_list.spec.toDOM = bulletListToDom
defaultMarkdownSchema.nodes.ordered_list.spec.toDOM = orderedListToDom
defaultMarkdownSchema.nodes.paragraph.spec.toDOM = paragraphToDom
defaultMarkdownSchema.nodes.blockquote.spec.toDOM = blockQuoteToDom
defaultMarkdownSchema.nodes.code_block.spec.toDOM = codeBlockToDom

/**
 * Custom schema for markdown in Go, based on the default ProseMirror
 * markdown schema.
 */
export const markdownSchema = defaultMarkdownSchema
