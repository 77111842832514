import * as sentry from '@sentry/vue'
import { watch } from 'vue'
import { useUser } from '../IdentityAndAccess/useUser'
import { useWorkspaces } from '../Workspaces/useWorkspaces'

/**
 * Sends information to sentry when the user or workspace changes.
 */
export const useSentryTracking = () => {
  const userStore = useUser()

  watch(
    () => userStore.user,
    (user) => {
      if (user) {
        sentry.setUser({
          email: user.email,
          id: user.id,
        })
      }
    },
    {
      immediate: true,
    },
  )

  const workspacesStore = useWorkspaces()
  watch(
    () => workspacesStore.currentWorkspace,
    (workspace) => {
      if (workspace) {
        sentry.setContext('Current Workspace', { id: workspace.id, name: workspace.name })
      } else {
        sentry.setContext('Current Workspace', null)
      }
    },
    {
      immediate: true,
    },
  )
}
