import Content from './Content.vue'
import Divider from './Divider.vue'
import Item from './Item.vue'
import Root from './Root.vue'

const Menu = {
  Root,
  Content,
  Item,
  Divider,
}

export default Menu
