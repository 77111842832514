<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink, useLink, type RouteLocationRaw } from 'vue-router'

const props = defineProps<{ active?: boolean; to?: RouteLocationRaw }>()

const { isActive: _isActive } = useLink({ to: props?.to ?? '/' })
const isActive = computed(() => {
  if (props.active !== undefined) {
    return props.active
  }

  return _isActive.value && !!props.to
})
</script>

<template>
  <component
    :is="to ? RouterLink : 'button'"
    :to="to"
    class="shrink-0 grow rounded-corner-6 bg-background-transparent px-1.5 py-1 text-sm-12px-default transition-colors data-[state='open']:bg-background-transparent-hovered hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    :class="isActive ? 'text-text' : 'text-text-subtlest'"
  >
    <slot />
  </component>
</template>
