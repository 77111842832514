<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import { nextTick, ref } from 'vue'

const props = defineProps<{
  width: number
}>()

const emit = defineEmits<{
  (e: 'resize', delta: number): void
}>()

const MIN_COL_WIDTH = 100

const resizeHandleRef = ref<HTMLElement | null>(null)
const wholeSpanRef = ref<HTMLElement | null>(null)
const dragging = ref(false)
const initialX = ref(0)
const delta = ref(0)

const setLeftAndTop = (): void => {
  if (!wholeSpanRef.value) return
  const gridContainer = document.getElementById('grid-container')
  const sidebar = document.getElementById('sidebar')
  const sidebarWidth = sidebar?.getBoundingClientRect().width ?? 0

  // Here's the calculation broken down:
  // 1. We get the resizeHandleRef's left position
  // 2. We need said left pos to be relative to the gridContainer, so
  // we subtract the gridContainer's left pos from it.
  // 3. We also need to account for the scroll position of the gridContainer.
  // 4. And the sidebar width, which should be subtracted from the left pos.
  // 5. We finally add the delta to the left pos, and
  // 6. We add 8 to center the line with the cursor
  const left =
    (resizeHandleRef.value?.getBoundingClientRect().left ?? 0) -
    (gridContainer?.getBoundingClientRect().left ?? 0) +
    (gridContainer?.scrollLeft ?? 0) -
    sidebarWidth +
    delta.value +
    8
  const top = gridContainer?.scrollTop ?? 0

  wholeSpanRef.value.style.translate = `${left}px ${top}px`

  /**
   * The blue vertical line that appears when resizing should begin at the top of the header cell and
   * continue until the top of the footer cell.
   */
  const footer = document.querySelector('[data-table-footer]')
  const footerTop = footer?.getBoundingClientRect().top
  const tableContainer = document.querySelector('[data-table-container]')
  const gridTop = tableContainer?.getBoundingClientRect().top

  if (typeof footerTop === 'number' && typeof gridTop === 'number') {
    wholeSpanRef.value.style.height = `${footerTop - gridTop}px`
  }
}

const onMouseDown = (e: MouseEvent) => {
  dragging.value = true
  initialX.value = e.clientX
  delta.value = 0

  nextTick(() => {
    if (wholeSpanRef.value) {
      setLeftAndTop()
    }
  })
}

useEventListener(document, 'mousemove', (e: MouseEvent) => {
  if (!dragging.value) return
  const deltaDragged = e.clientX - initialX.value
  const minAllowedDelta = -(props.width - MIN_COL_WIDTH)
  delta.value = Math.max(deltaDragged, minAllowedDelta)

  if (wholeSpanRef.value) {
    setLeftAndTop()
  }
})

useEventListener(document, 'mouseup', () => {
  if (!dragging.value) return
  dragging.value = false
  emit('resize', props.width + delta.value)
})
</script>

<template>
  <div
    ref="resizeHandleRef"
    data-test="handle"
    class="absolute right-0 h-5 w-3 cursor-col-resize rounded-full group-hover:visible"
    :class="dragging ? 'visible' : 'invisible'"
    @mousedown.stop="onMouseDown"
  >
    <div
      v-if="!dragging"
      class="ml-1 min-h-5 w-1 rounded-[3px] bg-background-gray-subtle"
    ></div>
  </div>

  <Teleport
    v-if="dragging"
    to="#grid-container"
  >
    <div
      ref="wholeSpanRef"
      class="absolute left-0 top-0 z-10 h-full border-l-2 border-background-primary"
    ></div>
  </Teleport>

  <!-- This is just here to add styling to the cursor -->
  <Teleport
    v-if="dragging"
    to="body"
  >
    <div class="absolute inset-0 z-10 cursor-col-resize" />
  </Teleport>
</template>
