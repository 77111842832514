<script lang="ts" setup>
/**
 * Used to render round avatar icons.
 *
 * The avatar icon can be given a url which then has it render an image, or it
 * will be given a text, from which it will extract 2 characters to render in a
 * styled manner.
 *
 * The 2 characters are either initials of the first to words in the given text,
 * or the first two characters (or less) of the single given word.
 */
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import { computed } from 'vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'
import { getRainbowColor } from './RainbowColor'

const props = withDefaults(
  defineProps<{
    fullText?: string | null
    iconName?: IconName
    url?: string | null
    size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    shape?: 'circle' | 'square'
    errorCondition?: boolean
    loadingCondition?: boolean
    noBackground?: boolean
  }>(),
  {
    fullText: undefined,
    url: undefined,
    iconName: undefined,
    size: 'md',
    shape: 'square',
    noBackground: false,
  },
)

const emit = defineEmits<{
  (e: 'error'): void
}>()

const color = computed(() => getRainbowColor(props.fullText ?? ''))
const style = computed(() => ({
  ...(!props.noBackground && {
    backgroundColor: color.value,
  }),
}))

const getIconText = (fullText: string): string => {
  const words = fullText.split(' ')
  if (props.size === '2xs') {
    return fullText.slice(0, 1)
  }

  return words.length >= 2 ? `${words[0][0]}${words[1][0]}` : fullText.substring(0, 2)
}
const iconText = computed(() => getIconText(props.fullText ?? ''))
</script>

<template>
  <div
    class="pointer-events-none grid place-items-center overflow-hidden"
    :class="[
      size === '2xs' && shape === 'square' && 'size-3 rounded-corner-4',
      size === 'xs' && shape === 'square' && 'size-4 rounded-corner-4',
      size === 'sm' && shape === 'square' && 'size-5 rounded-corner-4',
      size === 'md' && shape === 'square' && 'size-6 rounded-corner-6',
      size === 'lg' && shape === 'square' && 'size-8 rounded-corner-10',
      size === 'xl' && shape === 'square' && 'size-16 rounded-corner-16',
      size === '2xs' && shape === 'circle' && 'size-3 rounded-full',
      size === 'xs' && shape === 'circle' && 'size-4 rounded-full',
      size === 'sm' && shape === 'circle' && 'size-5 rounded-full',
      size === 'md' && shape === 'circle' && 'size-6 rounded-full',
      size === 'lg' && shape === 'circle' && 'size-8 rounded-full',
      size === 'xl' && shape === 'circle' && 'size-16 rounded-full',
    ]"
  >
    <div
      v-if="!errorCondition && url"
      role="img"
      class="size-full"
    >
      <ObjectURLImage
        class="size-full object-cover"
        :url="url"
        :loading="loadingCondition ?? false"
        @error="emit('error')"
      />
    </div>
    <span
      v-else-if="iconName"
      class="inline-flex size-full items-center justify-center bg-background-gray-subtlest"
    >
      <IconSprite
        :icon="iconName"
        :size="size === '2xs' ? 'xs' : size"
      />
    </span>
    <span
      v-else
      class="inline-flex size-full items-center justify-center uppercase text-text-inverted"
      :class="[
        size === 'sm' || size === 'xs' || (size === '2xs' && 'text-xxs-8px-default'),
        size === 'md' && 'text-sm-12px-default',
        size === 'lg' && 'text-md-13px-default',
        size === 'xl' && 'text-md-13px-default',
      ]"
      v-bind="$attrs"
      :style="style"
      >{{ iconText }}</span
    >
  </div>
</template>
