<script setup lang="ts">
import type { ResourceRole } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import type { OffsetOptions } from '@floating-ui/vue'
import { computed } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { roleLabelMap } from '../IdentityAndAccess/roleConfig'

type AllowedRole = Extract<ResourceRole, 'admin' | 'editor' | 'worker'>

const props = defineProps<{
  /** The selected role */
  value: ResourceRole
  /** floating-ui offset options to apply to the dropdown menu */
  offset?: OffsetOptions
  /** All roles that the user is able to invite/update */
  allowedRoles: AllowedRole[]
}>()

const emit = defineEmits<{
  (e: 'change', value: AllowedRole): void
}>()

type Option = {
  id: AllowedRole
  data: {
    label: string
    id: AllowedRole
    description: string
  }
}

const hasWorkerRole = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)

const OPTIONS = computed<Option[]>(() => {
  const roles: Option[] = []

  if (props.allowedRoles.includes('admin')) {
    roles.push({
      id: 'admin',
      data: {
        label: roleLabelMap['admin'],
        id: 'admin',
        description: 'Has full access to view, edit, and share all projects within the workspace.',
      },
    })
  }

  if (props.allowedRoles.includes('editor')) {
    roles.push({
      id: 'editor',
      data: {
        label: roleLabelMap['editor'],
        id: 'editor',
        description:
          'Can view and edit their own projects or those they’ve been invited to collaborate on.',
      },
    })
  }

  if (hasWorkerRole.value && props.allowedRoles.includes('worker')) {
    roles.push({
      id: 'worker',
      data: {
        label: roleLabelMap['worker'],
        id: 'worker',
        description: 'Can view only projects where they have assigned work.',
      },
    })
  }

  return roles
})

const onSelect = (option: AllowedRole) => {
  emit('change', option)
}
</script>

<template>
  <SelectDropdown :offset="offset">
    <template #trigger="{ isOpen }">
      <slot
        name="trigger"
        :is-open="isOpen"
      />
    </template>
    <template #dropdown="{ close }">
      <ListMenu
        :items="OPTIONS"
        class="max-w-72"
        :initial-active-item-predicate="(item) => item.id === value"
      >
        <template #item="{ item, active, key, setActiveItem }">
          <ListMenuItem
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onSelect(item.data.id), close()"
          >
            <template #prefix>
              <IconSprite
                :icon="value === item.id ? 'check' : 'blank'"
                class="mr-1 size-5 text-icon-subtle"
              />
            </template>
            <div>
              <div class="text-sm-12px-default text-text">{{ item.data.label }}</div>
              <div class="text-sm-12px-light text-text-subtle">{{ item.data.description }}</div>
            </div>
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </SelectDropdown>
</template>
