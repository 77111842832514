<script setup lang="ts">
import type { PlanTemplate } from '@/modules/Billing/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed } from 'vue'
import { formatLimitValue } from '../Billing/formatLimitValue'

type Value = string | boolean | number | undefined

const props = withDefaults(
  defineProps<{
    isActivePlan?: boolean
    value?: Value
    period?: PlanTemplate['billingPeriod']
    estimateEnabled?: boolean
  }>(),
  {
    isActivePlan: false,
    value: undefined,
    period: undefined,
    estimateEnabled: false,
  },
)

const tokensToValueEstimate = (tokens: number | string) => {
  if (typeof tokens === 'string' || !props.estimateEnabled) {
    return ''
  }
  return ` (${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(tokens / 10_000)})`
}

const ariaLabel = computed<'yes' | 'no' | undefined>(() => {
  if (typeof props.value === 'boolean') {
    return props.value ? 'yes' : 'no'
  }

  return undefined
})
</script>

<template>
  <td
    class="h-7 px-4 py-1 text-sm-12px-light text-text"
    :class="isActivePlan ? 'bg-surface-secondary' : 'bg-background-transparent'"
    :ariaLabel="ariaLabel"
  >
    <template v-if="typeof value === 'string' || typeof value === 'number'">
      {{ formatLimitValue(value, true) }}{{ tokensToValueEstimate(value) }}
      {{ period === 'monthly' ? ' / month' : period === 'yearly' ? ' / year' : '' }}
    </template>
    <IconSprite
      v-else-if="typeof value === 'boolean'"
      class="inline-flex"
      :class="value ? 'text-icon-subtlest' : 'text-icon-disabled'"
      :icon="value ? 'check' : 'close'"
    />
  </td>
</template>
