<template>
  <div>
    <h2
      v-if="$slots.header"
      class="px-4 py-1.5 text-xs-11px-bold text-text-subtlest"
    >
      <slot name="header" />
    </h2>
    <div class="mt-0.5 flex flex-col px-2">
      <slot />
    </div>
  </div>
</template>
