import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import type { Item as MentionableItem } from '@/sharedComponents/MentionableTextInput.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useLibraryStore } from '../Library/libraryStore'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import { useProperty } from './useProperty'

/**
 * Provides functions that are used to handle events emitted from the mentionable
 * text input, which adds input properties to AI tools via a mentionable text area.
 */
export const useMentionableInputs = () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const propertyStore = useProperty()
  const libraryStore = useLibraryStore()

  const onAddMention = (mention: MentionableItem) => {
    if (mention.group === 'Library') {
      if (propertyStore.editedInputs.some((input) => input.entityId === mention.id)) {
        return
      }
      assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

      const inputType = libraryStore.getItemType(mention.id)
      propertyStore.editedInputs.push({
        entityId: mention.id,
        propertyId:
          inputType === 'file'
            ? libraryStore.library.fileProperty.id
            : libraryStore.library.textProperty.id,
      })

      captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_MENTIONED, { id: mention.id, value: true })
      return
    }

    if (propertyStore.editedInputs.some((input) => input.propertyId === mention.id)) {
      return
    }

    propertyStore.editedInputs.push({
      propertyId: mention.id,
    })

    captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_MENTIONED, { id: mention.id, value: true })
  }

  const welcomeTour = useWelcomeTour()
  const onRemoveMention = (mention: MentionableItem) => {
    if (welcomeTour.status === 'IN_PROGRESS' || !propertyStore.editedProperty) return
    if (mention.group === 'Library') {
      const filePropertyId = libraryStore.library?.fileProperty.id
      assertIsNotNullOrUndefined(filePropertyId, 'Library is not loaded')

      propertyStore.editedProperty.inputs = propertyStore.editedInputs.filter(
        ({ entityId, propertyId }) => entityId !== mention.id || propertyId !== filePropertyId,
      )
    } else {
      propertyStore.editedProperty.inputs = propertyStore.editedInputs.filter(
        ({ propertyId }) => propertyId !== mention.id,
      )
    }

    captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_MENTIONED, { id: mention.id, value: false })
  }
  return {
    onAddMention,
    onRemoveMention,
  }
}
