<script setup lang="ts">
import { computed, ref, toRef } from 'vue'
import { RouterLink } from 'vue-router'

import IconSprite from '@/uiKit/IconSprite.vue'

import { useTableCellFocus } from './useTableCellFocus'

import CsvFileContent from '@/modules/Project/CsvFileContent.vue'
import { useFileType } from '@/sharedComposables/useFileType'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconButton from '@/uiKit/IconButton.vue'
import AudioFileContent from './AudioFileContent.vue'
import ImageFileContent from './ImageFileContent.vue'
import PdfFileContent from './PdfFileContent.vue'
import { injectPinnedProp } from './usePinnedColumn'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { PINNED_SELECTED_CELL_Z_INDEX, SELECTED_CELL_Z_INDEX } from './useTableZIndices'

/**
 * Renders a file field cell once the file has been uploaded.
 * Displays the filename and if the file is a supported type,
 * it will render an inline preview of the file when the cell
 * is selected.
 */

const props = defineProps<{
  filename: string
  isFocused: boolean
  isSelected: boolean
  url: string
  pdfProjectId?: string | null
  entityId?: string
  projectId?: string
  workspaceId?: string
  hasSelectedRange: boolean
}>()

const emit = defineEmits<{
  (e: 'blur'): void
  (e: 'replace', file: File): void
}>()

const isPinned = injectPinnedProp()

const fileType = useFileType(computed(() => props.url))

const showPreview = computed(() => !props.hasSelectedRange && props.isSelected && fileType.value)

const cell = ref<HTMLElement | null>(null)
useTableCellFocus({
  cell: cell,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const onReplaceFile = (event: Event) => {
  if (!(event.target instanceof HTMLInputElement)) {
    throw new Error('Event target is not an input element')
  }

  const file = event.target.files?.[0]
  if (!file) {
    return
  }

  emit('replace', file)
}

const resolveSubProjectRoute = useResolveProjectRoute()
</script>

<template>
  <div
    ref="cell"
    class="flex size-full flex-col items-start truncate rounded-corner-4 text-sm-12px-default text-text-subtle"
    :class="[
      isSelected
        ? 'absolute left-0 top-0 w-full bg-surface-primary shadow-md outline outline-2 outline-border-focused'
        : 'p-1.5',
      showPreview ? `min-h-[400px] min-w-[352px] gap-2` : 'px-1.5 py-[5px]',
    ]"
    :style="{
      zIndex: isSelected
        ? isPinned
          ? PINNED_SELECTED_CELL_Z_INDEX
          : SELECTED_CELL_Z_INDEX
        : undefined,
    }"
  >
    <div
      class="flex w-full items-center justify-between gap-1 truncate"
      :class="showPreview && 'p-1.5'"
    >
      <div class="min-w-0">
        <RouterLink
          v-if="isSelected && pdfProjectId && entityId && projectId && workspaceId"
          title="Open PDF"
          :to="
            resolveSubProjectRoute({
              workspaceId,
              parentEntityId: entityId,
              parentProjectId: projectId,
              projectId: pdfProjectId,
            })
          "
          aria-label="Open PDF"
          @click.stop
        >
          <BadgeItem
            :title="filename"
            variant="blue"
            size="sm"
            leading-icon="file"
            :label="filename"
          />
        </RouterLink>

        <BadgeItem
          v-else
          :title="filename"
          variant="neutral"
          size="sm"
          leading-icon="file"
          :label="filename"
        />
      </div>
      <div class="flex">
        <template v-if="isSelected">
          <a
            :href="url"
            target="_blank"
            :aria-label="`Download ${filename || 'file'}`"
          >
            <IconButton
              role="presentation"
              tabindex="-1"
              size="sm"
              variant="transparent"
              icon="arrow-bottom-circle"
              :aria-label="`Download ${filename || 'file'}`"
            />
          </a>

          <label
            for="file-input"
            class="flex size-5 cursor-pointer items-center justify-center rounded-corner-4 p-0.5 hover:bg-background-transparent-hovered"
            aria-label="Replace file"
          >
            <IconSprite
              role="presentation"
              class="text-icon-subtle"
              size="sm"
              icon="edit"
            />
          </label>

          <input
            id="file-input"
            name="file-input"
            class="hidden"
            type="file"
            @change="onReplaceFile"
          />
        </template>
      </div>
    </div>

    <template v-if="showPreview">
      <PdfFileContent
        v-if="fileType === 'pdf'"
        ref="pdfFileContentRef"
        :file-path="url"
      />

      <AudioFileContent
        v-else-if="fileType === 'audio'"
        :url="url"
      />

      <ImageFileContent
        v-else-if="fileType === 'image'"
        :url="url"
        class="rounded-md"
      />
      <CsvFileContent
        v-else-if="fileType === 'csv'"
        :url="url"
      />
    </template>
  </div>
</template>
