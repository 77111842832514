import { computed, onBeforeUnmount, unref, watch } from 'vue'

import type { RemoveProjectResponse } from '@/backend/removeProject'
import { type ProjectResponse, type TriggerResponse } from '@/backend/types'
import { serializeProperty } from '@/modules/Project/useProject'
import { useProjects } from '@/modules/Projects/useProjects'
import { useRecentProjects } from '@/modules/Projects/useRecentProjects'

import { serializeTrigger, useTriggers } from '@/modules/Triggers/useTriggers'
import type { Workspace } from '@/modules/Workspaces/useWorkspaces'

import type { ImportResponse } from '@/backend/startImport'
import { useWebSocketChannel } from '@/sharedComposables/useWebSocketChannel'
import { useImports } from './useImports'

export const useWorkspaceChannel = (workspaceId: Workspace['id']) => {
  const projectsStore = useProjects()
  const triggersStore = useTriggers()
  const recentProjectsStore = useRecentProjects()
  const importsStore = useImports()

  const topic = computed<`workspace:${string}`>(() => `workspace:${workspaceId}`)

  const { channel, channelState, leaveChannel } = useWebSocketChannel(topic)

  onBeforeUnmount(async () => {
    await leaveChannel()
  })

  watch(
    channel,
    () => {
      const wid = unref(workspaceId)
      if (!wid) return
      channel.value?.on('project:created', (data: ProjectResponse) => {
        projectsStore.addProject({
          id: data.id,
          name: data.name,
          workspaceId: data.workspace_id,
          propertyCount: data.properties.length,
          parentProperty: data.parent_property
            ? serializeProperty(data.parent_property)
            : undefined,
          coverImageUrls: data.cover_image_urls,
          updatedAt: data.updated_at,
        })
        recentProjectsStore.refresh(wid)
      })

      channel.value?.on('project:deleted', (data: RemoveProjectResponse) => {
        projectsStore.removeProject(data.id)
        recentProjectsStore.refresh(wid)
      })

      channel.value?.on('import:started', (data: ImportResponse) => {
        if (data.status === 'error' || data.status === 'complete') {
          // Let the import:progress event take care of the toast
          return
        }
        importsStore.addToast(data)
      })

      channel.value?.on('import:processed', (data: ImportResponse) => {
        importsStore.concludeToast(data)
      })

      channel.value?.on('project:updated', (data: ProjectResponse) => {
        projectsStore.updateProject(data.id, {
          name: data.name,
          workspaceId: data.workspace_id,
          propertyCount: data.properties.length,
          parentProperty: data.parent_property
            ? serializeProperty(data.parent_property)
            : undefined,
          coverImageUrls: data.cover_image_urls,
        })
        recentProjectsStore.refresh(wid)
      })

      channel.value?.on('trigger:created', (data: TriggerResponse) => {
        triggersStore.addTrigger(serializeTrigger(data))
      })

      channel.value?.on('trigger:deleted', (data: { id: string }) => {
        triggersStore.removeTrigger(data.id)
      })
    },
    { immediate: true },
  )

  return {
    channel,
    channelState,
    leaveChannel,
  }
}
