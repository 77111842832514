<script setup lang="ts">
import { storeToRefs } from 'pinia'

import CheckBox from '@/uiKit/CheckBox.vue'

import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useProject } from './useProject'

const project = useProject()
const { toggleAllEntities } = project
const { selectedEntityIds, activeView, allEntitiesSelected } = storeToRefs(project)

const permissionsStore = usePermissionsStore()
</script>

<template>
  <div class="inline-flex h-8 items-center justify-center gap-2 px-3 py-1.5 hover:cursor-pointer">
    <CheckBox
      v-if="permissionsStore.currentProjectPermissions.update_entities"
      :indeterminate="
        selectedEntityIds.size > 0 &&
        activeView &&
        activeView.entities &&
        selectedEntityIds.size < activeView.entities.length
      "
      :checked="allEntitiesSelected"
      click.native.stop
      @change="toggleAllEntities"
    />
  </div>
</template>
