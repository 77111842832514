<script setup lang="ts">
import { computed } from 'vue'

import { type CollectionProperty } from '@/modules/Project/useProject'
import type { TurnPropRequired } from '@/shared/types'
import SimpleTable from '@/uiKit/SimpleTable.vue'
import { computedAsync } from '@vueuse/core'
import { useListEntities } from './useListEntities'

const props = defineProps<{
  workspaceId: string
  projectId: string
  entityId: string
  property: CollectionProperty
}>()

const MAX_ENTITY_NUMBER = 50

const availableProperties = computed(() =>
  (props.property.config.properties ?? []).filter(
    (x): x is TurnPropRequired<typeof x, 'property_id'> => x.property_id !== undefined,
  ),
)

const listEntities = useListEntities()
const data = computedAsync(async () => {
  const collectionProjectId = props.property.config.subprojectConfig.child_project_id

  const entitiesResult = await listEntities({
    workspaceId: props.workspaceId,
    projectId: collectionProjectId,
    start: 0,
    end: MAX_ENTITY_NUMBER,
    parentEntityId: props.entityId,
  })

  if (!entitiesResult.ok) return []

  return entitiesResult.data.data.map((data) => ({
    id: data.id,
    data: availableProperties.value.reduce(
      (acc: { [key: string]: string | string[] | null }, { property_id }) => {
        if (!property_id) return acc

        const field = Object.values(data.fields).find((field) => field?.property_id === property_id)
        if (!field) return acc

        acc[property_id] = field.manual_value.value as string | string[] | null
        return acc
      },
      {},
    ),
  }))
})
</script>

<template>
  <div class="size-full overflow-scroll px-1">
    <SimpleTable
      v-if="data"
      :columns="availableProperties.map((p) => ({ key: p.property_id, label: p.name }))"
      :data="data"
      :hover="false"
    >
      <template
        v-for="availableProperty in availableProperties"
        #[availableProperty.property_id]="{ row }"
      >
        {{ row.data[availableProperty.property_id] }}
      </template>
    </SimpleTable>
  </div>
</template>
