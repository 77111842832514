<script setup lang="ts">
/**
 * Renders the "current workpace" button/trigger that opens the account dropdown,
 * With a  "select a workspace" fallback
 */

defineProps<{ displayName?: boolean }>()

import { storeToRefs } from 'pinia'

import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useWorkspaces } from './useWorkspaces'

const store = useWorkspaces()

const { currentWorkspace } = storeToRefs(store)

const onImageSrcError = () => {
  if (currentWorkspace.value) {
    store.setIconDownloadError(currentWorkspace.value.id, true)
  }
}
</script>

<template>
  <button
    class="flex items-center rounded-corner-8 bg-background-transparent p-1 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    aria-label="Current Workspace"
  >
    <div class="grow">
      <AvatarIcon
        :url="currentWorkspace?.iconUrl"
        :full-text="currentWorkspace?.name"
        size="sm"
        :error-condition="currentWorkspace?.iconDownloadError"
        :loading-condition="currentWorkspace?.iconUploading"
        @error="onImageSrcError"
      />
    </div>
    <h1
      class="truncate px-1.5 py-0.5 text-sm-12px-default"
      data-sentry="unmask"
    >
      {{ displayName && currentWorkspace?.name ? currentWorkspace?.name : 'Select a workspace' }}
    </h1>
    <IconSprite
      icon="chevron-bottom"
      size="lg"
      class="text-icon-subtlest"
    />
  </button>
</template>
