<script setup lang="ts">
import { Background } from '@vue-flow/background'
import { MarkerType, VueFlow, useVueFlow, type Edge, type Node } from '@vue-flow/core'
import { computed, nextTick, ref, watch } from 'vue'

import { isSelectProperty } from '@/shared/utils/typeGuards'
import EmptyWorkflow from './EmptyWorkflow.vue'
import FilterBar from './Filters/FilterBar.vue'
import ProjectTableViewsTabstrip from './ProjectTableViewsTabstrip.vue'
import ProjectTopBar from './ProjectTopBar.vue'
import { useLayout, type LayoutDirection } from './useLayout'
import { useProject } from './useProject'
import { useProjectSync } from './useProjectSync'
import { getSelectPropertyOptionColor, getViewColor } from './utils'
import WorkflowEdge from './WorkflowEdge.vue'
import WorkflowNode from './WorkflowNode.vue'

const props = defineProps<{
  workspaceId: string
  projectId: string
}>()

const { fitView, zoomOut } = useVueFlow()
const { layout } = useLayout()

const projectStore = useProject()
useProjectSync(props)

const getNodes = (): Node[] => {
  const edges = getEdges()

  const nodeList: Node[] = []
  for (const view of projectStore.views) {
    const isSource = edges.find((edge) => edge.source === view.id)
    const isTarget = edges.find((edge) => edge.target === view.id)

    const viewEntities = projectStore.viewInfo(view.id)?.entities ?? []

    let nodeType = 'default'
    if (isSource && isTarget) {
      nodeType = 'default'
    } else if (isSource) {
      nodeType = 'input'
    } else if (isTarget) {
      nodeType = 'output'
    }

    const color = getViewColor(view, projectStore.properties)

    nodeList.push({
      id: view.id,
      label: view.name,
      position: { x: 0, y: 0 },
      type: nodeType,
      draggable: true,
      selectable: false,
      connectable: false,
      focusable: false,
      deletable: false,
      width: 200,
      data: {
        backgroundColor:
          view.name === 'main' || color === 'rainbow-17'
            ? 'var(--color-background-gray-subtlest)'
            : `var(--${color}-desaturated)`,
        color:
          view.name === 'main' || color === 'rainbow-17'
            ? 'var(--color-icon-subtle)'
            : `var(--${color}-saturated)`,
        numEntities: viewEntities.length,
      },
    })
  }

  return nodeList
}

const getEdges = (): Edge[] => {
  // First, we filter out the triggers that are not related to the current project
  // and map each trigger to a list of all sources that point to the same target view
  const conditions = projectStore.views.flatMap((view) =>
    view.filters.flatMap((filter) => {
      const property = projectStore.properties.find(
        (property) => property.id === filter.property_id,
      )
      if (!isSelectProperty(property)) return []

      const optionColor = getSelectPropertyOptionColor(property, filter.select_option_value)

      const sources = projectStore.views.filter((view) =>
        (view.propertyIds || []).includes(filter.property_id),
      )

      return sources.map((source) => ({
        source,
        target: view.id,
        propertyName: property.name,
        optionValue: filter.select_option_value,
        optionColor,
      }))
    }),
  )

  // For (source, target) pairs that are the same, we aggregage propertyName, optionValue, and optionColor
  const uniqueSourceIds = Array.from(new Set(conditions.map((condition) => condition.source.id)))
  const edgeList: Edge[] = []
  for (const sourceId of uniqueSourceIds) {
    const filteredConditions = conditions.filter((condition) => condition.source.id === sourceId)
    const uniqueTargetIds = Array.from(
      new Set(filteredConditions.map((condition) => condition.target)),
    )
    for (const targetId of uniqueTargetIds) {
      const filteredConditionsByTarget = filteredConditions.filter(
        (condition) => condition.target === targetId,
      )

      const propertyNames = filteredConditionsByTarget.map((condition) => condition.propertyName)
      const optionValues = filteredConditionsByTarget.map((condition) => condition.optionValue)
      const optionColors = filteredConditionsByTarget.map((condition) => condition.optionColor)

      edgeList.push({
        id: `${sourceId}::${targetId}`,
        type: 'condition',
        source: sourceId,
        target: targetId,
        markerEnd: MarkerType.ArrowClosed,
        updatable: false,
        selectable: false,
        focusable: false,
        deletable: false,
        data: {
          propertyNames,
          optionValues,
          optionColors,
        },
      })
    }
  }

  return edgeList
}

const nodes = ref(getNodes())
const edges = ref(getEdges())

watch(
  () => projectStore.views,
  () => {
    nodes.value = getNodes()
    edges.value = getEdges()
  },
  { immediate: true },
)

const isEmpty = computed(() => nodes.value.length <= 1)

const layoutGraph = async (direction: LayoutDirection) => {
  nodes.value = layout(nodes.value, edges.value, direction)

  nextTick(() => {
    fitView()
    zoomOut()
  })
}
</script>

<template>
  <div class="flex size-full flex-col">
    <ProjectTopBar />

    <EmptyWorkflow v-if="isEmpty" />
    <div
      v-else
      class="flex h-full flex-col [contain:strict]"
      data-test="workflow-graph"
    >
      <VueFlow
        :nodes="nodes"
        :edges="edges"
        nodes-draggable
        :min-zoom="0.1"
        :max-zoom="10"
        @nodes-initialized="layoutGraph('LR')"
      >
        <Background
          pattern-color="var(--color-background-gray-subtle-hovered)"
          :gap="15"
          :size="1"
        />

        <template #node-default="nodeProps">
          <WorkflowNode v-bind="nodeProps" />
        </template>

        <template #node-input="nodeProps">
          <WorkflowNode v-bind="nodeProps" />
        </template>

        <template #node-output="nodeProps">
          <WorkflowNode v-bind="nodeProps" />
        </template>

        <template #edge-condition="edgeProps">
          <WorkflowEdge v-bind="edgeProps" />
        </template>
      </VueFlow>
    </div>

    <div class="flex flex-col">
      <FilterBar />
      <ProjectTableViewsTabstrip />
    </div>
  </div>
</template>
