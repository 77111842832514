import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { useProjectPermissionsStore } from '@/modules/Project/Permissions/projectPermissionsStore'
import { computed } from 'vue'

export function useProjectRole() {
  const userStore = useUser()
  const projectPermissionsStore = useProjectPermissionsStore()

  const currentProjectRole = computed(() => {
    const { id = '' } = userStore.user || {}
    const userRole = projectPermissionsStore.workspaceMemberRoles[id]
    return userRole || projectPermissionsStore.defaultRole
  })

  return { currentProjectRole }
}
