<script lang="ts" setup>
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

import ProjectName from '@/modules/Project/ProjectName.vue'
import type { Project } from '@/modules/Projects/useProjects'
import ProjectDropdown from '@/sharedComponents/ProjectDropdown.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

import ObjectURLImage from './ObjectURLImage.vue'
import { useProjects } from './useProjects'
import { useRecentProjects } from './useRecentProjects'

const props = defineProps<{ project: Project }>()

const projectRoute = computed(() => ({
  name: 'WorkspaceProject',
  params: { workspaceId: props.project.workspaceId, projectId: props.project.id },
}))

const projectsStore = useProjects()
const recentProjectsStore = useRecentProjects()

const onImageSrcError = () => {
  projectsStore.setCoverImageDownloadError(props.project.id, true)
  recentProjectsStore.setCoverImageDownloadError(props.project.id, true)
}

const setCoverImageUploading = (value: boolean) => {
  projectsStore.setCoverImageDownloadError(props.project.id, false)
  recentProjectsStore.setCoverImageDownloadError(props.project.id, false)
  projectsStore.setCoverImageUploading(props.project.id, value)
  recentProjectsStore.setCoverImageUploading(props.project.id, value)
}
</script>

<template>
  <RouterLink
    :to="projectRoute"
    class="contents"
  >
    <div
      data-test="project"
      class="flex h-[260px] min-w-[200px] max-w-[360px] flex-1 flex-col items-start justify-end gap-2 overflow-hidden rounded-corner-10 bg-background-gray-subtlest"
    >
      <div class="size-full rounded-corner-10 transition-all duration-300 hover:scale-110">
        <div
          v-if="project.coverImageDownloadError"
          class="flex size-full items-center justify-center text-text-subtle"
        >
          <IconSprite
            icon="file"
            size="xxxxl"
          />
        </div>
        <div
          v-else-if="project.coverImageUploading"
          class="flex size-full items-center justify-center text-text-subtle"
        >
          <CircularProgress
            size="lg"
            class="animate-spin"
          />
        </div>
        <div
          class="size-full rounded-corner-10 bg-gradient-to-b from-surface-primary-persist via-surface-primary-persist to-background-gray-sunken"
        >
          <ObjectURLImage
            class="size-full rounded-corner-10 object-cover"
            :url="project.coverImageUrls.high"
            :loading="project.coverImageUploading ?? false"
            @error="onImageSrcError"
          />
        </div>
      </div>
      <div
        class="pointer-events-none z-2 mt-[-76px] flex w-full shrink gap-1 p-4"
        :data-theme="!project.coverImageDownloadError ? 'dark' : ''"
        :class="
          !project.coverImageDownloadError &&
          !project.coverImageUploading &&
          'bg-gradient-to-t from-surface-popover-inverted to-background-transparent'
        "
      >
        <div class="grow flex-col">
          <div
            class="line-clamp-1 text-md-13px-bold"
            :class="[
              project.coverImageDownloadError || project.coverImageUploading
                ? 'text-text'
                : 'text-text-inverted-irreversible',
            ]"
          >
            <ProjectName :project="project" />
          </div>
          <div
            class="text-sm-12px-light"
            :class="[
              project.coverImageDownloadError
                ? 'text-text-subtlest'
                : 'text-text-inverted-subtle-irreversible',
            ]"
          >
            {{ project.propertyCount }} properties
          </div>
        </div>
        <div class="pointer-events-auto flex items-end">
          <ProjectDropdown
            :workspace-id="project.workspaceId"
            :project-id="project.id"
            size="lg"
            @cover-uploading="setCoverImageUploading(true)"
            @cover-uploaded="setCoverImageUploading(false)"
            @cover-upload-error="setCoverImageUploading(false)"
          />
        </div>
      </div>
    </div>
  </RouterLink>
</template>
