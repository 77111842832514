import { getEntity } from '@/backend/getEntity'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useRefreshUsage } from '../Billing/useRefreshUsage'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { entityPassesFilters } from './Filters/entityPassesFilters'
import { fieldMaybePassesFilters } from './Filters/fieldMaybePassesFilters'
import { useEntity } from './useEntity'
import { useFilters } from './useFilters'
import { serializeEntity, serializeField, useProject } from './useProject'
import type { ProjectChannelHandlers } from './useProjectChannel'

/**
 * Returns a set of handlers for the project channel. The handlers handle
 * websocket events for the project channel and update the stores accordingly.
 */
export const useProjectChannelHandlers = (): ProjectChannelHandlers => {
  const projectStore = useProject()
  const entityStore = useEntity()
  const filterStore = useFilters()
  const workspaceStore = useWorkspaces()

  const setStale = () => {
    projectStore.setEntitiesStale(true)
    entityStore.setEntitiesStale(true)
  }
  const { isOpen: sidebarIsOpen } = useSidebar()
  const { refreshUsage, hasSkippedRefresh } = useRefreshUsage()
  /**
   * The usage towards the workspace's current limits should be kept up to date
   * so the user can see their usage increasing as they take actions in the UI.
   */
  const refreshUsageIfSidebarIsOpen = () => {
    // Only refresh usage if the sidebar is open, as otherwise we will
    // put unnecessary load on the backend. If the sidebar is closed then
    // the user will not be able to see their usage increasing anyway.
    if (sidebarIsOpen.value) {
      refreshUsage()
      hasSkippedRefresh.value = false
    } else {
      hasSkippedRefresh.value = true
    }
  }

  return {
    onUpdateProperty: (property) => {
      projectStore.upsertProperty(property)
      setStale()
    },
    onDeleteProperty: (propertyId) => {
      projectStore.removeProperty(propertyId)
      refreshUsageIfSidebarIsOpen()
    },
    onUpdateEntity: (entity) => {
      const serializedEntity = serializeEntity(entity)
      const passesCurrentFilter = entityPassesFilters(serializedEntity, filterStore.currentFilter)
      if (passesCurrentFilter) {
        projectStore.upsertEntity(serializedEntity)
        entityStore.updateEntity(serializedEntity)
      }

      refreshUsageIfSidebarIsOpen()
    },
    onCreateEntity: (entity) => {
      const serializedEntity = serializeEntity(entity)
      const passesCurrentFilter = entityPassesFilters(serializedEntity, filterStore.currentFilter)
      if (passesCurrentFilter) {
        projectStore.upsertEntity(serializedEntity)
      }

      refreshUsageIfSidebarIsOpen()
    },
    onDeleteEntities: (entityIds) => {
      projectStore.removeEntitiesById(entityIds)
      entityStore.removeEntities(entityIds)
      refreshUsageIfSidebarIsOpen()
    },
    onDeleteActiveView: ({ entityId, viewId }) => {
      projectStore.removeEntityFromView(entityId, viewId)
      refreshUsageIfSidebarIsOpen()
    },
    onUpdateField: async (field) => {
      /**
       * This is where we handle the `field:updated` websocket message.
       * The logic here is a bit annoying when there are filters,
       * because the field doesn't contain all the information we need.
       * So if there is a filter and we're not sure if the entity fails
       * the filter, we need to load the full entity to check.
       */

      const serializedField = serializeField(field)

      // If no filter is applied, then we can be sure that the field change should
      // be made visible in the table
      if (!filterStore.currentFilter || filterStore.currentFilter.filters.length === 0) {
        projectStore.updateField(serializedField)
        entityStore.updateField(serializedField)
        refreshUsageIfSidebarIsOpen()
        return
      }

      const hasMaybePassedFilter = fieldMaybePassesFilters(
        serializedField,
        filterStore.currentFilter,
      )

      // The new field would definitely cause the entity to fail the filter, so
      // we can remove the entity from the table
      if (!hasMaybePassedFilter) {
        projectStore.removeEntitiesById([serializedField.entityId])
        entityStore.removeEntities([serializedField.entityId])
        refreshUsageIfSidebarIsOpen()
        return
      }

      // This is where it gets tricky. The field change might cause the entity to
      // pass the filter, but we can't be sure. We need to load the full entity to
      // know for certain.
      assertIsNotNullOrUndefined(workspaceStore.currentWorkspace, 'No current workspace')
      assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
      const entityResponse = await getEntity(
        workspaceStore.currentWorkspace.id,
        projectStore.projectId,
        serializedField.entityId,
      )

      if (!entityResponse.ok) {
        throw new Error(entityResponse.error.message)
      }

      const entity = serializeEntity(entityResponse.data)
      const passesCurrentFilter = entityPassesFilters(entity, filterStore.currentFilter)

      if (passesCurrentFilter) {
        projectStore.upsertEntity(entity)
        entityStore.updateEntity(entity)
      }
    },
    onUpdateView: (view) => {
      projectStore.upsertView(view)
      setStale()
    },
    onDeleteView: (viewId) => {
      projectStore.removeView(viewId)
      refreshUsageIfSidebarIsOpen()
    },
    onSetStale: setStale,
  }
}
