import { useNodeViewFactory } from '@prosemirror-adapter/vue'
import ProseMirrorMention, { getMentionContainer } from './ProseMirrorMention.vue'

/**
 * Provides ProseMirror NodeViews - NodeViews make it so that we can
 * render Vue components inside the ProseMirror editor.
 * @see https://prosemirror.net/docs/ref/#view.EditorProps.nodeViews
 *
 * This function will return an object that maps each node type to a
 * ProseMirror NodeView. Any node types not included here will be
 * rendered as regular ProseMirror nodes, following the spec from
 * each node's `toDOM` method.
 */
export const useProseMirrorComponents = () => {
  const nodeViewFactory = useNodeViewFactory()
  if (!nodeViewFactory) {
    return
  }

  return {
    mention: nodeViewFactory({
      component: ProseMirrorMention,
      as: getMentionContainer,
    }),
  }
}
