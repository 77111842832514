import type { operations } from '@/api'

import { post } from './client'

export type UploadProjectCoverImagePath = `/api/workspaces/${string}/projects/${string}`

export type UpdateProjectCoverImageResponse =
  operations['project-update-cover-image']['responses']['200']['content']['application/json']

export type UpdateProjectCoverImageRequest = Exclude<
  operations['project-update-cover-image']['requestBody'],
  undefined
>['content']['multipart/form-data']

export const uploadProjectCoverImage = async ({
  workspaceId,
  projectId,
  formData,
}: {
  workspaceId: string
  projectId: string
  formData: FormData
}) => {
  return post<unknown, UpdateProjectCoverImageResponse, UploadProjectCoverImagePath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/cover_image`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
}
