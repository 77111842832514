import * as sentry from '@sentry/vue'
import posthog from 'posthog-js'
import { type App } from 'vue'

export default {
  install(app: App<Element>) {
    if (!import.meta.env.VITE_POSTHOG_API_KEY || !import.meta.env.VITE_POSTHOG_INSTANCE) {
      return
    }

    try {
      app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_INSTANCE,
      })
    } catch (e) {
      // It would suck if a PostHog error caused a function to throw. So catch it here
      // and send to Sentry.
      const message = e instanceof Error ? e.message : 'Error when initializing PostHog'
      sentry.captureException(message)
    }
  },
}
