import type { operations } from '@/api'
import { post } from './client'

export type RecalculateEntityPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/recalculate`
export type RecalculateEntityRequest = Exclude<
  operations['entity-recalculate-single']['requestBody'],
  undefined
>['content']['application/json']
export type RecalculateEntityResponse = Exclude<
  operations['entity-recalculate-single']['responses']['200'],
  undefined
>['content']['application/json']

/**
 * Recalculate the tool values for a list of fields on the given entity.
 *
 * @param params
 * @param params.propertyIds - property IDs of all fields to recalculate.
 * @param params.force - `false` will do nothing if the hashes match. `true` will force a recalculation even if the hashes match.
 */
export const recalculateEntity = ({
  entityId,
  projectId,
  propertyIds,
  workspaceId,
  force = false,
}: {
  workspaceId: string
  projectId: string
  entityId: string
  propertyIds: string[]
  /**
   * - `force: false` will do nothing if the hashes match.
   * - `force: true` will force a recalculation even if the hashes match.
   */
  force?: boolean
}) =>
  post<RecalculateEntityRequest, RecalculateEntityResponse, RecalculateEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/recalculate`,
    {
      property_ids: propertyIds,
      force,
    },
  )
