import { PropertyType } from '@/backend/types'
import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { isMac } from '@/shared/utils'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { captureException } from '@sentry/vue'
import { onKeyStroke } from '@vueuse/core'
import { onBeforeUnmount, onMounted, type Ref } from 'vue'
import { LIMIT_EXCEEDED_CODE, useLimitedAction } from '../Billing/useLimitedAction'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useDeserializeTextToField } from './useDeserializeTextToField'
import { useListEntities } from './useListEntities'
import { serializeEntity, useProject, type Entity } from './useProject'
import { useSelectedFields } from './useSelectedFields'
import { useTable, type TableRange } from './useTable'

export const undeserializableFieldTypes = ['collection', 'file', 'file_collection', 'pdf'] as const

/**
 * Powers feature that makes it possible to copy and paste data from/to the table.
 * It will try to paste the data into the currently selected cell and all cells to the right and below.
 * If there is not enough space below, add new rows as needed.
 *
 * Tightly coupled to ProjectTable. If we want to use it in a task or a view table later,
 * depending on how those are implemented, we will need to generalise.
 */
export const useTableClipboard = (selectedRange: Ref<TableRange | null>) => {
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const tableStore = useTable()

  const { addEntity } = useLimitedAction()
  const listEntities = useListEntities()
  const deserialize = useDeserializeTextToField()
  const updateFieldValue = useSetFieldValue()
  /**
   * Updates the value of the selected cells with the given data.
   */
  async function updateCells(cells: string[][]) {
    assertIsNotNullOrUndefined(
      projectStore.activeView?.view.propertyIds,
      'Property IDs not found when pasting',
    )
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No project ID')
    const hasMultipleRows = cells.length > 1
    const hasMultipleCols = cells[0].length > 1
    const shouldPreviewBeforeSaving = hasMultipleRows || hasMultipleCols
    projectStore.resetDrafts()

    /** Row index of the start of the current selected range */
    const startRowIndex = selectedRange.value?.start.rowIndex ?? null
    /** Column index of the start of the current selected range */
    const startColIndex = selectedRange.value?.start.colIndex ?? null
    if (startRowIndex === null || startColIndex === null) {
      return
    }

    const endRowIndex = startRowIndex + cells.length - 1
    let hasPastedData = false
    if (shouldPreviewBeforeSaving) {
      const maxColIndex = projectStore.activeView.view.propertyIds.length - 1

      tableStore.previewRange = {
        start: { rowIndex: startRowIndex, colIndex: startColIndex },
        end: {
          rowIndex: endRowIndex,
          colIndex: Math.min(maxColIndex, startColIndex + cells[0].length - 1),
        },
      }
      tableStore.clearFocusedAndSelected()
    }

    const numberOfEntities = projectStore.activeView.entities
      ? projectStore.activeView.entities.length
      : 0

    for (let clipboardRowIndex = 0; clipboardRowIndex < cells.length; clipboardRowIndex++) {
      assertIsNotNullOrUndefined(projectStore.activeView.entities, 'Entities not found')
      const entityRowIndex = startRowIndex + clipboardRowIndex
      const pastedRow = cells[clipboardRowIndex]

      let entity: Entity | undefined
      const shouldAddEntity = entityRowIndex >= numberOfEntities
      if (shouldAddEntity) {
        const createEntityRes = await addEntity({
          workspaceId: workspacesStore.currentWorkspace.id,
          projectId: projectStore.projectId,
        })

        if (!createEntityRes.ok) {
          assertIsNotNullOrUndefined(tableStore.previewRange, 'No preview range')
          tableStore.previewRange.end.rowIndex = entityRowIndex - 1

          if (createEntityRes.error.code !== LIMIT_EXCEEDED_CODE) {
            captureException(new Error('Failed to create entity when pasting data'), {
              data: createEntityRes.error,
            })
            toast.warning('Unable to paste all rows.')
          }

          break
        }

        projectStore.upsertEntity(serializeEntity(createEntityRes.data))
      } else {
        entity = projectStore.activeView.entities[entityRowIndex]
        if (!entity) {
          // Because of virtualisation, we might not have loaded this entity yet
          const listEntityRes = await listEntities({
            workspaceId: workspacesStore.currentWorkspace.id,
            projectId: projectStore.projectId,
            start: entityRowIndex,
            end: entityRowIndex + 10,
          })
          if (!listEntityRes.ok) {
            assertIsNotNullOrUndefined(tableStore.previewRange, 'No preview range')
            tableStore.previewRange.end.rowIndex = entityRowIndex - 1
            toast.warning('Unable to paste all rows.')
            break
          }

          const listedEntities = listEntityRes.data.data.map(serializeEntity)
          projectStore.spliceEntities(
            listedEntities,
            null,
            entityRowIndex,
            projectStore.activeView.id,
          )
        }
      }

      entity = projectStore.activeView.entities[entityRowIndex]
      if (!entity) {
        break
      }

      for (let cellIndex = 0; cellIndex < pastedRow.length; cellIndex++) {
        if (!projectStore.activeView) {
          throw new Error("Can't paste data without an active view")
        }

        const pastedCellContent = pastedRow[cellIndex]
        const propertyId = projectStore.activeView.view.propertyIds?.at(startColIndex + cellIndex)
        if (!propertyId) {
          // This has probably happened because the cell is out of range
          continue
        }

        const property = projectStore.properties.find((p) => p.id === propertyId)
        if (!property) {
          throw new Error('Property not found when pasting data.')
        }

        const field = entity.fields.get(property.id)
        if (!field) {
          throw new Error('Field not found when pasting data.')
        }

        // It's annoying, but we can't use `.includes()` as
        // TS complains either here or below, depending
        // on how you type the exclude array.
        if (
          field.type === PropertyType.collection ||
          field.type === PropertyType.file ||
          field.type === PropertyType.file_collection ||
          field.type === PropertyType.pdf ||
          field.type === PropertyType.reference
        ) {
          continue
        }

        const draftValue = deserialize({
          fieldType: field.type,
          field,
          text: pastedCellContent,
        })
        const hasDraftValue = Array.isArray(draftValue) || typeof draftValue === 'string'
        if (hasDraftValue) {
          hasPastedData = true
        }

        if (hasDraftValue && shouldPreviewBeforeSaving) {
          const entityDraft = projectStore.draftFields[field.entityId] ?? {}
          entityDraft[field.propertyId] = draftValue

          projectStore.draftFields[entity.id] = entityDraft
        } else if (hasDraftValue) {
          assertIsNotNullOrUndefined(projectStore.projectId, 'Project ID not found')
          assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'Workspace ID not found')

          await updateFieldValue({
            fields: {
              [property.id]: { field, newValue: draftValue },
            },
            entityId: entity.id,
            projectId: projectStore.projectId,
            workspaceId: workspacesStore.currentWorkspace.id,
          })
        }
      }
    }

    if (!hasPastedData) {
      tableStore.previewRange = null
      tableStore.selectCell(startRowIndex, startColIndex)
      toast.warning('Unable to write clipboard data for the selected range.')
    }
  }

  const pasteListener = (event: ClipboardEvent) => {
    const text = event.clipboardData?.getData('text/plain')
    if (!text) {
      // the clipboard is empty - there is nothing to do
      return
    }

    // Parse the clipboard data into a table format - newlines separate rows, tabs separate columns
    const cells = text.split('\n').map((line) => line.split('\t'))

    if (cells && cells.length > 0 && selectedRange.value) {
      updateCells(cells)
      event.preventDefault()
    }
  }

  const { onCopyToSpreadsheet, onCopyToCsv } = useSelectedFields()

  onKeyStroke('c', (event) => {
    const modifier = isMac() ? event.metaKey : event.ctrlKey
    if (!event.shiftKey || !modifier) {
      return
    }

    onCopyToCsv()
  })

  onMounted(() => {
    document.addEventListener('copy', onCopyToSpreadsheet)
    document.addEventListener('paste', pasteListener)
  })
  onBeforeUnmount(() => {
    document.removeEventListener('copy', onCopyToSpreadsheet)
    document.removeEventListener('paste', pasteListener)
  })
}
