import { get } from './client'
import type { BackendMeta } from './types'

export type ListToolKeys = BackendMeta<'/api/workspaces/{workspace_id}/tool_keys', 'get'>

export const listToolKeys = (workspaceId: string) =>
  get<undefined, ListToolKeys['successResponse'], ListToolKeys['path']>(
    `/api/workspaces/${workspaceId}/tool_keys`,
    undefined,
  )
