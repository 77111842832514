import { getProject } from '@/backend/getProject'
import type { PartialRecord } from '@/shared/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { serializeProject, type Project } from '../Projects/useProjects'
import { serializeProperty, type Property } from './useProject'

type RelatedProjectEntry = {
  project: Project
  properties: Property[]
}

/**
 * This store is used to load and store all projects that are related (via reference properties)
 * to the current project.
 */
export const useRelatedProjectsStore = defineStore('relatedProjects', () => {
  /** Maps a reference property ID to its related project */
  const relatedProjects = ref<PartialRecord<Property['id'], RelatedProjectEntry>>({})

  const loadRelatedProject = async ({
    property,
    workspaceId,
  }: {
    workspaceId: string
    property: Property<'reference'>
  }) => {
    const res = await getProject(workspaceId, property.config.projectId)
    if (!res.ok) {
      throw new Error('Failed to load related project')
    }

    const mainView = res.data.views[0]
    const firstPropertyId = mainView.property_ids[0]
    if (!firstPropertyId) {
      return
    }

    relatedProjects.value[property.id] = {
      project: serializeProject(res.data),
      properties: res.data.properties.map(serializeProperty),
    }

    /**
     * Some weird hackiness follows. The backend will always return a cover image
     * URL, regardless of whether the project actually has a cover image or not.
     *
     * So here we try to load the cover image, and if it doesn't exist, we set a flag
     * on the project to indicate that the cover image couldn't be loaded and we
     * should show a fallback image instead.
     *
     * Do this here instead of in the UI so that the fallback image is ready right
     * away.
     */
    const coverUrl = res.data.cover_image_urls.low
    fetch(coverUrl).then((res) => {
      if (!res.ok) {
        const project = relatedProjects.value[property.id]?.project
        assertIsNotNullOrUndefined(project)
        project.coverImageDownloadError = true
      }
    })
  }

  const loadRelatedProjects = async ({
    referenceProperties,
    workspaceId,
  }: {
    workspaceId: string
    referenceProperties: Property<'reference'>[]
  }) => {
    relatedProjects.value = {}
    await Promise.all(
      referenceProperties.map(async (property) => loadRelatedProject({ property, workspaceId })),
    )
  }

  /** Gets the ID of the property used to name entities for this project. */
  const getNamePropertyId = (referencePropertyId: string) => {
    const relatedProject = relatedProjects.value[referencePropertyId]?.project
    if (!relatedProject) {
      throw new Error('No related project found')
    }

    const mainView = (relatedProject.views ?? [])[0]
    if (!mainView) {
      throw new Error('No main view found')
    }

    const firstPropertyId = mainView.propertyIds[0]
    if (!firstPropertyId) {
      throw new Error('No property found')
    }

    return firstPropertyId
  }

  /** Gets the property used to name entities for this project. */
  const getNameProperty = (referencePropertyId: string) => {
    const properties = relatedProjects.value[referencePropertyId]?.properties
    assertIsNotNullOrUndefined(properties, 'No related project found')

    const property = properties.find((p) => p.id === getNamePropertyId(referencePropertyId))
    assertIsNotNullOrUndefined(property, 'No property found')

    return property
  }

  /** Gets all properties for the reference property's related project. */
  const getPropertiesForReference = (referencePropertyId: string): Property[] => {
    const entry = relatedProjects.value[referencePropertyId]
    return entry?.properties ?? []
  }

  /** Gets the related project for a reference property */
  const getRelatedProject = (referencePropertyId: string): Project => {
    assertIsNotNullOrUndefined(
      relatedProjects.value[referencePropertyId],
      'No related project found',
    )
    return relatedProjects.value[referencePropertyId].project
  }

  return {
    relatedProjects,
    loadRelatedProjects,
    loadRelatedProject,
    getNameProperty,
    getNamePropertyId,
    getPropertiesForReference,
    getRelatedProject,
  }
})
