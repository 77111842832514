import type { PartialRecord } from '@/shared/types'
import { computed, type Ref } from 'vue'
import { serializePreview, type FieldPreview } from './types/fieldPreview'
import type { Entity, Property, ReferenceField } from './useProject'

type PreviewMap = Map<Property['id'], FieldPreview>
type EntityPreviews = PartialRecord<Entity['id'], PreviewMap>

/**
 * Preview fields are returned from the backend with no property type, but we need the property
 * type to serialize the field to a string (as different property types have different rules
 * for serialization).
 *
 * This composable takes a field's subproject preview and the properties of the project, and
 * returns an object that contains more useful information about the field previews.
 *
 * Ideally the backend would just return a property type with the field preview, but for now
 * we have to do this on the frontend.
 */
export const useFieldPreviews = (
  preview: Ref<ReferenceField['subprojectPreview']>,
  properties: Ref<Property[]>,
): Ref<EntityPreviews> => {
  const previewsWithTypes = computed(() => {
    if (!preview.value) {
      return {}
    }

    const entityPreview = preview.value.entity_previews.reduce<EntityPreviews>(
      (acc, entityPreview) => {
        const map: PreviewMap = new Map()
        entityPreview.field_previews.forEach((fieldPreview) => {
          const matchingProperty = properties.value.find(
            (property) => property.id === fieldPreview.property_id,
          )
          if (!matchingProperty) {
            return
          }

          map.set(
            fieldPreview.property_id,
            serializePreview(fieldPreview, matchingProperty.type, entityPreview.entity_id),
          )
        }, {})

        return {
          ...acc,
          [entityPreview.entity_id]: map,
        }
      },
      {},
    )

    return entityPreview
  })

  return previewsWithTypes
}
