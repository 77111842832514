<script setup lang="ts">
import { type RouteLocationRaw, RouterLink, useRouter } from 'vue-router'

defineProps<{
  to?: RouteLocationRaw
}>()

const router = useRouter()
</script>

<template>
  <RouterLink
    exact-active-class="pointer-events-none !text-text"
    :to="to || router.currentRoute.value"
    class="shrink-0 grow rounded-corner-6 bg-background-transparent px-1.5 py-1 text-sm-12px-default text-text-subtlest -outline-offset-1 transition-colors data-[state='open']:bg-background-transparent-hovered hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
  >
    <slot />
  </RouterLink>
</template>
