import type { operations } from '@/api'
import { post } from './client'

export type StartAskGoFileUploadPath =
  `/api/workspaces/${string}/ask_go/${string}/start_file_upload`

export type StartAskGoFileUploadRequest = Exclude<
  operations['ask-go-start-file-upload']['requestBody'],
  undefined
>['content']['application/json']

export type StartAskGoFileUploadResponse =
  operations['ask-go-start-file-upload']['responses']['200']['content']['application/json']

/**
 * Fires a network request to he backend to provide a file url to upload a file to
 * The backend action will generate a presigned url for the file and return it,
 * and also save it onto the manual_value of the given field.
 */
export const startAskGoFileUpload = (workspaceId: string, sessionId: string, filename: string) =>
  post<StartAskGoFileUploadRequest, StartAskGoFileUploadResponse, StartAskGoFileUploadPath>(
    `/api/workspaces/${workspaceId}/ask_go/${sessionId}/start_file_upload`,
    { filename },
  )
