<script setup lang="ts">
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import ErrorPage from '../App/ErrorPage.vue'

const props = defineProps<{
  inviteToken: string
  workspaceId: string
  projectId?: string
}>()

const router = useRouter()
const { captureAnalyticsEvent } = useAnalytics()
const userStore = useUser()

const isError = computed(() => userStore.invitationStatus === 'error')

onMounted(async () => {
  await userStore.validateUserInvite(props.workspaceId, props.inviteToken)
  userStore.invitationProjectId = props.projectId ?? null

  if (userStore.invitationStatus === 'valid') {
    captureAnalyticsEvent(ANALYTICS_EVENT.FIRST_LOGIN)

    if (props.projectId) {
      router.push({
        name: 'WorkspaceProject',
        params: { workspaceId: props.workspaceId, projectId: props.projectId },
        query: {},
      })
    } else {
      router.push({
        name: 'WorkspaceProjects',
        params: { workspaceId: props.workspaceId },
        query: {},
      })
    }
  }
})
</script>

<template>
  <ErrorPage
    :title="isError ? 'Error' : 'Accepting invite...'"
    :message="isError ? userStore.invitationErrorMessage : ''"
    :show-icon="isError"
    :show-button="isError"
  />
</template>
