import { computed, type Ref } from 'vue'
import { useTable, type CellLocation } from './useTable'

/**
 * Composable function that provides state on the cell's position within
 * the table's preview range.
 */
export const useTableCellPreview = ({
  cell,
  hasDraftValue,
}: {
  cell: Ref<CellLocation>
  hasDraftValue: Ref<boolean>
}) => {
  const tableStore = useTable()

  const previewState = computed(() => {
    if (!tableStore.previewRange) {
      return null
    }

    if (
      tableStore.isCellInPreviewRange({
        rowIndex: cell.value.rowIndex,
        colIndex: cell.value.colIndex,
      })
    ) {
      return hasDraftValue.value ? 'inside:draft' : 'inside:unchanged'
    }

    return 'outside'
  })

  type Edge = 'top' | 'right' | 'bottom' | 'left'
  /**
   * If there is a preview range, this computed property will be an array
   * of edges that the cell borders. If there is no preview range then this
   * property will be null.
   */
  const previewEdges = computed<Edge[] | null>(() => {
    if (
      !tableStore.previewRange ||
      !tableStore.isCellInPreviewRange({
        colIndex: cell.value.colIndex,
        rowIndex: cell.value.rowIndex,
      })
    ) {
      return null
    }

    const edges: Edge[] = []
    if (cell.value.rowIndex === tableStore.previewRange.start.rowIndex) {
      edges.push('top')
    }
    if (cell.value.rowIndex === tableStore.previewRange.end.rowIndex) {
      edges.push('bottom')
    }
    if (cell.value.colIndex === tableStore.previewRange.start.colIndex) {
      edges.push('left')
    }
    if (cell.value.colIndex === tableStore.previewRange.end.colIndex) {
      edges.push('right')
    }

    return edges
  })
  const shouldShowPreviewPopover = computed(
    () =>
      previewEdges.value &&
      previewEdges.value.includes('top') &&
      previewEdges.value.includes('left'),
  )

  return {
    previewState,
    previewEdges,
    shouldShowPreviewPopover,
  }
}
