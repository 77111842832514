import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import type { WorkspaceResponse } from '@/backend/types'

export type Workspace = {
  id: WorkspaceResponse['id']
  name: WorkspaceResponse['name']
  iconUrl?: WorkspaceResponse['icon_url']
  role?: WorkspaceResponse['role']
  createdAt: WorkspaceResponse['created_at']
  iconDownloadError?: boolean
  iconUploading?: boolean
}

export const serializeWorkspace = (workspace: WorkspaceResponse): Workspace => ({
  id: workspace.id,
  name: workspace.name,
  iconUrl: workspace.icon_url,
  role: workspace.role,
  createdAt: workspace.created_at,
})

export const useWorkspaces = defineStore('workspaces', () => {
  const workspaces = ref<Workspace[]>([])
  const lastUsedWorkspaceId = useStorage<string | null>('lastUsedWorkspaceId', null)

  const saveLastUsedWorkspaceId = (id: string | null) => {
    lastUsedWorkspaceId.value = id
  }

  const setWorkspaces = (newWorkspaces: Workspace[]) => {
    workspaces.value = newWorkspaces
  }

  const currentWorkspace = ref<Workspace>()

  const setCurrentWorkspace = (workspace: Workspace | undefined) => {
    currentWorkspace.value = workspace
    saveLastUsedWorkspaceId(workspace?.id ?? null)
  }

  const lastUsedWorkspace = computed(() =>
    workspaces.value.find((w) => w.id === lastUsedWorkspaceId.value),
  )

  const updateWorkspace = (workspace: Workspace) => {
    const index = workspaces.value.findIndex((w) => w.id === workspace.id)
    if (index !== -1) {
      workspaces.value[index] = workspace
    }
  }

  const addWorkspace = (workspace: Workspace) => workspaces.value.push(workspace)

  const setIconDownloadError = (id: string, value: boolean) => {
    const workspace = workspaces.value.find((w) => w.id === id)
    if (workspace) {
      workspace.iconDownloadError = value
    }
  }

  const setIconUploading = (id: string, value: boolean) => {
    const workspace = workspaces.value.find((w) => w.id === id)
    if (workspace) {
      workspace.iconUploading = value
    }
  }

  return {
    addWorkspace,
    currentWorkspace,
    lastUsedWorkspace,
    setCurrentWorkspace,
    updateWorkspace,
    workspaces,
    setWorkspaces,
    setIconDownloadError,
    setIconUploading,
  }
})
